import React from 'react';

const ScannerAnimation = ({ highlightedField }) => {
  return (
    <div className="relative w-full" style={{ paddingBottom: '66.67%' }}>
      <svg
        className="absolute top-0 left-0 w-full h-full"
        viewBox="0 0 380 240"
        preserveAspectRatio="xMidYMid meet"
      >
        <defs>
          <filter id="paper" x="-20%" y="-20%" width="140%" height="140%">
            <feTurbulence type="fractalNoise" baseFrequency="0.04" numOctaves="5" result="noise"/>
            <feDiffuseLighting in="noise" lightingColor="#fff" surfaceScale="2" result="diffLight">
              <feDistantLight azimuth="45" elevation="60"/>
            </feDiffuseLighting>
            <feComposite in="SourceGraphic" in2="diffLight" operator="arithmetic" k1="1" k2="0" k3="0.5" k4="0"/>
          </filter>
          <clipPath id="boarding-pass-shape">
            <path d="M10,0 h360 a20,20 0 0 1 20,20 v200 a20,20 0 0 1 -20,20 h-360 a20,20 0 0 1 -20,-20 v-200 a20,20 0 0 1 20,-20 z" />
          </clipPath>
          <linearGradient id="scanLine" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#3B82F6" stopOpacity="0.1" />
            <stop offset="50%" stopColor="#3B82F6" stopOpacity="0.3" />
            <stop offset="100%" stopColor="#3B82F6" stopOpacity="0.1" />
          </linearGradient>
          <filter id="glow">
            <feGaussianBlur stdDeviation="2.5" result="coloredBlur"/>
            <feMerge>
              <feMergeNode in="coloredBlur"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
        </defs>
        
        {/* Boarding Pass Background with 3D effect */}
        <g transform="skew(-5, 0)">
          <g clipPath="url(#boarding-pass-shape)">
            <rect x="0" y="0" width="380" height="240" fill="#FFFFFF" filter="url(#paper)" />
            <line x1="0" y1="80" x2="380" y2="80" stroke="#E5E7EB" strokeWidth="1" strokeDasharray="2,2" />
          </g>
        </g>
        
        {/* Airline Logo */}
        <g transform="skew(-5, 0)">
          <circle cx="40" cy="40" r="25" fill="#3B82F6" />
          <text x="75" y="48" fontSize="22" fill="#3B82F6" fontWeight="bold">BOARDING PASS</text>
        </g>
        
        {/* Flight Info */}
        <g transform="skew(-5, 0)">
          <g className={highlightedField === 'flightNumber' ? 'highlight' : ''}>
            <text x="30" y="100" fontSize="16" fill="#4B5563" fontWeight="bold">FLIGHT</text>
            <text x="30" y="125" fontSize="22" fill="#111827">AB 1234</text>
          </g>
          
          <g className={highlightedField === 'date' ? 'highlight' : ''}>
            <text x="120" y="100" fontSize="16" fill="#4B5563" fontWeight="bold">DATE</text>
            <text x="120" y="125" fontSize="22" fill="#111827">11 OCT</text>
          </g>
          
          <text x="210" y="100" fontSize="16" fill="#4B5563" fontWeight="bold">GATE</text>
          <text x="210" y="125" fontSize="22" fill="#111827">B22</text>
          
          <g className={highlightedField === 'seat' ? 'highlight' : ''}>
            <text x="280" y="100" fontSize="16" fill="#4B5563" fontWeight="bold">SEAT</text>
            <text x="280" y="125" fontSize="22" fill="#111827">14A</text>
          </g>
        </g>
        
        {/* Passenger Info */}
        <g transform="skew(-5, 0)">
          <g className={highlightedField === 'passengerName' ? 'highlight' : ''}>
            <text x="30" y="160" fontSize="16" fill="#4B5563" fontWeight="bold">PASSENGER NAME</text>
            <text x="30" y="185" fontSize="22" fill="#111827">FILAN FISTEKU</text>
          </g>
        </g>
        
        {/* Barcode */}
        <g transform="skew(-5, 0)">
          <g className={highlightedField === 'barcode' ? 'highlight' : ''}>
            <rect x="30" y="200" width="320" height="30" fill="#111827" />
            {[...Array(50)].map((_, i) => (
              <rect key={i} x={30 + i * 6} y="205" width={Math.random() * 3 + 1} height="20" fill="#FFFFFF" />
            ))}
          </g>
        </g>
        
        {/* Scanning Effect */}
        <g clipPath="url(#boarding-pass-shape)" transform="skew(-5, 0)">
          <rect x="0" y="0" width="380" height="240" fill="url(#scanLine)" opacity="0.7">
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="translate"
              from="0 -240"
              to="0 240"
              dur="3s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        
        {/* Scanning Frame */}
        <rect x="0" y="0" width="380" height="240" fill="none" stroke="#3B82F6" strokeWidth="4" rx="20" ry="20" transform="skew(-5, 0)">
          <animate
            attributeName="stroke-dashoffset"
            from="1240"
            to="0"
            dur="3s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-dasharray"
            values="0 1240;1240 0;0 1240"
            dur="3s"
            repeatCount="indefinite"
          />
        </rect>
        
        {/* Corner Highlights */}
        {['10,10', '370,10', '10,230', '370,230'].map((coords, index) => (
          <circle key={index} cx={coords.split(',')[0]} cy={coords.split(',')[1]} r="5" fill="#3B82F6" opacity="0.8" filter="url(#glow)" transform="skew(-5, 0)">
            <animate attributeName="opacity" values="0.8;0.2;0.8" dur="2s" repeatCount="indefinite" />
          </circle>
        ))}
        
        {/* Data Extraction Visualization */}
        {highlightedField && (
          <g transform="skew(-5, 0)">
            <line x1="390" y1="120" x2="420" y2="120" stroke="#3B82F6" strokeWidth="2">
              <animate attributeName="x2" from="390" to="420" dur="0.5s" fill="freeze" />
            </line>
            <circle cx="425" cy="120" r="5" fill="#3B82F6">
              <animate attributeName="r" from="0" to="5" dur="0.5s" fill="freeze" />
            </circle>
            <text x="435" y="125" fontSize="14" fill="#3B82F6" opacity="0">
              {highlightedField === 'flightNumber' && 'Extracting flight number...'}
              {highlightedField === 'date' && 'Verifying date...'}
              {highlightedField === 'passengerName' && 'Reading passenger name...'}
              {highlightedField === 'seat' && 'Confirming seat...'}
              {highlightedField === 'barcode' && 'Decoding barcode...'}
              <animate attributeName="opacity" from="0" to="1" dur="0.5s" fill="freeze" />
            </text>
          </g>
        )}
      </svg>
    </div>
  );
};

export default ScannerAnimation;