import React from 'react';
import { cn } from './utils';

const Alert = ({ children, variant = 'default', className, ...props }) => {
  const variantClasses = {
    default: 'bg-blue-100 text-blue-800 border-blue-500',
    destructive: 'bg-red-100 text-red-800 border-red-500',
    warning: 'bg-yellow-100 text-yellow-800 border-yellow-500',
    success: 'bg-green-100 text-green-800 border-green-500',
  };

  return (
    <div className={cn('rounded-lg p-4 border-l-4', variantClasses[variant], className)} role="alert" {...props}>
      {children}
    </div>
  );
};

Alert.Title = ({ children, className, ...props }) => (
  <h5 className={cn('font-medium mb-1', className)} {...props}>{children}</h5>
);

Alert.Description = ({ children, className, ...props }) => (
  <div className={cn('text-sm', className)} {...props}>{children}</div>
);

export default Alert;