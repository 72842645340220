import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ScrollReveal from 'scrollreveal';
import 'animate.css';

const LiveChat = () => {
  const [activeTab, setActiveTab] = useState('howToUse');
  const [openQuestion, setOpenQuestion] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    ScrollReveal().reveal('.container > div', { 
      delay: 200,
      distance: '50px',
      origin: 'bottom',
      duration: 1000,
      easing: 'cubic-bezier(0.5, 0, 0, 1)',
      interval: 150
    });
  }, []);

  return (
    <div className="live-chat">
      <section className="hero relative bg-gradient-to-r from-blue-600 to-indigo-800 text-white overflow-hidden">
        <div className="absolute inset-0">
          <img src="/assets/images/live-chat-background.jpg" alt="Live Chat" className="w-full h-full object-cover opacity-20" />
          <div className="absolute inset-0 bg-blue-600 mix-blend-multiply"></div>
        </div>

        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
            Live Chat me Avioza
          </h1>
          <p className="mt-6 max-w-3xl text-xl text-blue-100">
            Merrni ndihmë të menjëhershme nga ekspertët tanë për çdo pyetje rreth kompensimit të fluturimeve.
          </p>
          <div className="mt-10 flex justify-center">
            <a href="#start-chat" className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-blue-700 bg-white hover:bg-blue-50">
              Fillo një Chat
              <svg className="ml-2 -mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </a>
          </div>
        </div>

        <div className="absolute bottom-0 left-0 right-0">
          <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax">
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </div>
      </section>

      <section className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center mb-12">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Shërbimi Ynë i Live Chat</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Ndihmë e menjëhershme për çdo nevojë
            </p>
          </div>

          <div className="mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              {[
                {
                  title: "Mbështetje 24/7",
                  description: "Ekspertët tanë janë të disponueshëm 24 orë në ditë, 7 ditë në javë për t'ju ndihmuar me çdo pyetje që keni.",
                  icon: (
                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  )
                },
                {
                  title: "Përgjigje të Shpejta",
                  description: "Koha mesatare e pritjes është më pak se 2 minuta. Marrim përgjegjësi për t'ju ofruar ndihmë sa më shpejt të jetë e mundur.",
                  icon: (
                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                    </svg>
                  )
                },
                {
                  title: "Ekspertizë e Specializuar",
                  description: "Agjentët tanë janë të trajnuar në të gjitha aspektet e kompensimit të fluturimeve dhe do t'ju ofrojnë këshilla të sakta.",
                  icon: (
                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                    </svg>
                  )
                },
                {
                  title: "Zgjidhje Efikase",
                  description: "Synojmë të zgjidhim shumicën e pyetjeve tuaja gjatë sesionit të parë të chat-it, duke kursyer kohën tuaj të çmuar.",
                  icon: (
                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                  )
                }
              ].map((item, index) => (
                <div key={index} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                      {item.icon}
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.title}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    {item.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </section>

      <section className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center mb-12">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Informacione për Live Chat</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Çfarë duhet të dini për shërbimin tonë të Live Chat
            </p>
          </div>

          <div className="flex justify-center mb-8">
            {['howToUse', 'availability', 'privacy'].map((tab, index) => (
              <button
                key={index}
                onClick={() => setActiveTab(tab)}
                className={`px-4 py-2 ${index === 0 ? 'rounded-l-full' : index === 2 ? 'rounded-r-full' : ''} focus:outline-none transition duration-300 ${
                  activeTab === tab ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'
                }`}
              >
                {tab === 'howToUse' ? 'Si të Përdorni' : tab === 'availability' ? 'Disponueshmëria' : 'Privatësia'}
              </button>
            ))}
          </div>

          <div className="bg-white rounded-lg shadow-lg p-6">
            {activeTab === 'howToUse' && (
              <div>
                <h3 className="text-2xl font-bold mb-4">Si të Përdorni Live Chat</h3>
                <ol className="list-decimal list-inside space-y-4">
                  <li>Klikoni butonin "Fillo një Chat" në fund të faqes.</li>
                  <li>Plotësoni formularin e shkurtër me emrin dhe adresën tuaj të email-it.</li>
                  <li>Zgjidhni temën e pyetjes suaj nga lista e dhënë.</li>
                  <li>Shkruani pyetjen tuaj në kutinë e mesazhit.</li>
                  <li>Klikoni "Dërgo" dhe prisni që një agjent t'ju përgjigjet.</li>
                </ol>
              </div>
            )}

            {activeTab === 'availability' && (
              <div>
                <h3 className="text-2xl font-bold mb-4">Disponueshmëria e Live Chat</h3>
                <p className="mb-4">Shërbimi ynë i Live Chat është i disponueshëm 24 orë në ditë, 7 ditë në javë. Megjithatë, kohët e pritjes mund të ndryshojnë në varësi të volumit të kërkesave.</p>
                <p>Kohët mesatare të pritjes:</p>
                <ul className="list-disc list-inside">
                  <li>Gjatë orëve të punës (9:00 - 17:00): 1-2 minuta</li>
                  <li>Jashtë orëve të punës: 3-5 minuta</li>
                  <li>Gjatë periudhave të ngarkuara: deri në 10 minuta</li>
                </ul>
              </div>
            )}

            {activeTab === 'privacy' && (
              <div>
                <h3 className="text-2xl font-bold mb-4">Privatësia në Live Chat</h3>
                <p className="mb-4">Ne vlerësojmë privatësinë tuaj dhe marrim masa për të mbrojtur informacionin tuaj personal gjatë sesioneve të Live Chat:</p>
                <ul className="list-disc list-inside">
                  <li>Të gjitha bisedat janë të enkriptuara.</li>
                  <li>Ne nuk ruajmë informacione personale të panevojshme nga bisedat.</li>
                  <li>Transkriptet e bisedave ruhen për një periudhë të kufizuar për qëllime të përmirësimit të shërbimit.</li>
                  <li>Ju mund të kërkoni fshirjen e transkriptit tuaj në çdo kohë.</li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </section>

      <section id="start-chat" className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center mb-12">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Fillo një Bisedë</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Jeni gati të bisedoni me ne?
            </p>
          </div>

          <div className="mt-10 max-w-lg mx-auto">
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="bg-blue-600 px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-white">
                  Live Chat me Avioza
                </h3>
              </div>
              <div className="px-4 py-5 sm:p-6">
                <form action="#" method="POST">
                  <div className="mb-4">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">Emri</label>
                    <input type="text" name="name" id="name" className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                    <input type="email" name="email" id="email" className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="topic" className="block text-sm font-medium text-gray-700">Tema</label>
                    <select id="topic" name="topic" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                      <option>Pyetje të Përgjithshme</option>
                      <option>Statusi i Kërkesës</option>
                      <option>Probleme Teknike</option>
                      <option>Tjetër</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="message" className="block text-sm font-medium text-gray-700">Mesazhi</label>
                    <textarea id="message" name="message" rows="4" className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"></textarea>
                  </div>
                  <div className="mt-5">
                    <button type="submit" className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                      Fillo Chatin
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center mb-12">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Pyetjet e Shpeshta për Live Chat</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Përgjigje për pyetjet tuaja më të zakonshme
            </p>
          </div>

          <div className="mt-10">
            <div className="space-y-6">
              {[
                {
                  question: "A është Live Chat falas?",
                  answer: "Po, shërbimi ynë i Live Chat është plotësisht falas për të gjithë klientët tanë. Ne besojmë në ofrimin e mbështetjes së shpejtë dhe efikase pa kosto shtesë."
                },
                {
                  question: "Sa kohë duhet të pres për një përgjigje?",
                  answer: "Zakonisht, koha mesatare e pritjes është më pak se 2 minuta. Megjithatë, kjo mund të ndryshojë në varësi të volumit të kërkesave. Gjatë periudhave të ngarkuara, koha maksimale e pritjes mund të shkojë deri në 10 minuta."
                }
              ].map((faq, index) => (
                <div key={index} className="bg-white rounded-xl shadow-lg overflow-hidden">
                  <button 
                    onClick={() => setOpenQuestion(openQuestion === index ? null : index)} 
                    className="flex justify-between items-center w-full px-4 py-5 sm:p-6 focus:outline-none"
                  >
                    <span className="text-lg font-semibold text-gray-900">{faq.question}</span>
                    <svg 
                      className={`h-6 w-6 text-blue-500 transform ${openQuestion === index ? 'rotate-180' : ''}`} 
                      fill="none" 
                      viewBox="0 0 24 24" 
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>
                  {openQuestion === index && (
                    <div className="px-4 pb-5 sm:px-6 sm:pb-6">
                      <p className="text-gray-700">{faq.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <style jsx>{`
        .waves {
          position: relative;
          width: 100%;
          height: 15vh;
          margin-bottom: -7px;
          min-height: 100px;
          max-height: 150px;
        }
        .parallax > use {
          animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
        }
        .parallax > use:nth-child(1) {
          animation-delay: -2s;
          animation-duration: 7s;
        }
        .parallax > use:nth-child(2) {
          animation-delay: -3s;
          animation-duration: 10s;
        }
        .parallax > use:nth-child(3) {
          animation-delay: -4s;
          animation-duration: 13s;
        }
        .parallax > use:nth-child(4) {
          animation-delay: -5s;
          animation-duration: 20s;
        }
        @keyframes move-forever {
          0% {
            transform: translate3d(-90px,0,0);
          }
          100% { 
            transform: translate3d(85px,0,0);
          }
        }
      `}</style>
    </div>
  );
};

export default LiveChat;