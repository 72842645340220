import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence, useViewportScroll, useTransform } from 'framer-motion';
import { Shield, Zap, Globe, Scale, Users, BookOpen, Award, Plane, ChevronDown, TrendingUp, Briefcase, Heart, CheckCircle, Facebook, Instagram, Twitter, Linkedin, ArrowRight, Star } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const ImpactCard = ({ icon: Icon, title, description }) => (
  <motion.div
    className="bg-white bg-opacity-10 p-6 rounded-lg text-center"
    whileHover={{ scale: 1.05, boxShadow: "0 10px 20px rgba(0,0,0,0.1)" }}
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    viewport={{ once: true }}
  >
    <Icon className="w-12 h-12 mx-auto mb-4 text-white" />
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-sm">{description}</p>
  </motion.div>
);

const RrethNesh = () => {
  const [activeSection, setActiveSection] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const { scrollYProgress } = useViewportScroll();
  const opacity = useTransform(scrollYProgress, [0, 0.2], [1, 0]);
  const videoRef = useRef(null);
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      setIsScrolling(window.scrollY > 100);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.5;
    }
    const timer = setTimeout(() => setShowVideo(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const sections = [
    { title: "Misioni", icon: <Shield className="w-12 h-12" /> },
    { title: "Vlerat", icon: <Zap className="w-12 h-12" /> },
    { title: "Shërbimet", icon: <Globe className="w-12 h-12" /> },
    { title: "Ndikimi", icon: <Scale className="w-12 h-12" /> },
  ];

  const socialIcons = [
    { name: 'Facebook', icon: Facebook, url: 'https://www.facebook.com/AviozaAlbania' },
    { name: 'Instagram', icon: Instagram, url: 'https://www.instagram.com/AviozaAlbania' },
    { name: 'Twitter', icon: Twitter, url: 'https://www.twitter.com/AviozaAlbania' },
    { name: 'LinkedIn', icon: Linkedin, url: 'https://www.linkedin.com/company/AviozaAlbania' },
  ];

  const impactAreas = [
    {
      icon: Users,
      title: "Fuqizimi i Pasagjerëve",
      description: "Edukojmë dhe mbështesim pasagjerët për të kuptuar dhe kërkuar të drejtat e tyre."
    },
    {
      icon: TrendingUp,
      title: "Përmirësimi i Industrisë",
      description: "Nxisim kompanitë ajrore të përmirësojnë shërbimet dhe praktikat e tyre."
    },
    {
      icon: Globe,
      title: "Ndikimi Global",
      description: "Kontribuojmë në standardet ndërkombëtare për të drejtat e pasagjerëve."
    },
    {
      icon: Award,
      title: "Cilësi e Lartë",
      description: "Vendosim standarde të reja për shërbimin ndaj klientit në industrinë ligjore."
    },
    {
      icon: Briefcase,
      title: "Mundësi Punësimi",
      description: "Krijojmë vende pune cilësore në fushën e teknologjisë dhe ligjit."
    },
    {
      icon: Heart,
      title: "Mbështetje Sociale",
      description: "Kontribuojmë në mirëqenien e komunitetit përmes iniciativave sociale."
    }
  ];

  const coreValues = [
    {
      icon: <Shield className="w-20 h-20 text-blue-600" />,
      title: "Mbrojtje e Pakompromis",
      description: "Ne jemi mburoja juaj kundër padrejtësive në industrinë e aviacionit, duke luftuar pa u lodhur për të drejtat tuaja si pasagjerë.",
      detail: "Çdo rast trajtohet me përkushtim maksimal, duke siguruar që zëri juaj të dëgjohet dhe të drejtat tuaja të respektohen plotësisht."
    },
    {
      icon: <Zap className="w-20 h-20 text-blue-600" />,
      title: "Inovacion i Vazhdueshëm",
      description: "Teknologjia jonë e avancuar transformon proceset komplekse ligjore në zgjidhje të thjeshta dhe efikase për ju.",
      detail: "Përdorim algoritme të sofistikuara dhe inteligjencë artificiale për të analizuar çdo aspekt të kërkesës suaj, duke maksimizuar shanset për sukses."
    },
    {
      icon: <Globe className="w-20 h-20 text-blue-600" />,
      title: "Ekspertizë Vendore",
      description: "Njohuria jonë e thellë e kontekstit shqiptar siguron trajtim të personalizuar për çdo kërkesë.",
      detail: "Ekipi ynë kombinon ekspertizën globale me njohuritë specifike të tregut shqiptar, duke ofruar zgjidhje që reflektojnë realitetin lokal dhe standardet ndërkombëtare."
    }
  ];

  const services = [
    {
      icon: <Scale className="w-16 h-16 text-blue-600" />,
      title: "Vlerësim i Shpejtë dhe i Saktë",
      description: "Analizë e menjëhershme e rastit tuaj, duke përdorur të dhëna në kohë reale dhe precedentë ligjorë për të vlerësuar shanset tuaja për kompensim."
    },
    {
      icon: <Users className="w-16 h-16 text-blue-600" />,
      title: "Menaxhim i Plotë i Çështjes",
      description: "Nga dorëzimi i kërkesës deri te marrja e kompensimit, ne menaxhojmë çdo detaj, duke ju mbajtur të informuar në çdo hap."
    },
    {
      icon: <BookOpen className="w-16 h-16 text-blue-600" />,
      title: "Edukim dhe Fuqizim i Pasagjerëve",
      description: "Ofrojmë resurse edukative, seminare dhe këshillim personal për t'ju bërë udhëtarë më të informuar dhe të fuqizuar."
    },
    {
      icon: <Award className="w-16 h-16 text-blue-600" />,
      title: "Përfaqësim Ligjor i Specializuar",
      description: "Nëse nevojitet, ofrojmë përfaqësim ligjor të specializuar për rastet komplekse, duke përdorur ekspertizën tonë për të maksimizuar kompensimin tuaj."
    }
  ];

  const stats = [
    { label: "Klientë të Kënaqur", value: "10,000+" },
    { label: "Kompensim Total", value: "€5M+" },
    { label: "Raste të Fituara", value: "95%" },
    { label: "Kohë Mesatare e Zgjidhjes", value: "14 Ditë" },
  ];

  const teamMembers = [
    { name: "Arta Krasniqi", role: "CEO & Themeluese", image: "https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80" },
    { name: "Dritan Hoxha", role: "Drejtor Ligjor", image: "https://images.unsplash.com/photo-1560250097-0b93528c311a?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80" },
    { name: "Elona Berisha", role: "Drejtore e Teknologjisë", image: "https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80" },
    { name: "Arben Gashi", role: "Drejtor i Shërbimit ndaj Klientit", image: "https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80" },
  ];

  const testimonials = [
    {
      name: "Ardi Myftari",
      role: "Udhëtar i Shpeshtë",
      content: "Falë Avioza, mora kompensimin që meritoja për fluturimin tim të vonuar. Procesi ishte i thjeshtë dhe transparent.",
      image: "https://images.unsplash.com/photo-1564564321837-a57b7070ac4f?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80"
    },
    {
      name: "Besa Hoxha",
      role: "Sipërmarrëse",
      content: "Avioza më ndihmoi të kuptoj të drejtat e mia si pasagjere. Tani udhëtoj me më shumë besim dhe siguri.",
      image: "https://images.unsplash.com/photo-1573497019236-17f8177b81e8?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80"
    },
    {
      name: "Genti Shkodra",
      role: "Student",
      content: "Shërbimi i Avioza-s është i paçmuar. Ata e bënë të lehtë marrjen e kompensimit për fluturimin tim të anuluar.",
      image: "https://images.unsplash.com/photo-1565464027194-7957a2295fb7?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80"
    }
  ];

  const compensationData = [
    { month: 'Jan', amount: 50000 },
    { month: 'Feb', amount: 75000 },
    { month: 'Mar', amount: 90000 },
    { month: 'Apr', amount: 100000 },
    { month: 'May', amount: 120000 },
    { month: 'Jun', amount: 150000 },
  ];

  const claimTypeData = [
    { name: 'Vonesa', value: 400 },
    { name: 'Anulime', value: 300 },
    { name: 'Mbingarkesa', value: 200 },
    { name: 'Të Tjera', value: 100 },
  ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  return (
    <div className="bg-white overflow-hidden">
      <motion.section 
        className="h-screen flex items-center justify-center bg-black text-white relative overflow-hidden"
        style={{ opacity }}
      >
        <motion.div 
          className="absolute inset-0 z-0"
          initial={{ scale: 1.2, rotate: -5 }}
          animate={{ scale: 1, rotate: 0 }}
          transition={{ duration: 20, repeat: Infinity, repeatType: "reverse" }}
        >
         <AnimatePresence>
           {showVideo && (
             <motion.video
               initial={{ opacity: 0 }}
               animate={{ opacity: 0.5 }}
               exit={{ opacity: 0 }}
               transition={{ duration: 1 }}
               ref={videoRef}
               id="heroVideo"
               autoPlay
               loop
               muted
               playsInline
               className="w-full h-full object-cover"
             >
               <source src="https://videos.pexels.com/video-files/3044674/3044674-uhd_2560_1440_24fps.mp4" type="video/mp4" />
             </motion.video>
           )}
         </AnimatePresence>
        </motion.div>
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 text-center relative z-10">
          <motion.h1 
            className="text-6xl sm:text-8xl font-bold mb-6"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.8 }}
          >
            Rreth Avioza
          </motion.h1>
          <motion.p 
            className="text-2xl sm:text-4xl font-light mb-12"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.8 }}
          >
            Transformojmë sfidat e udhëtimit në të drejta të realizuara
          </motion.p>
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.6, duration: 0.8 }}
          >
            <a href="#misioni" className="text-white text-lg font-semibold py-3 px-8 border-2 border-white rounded-full hover:bg-white hover:text-blue-600 transition duration-300">
              Zbulo më shumë
            </a>
          </motion.div>
        </div>
        <motion.div 
          className="absolute bottom-10 left-1/2 transform -translate-x-1/2"
          animate={{ y: [0, 10, 0] }}
          transition={{ duration: 1.5, repeat: Infinity }}
        >
          <ChevronDown className="w-12 h-12" />
        </motion.div>
      </motion.section>

      <section id="misioni" className="py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.h2 
            className="text-5xl font-bold mb-12 text-center"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            Misioni ynë
          </motion.h2>
          <motion.p 
            className="text-xl text-gray-600 mb-12 leading-relaxed max-w-4xl mx-auto text-center"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            viewport={{ once: true }}
          >
            Në Avioza, ne po rizbulojmë konceptin e drejtësisë në udhëtim. Duke shfrytëzuar fuqinë e teknologjisë së avancuar dhe ekspertizës sonë ligjore, ne transformojmë kompleksitetin e të drejtave të pasagjerëve në zgjidhje të thjeshta dhe efikase. Çdo fluturim i vonuar, i anuluar apo i mbingarkuar nuk është thjesht një shqetësim - është një mundësi për të rivendosur ekuilibrin mes pasagjerëve dhe kompanive ajrore, duke siguruar që zëri juaj të dëgjohet dhe të drejtat tuaja të respektohen plotësisht.
          </motion.p>
          <motion.div 
            className="w-24 h-1 bg-blue-600 mx-auto"
            initial={{ width: 0 }}
            whileInView={{ width: 96 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
          />
        </div>
      </section>

      <section className="py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.h2 
            className="text-5xl font-bold mb-16 text-center"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            Vlerat tona thelbësore
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            {coreValues.map((value, index) => (
              <motion.div 
                key={index}
                className="bg-white p-8 rounded-lg shadow-xl text-center cursor-pointer"
                whileHover={{ scale: 1.05, boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)" }}
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: index * 0.2 }}
                viewport={{ once: true }}
              >
                <div className="mb-6 flex justify-center">{value.icon}</div>
                <h3 className="text-2xl font-semibold mb-4">{value.title}</h3>
                <p className="text-gray-600 mb-4">{value.description}</p>
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  whileHover={{ opacity: 1, height: "auto" }}
                  transition={{ duration: 0.3 }}
                  className="text-gray-700 mt-4 overflow-hidden"
                >
                  {value.detail}
                </motion.div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.h2 
            className="text-5xl font-bold mb-16 text-center"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            Si ju ndihmojmë
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {services.map((service, index) => (
              <motion.div 
                key={index}
                className="bg-white p-8 rounded-lg shadow-lg flex items-start"
                whileHover={{ y: -10 }}
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: index * 0.2 }}
                viewport={{ once: true }}
              >
                <div className="flex-shrink-0 mr-6">{service.icon}</div>
                <div>
                  <h3 className="text-2xl font-semibold mb-4">{service.title}</h3>
                  <p className="text-gray-600">{service.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-24 bg-blue-600 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.h2 
            className="text-5xl font-bold mb-16 text-center"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            Ndikimi ynë
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {impactAreas.map((area, index) => (
              <ImpactCard key={index} {...area} />
            ))}
          </div>
          <motion.div 
            className="mt-16 text-center"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            viewport={{ once: true }}
          >
            <p className="text-2xl font-light mb-4">
              Çdo ditë, ne punojmë për të bërë ndryshim pozitiv në jetën e pasagjerëve dhe në industrinë e aviacionit.
            </p>
            <motion.button
              className="mt-4 px-8 py-3 bg-white text-blue-600 rounded-full font-semibold hover:bg-blue-100 transition duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Zbulo historitë tona të suksesit
            </motion.button>
          </motion.div>
        </div>
      </section>

      <section className="py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.h2 
            className="text-5xl font-bold mb-16 text-center"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            Statistika dhe Arritje
          </motion.h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
            {stats.map((stat, index) => (
              <motion.div
                key={index}
                className="bg-white p-6 rounded-lg shadow-lg text-center"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: index * 0.1 }}
                viewport={{ once: true }}
              >
                <motion.p 
                  className="text-4xl font-bold text-blue-600 mb-2"
                  initial={{ scale: 0 }}
                  whileInView={{ scale: 1 }}
                  transition={{ type: "spring", stiffness: 100, delay: 0.2 + index * 0.1 }}
                >
                  {stat.value}
                </motion.p>
                <p className="text-gray-600">{stat.label}</p>
              </motion.div>
            ))}
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold mb-4">Kompensimi Mujor</h3>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={compensationData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="amount" stroke="#0088FE" strokeWidth={2} />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold mb-4">Llojet e Kërkesave</h3>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={claimTypeData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {claimTypeData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </section>

      <section className="py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.h2 
            className="text-5xl font-bold mb-16 text-center"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            Ekipi ynë
          </motion.h2>
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={'auto'}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            pagination={true}
            modules={[EffectCoverflow, Pagination]}
            className="mySwiper"
          >
            {teamMembers.map((member, index) => (
              <SwiperSlide key={index}>
                <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                  <img src={member.image} alt={member.name} className="w-32 h-32 rounded-full mx-auto mb-4 object-cover" />
                  <h3 className="text-xl font-semibold mb-2">{member.name}</h3>
                  <p className="text-gray-600">{member.role}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>

      <section className="py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.h2 
            className="text-5xl font-bold mb-16 text-center"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            Çfarë thonë klientët tanë
          </motion.h2>
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            {testimonials.map((testimonial, index) => (
              <SwiperSlide key={index}>
                <div className="bg-white p-8 rounded-lg shadow-lg text-center">
                  <img src={testimonial.image} alt={testimonial.name} className="w-24 h-24 rounded-full mx-auto mb-4 object-cover" />
                  <p className="text-xl mb-4">"{testimonial.content}"</p>
                  <h4 className="font-semibold">{testimonial.name}</h4>
                  <p className="text-gray-600">{testimonial.role}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>

      <section className="py-24 bg-blue-600 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <motion.h2 
            className="text-5xl font-bold mb-12"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            Bëhu pjesë e revolucionit Avioza
          </motion.h2>
          <motion.p 
            className="text-xl text-blue-100 mb-12 max-w-4xl mx-auto"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            viewport={{ once: true }}
          >
            Nëse keni përjetuar vonesa, anulime ose mbingarkesa në fluturimet tuaja, Avioza është këtu për t'ju ndihmuar. Bëhuni pjesë e lëvizjes sonë për të transformuar industrinë e udhëtimit ajror në Shqipëri dhe më gjerë.
          </motion.p>
          <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-6">
            <motion.button 
              className="bg-white text-blue-600 text-lg font-semibold py-4 px-12 rounded-full hover:bg-blue-100 transition duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.4 }}
              viewport={{ once: true }}
            >
              Kontrollo të drejtën tënde për kompensim
            </motion.button>
            <motion.button 
              className="bg-transparent border-2 border-white text-white text-lg font-semibold py-4 px-12 rounded-full hover:bg-white hover:text-blue-600 transition duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.6 }}
              viewport={{ once: true }}
            >
              Mëso më shumë për të drejtat e pasagjerëve
            </motion.button>
          </div>
        </div>
      </section>

      <section className="py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <motion.h2 
            className="text-5xl font-bold mb-12"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            Na ndiqni në rrugëtimin tonë
          </motion.h2>
          <motion.p 
            className="text-xl text-gray-600 mb-12 max-w-4xl mx-auto"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            viewport={{ once: true }}
          >
            Bashkohuni me ne ndërsa vazhdojmë të rritemi dhe të evoluojmë. Na ndiqni në mediat sociale për të qenë gjithmonë të informuar dhe të përditësuar me të rejat më të fundit në fushën e të drejtave të pasagjerëve.
          </motion.p>
          <div className="flex justify-center space-x-6">
            {socialIcons.map((social, index) => (
              <motion.a
                key={social.name}
                href={social.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800 transition duration-300"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.8 + index * 0.1 }}
                viewport={{ once: true }}
              >
                <social.icon className="w-8 h-8" />
                <span className="sr-only">{social.name}</span>
              </motion.a>
            ))}
          </div>
        </div>
      </section>

      <section className="py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.h2 
            className="text-5xl font-bold mb-16 text-center"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            Pyetje të Shpeshta
          </motion.h2>
          <div className="space-y-8">
            {[
              { 
                question: "Çfarë është Avioza?", 
                answer: "Avioza është një platformë inovative që ndihmon pasagjerët të marrin kompensimin që u takon për vonesat, anulimet dhe mbingarkesat e fluturimeve. Ne përdorim teknologji të avancuar dhe ekspertizë ligjore për të thjeshtuar procesin e kërkimit të kompensimit." 
              },
              { 
                question: "Si funksionon procesi i kërkimit të kompensimit?", 
                answer: "Procesi është i thjeshtë: ju futni detajet e fluturimit tuaj, ne vlerësojmë nëse keni të drejtë për kompensim, dhe nëse po, ne menaxhojmë të gjithë procesin në emrin tuaj, duke përfshirë komunikimin me kompaninë ajrore dhe ndjekjen ligjore nëse është e nevojshme." 
              },
              { 
                question: "Sa kushton shërbimi i Avioza?", 
                answer: "Ne punojmë në bazë të suksesit - nëse nuk fitojmë kompensimin për ju, nuk paguani asgjë. Nëse jemi të suksesshëm, ne mbajmë një përqindje të vogël të kompensimit si tarifë e shërbimit tonë." 
              },
              { 
                question: "Sa kohë duhet për të marrë kompensimin?", 
                answer: "Koha e marrjes së kompensimit varion në varësi të kompleksitetit të rastit dhe bashkëpunimit të kompanisë ajrore. Në shumicën e rasteve, procesi zgjat nga disa javë deri në disa muaj. Ne punojmë me shpejtësi maksimale për të siguruar kompensimin tuaj sa më shpejt të jetë e mundur." 
              }
            ].map((faq, index) => (
              <motion.div
                key={index}
                className="bg-white p-6 rounded-lg shadow-lg"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: index * 0.1 }}
                viewport={{ once: true }}
              >
                <h3 className="text-xl font-semibold mb-2">{faq.question}</h3>
                <p className="text-gray-600">{faq.answer}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default RrethNesh;