import React from 'react';
import { Plane, Clock, Calendar, AlertTriangle, CheckCircle, Info, Users, ChevronRight } from 'lucide-react';

const SelectFlight = ({ flights, selectedFlight, handleInputChange, nextStep, prevStep, checkEligibility }) => {
  const convertAirlineCode = (flightNumber) => {
    const airlineCode = flightNumber.substring(0, 2).toLowerCase();
    const flightCode = flightNumber.substring(2);
    
    const codeMapping = {
      'u2': 'EZS',
      'w6': 'WMT',
      // Add more mappings here as needed
    };

    const convertedAirlineCode = codeMapping[airlineCode] || flightNumber.substring(0, 2).toUpperCase();
    return convertedAirlineCode + flightCode;
  };

  const handleSelectFlight = (flight) => {
    const convertedFlightNumber = convertAirlineCode(flight.flight_number);
    handleInputChange('selectedFlight', convertedFlightNumber);
    handleInputChange('selectedDepartureDate', flight.date);
  };

  const handleNextStep = () => {
    const selectedFlightData = flights.find(f => convertAirlineCode(f.flight_number) === selectedFlight);
    checkEligibility(selectedFlight, selectedFlightData.date);
    nextStep();
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = getAlbanianMonth(date.getMonth());
    const year = date.getFullYear();
    const weekday = getAlbanianWeekday(date.getDay());
    
    return `${weekday}, ${day} ${month} ${year}`;
  };

  const getAlbanianMonth = (monthIndex) => {
    const months = ['Jan', 'Shk', 'Mar', 'Pri', 'Maj', 'Qer', 'Kor', 'Gus', 'Sht', 'Tet', 'Nën', 'Dhj'];
    return months[monthIndex];
  };

  const getAlbanianWeekday = (dayIndex) => {
    const weekdays = ['Die', 'Hën', 'Mar', 'Mër', 'Enj', 'Pre', 'Sht'];
    return weekdays[dayIndex];
  };

  const getIssueDetails = (issue) => {
    switch (issue) {
      case 'delayed':
        return { icon: <Clock className="text-amber-500" size={16} />, color: 'bg-amber-50 text-amber-700', label: 'Vonuar' };
      case 'cancelled':
        return { icon: <AlertTriangle className="text-red-500" size={16} />, color: 'bg-red-50 text-red-700', label: 'Anuluar' };
      case 'overbooked':
        return { icon: <Users className="text-indigo-500" size={16} />, color: 'bg-indigo-50 text-indigo-700', label: 'Mbirezervar' };
      default:
        return { icon: <Info className="text-gray-500" size={16} />, color: 'bg-gray-50 text-gray-700', label: 'Problem' };
    }
  };

  const getAirlineCode = (flightNumber) => flightNumber.slice(0, 2).toUpperCase();
  const getFlightNumberWithoutAirlineCode = (flightNumber) => flightNumber.slice(2);

  return (
    <div>
      <h2 className="text-2xl font-semibold text-gray-900 mb-6">Zgjidhni Fluturimin Tuaj</h2>
      <p className="text-sm lg:text-base text-gray-600 mb-4 sm:mb-6">Ju lutemi zgjidhni fluturimin për të cilin po kërkoni kompensim.</p>

      <div className="space-y-3 sm:space-y-4">
        {flights.map((flight) => {
          const convertedFlightNumber = convertAirlineCode(flight.flight_number);
          const isSelected = selectedFlight === convertedFlightNumber;
          const { icon, color, label } = getIssueDetails(flight.issue);
          const airlineCode = getAirlineCode(flight.flight_number);
          const flightNumberWithoutAirlineCode = getFlightNumberWithoutAirlineCode(flight.flight_number);
          return (
            <div
              key={flight.flight_number}
              onClick={() => handleSelectFlight(flight)}
              className={`bg-white rounded-lg p-3 sm:p-4 cursor-pointer transition-all duration-300 ease-in-out
                ${isSelected ? 'ring-2 ring-blue-500 shadow-md' : 'hover:shadow-sm'}
              `}
            >
              <div className="flex justify-between items-center mb-2 sm:mb-3">
                <div className="flex items-center space-x-2 sm:space-x-3">
                  <img 
                    src={`https://images.kiwi.com/airlines/128x128/${airlineCode}.png`} 
                    alt={`${flight.airline} logo`}
                    className="w-8 h-8 object-contain"
                    onError={(e) => {e.target.onerror = null; e.target.src = 'path/to/fallback/image.png'}}
                  />
                  <div>
                    <span className="text-sm sm:text-base  text-gray-900">
                      {flight.airline.toUpperCase()}
                    </span>
                    <div className="flex items-center text-xs text-gray-500">
                      {convertedFlightNumber}
                    </div>
                  </div>
                </div>
                <div className={`flex items-center space-x-1 px-2 py-1 rounded-full text-xs font-medium ${color}`}>
                  {icon}
                  <span className="hidden sm:inline">{label}</span>
                </div>
              </div>
              <div className="flex justify-between items-center bg-gray-50 p-2 sm:p-3 rounded-md">
                <div className="text-center flex-1">
                  <p className="text-xs text-gray-500 mt-0.5 hidden sm:block">{flight.departure.iataCode.toUpperCase()}</p>
                  <p className="text-base sm:text-lg font-bold text-gray-900">{flight.departure.airport.city}</p>
                  <p className="text-xs font-medium text-gray-700">{formatTime(flight.departure.scheduledTime)}</p>
                </div>
                <div className="flex-1 px-2 sm:px-4 relative">
                  <div className="h-px bg-gray-300 w-full"></div>
                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gray-50 px-1 sm:px-2">
                    <Plane className={`${isSelected ? 'text-blue-500' : 'text-gray-400'}`} size={14} />
                  </div>
                </div>
                <div className="text-center flex-1">
                  <p className="text-xs text-gray-500 mt-0.5 hidden sm:block">{flight.arrival.iataCode.toUpperCase()}</p>
                  <p className="text-base sm:text-lg font-bold text-gray-900">{flight.arrival.airport.city}</p>
                  <p className="text-xs font-medium text-gray-700">{formatTime(flight.arrival.scheduledTime)}</p>
                </div>
              </div>
              <div className="mt-2 sm:mt-3 flex justify-between items-center text-xs">
                <div className="flex items-center space-x-2">
                  <Calendar size={12} />
                  <span className="text-gray-600">{formatDate(flight.departure.scheduledTime)}</span>
                </div>
                {isSelected ? (
                  <div className="flex items-center text-blue-600">
                    <CheckCircle size={12} className="mr-1" />
                    <span className="font-medium">Zgjedhur</span>
                  </div>
                ) : (
                  <ChevronRight className="text-gray-400" size={16} />
                )}
              </div>
              </div>
          );
        })}
      </div>

      <div className="mt-8 flex justify-between">
        <button
          onClick={prevStep}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Kthehu
        </button>
        <button
          onClick={handleNextStep}
          disabled={!selectedFlight}
          className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Kontrollo Kualifikimin
        </button>
      </div>
    </div>
  );
};

export default SelectFlight;