export const convertAirlineCode = (flightNumber) => {
    const airlineCode = flightNumber.substring(0, 2).toLowerCase();
    const flightCode = flightNumber.substring(2);
    
    const codeMapping = {
      'u2': 'EZS',
      'w6': 'WMT',
      // Add more mappings here as needed
    };
  
    const convertedAirlineCode = codeMapping[airlineCode] || flightNumber.substring(0, 2).toUpperCase();
    return convertedAirlineCode + flightCode;
  };