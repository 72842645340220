import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const StatusChart = ({ compensations }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const statusCounts = compensations.reduce((acc, comp) => {
      acc[comp.status] = (acc[comp.status] || 0) + 1;
      return acc;
    }, {});

    const ctx = chartRef.current.getContext('2d');
    chartInstance.current = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['Në Pritje', 'Miratuar', 'Refuzuar'],
        datasets: [{
          data: [
            statusCounts.pending || 0,
            statusCounts.approved || 0,
            statusCounts.rejected || 0
          ],
          backgroundColor: ['#FFC300', '#4CAF50', '#F44336'],
          borderColor: '#FFFFFF',
          borderWidth: 2
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutout: '70%',
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [compensations]);

  const hasClaimsData = compensations.length > 0;

  return (
    <div className="bg-white rounded-3xl p-6 md:p-8 shadow-sm">
      <h3 className="text-xl md:text-2xl font-medium text-[#1D1D1F] mb-6">Shpërndarja e Statusit të Kërkesave</h3>
      <div className="h-64 md:h-80 relative">
        <canvas ref={chartRef}></canvas>
        {!hasClaimsData && (
          <div className="absolute inset-0 flex items-center justify-center">
            <p className="text-[#86868B] text-lg">Nuk ka të dhëna për të shfaqur</p>
          </div>
        )}
      </div>
      {hasClaimsData && (
        <div className="mt-4 grid grid-cols-3 gap-4">
          <div className="text-center">
            <p className="text-3xl font-semibold text-[#1D1D1F]">{compensations.filter(c => c.status === 'pending').length}</p>
            <p className="text-sm text-[#86868B]">Në Pritje</p>
          </div>
          <div className="text-center">
            <p className="text-3xl font-semibold text-[#1D1D1F]">{compensations.filter(c => c.status === 'approved').length}</p>
            <p className="text-sm text-[#86868B]">Miratuar</p>
          </div>
          <div className="text-center">
            <p className="text-3xl font-semibold text-[#1D1D1F]">{compensations.filter(c => c.status === 'rejected').length}</p>
            <p className="text-sm text-[#86868B]">Refuzuar</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default StatusChart;