import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import ScrollReveal from 'scrollreveal';
import { MagnifyingGlassIcon, XMarkIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import Fuse from 'fuse.js';

const PyetjeTeShpeshta = () => {
  const [activeTab, setActiveTab] = useState('general');
  const [openQuestion, setOpenQuestion] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    ScrollReveal().reveal('.container > div', { 
      delay: 200,
      distance: '50px',
      origin: 'bottom',
      duration: 1000,
      easing: 'cubic-bezier(0.5, 0, 0, 1)',
      interval: 150
    });
  }, []);

  const faqCategories = [
    { id: 'general', name: 'Të përgjithshme' },
    { id: 'eligibility', name: 'Kualifikimi për Kompensim' },
    { id: 'process', name: 'Paraqitja e Kërkesës' },
    { id: 'payment', name: 'Marrja e Pagesës' }
  ];

  const faqData = {
    general: [
      {
        question: "Çfarë është kompensimi i fluturimit?",
        answer: "Kompensimi i fluturimit është një pagesë që pasagjerët mund të marrin nga linjat ajrore në rast të vonesave të gjata, anulimeve ose mbingarkimit të fluturimeve. Kjo është e rregulluar nga ligjet e BE-së dhe vlen për shumicën e fluturimeve brenda ose nga Bashkimi Evropian."
      },
      {
        question: "Cilat janë të drejtat e mia si pasagjer?",
        answer: "Si pasagjer, ju keni të drejtë për kompensim financiar, rimbursim ose fluturim alternativ në rast të vonesave të gjata, anulimeve ose mbingarkimit. Gjithashtu, keni të drejtë për informacion, kujdes (ushqim, pije, akomodim) dhe asistencë gjatë problemeve me fluturimin."
      },
      {
        question: "A vlen kjo për të gjitha linjat ajrore?",
        answer: "Rregullorja e BE-së 261/2004 vlen për të gjitha linjat ajrore që operojnë brenda BE-së, si dhe për fluturimet që nisen nga BE-ja me çdo linjë ajrore. Për fluturimet që vijnë në BE nga jashtë, vlen vetëm për linjat ajrore të BE-së."
      },
      {
        question: "Çfarë ndodh nëse fluturimi im është anuluar?",
        answer: "Nëse fluturimi juaj anulohet, keni të drejtë për rimbursim të plotë, fluturim alternativ, dhe në shumë raste, kompensim financiar. Shuma e kompensimit varet nga distanca e fluturimit dhe koha e njoftimit para anulimit."
      },
      {
        question: "A mund të kërkoj kompensim për fluturime të kaluara?",
        answer: "Po, mund të kërkoni kompensim për fluturime të kaluara. Në shumicën e vendeve të BE-së, afati i parashkrimit është 3 vjet, por kjo mund të ndryshojë sipas vendit. Rekomandohet të paraqisni kërkesën sa më shpejt të jetë e mundur pas incidentit."
      },
      {
        question: "A vlen rregullorja e kompensimit për fluturimet jashtë BE-së?",
        answer: "Rregullorja e BE-së 261/2004 vlen për të gjitha fluturimet që nisen nga një aeroport në BE, pavarësisht nga destinacioni ose kompania ajrore. Gjithashtu, ajo vlen për fluturimet që vijnë në BE me një kompani ajrore të BE-së. Për fluturimet që nuk përfshihen në këto kategori, mund të aplikohen rregullore të tjera lokale ose ndërkombëtare."
      },
      {
        question: "Çfarë është Rregullorja EC 261/2004?",
        answer: "Rregullorja EC 261/2004 është një legjislacion i Bashkimit Evropian që përcakton të drejtat e pasagjerëve të linjave ajrore në rast të vonesave të gjata, anulimeve të fluturimeve dhe mbingarkimit. Kjo rregullore ofron mbrojtje për pasagjerët dhe përcakton kushtet për kompensim financiar."
      },
      {
        question: "A aplikohet Rregullorja EC 261/2004 për të gjitha linjat ajrore?",
        answer: "Rregullorja aplikohet për të gjitha fluturimet që nisen nga një aeroport në BE, pavarësisht nga kompania ajrore. Për fluturimet që vijnë në BE nga jashtë, ajo aplikohet vetëm për kompanitë ajrore të BE-së."
      },
      {
        question: "Çfarë është një 'rrethanë e jashtëzakonshme'?",
        answer: "'Rrethana e jashtëzakonshme' i referohet situatave që janë jashtë kontrollit të kompanisë ajrore, si kushte ekstreme të motit, greva të paparashikuara, ose çështje të sigurisë. Në këto raste, kompania ajrore mund të përjashtohet nga detyrimi për të paguar kompensim."
      },
      {
        question: "Si ndikon Brexit-i në të drejtat e pasagjerëve?",
        answer: "Pas Brexit-it, Mbretëria e Bashkuar ka ruajtur shumicën e të drejtave të pasagjerëve të ngjashme me ato të BE-së. Megjithatë, ka disa ndryshime të vogla dhe është e rëndësishme të kontrolloni rregullat specifike për fluturimet që përfshijnë Mbretërinë e Bashkuar."
      },
      {
        question: "A mund të kërkoj kompensim nëse fluturimi im është pjesë e një pakete udhëtimi?",
        answer: "Po, edhe nëse fluturimi juaj është pjesë e një pakete udhëtimi, ju keni të drejtë të kërkoni kompensim sipas Rregullores EC 261/2004 nëse plotësohen kriteret e tjera të përshtatshmërisë."
      },
      {
        question: "A mbulohen fluturimet charter nga Rregullorja EC 261/2004?",
        answer: "Po, fluturimet charter janë të mbuluara nga Rregullorja EC 261/2004, me kusht që të plotësohen kriteret e tjera të përshtatshmërisë, si nisja nga një aeroport i BE-së ose operimi nga një kompani ajrore e BE-së."
      },
      {
        question: "Çfarë ndodh nëse ndryshoj planet e mia të udhëtimit pas një vonese ose anulimi?",
        answer: "Nëse ndryshoni planet tuaja të udhëtimit pas një vonese ose anulimi, kjo mund të ndikojë në të drejtën tuaj për kompensim. Është e rëndësishme të ndiqni udhëzimet e kompanisë ajrore dhe të ruani të gjitha dokumentet që lidhen me ndryshimet e bëra."
      },
      {
        question: "A mund të kërkoj kompensim për një fluturim biznesi ose të klasit të parë?",
        answer: "Po, të drejtat e kompensimit aplikohen njësoj për të gjitha klasat e udhëtimit, përfshirë biznesin dhe klasën e parë. Shuma e kompensimit bazohet në distancën e fluturimit, jo në klasën e udhëtimit."
      },
      {
        question: "A mbulohen fëmijët dhe bebet nga Rregullorja EC 261/2004?",
        answer: "Po, fëmijët dhe bebet kanë të njëjtat të drejta për kompensim si pasagjerët e rritur. Kjo përfshin edhe bebet që udhëtojnë pa pagesë në prehrin e prindit, me kusht që të kenë një rezervim të konfirmuar."
      },
      {
        question: "A ndikon statusi im i anëtarësimit në programin e besnikërisë në të drejtën për kompensim?",
        answer: "Jo, statusi juaj në programin e besnikërisë nuk ndikon në të drejtën tuaj për kompensim. Të gjithë pasagjerët kanë të njëjtat të drejta sipas Rregullores EC 261/2004, pavarësisht nga statusi i tyre në programet e besnikërisë."
      }
    ],
    eligibility: [
      {
        question: "Kur kam të drejtë për kompensim?",
        answer: "Ju keni të drejtë për kompensim nëse fluturimi juaj është vonuar për më shumë se 3 orë në destinacionin final, është anuluar, ose jeni refuzuar në bord për shkak të mbingarkimit. Kjo vlen për fluturimet brenda BE-së ose që nisen nga BE-ja, si dhe për fluturimet që mbërrijnë në BE me një linjë ajrore të BE-së."
      },
      {
        question: "A ka ndonjë përjashtim nga e drejta për kompensim?",
        answer: "Po, ka disa përjashtime. Kompensimi nuk jepet në rast të rrethanave të jashtëzakonshme që nuk mund të shmangen, si kushte ekstreme të motit, greva të paparashikuara, ose çështje të sigurisë. Gjithashtu, nëse jeni njoftuar për anulimin të paktën 14 ditë para fluturimit, zakonisht nuk keni të drejtë për kompensim."
      },
      {
        question: "A jam i përshtatshëm nëse fluturimi im është vonuar, por jo anuluar?",
        answer: "Po, mund të jeni i përshtatshëm për kompensim nëse fluturimi juaj është vonuar. Nëse vonesa në mbërritjen tuaj në destinacionin final është më shumë se 3 orë, keni të drejtë për kompensim, me kusht që vonesa të mos jetë shkaktuar nga rrethana të jashtëzakonshme."
      },
      {
        question: "A mund të kërkoj kompensim nëse humbas një fluturim lidhës?",
        answer: "Po, nëse humbisni një fluturim lidhës për shkak të vonesës së fluturimit tuaj të parë dhe kjo rezulton në një vonesë prej më shumë se 3 orësh në destinacionin tuaj final, mund të keni të drejtë për kompensim. Kjo vlen kur të dy fluturimet janë pjesë e të njëjtit rezervim."
      },
      {
        question: "A jam i përshtatshëm nëse fluturimi im është riplanifikuar?",
        answer: "Nëse fluturimi juaj është riplanifikuar dhe kjo rezulton në një vonesë prej më shumë se 3 orësh në destinacionin tuaj final, mund të keni të drejtë për kompensim. Megjithatë, nëse jeni njoftuar për ndryshimin të paktën 14 ditë para datës origjinale të fluturimit, zakonisht nuk keni të drejtë për kompensim."
      },
      {
        question: "A kam të drejtë për kompensim nëse fluturimi im është devijuar?",
        answer: "Nëse fluturimi juaj është devijuar në një aeroport tjetër dhe kjo rezulton në një vonesë prej më shumë se 3 orësh në mbërritjen tuaj në destinacionin final, mund të keni të drejtë për kompensim. Kjo vlen edhe nëse kompania ajrore ju ofron transport alternativ për në destinacionin tuaj origjinal."
      },
      {
        question: "A jam i përshtatshëm për kompensim nëse kam udhëtuar me një biletë falas ose me zbritje?",
        answer: "Po, ju jeni të përshtatshëm për kompensim edhe nëse keni udhëtuar me një biletë falas ose me zbritje, përfshirë biletat e fituara përmes programeve të besnikërisë. Përjashtim bëjnë vetëm biletat e lëshuara me zbritje që nuk janë në dispozicion të publikut të gjerë."
      },
      {
        question: "A jam i përshtatshëm për kompensim nëse fluturimi im u vonua për shkak të një problemi teknik?",
        answer: "Në shumicën e rasteve, problemet teknike nuk konsiderohen si 'rrethana të jashtëzakonshme' dhe prandaj nuk e përjashtojnë kompaninë ajrore nga detyrimi për të paguar kompensim. Megjithatë, ka disa përjashtime për probleme teknike të paparashikueshme që nuk mund të zbuloheshin gjatë mirëmbajtjes së rregullt."
      },
      {
        question: "A jam i përshtatshëm për kompensim nëse fluturimi im u vonua për shkak të një greve?",
        answer: "Përshtatshmëria për kompensim në rast greve varet nga natyra e grevës. Grevat e stafit të kompanisë ajrore zakonisht nuk konsiderohen si 'rrethana të jashtëzakonshme' dhe pasagjerët mund të jenë të përshtatshëm për kompensim. Megjithatë, grevat e pavarura, si ato të kontrollorëve të trafikut ajror, zakonisht konsiderohen si 'rrethana të jashtëzakonshme'."
      },
      {
        question: "A jam i përshtatshëm për kompensim nëse fluturimi im u vonua për shkak të kushteve të këqija të motit?",
        answer: "Kushtet ekstreme të motit zakonisht konsiderohen si 'rrethana të jashtëzakonshme' dhe kompania ajrore nuk është e detyruar të paguajë kompensim. Megjithatë, nëse kompania ajrore nuk ka marrë masa të arsyeshme për të parandaluar vonesën, mund të ketë ende mundësi për kompensim."
      },
      {
        question: "A jam i përshtatshëm për kompensim nëse fluturimi im u vonua për shkak të një problemi në aeroportin e nisjes ose mbërritjes?",
        answer: "Problemet në aeroport, si defektet e sistemit ose mbingarkesa e pistave, zakonisht nuk konsiderohen si 'rrethana të jashtëzakonshme'. Në shumicën e rasteve, pasagjerët mund të jenë të përshtatshëm për kompensim nëse këto probleme shkaktojnë vonesa të gjata."
      },
      {
        question: "A jam i përshtatshëm për kompensim nëse fluturimi im u vonua për shkak të një incidenti të sigurisë?",
        answer: "Incidentet e sigurisë zakonisht konsiderohen si 'rrethana të jashtëzakonshme' dhe kompania ajrore nuk është e detyruar të paguajë kompensim. Siguria e pasagjerëve është prioriteti kryesor dhe vonesat e shkaktuara nga çështje të sigurisë janë zakonisht të justifikuara."
      },
      {
        question: "A jam i përshtatshëm për kompensim nëse fluturimi im u vonua për shkak të vonesës së një fluturimi të mëparshëm?",
        answer: "Nëse vonesa e fluturimit tuaj është shkaktuar nga vonesa e një fluturimi të mëparshëm (e njohur si 'vonesa në zinxhir'), përshtatshmëria juaj për kompensim varet nga arsyeja e vonesës fillestare. Nëse vonesa fillestare ishte për shkak të rrethanave të jashtëzakonshme, atëherë ju mund të mos jeni të përshtatshëm për kompensim."
      },
      {
        question: "A jam i përshtatshëm për kompensim nëse fluturimi im u vonua për shkak të problemeve me dokumentacionin e një pasagjeri tjetër?",
        answer: "Në përgjithësi, problemet me dokumentacionin e pasagjerëve të tjerë nuk konsiderohen si 'rrethana të jashtëzakonshme'. Nëse këto probleme shkaktojnë një vonesë të gjatë, ju mund të jeni të përshtatshëm për kompensim. Megjithatë, çdo rast vlerësohet individualisht."
      },
      {
        question: "A jam i përshtatshëm për kompensim nëse fluturimi im u anulua, por më ofruan një fluturim alternativ?",
        answer: "Edhe nëse ju ofrohet një fluturim alternativ, ju mund të jeni ende të përshtatshëm për kompensim. Përshtatshmëria varet nga koha e njoftimit për anulimin dhe sa vonë arritët në destinacionin tuaj final me fluturimin alternativ. Nëse arritët më shumë se 3 orë vonë, ju mund të keni të drejtë për kompensim."
      },
      {
        question: "A jam i përshtatshëm për kompensim nëse fluturimi im u vonua, por arrita në destinacionin final me më pak se 3 orë vonesë?",
        answer: "Nëse arritët në destinacionin tuaj final me më pak se 3 orë vonesë, zakonisht nuk jeni të përshtatshëm për kompensim sipas Rregullores EC 261/2004. Megjithatë, mund të keni ende të drejtë për ndihmë dhe kujdes nga kompania ajrore gjatë periudhës së vonesës."
      }
    ],
    process: [
      {
        question: "Si mund të paraqes një kërkesë për kompensim?",
        answer: "Për të paraqitur një kërkesë, mund të përdorni formën tonë online në faqen e internetit. Ju do të duhet të jepni detajet e fluturimit tuaj dhe dokumentet mbështetëse. Ne do të marrim përsipër procesin e plotë të kërkesës në emrin tuaj, duke komunikuar me linjën ajrore dhe ndjekur çështjen deri në zgjidhjen përfundimtare."
      },
      {
        question: "Çfarë dokumentesh më duhen për të paraqitur një kërkesë?",
        answer: "Dokumentet kryesore që nevojiten përfshijnë biletën tuaj të fluturimit, boarding pass-in, dhe çdo komunikim nga linja ajrore në lidhje me vonesën ose anulimin. Nëse keni bërë shpenzime shtesë për shkak të vonesës (p.sh. ushqim, akomodim), ruani faturat pasi mund t'ju duhen për rimbursim."
      },
      {
        question: "Sa kohë zgjat procesi i kompensimit?",
        answer: "Kohëzgjatja e procesit ndryshon nga rasti në rast. Disa kërkesa mund të zgjidhen brenda disa javësh, ndërsa të tjerat mund të kërkojnë disa muaj, veçanërisht nëse linja ajrore është e ngadaltë në përgjigje ose nëse çështja duhet të shkojë në gjykatë. Ne punojmë për të zgjidhur çdo kërkesë sa më shpejt të jetë e mundur."
      },
      {
        question: "Çfarë ndodh nëse linja ajrore refuzon kërkesën time?",
        answer: "Nëse linja ajrore refuzon kërkesën tuaj, ne do të shqyrtojmë arsyet e refuzimit dhe do të vendosim nëse duhet të vazhdojmë më tej. Nëse besojmë se keni një rast të fortë, mund të ndjekim rrugë ligjore për të siguruar kompensimin tuaj, duke përfshirë paraqitjen e çështjes në autoritetet kombëtare të zbatimit ose në gjykatë, nëse është e nevojshme."
      },
      {
        question: "A mund ta ndjek vetë kërkesën time për kompensim?",
        answer: "Po, mund ta ndiqni vetë kërkesën tuaj për kompensim duke kontaktuar drejtpërdrejt linjën ajrore. Megjithatë, procesi mund të jetë i gjatë dhe kompleks. Duke përdorur shërbimet tona, ju përfitoni nga ekspertiza jonë në fushën e kompensimit të fluturimeve dhe rritni gjasat për një rezultat të suksesshëm, pa pasur nevojë të merreni personalisht me procesin e kërkesës."
      },
      {
        question: "Çfarë ndodh nëse kompania ajrore falimenton gjatë procesit të kërkesës?",
        answer: "Nëse kompania ajrore falimenton gjatë procesit të kërkesës, situata bëhet më komplekse. Në shumë raste, kërkesat për kompensim bëhen pjesë e procesit të falimentimit dhe mund të trajtohen si borxhe të kompanisë. Ne do të vlerësojmë situatën dhe do t'ju këshillojmë për opsionet e disponueshme, duke përfshirë mundësinë e paraqitjes së një kërkese në procesin e falimentimit."
      },
      {
        question: "Si mund ta di nëse fluturimi im është i përshtatshëm për kompensim?",
        answer: "Mund të përdorni mjetin tonë online të kontrollit të përshtatshmërisë duke futur detajet e fluturimit tuaj. Ky mjet do të analizojë informacionin dhe do t'ju tregojë nëse keni të drejtë për kompensim. Nëse preferoni, mund të na kontaktoni direkt dhe ekspertët tanë do të vlerësojnë rastin tuaj."
      },
      {
        question: "Çfarë informacioni më duhet për të filluar procesin e kërkesës?",
        answer: "Për të filluar procesin e kërkesës, do t'ju duhet: numri i fluturimit, data e fluturimit, aeroportet e nisjes dhe mbërritjes, kohëzgjatja e vonesës ose detaje të anulimit, dhe nëse keni marrë ndonjë shpjegim nga kompania ajrore për vonesën ose anulimin. Dokumentet mbështetëse si bileta e fluturimit, boarding pass dhe çdo komunikim me kompaninë ajrore janë gjithashtu të dobishme."
      },
      {
        question: "Sa kohë zgjat procesi i kërkesës?",
        answer: "Kohëzgjatja e procesit të kërkesës mund të ndryshojë shumë. Disa kërkesa mund të zgjidhen brenda disa javësh, ndërsa të tjerat mund të kërkojnë disa muaj. Faktorët që ndikojnë në kohëzgjatje përfshijnë bashkëpunimin e kompanisë ajrore, kompleksitetin e rastit, dhe nëse çështja duhet të shkojë në gjykatë. Ne punojmë për të përshpejtuar procesin sa më shumë të jetë e mundur."
      },
      {
        question: "A mund të paraqes një kërkesë për dikë tjetër?",
        answer: "Po, mund të paraqisni një kërkesë për një person tjetër, si një anëtar i familjes ose një mik. Megjithatë, do t'ju duhet autorizimi i tyre me shkrim dhe detajet e tyre personale. Për fëmijët nën 18 vjeç, prindërit ose kujdestarët ligjorë mund të paraqesin kërkesën në emër të tyre."
      },
      {
        question: "Çfarë ndodh pasi të paraqes kërkesën time?",
        answer: "Pas paraqitjes së kërkesës, ne do të shqyrtojmë detajet e rastit tuaj dhe do të konfirmojmë përshtatshmërinë. Pastaj, do të përgatisim dhe paraqesim kërkesën tek kompania ajrore në emrin tuaj. Ne do t'ju mbajmë të informuar gjatë gjithë procesit dhe do t'ju njoftojmë për çdo përgjigje nga kompania ajrore ose zhvillim të rëndësishëm në rastin tuaj."
      },
      {
        question: "A mund të anuloj kërkesën time pasi ta kem paraqitur?",
        answer: "Po, mund të anuloni kërkesën tuaj në çdo kohë gjatë procesit. Megjithatë, nëse kemi filluar punën në rastin tuaj, mund të aplikohen tarifa administrative. Rekomandojmë të na kontaktoni sa më shpejt të jetë e mundur nëse dëshironi të anuloni kërkesën tuaj."
      },
      {
        question: "Çfarë ndodh nëse kompania ajrore refuzon të paguajë kompensimin?",
        answer: "Nëse kompania ajrore refuzon të paguajë kompensimin, ne do të vlerësojmë rastin tuaj dhe do të vendosim për hapat e mëtejshëm. Kjo mund të përfshijë paraqitjen e një ankese tek autoriteti kombëtar i zbatimit ose ndjekjen e çështjes në gjykatë. Ne do t'ju informojmë për të gjitha opsionet dhe do të vazhdojmë të luftojmë për të drejtat tuaja."
      },
      {
        question: "A mund të ndjek progresin e kërkesës sime?",
        answer: "Po, ne ofrojmë një portal online ku mund të kontrolloni statusin e kërkesës suaj në çdo kohë. Do t'ju dërgojmë gjithashtu përditësime të rregullta me email për çdo zhvillim të rëndësishëm në rastin tuaj. Nëse keni pyetje shtesë, ekipi ynë i shërbimit ndaj klientit është gjithmonë i disponueshëm për t'ju ndihmuar."
      },
      {
        question: "Çfarë ndodh nëse kam humbur dokumentet e mia të udhëtimit?",
        answer: "Nëse keni humbur dokumentet tuaja të udhëtimit, mos u shqetësoni. Ne mund të ndihmojmë në gjetjen e informacionit të nevojshëm nga burime të tjera. Kjo mund të përfshijë kontaktimin e kompanisë ajrore për të konfirmuar detajet e fluturimit tuaj ose përdorimin e të dhënave të rezervimit online. Thjesht na informoni për situatën dhe ne do të punojmë për të gjetur një zgjidhje."
      },
      {
        question: "A ka ndonjë afat kohor për paraqitjen e kërkesës?",
        answer: "Afatet kohore për paraqitjen e kërkesës ndryshojnë sipas vendit. Në shumicën e vendeve të BE-së, keni deri në 3 vjet nga data e fluturimit për të paraqitur një kërkesë. Megjithatë, rekomandojmë të paraqisni kërkesën sa më shpejt të jetë e mundur pas incidentit, pasi kjo mund të rrisë gjasat e suksesit dhe të përshpejtojë procesin."
      }
    ],
    payment: [
      {
        question: "Sa është shuma e kompensimit që mund të marr?",
        answer: "Shuma e kompensimit varet nga distanca e fluturimit dhe kohëzgjatja e vonesës: \n• 250€ për fluturime deri në 1500 km \n• 400€ për fluturime brenda BE-së mbi 1500 km dhe për të gjitha fluturimet e tjera ndërmjet 1500 dhe 3500 km \n• 600€ për fluturime mbi 3500 km \nKëto shuma mund të reduktohen me 50% nëse vonesa është më pak se 4 orë për fluturimet mbi 3500 km."
      },
      {
        question: "Si paguhet kompensimi?",
        answer: "Kompensimi zakonisht paguhet me transfertë bankare direkt në llogarinë tuaj. Në disa raste, linjat ajrore mund të ofrojnë kuponë udhëtimi, por ju keni të drejtë të kërkoni pagesë në para. Pasi të pranohet kërkesa juaj, ne do t'ju njoftojmë për detajet e pagesës dhe do të sigurojmë që të merrni kompensimin tuaj sa më shpejt të jetë e mundur."
      },
      {
        question: "A ka ndonjë tarifë për shërbimet tuaja?",
        answer: "Ne punojmë në bazë të parimit 'No win, no fee'. Kjo do të thotë që nuk ka kosto fillestare për ju. Ne marrim një përqindje të kompensimit vetëm nëse jemi të suksesshëm në sigurimin e pagesës për ju. Tarifa jonë është zakonisht 25% plus TVSH e shumës së kompensimit. Nëse nuk arrijmë të sigurojmë kompensimin tuaj, nuk do të paguani asgjë."
      },
      {
        question: "Sa shpejt do të marr kompensimin tim?",
        answer: "Koha e marrjes së kompensimit varion nga rasti në rast. Disa kërkesa mund të zgjidhen brenda disa javësh, ndërsa të tjerat mund të kërkojnë disa muaj, veçanërisht nëse linja ajrore është e ngadaltë në përgjigje. Sapo të pranohet kërkesa dhe të bëhet pagesa nga linja ajrore, ne do të transferojmë menjëherë pjesën tuaj të kompensimit, zakonisht brenda 7 ditëve pune."
      },
      {
        question: "A mund të kërkoj rimbursim për shpenzimet e bëra gjatë vonesës?",
        answer: "Po, përveç kompensimit standard, ju keni të drejtë të kërkoni rimbursim për shpenzimet e arsyeshme të bëra gjatë vonesës së fluturimit, si ushqim, pije, akomodim dhe transport. Është e rëndësishme të ruani të gjitha faturat për këto shpenzime. Ne mund t'ju ndihmojmë gjithashtu në procesin e kërkimit të rimbursimit për këto shpenzime shtesë nga linja ajrore."
      },
      {
        question: "A ka ndonjë kufizim kohor për të kërkuar kompensimin?",
        answer: "Afati i parashkrimit për të kërkuar kompensim ndryshon sipas vendit. Në shumicën e vendeve të BE-së, ky afat është 3 vjet nga data e fluturimit. Megjithatë, në disa vende mund të jetë më i shkurtër ose më i gjatë. Rekomandojmë të paraqisni kërkesën sa më shpejt të jetë e mundur pas incidentit për të maksimizuar shanset e suksesit."
      },
      {
        question: "A mund të kërkoj kompensim për një grup udhëtarësh?",
        answer: "Po, mund të paraqisni një kërkesë për një grup udhëtarësh që kanë udhëtuar së bashku në të njëjtin fluturim. Ju duhet të keni autorizimin e secilit anëtar të grupit për të vepruar në emër të tyre. Secili pasagjer ka të drejtë për kompensimin e plotë individual bazuar në distancën e fluturimit, pavarësisht nëse kanë udhëtuar si pjesë e një grupi."
      },
      {
        question: "Cilat janë metodat e pagesës për kompensimin?",
        answer: "Zakonisht, kompensimi paguhet përmes transfertës bankare direkt në llogarinë tuaj. Kjo është metoda më e shpejtë dhe më e sigurt. Në disa raste, mund të ofrohen edhe metoda alternative si çeqet ose PayPal. Ne do t'ju informojmë për opsionet e disponueshme kur kërkesa juaj të jetë aprovuar."
      },
      {
        question: "A mund të marr kompensimin në një monedhë tjetër?",
        answer: "Kompensimi zakonisht paguhet në euro, siç përcaktohet në Rregulloren EC 261/2004. Megjithatë, në varësi të vendndodhjes suaj dhe preferencave, mund të jetë e mundur të merrni pagesën në monedhën tuaj lokale. Kursi i këmbimit do të përcaktohet në momentin e pagesës. Ju lutemi na informoni për preferencën tuaj të monedhës kur të paraqisni kërkesën."
      },
      {
        question: "A duhet të paguaj taksa për kompensimin e marrë?",
        answer: "Trajtimi tatimor i kompensimit për fluturimet mund të ndryshojë sipas vendit dhe rrethanave individuale. Në shumë raste, kompensimi konsiderohet si një rimbursim për shqetësimin dhe jo si të ardhura të tatueshme. Megjithatë, rekomandojmë të konsultoheni me një këshilltar tatimor për situatën tuaj specifike, pasi ne nuk mund të ofrojmë këshilla tatimore."
      },
      {
        question: "Çfarë ndodh nëse kompania ajrore ofron kuponë udhëtimi në vend të kompensimit në para?",
        answer: "Sipas Rregullores EC 261/2004, ju keni të drejtë të zgjidhni midis kompensimit në para dhe kuponëve të udhëtimit. Nëse kompania ajrore ofron vetëm kuponë, ju keni të drejtë të refuzoni dhe të kërkoni pagesë në para. Ne do t'ju këshillojmë për opsionin më të mirë bazuar në rrethanat tuaja dhe do të negociojmë me kompaninë ajrore në emrin tuaj."
      },
      {
        question: "A mund të marr kompensim shtesë për shpenzimet e bëra gjatë vonesës?",
        answer: "Përveç kompensimit standard, ju mund të keni të drejtë të kërkoni rimbursim për shpenzimet e arsyeshme të bëra gjatë vonesës, si ushqim, pije, akomodim dhe transport. Këto shpenzime duhet të jenë të nevojshme, të arsyeshme dhe të dokumentuara me fatura. Ne mund t'ju ndihmojmë të paraqisni kërkesë për këto shpenzime shtesë së bashku me kërkesën tuaj kryesore për kompensim."
      }
    ]
  };

  // Prepare data for Fuse.js
  const searchableData = useMemo(() => {
    return Object.values(faqData).flat().map((item, index) => ({
      ...item,
      id: index,
    }));
  }, [faqData]);

  // Initialize Fuse instance
  const fuse = useMemo(() => {
    return new Fuse(searchableData, {
      keys: ['question', 'answer'],
      threshold: 0.3,
      ignoreLocation: true,
      includeMatches: true,
    });
  }, [searchableData]);

  const filteredFaqData = useMemo(() => {
    if (!searchTerm.trim()) return faqData;

    const searchResults = fuse.search(searchTerm);
    const matchedQuestions = searchResults.map(result => result.item);

    return {
      all: matchedQuestions
    };
  }, [faqData, searchTerm, fuse]);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setActiveTab(value.trim() ? 'all' : 'general');

    if (value.trim()) {
      const results = fuse.search(value).slice(0, 5);
      setSuggestions(results.map(result => result.item.question));
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion);
    setSuggestions([]);
    setActiveTab('all');
  };

  const displayQuestions = useMemo(() => {
    if (searchTerm.trim()) {
      return filteredFaqData.all || [];
    }
    return faqData[activeTab] || [];
  }, [searchTerm, filteredFaqData, faqData, activeTab]);

  return (
    <div className="pyetje-te-shpeshta">
      <section className="hero relative bg-gradient-to-r from-blue-600 to-indigo-800 text-white overflow-hidden">
        <div className="absolute inset-0">
          <img src="/assets/images/faq-background.jpg" alt="Frequently Asked Questions" className="w-full h-full object-cover opacity-90" />
          <div className="absolute inset-0 bg-stone-600 mix-blend-multiply"></div>
        </div>

        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
            Pyetje të Shpeshta
          </h1>
          <p className="mt-6 max-w-3xl text-xl text-blue-100">
            Gjeni përgjigje për pyetjet më të zakonshme rreth kompensimit të fluturimeve dhe shërbimeve tona.
          </p>

          <div className="mt-10 max-w-sm sm:max-w-none sm:flex">
            <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
              <a href="#faq-section" className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-blue-700 bg-white hover:bg-blue-50 sm:px-8">
                Shiko pyetjet
              </a>
              <Link to="/compensation/request" className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-500 bg-opacity-60 hover:bg-opacity-70 sm:px-8">
                Kontrollo fluturimin
              </Link>
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 left-0 right-0">
          <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax">
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </div>
      </section>

      <section id="faq-section" className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center mb-12">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Informacione të dobishme</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Pyetjet më të shpeshta
            </p>
          </div>
{/* Improved Search input */}
<div className="max-w-xl mx-auto mb-8">
  <div className="relative">
    <input
      type="text"
      placeholder="Kërko pyetje..."
      value={searchTerm}
      onChange={handleSearch}
      className="w-full px-4 py-3 border-2 border-gray-300 rounded-full focus:ring-2 focus:ring-blue-500 focus:border-blue-500 pl-12 pr-10 text-lg transition duration-300 ease-in-out"
    />
    <MagnifyingGlassIcon className="absolute left-4 top-1/2 transform -translate-y-1/2 h-6 w-6 text-gray-400" />
    {searchTerm && (
      <button
        onClick={() => {
          setSearchTerm('');
          setSuggestions([]);
          setActiveTab('general');
        }}
        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
      >
        <XMarkIcon className="h-5 w-5" />
      </button>
    )}
    {suggestions.length > 0 && (
      <div className="absolute z-10 w-full bg-white mt-2 border border-gray-200 rounded-lg shadow-lg overflow-hidden">
        <ul className="max-h-60 overflow-y-auto py-2">
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
              className="px-4 py-2 hover:bg-gray-50 cursor-pointer transition duration-200 ease-in-out"
            >
              <div className="flex items-center">
                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400 mr-3" />
                <span className="text-gray-700">{suggestion}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    )}
  </div>
</div>

<div className="mt-10">
  {!searchTerm && (
    <div className="flex flex-wrap justify-center mb-8">
      {faqCategories.map((category) => (
        <button
          key={category.id}
          onClick={() => setActiveTab(category.id)}
          className={`px-6 py-2 rounded-full m-2 focus:outline-none transition duration-300 ${
            activeTab === category.id
              ? 'bg-blue-600 text-white shadow-md'
              : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
          }`}
        >
          {category.name}
        </button>
      ))}
    </div>
  )}

  <div className="space-y-6">
    <AnimatePresence mode="wait">
      {displayQuestions.map((item, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className="bg-white rounded-xl shadow-lg overflow-hidden mb-6"
        >
          <button
            onClick={() => setOpenQuestion(openQuestion === index ? null : index)}
            className="flex justify-between items-center w-full px-6 py-5 focus:outline-none hover:bg-gray-50 transition duration-200 ease-in-out"
          >
            <span className="text-lg font-semibold text-gray-900">{item.question}</span>
            <ChevronDownIcon
              className={`h-6 w-6 text-blue-500 transform transition-transform duration-200 ${
                openQuestion === index ? 'rotate-180' : ''
              }`}
            />
          </button>
          <AnimatePresence>
            {openQuestion === index && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="px-6 pb-5"
              >
                <p className="text-gray-700">{item.answer}</p>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      ))}
    </AnimatePresence>
  </div>
</div>
        </div>
      </section>

      <section className="py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-semibold mb-16 text-center">Pse të zgjidhni Avioza</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div className="bg-white rounded-xl shadow-lg p-8 flex flex-col items-center text-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <div className="bg-blue-100 rounded-full p-6 mb-4">
                <svg className="h-12 w-12 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <h3 className="text-2xl font-semibold mb-4">Ekspertizë e specializuar</h3>
              <p className="text-gray-600">Ne jemi të specializuar në kompensimin e fluturimeve dhe kemi njohuri të thella për ligjet përkatëse.</p>
            </div>
            <div className="bg-white rounded-xl shadow-lg p-8 flex flex-col items-center text-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <div className="bg-blue-100 rounded-full p-6 mb-4">
                <svg className="h-12 w-12 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <h3 className="text-2xl font-semibold mb-4">Pa pagesë paraprake</h3>
              <p className="text-gray-600">Ne punojmë në bazë të "No win, no fee". Ju paguani vetëm nëse fitoni kompensimin tuaj.</p>
            </div>
            <div className="bg-white rounded-xl shadow-lg p-8 flex flex-col items-center text-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <div className="bg-blue-100 rounded-full p-6 mb-4">
                <svg className="h-12 w-12 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
                </div>
              <h3 className="text-2xl font-semibold mb-4">Proces i shpejtë dhe i thjeshtë</h3>
              <p className="text-gray-600">Ne e bëjmë procesin sa më të lehtë për ju, duke u marrë me të gjitha aspektet e kërkesës tuaj.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="cta bg-gradient-to-r from-blue-500 to-indigo-600 py-24">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl font-semibold text-white mb-8">Keni ende pyetje?</h2>
          <p className="text-xl text-blue-100 mb-12">Nëse nuk gjetët përgjigjen që po kërkoni, na kontaktoni dhe do t'ju ndihmojmë me kënaqësi.</p>
          <Link to="/contact" className="inline-block bg-white text-blue-600 font-semibold px-8 py-4 rounded-full shadow-lg hover:bg-blue-50 transition duration-300 animate__animated animate__pulse animate__infinite">
            Na Kontaktoni
          </Link>
        </div>
      </section>

      <style jsx>{`
        .waves {
          position: relative;
          width: 100%;
          height: 15vh;
          margin-bottom: -7px;
          min-height: 100px;
          max-height: 150px;
        }
        .parallax > use {
          animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
        }
        .parallax > use:nth-child(1) {
          animation-delay: -2s;
          animation-duration: 7s;
        }
        .parallax > use:nth-child(2) {
          animation-delay: -3s;
          animation-duration: 10s;
        }
        .parallax > use:nth-child(3) {
          animation-delay: -4s;
          animation-duration: 13s;
        }
        .parallax > use:nth-child(4) {
          animation-delay: -5s;
          animation-duration: 20s;
        }
        @keyframes move-forever {
          0% {
            transform: translate3d(-90px,0,0);
          }
          100% { 
            transform: translate3d(85px,0,0);
          }
        }
      `}</style>
    </div>
  );
};

export default PyetjeTeShpeshta;