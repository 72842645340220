import React from 'react';

const Actions = ({ onOpenUpdateModal, onOpenSupportModal }) => {
  return (
    <section className="bg-white rounded-xl shadow-sm p-6">
      <h2 className="text-2xl sm:text-3xl font-semibold mb-8 text-center">Veprime</h2>
      <div className="flex flex-wrap justify-center gap-4">
        <button 
          onClick={onOpenUpdateModal}
          className="px-6 py-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-sm font-medium"
        >
          Përditëso Kërkesën
        </button>
        <button 
          onClick={onOpenSupportModal}
          className="px-6 py-3 bg-gray-100 text-gray-700 rounded-full hover:bg-gray-200 transition duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 text-sm font-medium"
        >
          Kontakto Mbështetjen
        </button>
      </div>
    </section>
  );
};

export default Actions;