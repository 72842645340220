import React, { useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { EyeIcon, CreditCardIcon, ArrowDownTrayIcon, ChevronUpIcon, ChevronDownIcon, MagnifyingGlassIcon, FunnelIcon } from '@heroicons/react/24/outline';
import { formatDate, translateStatus, translatePayoutStatus, translateIncidentType } from '../../utils/translationUtils';

const ClaimsTable = ({ compensations, onOpenBankDetails, onExport, userRole, commissionRate, isLoading }) => {
  const navigate = useNavigate();
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const itemsPerPage = 10;

  const handleViewDetails = useCallback((claim) => {
    const identifier = claim.pnr || claim._id;
    navigate(`/dashboard/claim/${identifier}`);
  }, [navigate]);

  const handleSort = useCallback((column) => {
    setSortColumn(prevColumn => column === prevColumn ? column : column);
    setSortDirection(prevDirection => column === sortColumn ? (prevDirection === 'asc' ? 'desc' : 'asc') : 'asc');
  }, [sortColumn]);

  const handleFilterChange = useCallback((key, value) => {
    setFilters(prevFilters => ({ ...prevFilters, [key]: value }));
    setCurrentPage(1);
  }, []);

  const filteredAndSortedCompensations = useMemo(() => {
    return compensations
      .filter(claim => 
        Object.entries(filters).every(([key, value]) => 
          value === '' || (claim[key] && claim[key].toString().toLowerCase().includes(value.toLowerCase()))
        ) &&
        Object.values(claim).some(value => 
          value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
      .sort((a, b) => {
        if (!sortColumn) return 0;
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];
        if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
  }, [compensations, searchTerm, sortColumn, sortDirection, filters]);

  const paginatedCompensations = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredAndSortedCompensations.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredAndSortedCompensations, currentPage]);

  const totalPages = Math.ceil(filteredAndSortedCompensations.length / itemsPerPage);

  const getAirlineCode = (flightNumber) => {
    return flightNumber.slice(0, 2);
  };

  const TableHeader = useCallback(({ column, label }) => (
    <th 
      scope="col" 
      className="px-6 py-3 text-left text-xs font-medium text-[#86868B] uppercase tracking-wider cursor-pointer"
      onClick={() => handleSort(column)}
    >
      <div className="flex items-center">
        {label}
        {sortColumn === column && (
          sortDirection === 'asc' ? <ChevronUpIcon className="w-4 h-4 ml-1" /> : <ChevronDownIcon className="w-4 h-4 ml-1" />
        )}
      </div>
    </th>
  ), [handleSort, sortColumn, sortDirection]);

  if (isLoading) {
    return <div className="text-center py-10">Duke u ngarkuar...</div>;
  }

  if (compensations.length === 0) {
    return <div className="text-center py-10">Nuk u gjetën kërkesa.</div>;
  }

  return (
    <div className="mb-12 md:mb-16">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 md:mb-8">
        <h2 className="text-2xl md:text-3xl font-semibold text-[#1D1D1F] mb-4 sm:mb-0">Kërkesat Tuaja të Kompensimit</h2>
        <div className="flex space-x-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Kërko kërkesat..."
              className="pl-10 pr-4 py-2 border border-gray-300 rounded-full"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
          </div>
          <button 
            onClick={() => setShowFilters(!showFilters)}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-full text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
          >
            <FunnelIcon className="mr-2 h-5 w-5" />
            Filtro
          </button>
          <button onClick={onExport} className="inline-flex items-center px-6 py-3 border border-[#0071E3] text-sm font-medium rounded-full text-[#0071E3] bg-white hover:bg-[#0071E3] hover:text-white transition-colors duration-300">
            <ArrowDownTrayIcon className="mr-2 h-5 w-5" />
            Eksporto
          </button>
          <a href="/compensation-request" className="inline-flex items-center px-6 py-3 text-sm font-medium rounded-full text-white bg-[#0071E3] hover:bg-[#0077ED] transition-colors duration-300">
            Kërko Kompensim të Ri
          </a>
        </div>
      </div>
      {showFilters && (
        <div className="mb-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <input
            type="text"
            placeholder="Filtro sipas numrit të fluturimit"
            className="px-4 py-2 border border-gray-300 rounded-md"
            onChange={(e) => handleFilterChange('flight_number', e.target.value)}
          />
          <input
            type="text"
            placeholder="Filtro sipas arsyes"
            className="px-4 py-2 border border-gray-300 rounded-md"
            onChange={(e) => handleFilterChange('claim_reason', e.target.value)}
          />
          <input
            type="text"
            placeholder="Filtro sipas statusit"
            className="px-4 py-2 border border-gray-300 rounded-md"
            onChange={(e) => handleFilterChange('status', e.target.value)}
          />
          <input
            type="text"
            placeholder="Filtro sipas statusit të pagesës"
            className="px-4 py-2 border border-gray-300 rounded-md"
            onChange={(e) => handleFilterChange('payout_status', e.target.value)}
          />
        </div>
      )}
      <div className="bg-[#F5F5F7] rounded-3xl overflow-hidden shadow-sm">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-[#F5F5F7]">
              <tr>
                <TableHeader column="pnr" label="Nr. i Kërkesës" />
                <TableHeader column="departure_airport" label="Nga - Për" />
                <TableHeader column="flight_number" label="Nr. i Fluturimit" />
                <TableHeader column="scheduled_departure" label="Data e Nisjes" />
                <TableHeader column="claim_reason" label="Arsyeja" />
                <TableHeader column="arrival_delay" label="Vonesa" />
                <TableHeader column="amount" label="Shuma" />
                {userRole === 'partner' && (
                  <TableHeader column="commission" label="Komisioni" />
                )}
                <TableHeader column="status" label="Statusi" />
                <TableHeader column="payout_status" label="Statusi i Pagesës" />
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-[#86868B] uppercase tracking-wider">Veprime</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-100">
              {paginatedCompensations.map((claim) => (
                <tr key={claim.pnr} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-[#86868B]">{claim.pnr}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-[#1D1D1F]">{`${claim.departure_airport.iata} - ${claim.arrival_airport.iata}`}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-[#86868B]">
                    <div className="flex items-center">
                      <img 
                        src={`https://images.kiwi.com/airlines/128/${getAirlineCode(claim.flight_number)}.png`}
                        alt={`${claim.airline} logo`}
                        className="w-6 h-6 mr-2 object-contain"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/assets/images/default-airline.png'; // Fallback to a default image
                        }}
                      />
                      {claim.flight_number}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-[#86868B]">{formatDate(claim.scheduled_departure)}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-[#86868B]">{translateIncidentType(claim.incident_type)}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-[#86868B]">{`${claim.delay_duration} minuta`}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-[#1D1D1F]">{`€${claim.amount}`}</td>
                  {userRole === 'partner' && (
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-[#1D1D1F]">{`€${(claim.amount * commissionRate / 100).toFixed(2)}`}</td>
                  )}
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      claim.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                      claim.status === 'approved' ? 'bg-green-100 text-green-800' :
                      'bg-red-100 text-red-800'
                    }`}>
                      {translateStatus(claim.status)}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      claim.payout_status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                      claim.payout_status === 'awaiting_bank_details' ? 'bg-blue-100 text-blue-800' :
                      claim.payout_status === 'in_process' ? 'bg-indigo-100 text-indigo-800' :
                      'bg-green-100 text-green-800'
                    }`}>
                      {translatePayoutStatus(claim.payout_status)}
                    </span>
                    {claim.payout_status === 'awaiting_bank_details' && (
                      <button
                        onClick={() => onOpenBankDetails(claim)}
                        className="text-[#0071E3] hover:text-[#0077ED] transition duration-300"
                      >
                        <CreditCardIcon className="h-5 w-5 inline-block mr-1" />
                        Detajet Bankare
                      </button>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <button
                      onClick={() => handleViewDetails(claim)}
                      className="text-[#0071E3] hover:text-[#0077ED] transition duration-300 mr-2"
                    >
                      <EyeIcon className="h-5 w-5 inline-block mr-1" />
                      Shiko Detajet
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="mt-4 flex justify-between items-center">
        <div>
          Duke shfaqur {((currentPage - 1) * itemsPerPage) + 1} deri në {Math.min(currentPage * itemsPerPage, filteredAndSortedCompensations.length)} nga {filteredAndSortedCompensations.length} kërkesa
        </div>
        <div className="flex space-x-2">
          <button
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="px-4 py-2 border rounded-md disabled:opacity-50"
          >
            Mëparshme
          </button>
          <button
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="px-4 py-2 border rounded-md disabled:opacity-50"
          >
            Tjetër
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClaimsTable;
