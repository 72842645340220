import React from 'react';
import { FiMail, FiSmartphone } from 'react-icons/fi';

const NotificationPreferences = ({ emailNotifications, smsNotifications, setEmailNotifications, setSmsNotifications, onUpdate }) => {
  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">Preferencat e Njoftimeve</h3>
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <FiMail className="h-5 w-5 text-gray-400 mr-2" />
            <span className="text-sm font-medium text-gray-700">Njoftimet me email</span>
          </div>
          <label className="flex items-center cursor-pointer">
            <div className="relative">
              <input
                type="checkbox"
                className="sr-only"
                checked={emailNotifications}
                onChange={(e) => setEmailNotifications(e.target.checked)}
              />
              <div className={`block w-14 h-8 rounded-full ${emailNotifications ? 'bg-blue-600' : 'bg-gray-300'}`}></div>
              <div className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${emailNotifications ? 'transform translate-x-6' : ''}`}></div>
            </div>
          </label>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <FiSmartphone className="h-5 w-5 text-gray-400 mr-2" />
            <span className="text-sm font-medium text-gray-700">Njoftimet me SMS</span>
          </div>
          <label className="flex items-center cursor-pointer">
            <div className="relative">
              <input
                type="checkbox"
                className="sr-only"
                checked={smsNotifications}
                onChange={(e) => setSmsNotifications(e.target.checked)}
              />
              <div className={`block w-14 h-8 rounded-full ${smsNotifications ? 'bg-blue-600' : 'bg-gray-300'}`}></div>
              <div className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${smsNotifications ? 'transform translate-x-6' : ''}`}></div>
            </div>
          </label>
        </div>
      </div>
      <button
        onClick={onUpdate}
        className="mt-6 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Ruaj Preferencat
      </button>
    </div>
  );
};

export default NotificationPreferences;