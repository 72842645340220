import React, { useState } from 'react';

const UpdateModal = ({ onClose, onSubmit }) => {
  const [updateMessage, setUpdateMessage] = useState('');

  const handleSubmit = () => {
    onSubmit(updateMessage);
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2" id="modal-title">
              Përditëso Kërkesën
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500 mb-4">
                Ju lutemi specifikoni çdo informacion shtesë ose ndryshim në kërkesën tuaj.
              </p>
              <textarea 
                value={updateMessage}
                onChange={(e) => setUpdateMessage(e.target.value)}
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm" 
                rows="4" 
                placeholder="Shkruani mesazhin tuaj këtu..."
              ></textarea>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button 
              onClick={handleSubmit}
              type="button" 
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Dërgo
            </button>
            <button 
              onClick={onClose}
              type="button" 
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Anulo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateModal;