import React from 'react';
import { X, ImageIcon, ArrowLeft } from 'lucide-react';
import Button from './Button';
import Alert from './Alert';

const PreviewStep = ({ previewUrl, onScan, onReset, showTips, setShowTips }) => {
  return (
    <div className="space-y-4">
      <div className="relative border-2 border-dashed border-gray-300 rounded-lg p-4">
        <img src={previewUrl} alt="Pamja paraprake e Boarding Pass" className="max-w-full h-auto rounded" />
        <button 
          onClick={onReset} 
          className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition duration-300"
          aria-label="Fshi foton"
        >
          <X className="h-4 w-4" />
        </button>
      </div>
      <Button onClick={onScan} className="w-full">
        <ImageIcon className="mr-2 h-5 w-5" />
        Skano Boarding Pass
      </Button>
      <Button variant="secondary" onClick={() => setShowTips(!showTips)} className="w-full">
        {showTips ? 'Fshih këshillat' : 'Shfaq këshilla për një skanim të suksesshëm'}
      </Button>
      {showTips && (
        <Alert variant="default">
          <Alert.Title>Këshilla për skanim të suksesshëm:</Alert.Title>
          <Alert.Description>
            <ul className="list-disc pl-5 space-y-1 text-sm">
              <li>Sigurohuni që të gjitha detajet janë të dukshme dhe të lexueshme</li>
              <li>Shmangni hijet dhe reflektimet</li>
              <li>Mbajeni kamerën paralel me boarding pass-in</li>
              <li>Përdorni ndriçim të mirë</li>
              <li>Përfshini të gjithë boarding pass-in në foto</li>
            </ul>
          </Alert.Description>
        </Alert>
      )}
      <Button variant="secondary" onClick={onReset} className="w-full">
        <ArrowLeft className="mr-2 h-4 w-4" />
        Kthehu te fillimi
      </Button>
    </div>
  );
};

export default PreviewStep;