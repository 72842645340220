import React, { useState } from 'react';

const PassengerInfo = ({ claim }) => {
  const [showSignature, setShowSignature] = useState(false);

  return (
    <section className="mb-12 bg-white rounded-xl shadow-sm p-6">
      <h2 className="text-2xl sm:text-3xl font-semibold mb-8 text-center">Informacioni i Pasagjerit</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
        <div>
          <p className="text-gray-600 mb-1">Emri i Pasagjerit:</p>
          <p className="text-xl font-semibold">{claim.passenger.passenger_name}</p>
          {claim.signature && (
            <button 
              onClick={() => setShowSignature(!showSignature)}
              className="mt-2 text-blue-600 hover:text-blue-800 transition duration-300 text-sm font-medium"
            >
              {showSignature ? 'Fshih Nënshkrimin' : 'Shfaq Nënshkrimin'}
            </button>
          )}
          {showSignature && claim.signature && (
            <div className="mt-2">
              <img src={claim.signature} alt="Nënshkrimi i Pasagjerit" className="max-w-64 h-auto" />
            </div>
          )}
        </div>
        <div>
          <p className="text-gray-600 mb-1">Email:</p>
          <p className="text-xl font-semibold">{claim.passenger.email}</p>
        </div>
        <div>
          <p className="text-gray-600 mb-1">Telefon:</p>
          <p className="text-xl font-semibold">{claim.passenger.phone || 'N/A'}</p>
        </div>
        <div>
          <p className="text-gray-600 mb-1">Metoda e Preferuar e Kontaktit:</p>
          <p className="text-xl font-semibold">{claim.passenger.preferred_contact_method}</p>
        </div>
      </div>
    </section>
  );
};

export default PassengerInfo;