import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ScrollReveal from 'scrollreveal';
import 'animate.css';

const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    ScrollReveal().reveal('.container > div', { 
      delay: 200,
      distance: '50px',
      origin: 'bottom',
      duration: 1000,
      easing: 'cubic-bezier(0.5, 0, 0, 1)',
      interval: 150
    });
  }, []);

  return (
    <div className="blog">
      <section className="hero relative bg-gradient-to-r from-blue-600 to-indigo-800 text-white overflow-hidden">
        <div className="absolute inset-0">
          <img src="/assets/images/flight_cancel.jpg" alt="Blog" className="w-full h-full object-cover opacity-20" />
          <div className="absolute inset-0 bg-blue-600 mix-blend-multiply"></div>
        </div>

        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
            Blog i Avioza
          </h1>
          <p className="mt-6 max-w-3xl text-xl text-blue-100">
            Mësoni më shumë rreth të drejtave të pasagjerëve, këshilla udhëtimi dhe të rejat e industrisë.
          </p>
        </div>

        <div className="absolute bottom-0 left-0 right-0">
          <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax">
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </div>
      </section>

      <section className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center mb-12">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Postimi i Fundit</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Zbuloni artikullin tonë më të ri
            </p>
          </div>

          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <img className="w-full h-64 object-cover" src="https://images.pexels.com/photos/358319/pexels-photo-358319.jpeg" alt="Featured blog post" />
            <div className="p-6">
              <h3 className="text-2xl font-bold text-gray-900 mb-2">Si të kërkoni kompensim për fluturimin e anuluar</h3>
              <p className="text-gray-600 mb-4">Publikuar më 15 Qershor, 2024 | nga Avioza</p>
              <p className="text-gray-700 mb-4">Mësoni hapat e nevojshëm për të kërkuar kompensimin që ju takon kur fluturimi juaj anulohet. Në këtë artikull, ne shpjegojmë procedurat, dokumentet e nevojshme dhe afatet kohore që duhet të keni parasysh.</p>
              <Link to="/blog/post/1" className="inline-block bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-300">Lexo më shumë</Link>
            </div>
          </div>
        </div>
      </section>

      <section className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center mb-12">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Postimet e Fundit</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Artikujt tanë më të fundit
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                title: "10 këshilla për udhëtim pa stres",
                date: "Publikuar më 10 Qershor, 2023",
                description: "Zbuloni se si të bëni udhëtimin tuaj të ardhshëm më të lehtë dhe më të këndshëm me këto këshilla praktike.",
                image: "https://images.pexels.com/photos/346885/pexels-photo-346885.jpeg",
                link: "/blog/post/2"
              },
              {
                title: "Të drejtat tuaja në rast të vonesave të fluturimit",
                date: "Publikuar më 5 Qershor, 2023",
                description: "Një udhëzues i detajuar për të drejtat tuaja si pasagjer kur fluturimi juaj vonohet.",
                image: "https://images.pexels.com/photos/322819/pexels-photo-322819.jpeg",
                link: "/blog/post/3"
              },
              {
                title: "Si të paketoni për udhëtime të gjata",
                date: "Publikuar më 1 Qershor, 2023",
                description: "Mësoni teknika efikase për të paketuar gjithçka që ju nevojitet për udhëtime të gjata.",
                image: "https://images.pexels.com/photos/22743916/pexels-photo-22743916/free-photo-of-hand-resting-on-the-luggage.jpeg",
                link: "/blog/post/4"
              }
            ].map((post, index) => (
              <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
                <img className="w-full h-48 object-cover" src={post.image} alt={`Blog post ${index + 1}`} />
                <div className="p-6">
                  <h3 className="text-xl font-bold text-gray-900 mb-2">{post.title}</h3>
                  <p className="text-gray-600 mb-4">{post.date}</p>
                  <p className="text-gray-700 mb-4">{post.description}</p>
                  <Link to={post.link} className="text-blue-600 hover:underline">Lexo më shumë</Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Kategoritë</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Eksploro sipas temës
            </p>
          </div>
          <div className="mt-10 flex flex-wrap justify-center gap-4">
            {[
              { name: "Të drejtat e pasagjerëve", link: "/blog/category/te-drejtat-e-pasagjereve" },
              { name: "Këshilla udhëtimi", link: "/blog/category/keshilla-udhetimi" },
              { name: "Kompensim fluturimi", link: "/blog/category/kompensim-fluturimi" },
              { name: "Lajme industrie", link: "/blog/category/lajme-industrie" }
            ].map((category, index) => (
              <Link key={index} to={category.link} className="px-4 py-2 bg-blue-100 text-blue-700 rounded-full hover:bg-blue-200 transition duration-300">
                {category.name}
              </Link>
            ))}
          </div>
        </div>
      </section>

      <style jsx>{`
        .waves {
          position: relative;
          width: 100%;
          height: 15vh;
          margin-bottom: -7px;
          min-height: 100px;
          max-height: 150px;
        }
        .parallax > use {
          animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
        }
        .parallax > use:nth-child(1) {
          animation-delay: -2s;
          animation-duration: 7s;
        }
        .parallax > use:nth-child(2) {
          animation-delay: -3s;
          animation-duration: 10s;
        }
        .parallax > use:nth-child(3) {
          animation-delay: -4s;
          animation-duration: 13s;
        }
        .parallax > use:nth-child(4) {
          animation-delay: -5s;
          animation-duration: 20s;
        }
        @keyframes move-forever {
          0% {
            transform: translate3d(-90px,0,0);
          }
          100% { 
            transform: translate3d(85px,0,0);
          }
        }
      `}</style>
    </div>
  );
};

export default Blog;