import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const HomeHowItWorksSection = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [statCount, setStatCount] = useState(0);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const steps = [
    {
      title: "Kontrolloni të Drejtën Tuaj",
      description: "Identifikojmë shpejt nëse keni të drejtë për kompensim bazuar në rregulloren EC 261/2004.",
      icon: "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4",
      content: (
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h4 className="text-2xl font-light mb-4">Rastet e Kualifikueshme</h4>
          <ul className="space-y-2">
            {[
              "Vonesa mbi 3 orë",
              "Anulime të fluturimeve",
              "Mbingarkesa (Overbooking)",
              "Humbje e lidhjeve të fluturimit",
              "Problemet me bagazhet"
            ].map((item, index) => (
              <li key={index} className="flex items-center text-lg text-medium-gray">
                <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                {item}
              </li>
            ))}
          </ul>
        </div>
      )
    },
    {
      title: "Ne Menaxhojmë Kërkesën",
      description: "Ekipi ynë i ekspertëve merr përsipër të gjithë procesin ligjor dhe komunikimin me kompaninë ajrore.",
      icon: "M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z",
      content: (
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h4 className="text-2xl font-light mb-4">Ekspertiza Jonë në Veprim</h4>
          <div className="space-y-4">
            <p className="text-lg text-medium-gray">Ekipi ynë ka trajtuar me sukses mijëra raste. Ja çfarë thonë klientët tanë:</p>
            <blockquote className="italic border-l-4 border-black pl-4 py-2 text-lg text-medium-gray">
              "Falë Avioza, mora 600€ kompensim për fluturimin tim të anuluar. Procesi ishte i thjeshtë dhe pa stres!"
              <footer className="text-base mt-2">- Arta M., Tiranë</footer>
            </blockquote>
          </div>
        </div>
      )
    },
    {
      title: "Merrni Kompensimin Tuaj",
      description: "Sapo kërkesa juaj të miratohet, ne transferojmë kompensimin direkt në llogarinë tuaj bankare.",
      icon: "M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z",
      content: (
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h4 className="text-2xl font-light mb-4">Statistika e Suksesit Tonë</h4>
          <div className="text-center">
            <p className="text-5xl font-light text-blue-600 mb-2">€{statCount.toLocaleString()}</p>
            <p className="text-lg text-medium-gray">Shuma totale e kompensimeve të fituara për klientët tanë</p>
          </div>
        </div>
      )
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStep((prevStep) => (prevStep + 1) % steps.length);
    }, 8000);

    const countInterval = setInterval(() => {
      setStatCount(prevCount => {
        const increment = Math.floor(Math.random() * 1000) + 500;
        return prevCount + increment > 10000000 ? 10000000 : prevCount + increment;
      });
    }, 100);

    return () => {
      clearInterval(interval);
      clearInterval(countInterval);
    };
  }, []);

  return (
    <section className="py-32 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-6xl font-light text-center mb-12">Si Funksionon</h2>
        <p className="text-2xl text-center mb-16">Procesi ynë i thjeshtë siguron që ju të merrni kompensimin që meritoni, pa stres dhe pa komplikime.</p>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-start">
          <div className="space-y-8">
            {steps.map((step, index) => (
              <motion.div
                key={index}
                className={`p-6 rounded-lg transition-all duration-500 cursor-pointer ${
                  activeStep === index ? 'bg-black text-white shadow-xl' : 'bg-white hover:bg-gray-50'
                }`}
                onClick={() => setActiveStep(index)}
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.98 }}
              >
                <div className="flex items-center mb-4">
                  <div className={`w-16 h-16 rounded-full flex items-center justify-center ${
                    activeStep === index ? 'bg-white text-black' : 'bg-light-gray text-black'
                  }`}>
                    <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={step.icon} />
                    </svg>
                  </div>
                  <h3 className="text-2xl font-light ml-4">{step.title}</h3>
                </div>
                <p className={`text-lg ${activeStep === index ? 'text-gray-100' : 'text-medium-gray'}`}>
                  {step.description}
                </p>
              </motion.div>
            ))}
          </div>

          <div className="lg:sticky lg:top-24">
            <AnimatePresence mode="wait">
              <motion.div
                key={activeStep}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
              >
                {steps[activeStep].content}
              </motion.div>
            </AnimatePresence>

            <div className="mt-8">
              <div className="aspect-w-16 aspect-h-9 bg-light-gray rounded-lg overflow-hidden shadow-xl">
                {isVideoPlaying ? (
                  <iframe
                    src="https://www.youtube.com/embed/dQw4w9WgXcQ?autoplay=1"
                    title="Process explanation"
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    className="w-full h-full"
                  ></iframe>
                ) : (
                  <div className="relative">
                    <img
                      src="https://images.pexels.com/photos/6893950/pexels-photo-6893950.jpeg"
                      alt="How it works video placeholder"
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 flex items-center justify-center">
                      <button
                        onClick={() => setIsVideoPlaying(true)}
                        className="w-20 h-20 bg-black bg-opacity-75 rounded-full flex items-center justify-center transition-all duration-300 hover:bg-opacity-100 focus:outline-none focus:ring-4 focus:ring-blue-300 focus:ring-opacity-50"
                      >
                        <svg className="w-10 h-10 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
                        </svg>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-32 text-center">
      <a href="/compensation-request" className="inline-flex items-center justify-center px-12 py-6 border border-transparent text-2xl font-light rounded-full text-white bg-black hover:bg-opacity-90 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black group animate__animated animate__tada animate__delay-2s animate__slower animate__infinite">
        Fillo Kërkesën Tënde
        <svg className="ml-2 -mr-1 w-6 h-6 group-hover:translate-x-1 transition-transform duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
        </svg>
      </a>
    </div>
      </div>
    </section>
  );
};

export default HomeHowItWorksSection;