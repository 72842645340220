import api from '../utils/api';

export const authService = {
  login: async (email, password) => {
    const response = await api.post('/auth/login', { email, password });
    return response.data;
  },

  register: async (name, email, password) => {
    const response = await api.post('/auth/register', { name, email, password });
    return response.data;
  },

  validateToken: async (token) => {
    const response = await api.get('/auth/validate-token', {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  },
};