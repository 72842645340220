import React from 'react';

const WeatherConditions = ({ eligibilityResult }) => {
  if (!eligibilityResult || !eligibilityResult.weather) {
    return null;
  }

  return (
    <div className="bg-white rounded-lg p-6 border border-gray-200">
      <h3 className="text-lg font-semibold text-gray-900 mb-4">Kushtet e Motit</h3>
      <div className="grid grid-cols-2 gap-4">
        {eligibilityResult.weather.departure && (
          <div>
            <h4 className="font-medium text-gray-900 mb-2">Aeroporti i Nisjes</h4>
            <p className="text-sm text-gray-500">Kushtet: {eligibilityResult.weather.departure.condition || 'N/A'}</p>
            <p className="text-sm text-gray-500">Ashpërsia e motit: {eligibilityResult.weather.departure.severity || 'N/A'}</p>
            <p className="text-sm text-gray-500">Temperatura: {eligibilityResult.weather.departure.temperature ? `${eligibilityResult.weather.departure.temperature}°C` : 'N/A'}</p>
            <p className="text-sm text-gray-500">Shpejtësia e erës: {eligibilityResult.weather.departure.wind_speed ? `${eligibilityResult.weather.departure.wind_speed} km/h` : 'N/A'}</p>
          </div>
        )}
        {eligibilityResult.weather.arrival && (
          <div>
            <h4 className="font-medium text-gray-900 mb-2">Aeroporti i Mbërritjes</h4>
            <p className="text-sm text-gray-500">Kushtet: {eligibilityResult.weather.arrival.condition || 'N/A'}</p>
            <p className="text-sm text-gray-500">Ashpërsia e motit: {eligibilityResult.weather.arrival.severity || 'N/A'}</p>
            <p className="text-sm text-gray-500">Temperatura: {eligibilityResult.weather.arrival.temperature ? `${eligibilityResult.weather.arrival.temperature}°C` : 'N/A'}</p>
            <p className="text-sm text-gray-500">Shpejtësia e erës: {eligibilityResult.weather.arrival.wind_speed ? `${eligibilityResult.weather.arrival.wind_speed} km/h` : 'N/A'}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default WeatherConditions;