import React from 'react';

const AdditionalInfo = ({ claim }) => {
  return (
    <section className="mb-12 bg-white rounded-xl shadow-sm p-6">
      <h2 className="text-2xl sm:text-3xl font-semibold mb-8 text-center">Informacione Shtesë</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
        <div>
          <p className="text-gray-600 mb-1">Lloji i Avionit:</p>
          <p className="text-xl font-semibold">{claim.aircraft_type || 'N/A'}</p>
        </div>
        <div>
          <p className="text-gray-600 mb-1">Arsyeja e Pranueshmërisë:</p>
          <p className="text-xl font-semibold">{claim.eligibility_reason || 'N/A'}</p>
        </div>
        {claim.exceptional_circumstances && (
          <div className="col-span-2">
            <p className="text-gray-600 mb-1">Rrethana të Jashtëzakonshme:</p>
            <p className="text-xl font-semibold">{claim.exceptional_circumstances.exists ? 'Po' : 'Jo'}</p>
            {claim.exceptional_circumstances.description && (
              <p className="text-gray-600 mt-2">{claim.exceptional_circumstances.description}</p>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default AdditionalInfo;