import { useState } from 'react';
import { airportService } from '../services/airportService';

export const useAirportSearch = () => {
  const [airports, setAirports] = useState([]);

  const searchAirports = async (query) => {
    if (query.length < 2) {
      setAirports([]);
      return;
    }

    try {
      const results = await airportService.searchAirports(query);
      setAirports(results);
    } catch (error) {
      console.error('Error searching airports:', error);
      setAirports([]);
    }
  };

  return { searchAirports, airports };
};