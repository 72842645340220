import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import { formatCurrency } from '../../utils/translationUtils';

const AdminCompensationTrend = ({ analytics }) => {
  const chartRef = useRef(null);
  const [selectedMonth, setSelectedMonth] = useState(null);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    const ctx = document.getElementById('compensationTrendChart').getContext('2d');
    chartRef.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels: analytics.monthlyClaimsData.map(item => item.month),
        datasets: [{
          label: 'Totali i Kompensimit',
          data: analytics.monthlyClaimsData.map(item => item.totalCompensation),
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1,
          fill: false
        }, {
          label: 'Numri i Kërkesave',
          data: analytics.monthlyClaimsData.map(item => item.claimCount),
          borderColor: 'rgb(255, 99, 132)',
          tension: 0.1,
          fill: false,
          yAxisID: 'y1'
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Trendi i Kompensimit Mujor',
            font: {
              size: 16
            }
          },
          tooltip: {
            mode: 'index',
            intersect: false,
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Shuma e Kompensimit (€)'
            }
          },
          y1: {
            beginAtZero: true,
            position: 'right',
            title: {
              display: true,
              text: 'Numri i Kërkesave'
            },
            grid: {
              drawOnChartArea: false,
            },
          }
        },
        onClick: (event, elements) => {
          if (elements.length > 0) {
            const index = elements[0].index;
            setSelectedMonth(analytics.monthlyClaimsData[index]);
          }
        }
      }
    });

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [analytics]);

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div style={{ height: '300px' }}>
        <canvas id="compensationTrendChart"></canvas>
      </div>
      {selectedMonth && (
        <div className="mt-4">
          <h3 className="text-lg font-medium text-gray-900">Detaje për {selectedMonth.month}</h3>
          <ul className="mt-2 space-y-2">
            <li className="text-sm text-gray-600">Totali i Kompensimit: {formatCurrency(selectedMonth.totalCompensation)}</li>
            <li className="text-sm text-gray-600">Numri i Kërkesave: {selectedMonth.claimCount}</li>
            <li className="text-sm text-gray-600">Kompensimi Mesatar: {formatCurrency(selectedMonth.totalCompensation / selectedMonth.claimCount)}</li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default AdminCompensationTrend;