import React, { useState } from 'react';
import { formatDate, formatCurrency, translateStatus, translatePayoutStatus } from '../../utils/translationUtils';
import { adminService } from '../../services/adminService';
import ErrorMessage from '../common/ErrorMessage';
import LoadingSpinner from '../common/LoadingSpinner';

const AdminClaimDetails = ({ claim, onUpdate, onClose, isUpdating }) => {
  const [editedClaim, setEditedClaim] = useState(claim);
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedClaim(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      const updatedClaim = await adminService.updateClaim(editedClaim.pnr, {
        status: editedClaim.status,
        payout_status: editedClaim.payout_status,
        amount: editedClaim.amount,
        flight_number: editedClaim.flight_number,
        // Add any other fields that need to be updated
      });
      onUpdate(updatedClaim);
    } catch (err) {
      console.error('Error updating claim:', err);
      setError(err.response?.data?.message || 'An error occurred while updating the claim. Please try again.');
    }
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Detajet e Kërkesës
        </h3>
        <button
          onClick={onClose}
          disabled={isUpdating}
          className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center disabled:opacity-50"
        >
          Mbyll
        </button>
      </div>
      {error && <ErrorMessage message={error} />}
      <div className="border-t border-gray-200">
        <form onSubmit={handleSubmit}>
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">PNR</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {editedClaim.pnr}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Përdoruesi</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {editedClaim.user.email}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Fluturimi</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="flight_number"
                  value={editedClaim.flight_number}
                  onChange={handleInputChange}
                  disabled={isUpdating}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:opacity-50"
                />
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Shuma</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input
                  type="number"
                  name="amount"
                  value={editedClaim.amount}
                  onChange={handleInputChange}
                  disabled={isUpdating}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:opacity-50"
                />
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Statusi</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <select
                  name="status"
                  value={editedClaim.status}
                  onChange={handleInputChange}
                  disabled={isUpdating}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:opacity-50"
                >
                  <option value="pending">Në pritje</option>
                  <option value="under_review">Në shqyrtim</option>
                  <option value="approved">Miratuar</option>
                  <option value="rejected">Refuzuar</option>
                  <option value="on_hold">Në pritje</option>
                </select>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Statusi i Pagesës</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <select
                  name="payout_status"
                  value={editedClaim.payout_status}
                  onChange={handleInputChange}
                  disabled={isUpdating}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:opacity-50"
                >
                  <option value="pending">Në pritje</option>
                  <option value="awaiting_bank_details">Në pritje të detajeve bankare</option>
                  <option value="in_process">Në proces</option>
                  <option value="completed">Kompletuar</option>
                  <option value="failed">Dështuar</option>
                  <option value="approved">Miratuar</option>
                </select>
              </dd>
            </div>
            {/* Add more fields as needed */}
          </dl>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
              type="submit"
              disabled={isUpdating}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
            >
              {isUpdating ? (
                <>
                  <LoadingSpinner size="sm" />
                  <span className="ml-2">Ruajtja...</span>
                </>
              ) : (
                'Ruaj Ndryshimet'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminClaimDetails;
