import React, { useState, useEffect } from 'react';
import { useAirportSearch } from '../../hooks/useAirportSearch';

const FlightDetails = ({ formData, handleInputChange, fetchFlights, nextStep, isFetchingFlights }) => {
  const [errors, setErrors] = useState({});
  const { searchAirports, airports: departureAirports } = useAirportSearch();
  const { searchAirports: searchArrivalAirports, airports: arrivalAirports } = useAirportSearch();

  useEffect(() => {
    if (formData.departureAirport && formData.arrivalAirport && formData.departureDate) {
      fetchFlights({
        departure_iata: formData.departureAirport,
        arrival_iata: formData.arrivalAirport,
        departure_date: formData.departureDate
      });
      nextStep();
    }
  }, []);

  const validateDepartureDate = (date) => {
    if (!date) {
      return 'Data e nisjes është e detyrueshme';
    }
    const selectedDate = new Date(date);
    const today = new Date();
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 1);
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 3);

    if (selectedDate > maxDate) {
      return 'Data e nisjes nuk mund të jetë në të ardhmen';
    } else if (selectedDate < minDate) {
      return 'Data e nisjes nuk mund të jetë më shumë se 3 vjet në të kaluarën';
    }
    return null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dateError = validateDepartureDate(formData.departureDate);
    if (dateError) {
      setErrors({ departureDate: dateError });
      return;
    }
    fetchFlights({
      departure_iata: formData.departureAirport,
      arrival_iata: formData.arrivalAirport,
      departure_date: formData.departureDate
    });
    nextStep();
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label htmlFor="departureAirport" className="block text-sm font-medium text-gray-700">Aeroporti i Nisjes</label>
        <input
          type="text"
          id="departureAirport"
          value={formData.departureAirport}
          onChange={(e) => handleInputChange('departureAirport', e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          required
        />
      </div>
      <div>
        <label htmlFor="arrivalAirport" className="block text-sm font-medium text-gray-700">Aeroporti i Mbërritjes</label>
        <input
          type="text"
          id="arrivalAirport"
          value={formData.arrivalAirport}
          onChange={(e) => handleInputChange('arrivalAirport', e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          required
        />
      </div>
      <div>
        <label htmlFor="departureDate" className="block text-sm font-medium text-gray-700">Data e Nisjes</label>
        <input
          type="date"
          id="departureDate"
          value={formData.departureDate}
          onChange={(e) => handleInputChange('departureDate', e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          required
        />
        {errors.departureDate && <p className="mt-2 text-sm text-red-600">{errors.departureDate}</p>}
      </div>
      <div className="mt-8 flex justify-end">
        <button
          type="submit"
          disabled={isFetchingFlights || !formData.departureAirport || !formData.arrivalAirport || !formData.departureDate}
          className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isFetchingFlights ? 'Duke kërkuar...' : 'Gjej Fluturimet'}
        </button>
      </div>
    </form>
  );
};

export default FlightDetails;