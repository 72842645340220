import React from 'react';
import { Camera, AlertTriangle } from 'lucide-react';
import Button from './Button';

const CameraStep = ({ videoRef, canvasRef, cameraError, onCapture, onRetry }) => {
  return (
    <div className="relative w-full h-[calc(100vh-200px)] max-h-[500px]">
      {cameraError ? (
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-gray-100 text-red-500 p-4">
          <AlertTriangle className="h-12 w-12 mb-4" />
          <p className="text-center mb-4">{cameraError}</p>
          <p className="text-sm text-gray-600 text-center mb-4">
            Sigurohuni që keni dhënë leje për kamerën dhe që po përdorni një shfletues të përditësuar që mbështet këtë funksion.
          </p>
          <Button onClick={onRetry} variant="secondary">
            Provo përsëri
          </Button>
        </div>
      ) : (
        <>
          <video
            ref={videoRef}
            autoPlay
            playsInline
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 border-[40px] border-black border-opacity-50">
            <div className="relative w-full h-full border-2 border-white border-dashed"></div>
          </div>
          <button
            onClick={onCapture}
            className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-white text-black rounded-full p-4"
            aria-label="Capture image"
          >
            <Camera className="h-8 w-8" />
          </button>
        </>
      )}
      <canvas ref={canvasRef} className="hidden" />
    </div>
  );
};

export default CameraStep;