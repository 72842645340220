import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  ClockIcon, 
  ScaleIcon, 
  ShoppingCartIcon, 
  HomeIcon, 
  PhoneIcon, 
  InformationCircleIcon, 
  DocumentCheckIcon, 
  DocumentTextIcon, 
  CreditCardIcon, 
  ShieldCheckIcon,
  CheckIcon,
  ExclamationTriangleIcon,
  PaperAirplaneIcon
} from '@heroicons/react/24/outline';
import ScrollReveal from 'scrollreveal';
import 'animate.css';

const VonesatEFluturimeve = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    ScrollReveal().reveal('.container > div', { 
      delay: 200,
      distance: '50px',
      origin: 'bottom',
      duration: 1000,
      easing: 'cubic-bezier(0.5, 0, 0, 1)',
      interval: 150
    });
  }, []);

  return (
    <div className="vonesat-e-fluturimeve">
      <style jsx>{`
        .waves {
          position: relative;
          width: 100%;
          height: 15vh;
          margin-bottom: -7px;
          min-height: 100px;
          max-height: 150px;
        }
        .parallax > use {
          animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
        }
        .parallax > use:nth-child(1) {
          animation-delay: -2s;
          animation-duration: 7s;
        }
        .parallax > use:nth-child(2) {
          animation-delay: -3s;
          animation-duration: 10s;
        }
        .parallax > use:nth-child(3) {
          animation-delay: -4s;
          animation-duration: 13s;
        }
        .parallax > use:nth-child(4) {
          animation-delay: -5s;
          animation-duration: 20s;
        }
        @keyframes move-forever {
          0% { transform: translate3d(-90px,0,0); }
          100% { transform: translate3d(85px,0,0); }
        }
      `}</style>

      <section className="hero relative bg-gradient-to-r from-blue-600 to-indigo-800 text-white overflow-hidden">
        <div className="absolute inset-0">
          <img src="https://avioza.al/assets/images/hero-vonesat.png" alt="Delayed Flight" className="w-full h-full object-cover opacity-90" />
          <div className="absolute inset-0 bg-stone-600 mix-blend-multiply"></div>
        </div>

        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
            Vonesat e Fluturimeve
          </h1>
          <p className="mt-6 max-w-3xl text-xl text-blue-100">
            Mësoni të drejtat tuaja dhe si të merrni kompensimin që meritoni për fluturimet e vonuara.
          </p>

          <div className="mt-10 max-w-sm sm:max-w-none sm:flex">
            <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
              <a href="#learn-more" className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-blue-700 bg-white hover:bg-blue-50 sm:px-8">
                Mëso më shumë
              </a>
              <Link to="/compensation/request" className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-500 bg-opacity-60 hover:bg-opacity-70 sm:px-8">
                Kontrollo fluturimin
              </Link>
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 left-0 right-0">
          <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax">
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </div>
      </section>

      <section id="learn-more" className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Të drejtat tuaja</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Çfarë duhet të dini për vonesat e fluturimeve
            </p>
          </div>

          <div className="mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                    <ClockIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Kur konsiderohet një fluturim i vonuar?</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  Një fluturim konsiderohet i vonuar kur arrin në destinacionin përfundimtar më shumë se 3 orë pas kohës së planifikuar.
                </dd>
              </div>

              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                    <ScaleIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Sa kompensim mund të merrni?</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  Kompensimi varion nga 250€ deri në 600€, në varësi të distancës së fluturimit dhe kohëzgjatjes së vonesës.
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </section>

      <section className="py-24">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-semibold mb-16 text-center">Llojet e vonesave</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div className="bg-white rounded-xl shadow-lg p-8 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <div className="text-center mb-6">
                <ClockIcon className="inline-block h-16 w-16 text-blue-600" aria-hidden="true" />
              </div>
              <h3 className="text-2xl font-semibold mb-4 text-center">Vonesa të shkurtra</h3>
              <p className="text-gray-600 text-center">Më pak se 3 orë. Zakonisht nuk kualifikohen për kompensim, por linja ajrore mund të ofrojë shërbime të caktuara.</p>
            </div>
            <div className="bg-white rounded-xl shadow-lg p-8 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <div className="text-center mb-6">
                <ClockIcon className="inline-block h-16 w-16 text-blue-600" aria-hidden="true" />
              </div>
              <h3 className="text-2xl font-semibold mb-4 text-center">Vonesa të gjata</h3>
              <p className="text-gray-600 text-center">3 orë ose më shumë. Kualifikohen për kompensim sipas rregullores EU 261/2004. Shuma varet nga distanca e fluturimit.</p>
            </div>
            <div className="bg-white rounded-xl shadow-lg p-8 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <div className="text-center mb-6">
                <ClockIcon className="inline-block h-16 w-16 text-blue-600" aria-hidden="true" />
              </div>
              <h3 className="text-2xl font-semibold mb-4 text-center">Vonesa të zgjatura</h3>
              <p className="text-gray-600 text-center">5 orë ose më shumë. Pasagjerët kanë të drejtë të zgjedhin midis vazhdimit të udhëtimit, rimbursimit të plotë, ose kthimit në pikën fillestare.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-semibold mb-16 text-center">Të drejtat tuaja gjatë vonesave</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="bg-white rounded-xl shadow-lg p-6 flex flex-col items-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <ShoppingCartIcon className="h-12 w-12 text-blue-600 mb-4" aria-hidden="true" />
              <p className="text-gray-700 text-center">Ushqim dhe pije</p>
            </div>
            <div className="bg-white rounded-xl shadow-lg p-6 flex flex-col items-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <HomeIcon className="h-12 w-12 text-blue-600 mb-4" aria-hidden="true" />
              <p className="text-gray-700 text-center">Akomodim nëse vonesa zgjat gjatë natës</p>
            </div>
            <div className="bg-white rounded-xl shadow-lg p-6 flex flex-col items-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <PhoneIcon className="h-12 w-12 text-blue-600 mb-4" aria-hidden="true" />
              <p className="text-gray-700 text-center">Dy telefonata falas, mesazhe me faks ose email</p>
            </div>
            <div className="bg-white rounded-xl shadow-lg p-6 flex flex-col items-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <InformationCircleIcon className="h-12 w-12 text-blue-600 mb-4" aria-hidden="true" />
              <p className="text-gray-700 text-center">Informacion i përditësuar për situatën</p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-24">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-semibold mb-16 text-center">Si të veproni në rast vonese</h2>
          <div className="max-w-4xl mx-auto">
            <div className="bg-white rounded-xl shadow-lg p-8">
              <ol className="list-none space-y-6">
                <li className="flex items-center">
                  <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-blue-600 text-white rounded-full mr-4">1</span>
                  <span className="text-lg text-gray-700">Mblidhni të gjitha dokumentet përkatëse (boarding pass, email konfirmimi, etj.)</span>
                </li>
                <li className="flex items-center">
                  <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-blue-600 text-white rounded-full mr-4">2</span>
                  <span className="text-lg text-gray-700">Kërkoni shpjegim zyrtar nga linja ajrore për arsyen e vonesës</span>
                </li>
                <li className="flex items-center">
                  <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-blue-600 text-white rounded-full mr-4">3</span>
                  <span className="text-lg text-gray-700">Ruani të gjitha faturat për shpenzimet e bëra gjatë vonesës</span>
                </li>
                <li className="flex items-center">
                  <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-blue-600 text-white rounded-full mr-4">4</span>
                  <span className="text-lg text-gray-700">Kontaktoni linjën ajrore për të kërkuar kompensim</span>
                </li>
                <li className="flex items-center">
                  <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-blue-600 text-white rounded-full mr-4">5</span>
                  <span className="text-lg text-gray-700">Nëse linja ajrore refuzon, kontaktoni Avioza për ndihmë të specializuar</span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-semibold mb-16 text-center">Shuma e kompensimit</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div className="bg-white rounded-xl shadow-lg p-8 text-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <ScaleIcon className="h-16 w-16 text-blue-600 mx-auto mb-4" aria-hidden="true" />
              <h3 className="text-2xl font-semibold mb-4">Deri në 1,500 km</h3>
              <p className="text-4xl font-bold text-blue-600">250€</p>
            </div>
            <div className="bg-white rounded-xl shadow-lg p-8 text-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <ScaleIcon className="h-16 w-16 text-blue-600 mx-auto mb-4" aria-hidden="true" />
              <h3 className="text-2xl font-semibold mb-4">1,500 km - 3,500 km</h3>
              <p className="text-4xl font-bold text-blue-600">400€</p>
            </div>
            <div className="bg-white rounded-xl shadow-lg p-8 text-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <ScaleIcon className="h-16 w-16 text-blue-600 mx-auto mb-4" aria-hidden="true" />
              <h3 className="text-2xl font-semibold mb-4">Mbi 3,500 km</h3>
              <p className="text-4xl font-bold text-blue-600">600€</p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-24">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-semibold mb-16 text-center">Si mund t'ju ndihmojë Avioza</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div className="bg-white rounded-xl shadow-lg p-8 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <CheckIcon className="h-16 w-16 text-blue-600 mb-6" aria-hidden="true" />
              <h3 className="text-2xl font-semibold mb-4">Vlerësim i shpejtë dhe falas</h3>
              <p className="text-gray-600">Ne do të vlerësojmë menjëherë nëse fluturimi juaj kualifikohet për kompensim, pa asnjë kosto për ju.</p>
            </div>
            <div className="bg-white rounded-xl shadow-lg p-8 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <DocumentTextIcon className="h-16 w-16 text-blue-600 mb-6" aria-hidden="true" />
              <h3 className="text-2xl font-semibold mb-4">Përgatitje dhe dorëzim i kërkesës</h3>
              <p className="text-gray-600">Ne do të përgatisim të gjithë dokumentacionin e nevojshëm dhe do ta dorëzojmë kërkesën tuaj në emrin tuaj.</p>
            </div>
            <div className="bg-white rounded-xl shadow-lg p-8 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <PhoneIcon className="h-16 w-16 text-blue-600 mb-6" aria-hidden="true" />
              <h3 className="text-2xl font-semibold mb-4">Komunikim me linjën ajrore</h3>
              <p className="text-gray-600">Ne do të komunikojmë drejtpërdrejt me linjën ajrore për të siguruar kompensimin tuaj, duke ju kursyer kohë dhe stres.</p>
            </div>
            <div className="bg-white rounded-xl shadow-lg p-8 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <ShieldCheckIcon className="h-16 w-16 text-blue-600 mb-6" aria-hidden="true" />
              <h3 className="text-2xl font-semibold mb-4">Ndihmë ligjore</h3>
              <p className="text-gray-600">Nëse është e nevojshme, ne ofrojmë ndihmë ligjore për të siguruar që ju të merrni kompensimin që meritoni.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="cta bg-gradient-to-r from-blue-500 to-indigo-600 py-24">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl font-semibold text-white mb-8">Gati për të kontrolluar fluturimin tuaj?</h2>
          <p className="text-xl text-blue-100 mb-12">Zbuloni nëse kualifikoheni për kompensim për vonesën e fluturimit tuaj.</p>
          <Link to="/compensation/request" className="inline-block bg-white text-blue-600 font-semibold px-8 py-4 rounded-full shadow-lg hover:bg-blue-50 transition duration-300 animate__animated animate__pulse animate__infinite">
            Kontrollo Tani
          </Link>
        </div>
      </section>
    </div>
  );
};

export default VonesatEFluturimeve;