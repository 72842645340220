import React from 'react';
import { formatDate, translatePayoutStatus } from '../../../utils/translationUtils';

const CompensationInfo = ({ claim }) => {
  return (
    <section className="mb-12 grid grid-cols-1 md:grid-cols-2 gap-8">
      <div className="bg-white rounded-xl shadow-sm p-6">
        <h3 className="text-xl font-semibold text-gray-800 mb-6">Informacioni i Kompensimit</h3>
        <ul className="space-y-4">
          <li className="flex justify-between items-center border-b pb-2">
            <span className="text-gray-600">Shuma Bazë:</span>
            <span className="font-medium">€{claim.compensation_calculation?.base_amount?.toFixed(2)}</span>
          </li>
          <li className="flex justify-between items-center border-b pb-2">
            <span className="text-gray-600">Shpenzime Shtesë:</span>
            <span className="font-medium">€{claim.compensation_calculation?.additional_expenses?.toFixed(2)}</span>
          </li>
          <li className="flex justify-between items-center border-b pb-2">
            <span className="text-gray-600">Komisioni ynë (20%):</span>
            <span className="font-medium">€{claim.compensation_calculation?.commission?.toFixed(2)}</span>
          </li>
          <li className="flex justify-between items-center pt-2">
            <span className="text-gray-800 font-semibold">Totali i Kompensimit Tuaj:</span>
            <span className="text-2xl font-bold text-blue-600">€{claim.compensation_calculation?.total_compensation?.toFixed(2)}</span>
          </li>
        </ul>
        {claim.compensation_calculation?.calculation_notes && (
          <div className="mt-4">
            <h4 className="text-lg font-semibold text-gray-800 mb-2">Shënime për Llogaritjen:</h4>
            <p className="text-gray-600">{claim.compensation_calculation.calculation_notes}</p>
          </div>
        )}
      </div>
      <div className="bg-white rounded-xl shadow-sm p-6">
        <h3 className="text-xl font-semibold text-gray-800 mb-6">Statusi i Pagesës</h3>
        <div className="space-y-4">
          <p className="flex justify-between items-center">
            <span className="text-gray-600">Statusi Aktual:</span>
            <span className="font-medium">{translatePayoutStatus(claim.payout_status)}</span>
          </p>
          {claim.payout_status === 'completed' && claim.payment_date && (
            <p className="flex justify-between items-center">
              <span className="text-gray-600">Data e Pagesës:</span>
              <span className="font-medium">{formatDate(claim.payment_date)}</span>
            </p>
          )}
          {claim.payout_status !== 'completed' && (
            <p className="text-sm text-gray-500 italic mt-4">
              Pagesat zakonisht procesohen brenda 5-10 ditëve pune pas aprovimit të kërkesës.
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default CompensationInfo;