import React from 'react';

const FlightStatus = ({ eligibilityResult }) => {
  return (
    <div className="bg-white rounded-lg p-6 border border-gray-200">
      <h3 className="text-lg font-semibold text-gray-900 mb-4">Statusi i Fluturimit</h3>
      <div className="flex items-center">
        <span className={`px-3 py-1 rounded-full text-sm font-medium ${
          eligibilityResult.status === 'cancelled' ? 'bg-red-100 text-red-800' :
          eligibilityResult.status === 'delayed' ? 'bg-yellow-100 text-yellow-800' :
          eligibilityResult.status === 'arrived' ? 'bg-green-100 text-green-800' :
          eligibilityResult.status === 'scheduled' ? 'bg-blue-100 text-blue-800' :
          eligibilityResult.status === 'diverted' ? 'bg-purple-100 text-purple-800' :
          'bg-indigo-100 text-indigo-800'
        }`}>
          {eligibilityResult.status === 'cancelled' ? 'Anuluar' :
           eligibilityResult.status === 'delayed' ? 'Vonuar' :
           eligibilityResult.status === 'arrived' ? 'Mbërritur' :
           eligibilityResult.status === 'scheduled' ? 'Planifikuar' :
           eligibilityResult.status === 'diverted' ? 'Devijuar' :
           eligibilityResult.status.toUpperCase()}
        </span>
      </div>
    </div>
  );
};

export default FlightStatus;