import React from 'react';
import { motion } from 'framer-motion';
import { Clock, FileText, Phone, UserCheck, Award, ArrowRight } from 'lucide-react';

const ComparisonItem = ({ icon: Icon, title, description }) => (
  <div className="flex items-start space-x-4 mb-6">
    <div className="flex-shrink-0 mt-1">
      <Icon className="w-8 h-8 text-current" />
    </div>
    <div>
      <h4 className="text-2xl font-light mb-2">{title}</h4>
      <p className="text-lg text-medium-gray">{description}</p>
    </div>
  </div>
);

const AviozaAdvantages = () => {
  const withoutAvioza = [
    { icon: Clock, title: "Proces i gjatë", description: "Mund të zgjasë me muaj të tërë" },
    { icon: FileText, title: "Formularë të ndërlikuar", description: "Dokumentacion i gjerë dhe kompleks" },
    { icon: Phone, title: "Komunikim i vështirë", description: "Pritje e gjatë për përgjigje" },
    { icon: UserCheck, title: "Mungesë ekspertize", description: "Nevojitet njohuri e gjerë ligjore" },
    { icon: Award, title: "Sukses i pasigurt", description: "Shkallë e ulët suksesi" },
  ];

  const withAvioza = [
    { icon: Clock, title: "Proces i shpejtë", description: "Vlerësim dhe procesim i menjëhershëm" },
    { icon: FileText, title: "Proces i thjeshtë", description: "Vetëm disa hapa të thjeshtë online" },
    { icon: Phone, title: "Komunikim transparent", description: "Përditësime të rregullta dhe mbështetje" },
    { icon: UserCheck, title: "Ekspertë të dedikuar", description: "Ekip i specializuar në dispozicion" },
    { icon: Award, title: "Sukses i garantuar", description: "Shkallë e lartë suksesi, kompensim maksimal" },
  ];

  return (
    <section className="py-24 bg-white shadow-2xl">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-6xl font-light text-center mb-12">Përparësitë Avioza.</h2>
        <p className="text-2xl text-center mb-16 text-medium-gray">Ne kemi transformuar procesin e kompensimit në një përvojë të thjeshtë dhe të këndshme</p>

        <div className="flex flex-col md:flex-row justify-between items-center md:items-stretch space-y-8 md:space-y-0 md:space-x-8 relative">
          <motion.div 
            className="bg-white p-8 rounded-lg shadow-lg w-full md:w-5/12"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h3 className="text-3xl font-light mb-8 text-black">Pa Avioza</h3>
            {withoutAvioza.map((item, index) => (
              <ComparisonItem key={index} {...item} />
            ))}
          </motion.div>

          <motion.div 
            className="flex items-center justify-center w-16 h-16 md:h-auto"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <div className="bg-light-gray rounded-full p-4 transition-all duration-300 group hover:bg-black">
              <ArrowRight className="w-8 h-8 text-black group-hover:text-white transition-colors duration-300" />
            </div>
          </motion.div>

          <motion.div 
            className="bg-black p-8 rounded-lg shadow-lg w-full md:w-5/12 text-white"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h3 className="text-3xl font-light mb-8">Me Avioza</h3>
            {withAvioza.map((item, index) => (
              <ComparisonItem key={index} {...item} />
            ))}
          </motion.div>
        </div>

        <motion.div 
          className="mt-20 text-center"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <a 
            href="#" 
            className="inline-block bg-black text-white px-10 py-4 rounded-full text-2xl font-light hover:bg-opacity-80 transition duration-300"
          >
            Filloni Kërkesën Tuaj Tani
          </a>
        </motion.div>
      </div>
    </section>
  );
};

export default AviozaAdvantages;