import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Plane, Calendar, ArrowRight, Check, AlertCircle, Info, Loader, X, ChevronRight, ChevronLeft } from 'lucide-react';
import { useAirportSearch } from '../../hooks/useAirportSearch';
import { debounce } from 'lodash';
import { format, isAfter, isBefore, subYears } from 'date-fns';

// Custom animated Tooltip component
const Tooltip = ({ content, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="relative inline-block">
      <div
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        onFocus={() => setIsVisible(true)}
        onBlur={() => setIsVisible(false)}
      >
        {children}
      </div>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.2 }}
            className="absolute z-10 px-3 py-2 text-sm font-medium text-white bg-black rounded-lg shadow-sm tooltip dark:bg-black -top-10 left-1/2 transform -translate-x-1/2"
          >
            {content}
            <div className="tooltip-arrow" data-popper-arrow></div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

// Animated progress bar component
const ProgressBar = ({ currentStep, totalSteps }) => {
  const progress = (currentStep / totalSteps) * 100;

  return (
    <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-200">
      <motion.div
        className="bg-blue-600 h-2.5 rounded-full"
        initial={{ width: 0 }}
        animate={{ width: `${progress}%` }}
        transition={{ duration: 0.5 }}
      />
    </div>
  );
};

const HomeCompensationForm = () => {
  const [formState, setFormState] = useState({
    departureAirport: '',
    arrivalAirport: '',
    departureDate: '',
    errors: {},
    touched: {},
    isSubmitting: false,
    currentStep: 1,
  });
  const navigate = useNavigate();
  const { searchAirports, airports, isLoading } = useAirportSearch();

  const debouncedSearchAirports = useMemo(
    () => debounce(searchAirports, 300),
    [searchAirports]
  );

  const validateField = (name, value, otherAirport = '') => {
    let error = '';
    switch (name) {
      case 'departureAirport':
      case 'arrivalAirport':
        if (!value) {
          error = `${name === 'departureAirport' ? 'Aeroporti i nisjes' : 'Aeroporti i mbërritjes'} është i detyrueshëm`;
        } else if (value.length < 3) {
          error = 'Ju lutemi shkruani të paktën 3 karaktere';
        } else if (value === otherAirport) {
          error = 'Aeroportet e nisjes dhe mbërritjes duhet të jenë të ndryshme';
        }
        break;
      case 'departureDate':
        if (!value) {
          error = 'Data e nisjes është e detyrueshme';
        } else {
          const selectedDate = new Date(value);
          const today = new Date();
          const threeYearsAgo = subYears(today, 3);
          if (isAfter(selectedDate, today)) {
            error = 'Data e nisjes nuk mund të jetë në të ardhmen';
          } else if (isBefore(selectedDate, threeYearsAgo)) {
            error = 'Data e nisjes nuk mund të jetë më shumë se 3 vjet në të shkuarën';
          }
        }
        break;
      default:
        break;
    }
    return error;
  };

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormState(prev => {
      const otherAirport = name === 'departureAirport' ? prev.arrivalAirport : prev.departureAirport;
      const newState = { 
        ...prev, 
        [name]: value, 
        touched: { ...prev.touched, [name]: true },
        errors: { 
          ...prev.errors, 
          [name]: validateField(name, value, otherAirport),
          [name === 'departureAirport' ? 'arrivalAirport' : 'departureAirport']: validateField(
            name === 'departureAirport' ? 'arrivalAirport' : 'departureAirport',
            name === 'departureAirport' ? prev.arrivalAirport : prev.departureAirport,
            value
          )
        }
      };
      
      if (name === 'departureAirport' || name === 'arrivalAirport') {
        debouncedSearchAirports(value);
      }
      
      return newState;
    });
  }, [debouncedSearchAirports]);

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formState).forEach(key => {
      if (key !== 'errors' && key !== 'touched' && key !== 'isSubmitting' && key !== 'currentStep') {
        const otherAirport = key === 'departureAirport' ? formState.arrivalAirport : formState.departureAirport;
        const error = validateField(key, formState[key], otherAirport);
        if (error) {
          newErrors[key] = error;
        }
      }
    });
    return newErrors;
  };

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    const newErrors = validateForm();

    if (Object.keys(newErrors).length > 0) {
      setFormState(prev => ({ 
        ...prev, 
        errors: newErrors, 
        touched: { departureAirport: true, arrivalAirport: true, departureDate: true } 
      }));
      return;
    }

    setFormState(prev => ({ ...prev, isSubmitting: true, errors: {} }));

    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1500));
      navigate('/compensation-request', { 
        state: { 
          departureAirport: formState.departureAirport, 
          arrivalAirport: formState.arrivalAirport, 
          departureDate: formState.departureDate, 
          skipFirstStep: true 
        }
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      setFormState(prev => ({ 
        ...prev, 
        isSubmitting: false, 
        errors: { ...prev.errors, submit: 'Ndodhi një gabim. Ju lutemi provoni përsëri.' }
      }));
    }
  }, [formState.departureAirport, formState.arrivalAirport, formState.departureDate, navigate]);

  useEffect(() => {
    const dateInput = document.getElementById('departureDate');
    if (dateInput) {
      const today = new Date();
      const minDate = subYears(today, 3);

      dateInput.max = format(today, 'yyyy-MM-dd');
      dateInput.min = format(minDate, 'yyyy-MM-dd');
    }
  }, []);

  const renderInput = (id, label, icon, placeholder, type = "text", tooltip) => {
    const Icon = icon;
    const error = formState.touched[id] && formState.errors[id];
    
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        className="relative group"
      >
        <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
          {label}
          {tooltip && (
            <Tooltip content={tooltip}>
              <Info className="inline-block ml-1 h-4 w-4 text-gray-400 cursor-help" />
            </Tooltip>
          )}
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Icon className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type={type}
            id={id}
            name={id}
            className={`block w-full pl-10 pr-10 py-2.5 text-base border rounded-md transition-all duration-200 
              ${error ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-blue-500 focus:ring-blue-500'}
              bg-white`}
            placeholder={placeholder}
            value={formState[id]}
            onChange={handleInputChange}
            onBlur={() => setFormState(prev => ({ ...prev, touched: { ...prev.touched, [id]: true } }))}
            required
            aria-invalid={error ? "true" : "false"}
            aria-describedby={error ? `${id}-error` : undefined}
          />
          <AnimatePresence>
            {formState[id] && (
              <motion.button
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.5 }}
                transition={{ duration: 0.2 }}
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                onClick={() => setFormState(prev => ({ ...prev, [id]: '' }))}
                aria-label="Clear input"
              >
                <X className="h-5 w-5 text-gray-400 hover:text-gray-600" />
              </motion.button>
            )}
          </AnimatePresence>
        </div>
        <AnimatePresence>
          {error && (
            <motion.p
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.2 }}
              className="mt-1 text-sm text-red-600"
              id={`${id}-error`}
            >
              {error}
            </motion.p>
          )}
        </AnimatePresence>
        {(id === 'departureAirport' || id === 'arrivalAirport') && (
          <div className="relative">
            {isLoading && (
              <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
                <Loader className="animate-spin h-5 w-5 text-blue-500" />
              </div>
            )}
            {airports.length > 0 && (
              <ul className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-sm overflow-auto focus:outline-none">
                {airports.map((airport) => (
                  <motion.li
                    key={airport.iata}
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.2 }}
                    className="cursor-pointer select-none relative py-2 px-3 hover:bg-gray-100"
                    onClick={() => setFormState(prev => ({ ...prev, [id]: airport.iata }))}
                  >
                    <div className="flex items-center justify-between">
                      <span className="font-medium">{airport.iata}</span>
                      <span className="text-xs text-gray-500">{airport.country}</span>
                    </div>
                    <p className="text-xs text-gray-500 mt-0.5">{airport.name}</p>
                  </motion.li>
                ))}
              </ul>
            )}
          </div>
        )}
      </motion.div>
    );
  };

  const renderStep = () => {
    switch (formState.currentStep) {
      case 1:
        return (
          <>
            {renderInput("departureAirport", "Aeroporti i Nisjes", Plane, "p.sh. TIA", "text", "Shkruani kodin IATA të aeroportit ose emrin e qytetit")}
            {renderInput("arrivalAirport", "Aeroporti i Mbërritjes", Plane, "p.sh. LHR", "text", "Shkruani kodin IATA të aeroportit ose emrin e qytetit")}
          </>
        );
      case 2:
        return renderInput("departureDate", "Data e Nisjes", Calendar, "", "date", "Zgjidhni datën e fluturimit tuaj");
      default:
        return null;
    }
  };

  const nextStep = () => {
    if (formState.currentStep < 2) {
      setFormState(prev => ({ ...prev, currentStep: prev.currentStep + 1 }));
    }
  };

  const prevStep = () => {
    if (formState.currentStep > 1) {
      setFormState(prev => ({ ...prev, currentStep: prev.currentStep - 1 }));
    }
  };

  const isNextButtonDisabled = formState.currentStep === 1 && 
    (!formState.departureAirport || !formState.arrivalAirport || 
     formState.errors.departureAirport || formState.errors.arrivalAirport);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-12"
    >
      <form
        onSubmit={handleSubmit}
        className="bg-white rounded-3xl shadow-2xl p-8 md:p-12"
      >
        <h2 className="text-4xl md:text-5xl font-light text-gray-900 mb-2 text-center tracking-tight">Kontrollo Kompensimin</h2>
        <p className="text-center text-gray-600 text-lg mb-10 max-w-2xl mx-auto">Jepni detajet e fluturimit tuaj për të parë nëse kualifikoheni për kompensim.</p>
        
        <ProgressBar currentStep={formState.currentStep} totalSteps={2} />
        
        <div className="mb-8">
          {renderStep()}
        </div>
        
        <AnimatePresence>
          {formState.errors.submit && (
            <motion.p
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.2 }}
              className="mt-4 text-sm text-red-600 text-center"
            >
              {formState.errors.submit}
            </motion.p>
          )}
        </AnimatePresence>
        
        <div className="mt-12 flex justify-between">
          {formState.currentStep > 1 && (
            <motion.button
              type="button"
              onClick={prevStep}
              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <ChevronLeft className="mr-2 h-5 w-5" />
              Kthehu
            </motion.button>
          )}
          {formState.currentStep < 2 ? (
            <motion.button
              type="button"
              onClick={nextStep}
              disabled={isNextButtonDisabled}
              className={`
                inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white
                ${isNextButtonDisabled
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'}
              `}
              whileHover={isNextButtonDisabled ? {} : { scale: 1.05 }}
              whileTap={isNextButtonDisabled ? {} : { scale: 0.95 }}
            >
              Vazhdo
              <ChevronRight className="ml-2 h-5 w-5" />
            </motion.button>
          ) : (
            <motion.button
              type="submit"
              disabled={formState.isSubmitting}
              className={`
                inline-flex items-center px-8 py-3 border border-transparent text-lg font-medium rounded-full
                text-white bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 
                transition-all duration-200
                ${formState.isSubmitting ? 'opacity-75 cursor-not-allowed' : 'hover:bg-blue-700 transform hover:scale-105'}
              `}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {formState.isSubmitting ? (
                <>
                  <Loader className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                  Duke Procesuar...
                </>
              ) : (
                <>
                  Kontrollo Kompensimin
                  <ArrowRight className="ml-2 -mr-1 h-5 w-5" />
                </>
              )}
            </motion.button>
          )}
        </div>
        <div className="mt-6 text-center">
          <Tooltip content="Klikoni për të mësuar më shumë rreth procesit të kompensimit">
            <motion.button 
              type="button" 
              className="text-sm text-blue-600 hover:text-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 inline-flex items-center rounded-md px-2 py-1"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Info className="h-4 w-4 mr-1" />
              Mëso më shumë rreth procesit të kompensimit
            </motion.button>
          </Tooltip>
        </div>
      </form>
    </motion.div>
  );
};

export default HomeCompensationForm;