import React, { useState, useEffect } from 'react';
import { adminService } from '../../services/adminService';
import { useNotification } from '../../hooks/useNotification';

const AdminSettings = () => {
  const [settings, setSettings] = useState({
    commissionRate: '',
    payoutThreshold: '',
    maxClaimAge: '',
    automaticPayouts: false,
    emailNotifications: true,
    maintenanceMode: false,
    currencyCode: 'EUR',
    language: 'sq',
  });
  const [loading, setLoading] = useState(true);
  const { showNotification } = useNotification();

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const data = await adminService.getSettings();
      setSettings(data);
      setLoading(false);
    } catch (error) {
      console.error('Gabim gjatë marrjes së cilësimeve:', error);
      showNotification('Dështoi në ngarkimin e cilësimeve', 'error');
    }
  };

  const handleSettingChange = (key, value) => {
    setSettings(prevSettings => ({
      ...prevSettings,
      [key]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await adminService.updateSettings(settings);
      showNotification('Cilësimet u përditësuan me sukses', 'success');
    } catch (error) {
      console.error('Gabim gjatë përditësimit të cilësimeve:', error);
      showNotification('Dështoi në përditësimin e cilësimeve', 'error');
    }
  };

  if (loading) {
    return <div>Duke ngarkuar cilësimet...</div>;
  }

  return (
    <div className="mt-8">
      <h2 className="text-lg font-medium text-gray-900">Cilësimet e Administratorit</h2>
      <form onSubmit={handleSubmit} className="mt-4 space-y-6">
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Cilësimet e Kompensimit</h3>
              <p className="mt-1 text-sm text-gray-500">
                Këto cilësime ndikojnë në llogaritjen dhe pagesën e kompensimeve.
              </p>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="commissionRate" className="block text-sm font-medium text-gray-700">
                    Norma e Komisionit (%)
                  </label>
                  <input
                    type="number"
                    id="commissionRate"
                    name="commissionRate"
                    value={settings.commissionRate}
                    onChange={(e) => handleSettingChange('commissionRate', e.target.value)}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="payoutThreshold" className="block text-sm font-medium text-gray-700">
                    Pragu i Pagesës (€)
                  </label>
                  <input
                    type="number"
                    id="payoutThreshold"
                    name="payoutThreshold"
                    value={settings.payoutThreshold}
                    onChange={(e) => handleSettingChange('payoutThreshold', e.target.value)}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="maxClaimAge" className="block text-sm font-medium text-gray-700">
                    Mosha Maksimale e Kërkesës (ditë)
                  </label>
                  <input
                    type="number"
                    id="maxClaimAge"
                    name="maxClaimAge"
                    value={settings.maxClaimAge}
                    onChange={(e) => handleSettingChange('maxClaimAge', e.target.value)}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="automaticPayouts" className="block text-sm font-medium text-gray-700">
                    Pagesat Automatike
                  </label>
                  <select
                    id="automaticPayouts"
                    name="automaticPayouts"
                    value={settings.automaticPayouts.toString()}
                    onChange={(e) => handleSettingChange('automaticPayouts', e.target.value === 'true')}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option value="true">Aktiv</option>
                    <option value="false">Joaktiv</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Cilësimet e Sistemit</h3>
              <p className="mt-1 text-sm text-gray-500">
                Këto cilësime ndikojnë në funksionimin e përgjithshëm të sistemit.
              </p>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="emailNotifications" className="block text-sm font-medium text-gray-700">
                    Njoftimet me Email
                  </label>
                  <select
                    id="emailNotifications"
                    name="emailNotifications"
                    value={settings.emailNotifications.toString()}
                    onChange={(e) => handleSettingChange('emailNotifications', e.target.value === 'true')}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option value="true">Aktiv</option>
                    <option value="false">Joaktiv</option>
                  </select>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="maintenanceMode" className="block text-sm font-medium text-gray-700">
                    Modaliteti i Mirëmbajtjes
                  </label>
                  <select
                    id="maintenanceMode"
                    name="maintenanceMode"
                    value={settings.maintenanceMode.toString()}
                    onChange={(e) => handleSettingChange('maintenanceMode', e.target.value === 'true')}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option value="false">Joaktiv</option>
                    <option value="true">Aktiv</option>
                  </select>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="currencyCode" className="block text-sm font-medium text-gray-700">
                    Kodi i Monedhës
                  </label>
                  <select
                    id="currencyCode"
                    name="currencyCode"
                    value={settings.currencyCode}
                    onChange={(e) => handleSettingChange('currencyCode', e.target.value)}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option value="EUR">Euro (EUR)</option>
                    <option value="USD">Dollar Amerikan (USD)</option>
                    <option value="GBP">Paund Britanik (GBP)</option>
                  </select>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="language" className="block text-sm font-medium text-gray-700">
                    Gjuha e Sistemit
                  </label>
                  <select
                    id="language"
                    name="language"
                    value={settings.language}
                    onChange={(e) => handleSettingChange('language', e.target.value)}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option value="sq">Shqip</option>
                    <option value="en">English</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Ruaj Cilësimet
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdminSettings;