import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Chart from 'chart.js/auto';
import ScrollReveal from 'scrollreveal';
import HomeHowItWorksSection from './HomeHowItWorksSection';  // Adjust the import path as needed
import AviozaAdvantages from './AviozaAdvantages';
import InteractiveFlightJourneyTimeline from './InteractiveFlightJourneyTimeline';  // Adjust the import path as needed
import { motion } from 'framer-motion';
import { CurrencyEuroIcon, ClockIcon, SparklesIcon } from '@heroicons/react/24/outline';
import HomeCompensationForm from '../components/compensation/HomeCompensationForm';

const Home = () => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);


  useEffect(() => {
    window.scrollTo(0, 0);
    // Hero video playback
    const video = document.getElementById('heroVideo');
    let isPlayingForward = true;

    video.addEventListener('ended', () => {
      if (isPlayingForward) {
        video.playbackRate = -1;
        video.currentTime = video.duration;
        video.play();
      } else {
        video.playbackRate = 1;
        video.currentTime = 0;
        video.play();
      }
      isPlayingForward = !isPlayingForward;
    });
    video.playbackRate = 0.8;

    // ScrollReveal initialization
    ScrollReveal().reveal('.container > div', { 
      delay: 200,
      distance: '50px',
      origin: 'bottom',
      duration: 1000,
      easing: 'cubic-bezier(0.5, 0, 0, 1)',
      interval: 150
    });

    // Testimonials carousel
    const testimonials = [
      {
        quote: "600€ kompensim për fluturimin e anuluar.",
        description: "Procesi ishte krejtësisht pa stres. Brenda 3 ditësh pas dorëzimit të kërkesës sime, kompensimi u konfirmua dhe u transferua në llogarinë time bankare.",
        initials: "MK",
        name: "Maria K.",
        context: "Fluturim i anuluar"
      },
      {
        quote: "400€ kompensim për vonesën 4-orëshe.",
        description: "Avioza e bëri të gjithë procesin të lehtë. Brenda 3 javësh, mora kompensimin tim. Nuk mendova kurrë se do të ishte kaq e thjeshtë.",
        initials: "JD",
        name: "John D.",
        context: "Vonesë e gjatë"
      },
      {
        quote: "250€ për humbjen e lidhjes së fluturimit.",
        description: "Falë Avioza, morra kompensim edhe pse nuk e dija se kisha të drejtë. Ata u kujdesën për gjithçka.",
        initials: "AL",
        name: "Ana L.",
        context: "Lidhje e humbur"
      },
      {
        quote: "580€ kompensim për mbingarkesë.",
        description: "Kur u refuzova në bord për shkak të mbingarkesës, Avioza më ndihmoi të marr kompensimin maksimal të mundshëm.",
        initials: "RH",
        name: "Robert H.",
        context: "Mbingarkesë"
      },
      {
        quote: "350€ për vonesën e bagazhit tim.",
        description: "Bagazhi im u vonua për 3 ditë. Avioza më ndihmoi të marr kompensim për shpenzimet shtesë që bëra.",
        initials: "ES",
        name: "Emma S.",
        context: "Vonesë bagazhi"
      },
      {
        quote: "500€ për ndryshimin e orarit të fluturimit.",
        description: "Fluturimi im u ndryshua me 5 orë pa njoftim paraprak. Falë Avioza, morra kompensim të plotë për këtë shqetësim.",
        initials: "TM",
        name: "Tom M.",
        context: "Ndryshim orari"
      }
    ];

    let currentIndex = 0;
    const container = document.getElementById('testimonials-container');
    const dotsContainer = document.getElementById('navigation-dots');
    const prevBtn = document.getElementById('prev-btn');
    const nextBtn = document.getElementById('next-btn');

    function renderTestimonials() {
      container.innerHTML = testimonials.map((testimonial, index) => `
        <div class="w-full flex-shrink-0 px-4">
          <div class="text-center max-w-4xl mx-auto">
            <blockquote class="text-[28px] sm:text-[40px] lg:text-[56px] leading-tight font-semibold mb-8">${testimonial.quote}</blockquote>
            <p class="text-[21px] text-[#86868b] mb-12 max-w-3xl mx-auto">${testimonial.description}</p>
            <div class="inline-flex items-center">
              <div class="w-16 h-16 rounded-full bg-gradient-to-r from-[#35c3f3] to-[#8b9fe8] flex items-center justify-center text-3xl font-bold mr-4">${testimonial.initials}</div>
              <div class="text-left">
                <p class="font-semibold text-[19px]">${testimonial.name}</p>
                <p class="text-[17px] text-[#86868b]">${testimonial.context}</p>
              </div>
            </div>
          </div>
        </div>
      `).join('');

      dotsContainer.innerHTML = testimonials.map((_, index) => `
        <button data-index="${index}" 
                class="w-2 h-2 rounded-full transition-all duration-300 ease-in-out focus:outline-none hover:scale-150 ${index === currentIndex ? 'bg-white' : 'bg-gray-600'}">
        </button>
      `).join('');

      updateCarousel();
    }

    function updateCarousel() {
      container.style.transform = `translateX(-${currentIndex * 100}%)`;
      Array.from(dotsContainer.children).forEach((dot, index) => {
        dot.classList.toggle('bg-white', index === currentIndex);
        dot.classList.toggle('bg-gray-600', index !== currentIndex);
      });
    }

    function next() {
      currentIndex = (currentIndex + 1) % testimonials.length;
      updateCarousel();
    }

    function prev() {
      currentIndex = (currentIndex - 1 + testimonials.length) % testimonials.length;
      updateCarousel();
    }

    renderTestimonials();
    prevBtn.addEventListener('click', prev);
    nextBtn.addEventListener('click', next);
    dotsContainer.addEventListener('click', (e) => {
      if (e.target.hasAttribute('data-index')) {
        currentIndex = parseInt(e.target.getAttribute('data-index'));
        updateCarousel();
      }
    });

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active');
        }
      });
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    });

    document.querySelectorAll('#testimonials-section .testimonials-animate').forEach(el => {
      observer.observe(el);
    });
  }, []);

  return (
    <div className="bg-white text-[#1D1D1F] font-sans">
      {/* Hero Section */}
<section className="relative h-screen flex items-center justify-center overflow-hidden bg-[#F5F5F7]">
  <div className="absolute inset-0">
    <video id="heroVideo" autoPlay loop muted playsInline className="absolute w-full h-full object-cover">
      <source src={`${process.env.PUBLIC_URL}/assets/videos/hero.mp4`} type="video/mp4" />
    </video>
    <div className="absolute inset-0 bg-gradient-to-b from-black/70 to-transparent"></div>
  </div>
  <div className="relative z-10 text-center text-white px-4 sm:px-6 lg:px-8 max-w-5xl mx-auto">
    <h1 className="text-6xl sm:text-7xl md:text-8xl font-bold tracking-tight mb-8 animate__animated animate__fadeIn">
      Drejtësi në çdo fluturim
    </h1>
    <p className="text-xl sm:text-2xl font-normal mb-12 animate__animated animate__fadeIn animate__delay-1s max-w-2xl mx-auto">
      Kompensim i lehtë për fluturimet e <strong>vonuara</strong> dhe të <strong>anuluara</strong>.
      <br />
      Sigurohuni që të merrni atë që ju takon.
    </p>
    <Link 
      to="/compensation-request" 
      className="inline-block bg-[#0071E3] text-white px-8 py-4 rounded-full text-lg font-semibold transition duration-300 ease-in-out transform hover:scale-105 hover:bg-[#005bbf] animate__animated animate__fadeInUp animate__delay-2s"
      aria-label="Zbuloni të Drejtën Tuaj"
    >
      Zbuloni të Drejtën Tuaj
    </Link>
    <div className="mt-8 animate__animated animate__fadeIn animate__delay-3s">
      <p className="text-lg text-gray-300">
        Kontrolloni të drejtën tuaj tani dhe merrni kompensimin që meritoni.
      </p>
    </div>
  </div>
</section>
      {/* Floating Compensation Form */}
      <div className="relative z-20 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8" style={{marginTop: '-200px'}}>
          <HomeCompensationForm isHomePage={true} />
      </div>

      {/* Problem Statement */}
      <section id="hero-section" className="bg-black text-white overflow-hidden">
        <div className="max-w-[980px] mx-auto px-6">
          <div className="py-20 sm:py-28">
            <h2 className="text-[56px] sm:text-[72px] lg:text-[96px] leading-[1.05] font-semibold text-center mb-6">
              90% e pasagjerëve<br />humbasin kompensimin.
            </h2>
            <p className="text-[21px] sm:text-[24px] lg:text-[28px] leading-[1.1428] text-center text-[#86868b] mb-12 max-w-[600px] mx-auto font-medium">
              Ne po ndryshojmë këtë realitet, duke siguruar që çdo pasagjer të marrë atë që i takon.
            </p>
            <div className="relative w-full flex items-center justify-center">
  <div className="w-[80vw] max-w-[700px] aspect-square">
    <svg className="w-full h-full" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 16v-2l-8-5V3.5c0-.83-.67-1.5-1.5-1.5S10 2.67 10 3.5V9l-8 5v2l8-2.5V19l-2 1.5V22l3.5-1 3.5 1v-1.5L13 19v-5.5l8 2.5z" fill="#2997ff"/>
    </svg>
  </div>
</div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 py-16 border-t border-[#d1d1d1]/20">
  <motion.div 
    className="text-center p-6 bg-gray-900/50 rounded-2xl transition-all duration-300 hover:bg-gray-600/50"
    whileHover={{ scale: 1.05 }}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <CurrencyEuroIcon className="w-16 h-16 text-blue-500 mx-auto mb-4" />
    <motion.p 
      className="text-5xl sm:text-4xl lg:text-5xl font-bold mb-2"
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ type: "spring", stiffness: 100, delay: 0.2 }}
    >
      600€
    </motion.p>
    <p className="text-lg sm:text-xl lg:text-2xl text-[#aeaeb1] leading-tight">
      kompensim maksimal<br />për fluturim
    </p>
  </motion.div>
  
  <motion.div 
    className="text-center p-6 bg-gray-900/50 rounded-2xl transition-all duration-300 hover:bg-gray-600/50"
    whileHover={{ scale: 1.05 }}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: 0.1 }}
  >
    <ClockIcon className="w-16 h-16 text-blue-500 mx-auto mb-4" />
    <motion.p 
      className="text-5xl sm:text-4xl lg:text-5xl font-bold mb-2"
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ type: "spring", stiffness: 100, delay: 0.3 }}
    >
      3 min
    </motion.p>
    <p className="text-lg sm:text-xl lg:text-2xl text-[#86868b] leading-tight">
      për të kontrolluar<br />kualifikimin tuaj
    </p>
  </motion.div>
  
  <motion.div 
    className="text-center p-6 bg-gray-900/50 rounded-2xl transition-all duration-300 hover:bg-gray-600/50"
    whileHover={{ scale: 1.05 }}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: 0.2 }}
  >
    <SparklesIcon className="w-16 h-16 text-blue-500 mx-auto mb-4" />
    <motion.p 
      className="text-5xl sm:text-4xl lg:text-5xl font-bold mb-2"
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ type: "spring", stiffness: 100, delay: 0.4 }}
    >
      Falas
    </motion.p>
    <p className="text-lg sm:text-xl lg:text-2xl text-[#86868b] leading-tight">
      Nuk ka asnjë kosto<br />nëse nuk fitoni
    </p>
  </motion.div>
</div>
        </div>
      </section>

      {/* <section className="relative py-32 bg-black text-white overflow-hidden border-t border-[#d1d1d1]/20">
        <div className="absolute inset-0 opacity-50">
        </div>
        <div className="relative max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <Link to="#compensation-form" className="inline-block px-12 py-6 bg-white text-black rounded-full text-2xl font-light hover:bg-opacity-90 transition duration-300 transform hover:scale-105 animate__animated animate__tada animate__delay-2s animate__slower animate__infinite">
            Filloni Kërkesën Tuaj Tani
          </Link>
        </div>
      </section> */}

      {/* How It Works Section */}
{/* <section className="py-32 bg-white overflow-hidden">
  <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
    <h2 className="text-6xl font-light mb-12 text-black">
      Si Funksionon
    </h2>
    <p className="text-2xl mb-16 text-medium-gray max-w-[600px] mx-auto">
      Nga vonesa në kompensim, procesi ynë i thjeshtë siguron që ju të merrni atë që meritoni.
    </p>
    
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
      <div className="space-y-12">
        {[
          { 
            title: "Kontrolloni të Drejtën Tuaj",
            description: "Jepni detajet e fluturimit tuaj dhe ne do të vlerësojmë menjëherë të drejtën tuaj për kompensim.",
            icon: "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
          },
          {
            title: "Ne Menaxhojmë Kërkesën",
            description: "Ekipi ynë i ekspertëve merr përsipër të gjithë procesin ligjor dhe komunikimin me kompaninë ajrore.",
            icon: "M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
          },
          {
            title: "Merrni Kompensimin Tuaj",
            description: "Sapo kërkesa juaj të miratohet, ne transferojmë kompensimin direkt në llogarinë tuaj bankare.",
            icon: "M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
          }
        ].map((step, index) => (
          <div key={index} className="flex items-start group hover:bg-gray-50 rounded-lg p-4 transition-all duration-300">
            <div className="flex-shrink-0 w-16 h-16 rounded-full bg-light-gray flex items-center justify-center transition-all duration-300 group-hover:bg-black group-hover:text-white">
              <svg className="w-8 h-8 text-black group-hover:text-white transition-colors duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={step.icon} />
              </svg>
            </div>
            <div className="ml-6 text-left">
              <h3 className="text-2xl font-light mb-4 text-black group-hover:text-[#0071E3] transition-colors duration-300">{step.title}</h3>
              <p className="text-lg text-medium-gray">{step.description}</p>
            </div>
          </div>
        ))}
      </div>
      
      <div className="relative">
  <div className="aspect-w-16 aspect-h-9 bg-black rounded-3xl overflow-hidden shadow-2xl group mb-6">
    <img src="https://images.pexels.com/photos/6893950/pexels-photo-6893950.jpeg" alt="How it works video placeholder" className="object-cover w-full h-full transition-transform duration-300 group-hover:scale-105" />
    <div className="absolute inset-0 flex items-center justify-center">
      <button className="w-20 h-20 bg-white bg-opacity-80 rounded-full flex items-center justify-center transition-all duration-300 hover:scale-110 focus:outline-none focus:ring-4 focus:ring-[#0071E3] focus:ring-opacity-50 group">
        <svg className="w-10 h-10 text-[#0071E3] group-hover:text-[#005bbf] transition-colors duration-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
        </svg>
      </button>
    </div>
  </div>
  
</div>
    </div>

    <div className="mt-32 text-center">
      <a href="/compensation-request" className="inline-flex items-center justify-center px-12 py-6 border border-transparent text-2xl font-light rounded-full text-white bg-black hover:bg-opacity-90 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black group animate__animated animate__tada animate__delay-2s animate__slower animate__infinite">
        Fillo Kërkesën Tënde
        <svg className="ml-2 -mr-1 w-6 h-6 group-hover:translate-x-1 transition-transform duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
        </svg>
      </a>
    </div>
  </div>
</section> */}

<section>
<HomeHowItWorksSection />

</section>

      {/* Avioza Solution */}
      <section className="py-4 bg-white">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-6xl font-light mb-12">Zgjidhja Avioza</h2>
          <p className="text-2xl mb-16">Ne kemi transformuar procesin e kompensimit në një përvojë të thjeshtë dhe të këndshme</p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div className="group">
              <div className="aspect-w-1 aspect-h-1 bg-light-gray rounded-full flex items-center justify-center mb-6 transition-all duration-300 group-hover:bg-black group-hover:text-white">
                <svg className="w-16 h-16 text-black group-hover:text-white transition-colors duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path></svg>
              </div>
              <h3 className="text-2xl font-light mb-4">Teknologji e Avancuar</h3>
              <p className="text-lg text-medium-gray">Algoritmet tona inteligjente vlerësojnë kërkesën tuaj në sekonda</p>
            </div>
            <div className="group">
              <div className="aspect-w-1 aspect-h-1 bg-light-gray rounded-full flex items-center justify-center mb-6 transition-all duration-300 group-hover:bg-black group-hover:text-white">
                <svg className="w-16 h-16 text-black group-hover:text-white transition-colors duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"></path></svg>
              </div>
              <h3 className="text-2xl font-light mb-4">Ekspertizë Ligjore</h3>
              <p className="text-lg text-medium-gray">Ekipi ynë i specializuar siguron suksesin e kërkesës suaj</p>
            </div>
            <div className="group">
              <div className="aspect-w-1 aspect-h-1 bg-light-gray rounded-full flex items-center justify-center mb-6 transition-all duration-300 group-hover:bg-black group-hover:text-white">
                <svg className="w-16 h-16 text-black group-hover:text-white transition-colors duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
              </div>
              <h3 className="text-2xl font-light mb-4">Kompensim Maksimal</h3>
              <p className="text-lg text-medium-gray">Ne sigurojmë që ju të merrni shumën që meritoni, deri në 600€</p>
            </div>
          </div>
        </div>
      </section>

      {/* Trust and Security */}
      <section className="py-24 bg-white">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl sm:text-5xl font-bold text-center mb-16">Besimi dhe Siguria</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div>
              <h3 className="text-3xl font-light mb-8">Certifikimet Tona</h3>
              <ul className="space-y-6">
                <li className="flex items-center">
                  <svg className="w-8 h-8 text-green-500 mr-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"></path></svg>
                  <span className="text-xl">ISO 27001 Certifikim për Sigurinë e Informacionit</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-8 h-8 text-green-500 mr-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"></path></svg>
                  <span className="text-xl">GDPR Compliant</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-8 h-8 text-green-500 mr-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"></path></svg>
                  <span className="text-xl">SSL Encryption për Transaksione të Sigurta</span>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-2xl font-semibold mb-6">Kompensime të Suksesshme</h3>
              <div className="bg-[#F5F5F7] rounded-2xl p-8">
                <div className="text-5xl font-bold text-[#0071E3] mb-4">€30M+</div>
                <p className="text-xl text-gray-700">Kompensime të fituara për klientët tanë</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials */}
      <section id="testimonials-section" className="relative bg-black text-white overflow-hidden py-32 sm:py-48">
        <div className="absolute inset-0 opacity-30">
          <img src="https://avioza.al/assets/images/pexels-gustavo-fring-3885595.jpg" alt="" className="w-full h-full object-cover blur-sm" />
        </div>
        <div className="max-w-[980px] mx-auto px-6">
          <h2 className="text-center text-[40px] sm:text-[56px] lg:text-[80px] font-semibold leading-tight mb-16 testimonials-animate">
            Histori suksesi.
          </h2>
          
          <div className="relative testimonials-carousel">
            <div className="absolute top-0 left-1/2 -translate-x-1/2 w-[200%] aspect-[2/1] bg-gradient-to-b from-[#2997ff] via-[#2997ff]/50 to-transparent opacity-100 blur-3xl -z-10"></div>
            
            <div className="overflow-hidden">
              <div id="testimonials-container" className="flex transition-transform duration-500 ease-out">
                {/* Testimonials will be inserted here by JavaScript */}
              </div>
            </div>

            {/* Navigation dots */}
            <div id="navigation-dots" className="flex justify-center mt-12 space-x-3">
              {/* Dots will be inserted here by JavaScript */}
            </div>

            {/* Navigation arrows */}
            <button id="prev-btn" className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-black/20 hover:bg-black/40 p-3 rounded-full focus:outline-none transition-all duration-300 ease-in-out">
              <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path></svg>
            </button>
            <button id="next-btn" className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-black/20 hover:bg-black/40 p-3 rounded-full focus:outline-none transition-all duration-300 ease-in-out">
            <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
            </button>
          </div>

          <div className="mt-32 text-center testimonials-animate">
            <a href="#" className="inline-flex items-center text-[21px] text-[#2997ff] font-semibold group">
              Shiko të gjitha historitë e suksesit
              <svg className="w-5 h-5 ml-2 transition-transform duration-300 ease-in-out group-hover:translate-x-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
            </a>
          </div>
        </div>
      </section>

      {/* Avioza Advantages
      <section className="py-16 sm:py-24 bg-white overflow-hidden">
        <div className="max-w-[980px] mx-auto px-6">
          <h2 className="text-center text-[40px] sm:text-[56px] lg:text-[80px] font-semibold leading-tight text-[#1d1d1f] mb-16">
            Përparësitë Avioza.
          </h2>
          
          <div className="grid grid-cols-1 gap-12">
            <div className="text-center space-y-6">
              <h3 className="text-[28px] sm:text-[40px] font-semibold text-[#1d1d1f]">Metoda Tradicionale</h3>
              <p className="text-[21px] leading-relaxed text-[#86868b] max-w-3xl mx-auto">
                Procesi i zakonshëm për kërkimin e kompensimit është i ngadaltë dhe shpesh jo efektiv.
              </p>
            </div>
            
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-12">
              <div className="text-center">
                <div className="w-20 h-20 rounded-full bg-[#ff3b30] flex items-center justify-center mx-auto mb-6">
                  <svg className="w-10 h-10 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                </div>
                <h4 className="text-[21px] font-semibold text-[#1d1d1f] mb-2">Proces i gjatë</h4>
                <p className="text-[17px] leading-relaxed text-[#86868b]">Mund të zgjasë me muaj të tërë</p>
              </div>
              <div className="text-center">
                <div className="w-20 h-20 rounded-full bg-[#ff3b30] flex items-center justify-center mx-auto mb-6">
                  <svg className="w-10 h-10 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"></path></svg>
                </div>
                <h4 className="text-[21px] font-semibold text-[#1d1d1f] mb-2">Kompleks</h4>
                <p className="text-[17px] leading-relaxed text-[#86868b]">Kërkon njohuri ligjore specifike</p>
              </div>
              <div className="text-center">
                <div className="w-20 h-20 rounded-full bg-[#ff3b30] flex items-center justify-center mx-auto mb-6">
                  <svg className="w-10 h-10 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"></path></svg>
                </div>
                <h4 className="text-[21px] font-semibold text-[#1d1d1f] mb-2">Shpesh i pasuksesshëm</h4>
                <p className="text-[17px] leading-relaxed text-[#86868b]">Shumë kërkesa refuzohen</p>
              </div>
            </div>
          </div>

          <div className="mt-32 grid grid-cols-1 gap-12">
            <div className="text-center space-y-6">
              <h3 className="text-[28px] sm:text-[40px] font-semibold text-[#1d1d1f]">Me Avioza</h3>
              <p className="text-[21px] leading-relaxed text-[#86868b] max-w-3xl mx-auto">
                Ne kemi revolucionarizuar procesin, duke e bërë atë të shpejtë, të lehtë dhe efektiv.
              </p>
            </div>
            
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-12">
              <div className="text-center">
                <div className="w-20 h-20 rounded-full bg-[#2997ff] flex items-center justify-center mx-auto mb-6">
                  <svg className="w-10 h-10 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path></svg>
                </div>
                <h4 className="text-[21px] font-semibold text-[#1d1d1f] mb-2">I shpejtë</h4>
                <p className="text-[17px] leading-relaxed text-[#86868b]">Procesi zgjat vetëm disa minuta</p>
              </div>
              <div className="text-center">
                <div className="w-20 h-20 rounded-full bg-[#2997ff] flex items-center justify-center mx-auto mb-6">
                  <svg className="w-10 h-10 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"></path></svg>
                </div>
                <h4 className="text-[21px] font-semibold text-[#1d1d1f] mb-2">Ekspertizë</h4>
                <p className="text-[17px] leading-relaxed text-[#86868b]">Ekipi ynë ligjor merret me gjithçka</p>
              </div>
              <div className="text-center">
                <div className="w-20 h-20 rounded-full bg-[#2997ff] flex items-center justify-center mx-auto mb-6">
                  <svg className="w-10 h-10 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path></svg>
                </div>
                <h4 className="text-[21px] font-semibold text-[#1d1d1f] mb-2">Sukses i garantuar</h4>
                <p className="text-[17px] leading-relaxed text-[#86868b]">Shkallë e lartë suksesi në kërkesat</p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section>
<AviozaAdvantages />

</section>

<section>
{/* Interactive Flight Journey Timeline Section */}
<InteractiveFlightJourneyTimeline />
</section>

      {/* FAQ Section */}
      <section className="py-24 sm:py-24 bg-[#fbfbfd] overflow-hidden">
        <div className="max-w-[980px] mx-auto px-6">
          <h2 className="text-center text-[40px] sm:text-[56px] lg:text-[80px] font-semibold leading-tight text-[#1d1d1f] mb-16">
            Pyetje të Shpeshta.
          </h2>
          
          <div className="space-y-12">
            {/* FAQ items would go here */}
            {/* You might want to create a separate FAQ component for better organization */}
          </div>
        </div>
      </section>

      {/* Final CTA */}
      <section className="relative py-32 bg-black text-white overflow-hidden">
        <div className="absolute inset-0 opacity-50">
          <img src="https://avioza.al/assets/images/hero-traveler.jpg" alt="" className="w-full h-full object-cover" />
        </div>
        <div className="relative max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-5xl font-light mb-8">Gati për të Marrë Kompensimin që Meritoni?</h2>
          <p className="text-2xl font-light mb-12">Miliona euro në kompensime presin për t'u kërkuar. Mos lejoni që të drejtat tuaja të fluturojnë larg.</p>
          <Link to="#calculator" className="inline-block px-12 py-6 bg-white text-black rounded-full text-2xl font-light hover:bg-opacity-90 transition duration-300 transform hover:scale-105">
            Filloni Kërkesën Tuaj Tani
          </Link>
        </div>
      </section>

      {/* Contact and Support */}
      <section className="py-32 bg-light-gray">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-6xl font-light text-center mb-24">Kontakti dhe Mbështetja</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
            <div>
              <h3 className="text-3xl font-light mb-8">Na Kontaktoni</h3>
              <form className="space-y-6">
                <div>
                  <label htmlFor="name" className="block text-xl font-light text-medium-gray mb-2">Emri</label>
                  <input type="text" id="name" name="name" className="w-full px-4 py-3 border-2 border-black rounded-lg focus:ring-black focus:border-black transition duration-300" />
                </div>
                <div>
                  <label htmlFor="email" className="block text-xl font-light text-medium-gray mb-2">Email</label>
                  <input type="email" id="email" name="email" className="w-full px-4 py-3 border-2 border-black rounded-lg focus:ring-black focus:border-black transition duration-300" />
                </div>
                <div>
                  <label htmlFor="message" className="block text-xl font-light text-medium-gray mb-2">Mesazhi</label>
                  <textarea id="message" name="message" rows="4" className="w-full px-4 py-3 border-2 border-black rounded-lg focus:ring-black focus:border-black transition duration-300"></textarea>
                </div>
                <button type="submit" className="w-full bg-black text-white text-xl font-light py-4 rounded-lg hover:bg-opacity-90 transition duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black">
                  Dërgo Mesazhin
                </button>
              </form>
            </div>
            <div>
              <h3 className="text-3xl font-light mb-8">Mbështetje 24/7</h3>
              <div className="space-y-6">
                <div className="flex items-center">
                  <svg className="w-8 h-8 text-black mr-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path></svg>
                  <span className="text-xl">+355 69 123 4567</span>
                </div>
                <div className="flex items-center">
                  <svg className="w-8 h-8 text-black mr-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>
                  <span className="text-xl">support@avioza.al</span>
                </div>
                <div className="flex items-center">
                  <svg className="w-8 h-8 text-black mr-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"></path></svg>
                  <span className="text-xl">Chat i drejtpërdrejtë në dispozicion</span>
                </div>
              </div>
              <div className="mt-12">
                <h4 className="text-2xl font-light mb-4">Orari i Punës</h4>
                <p className="text-xl text-medium-gray">E Hënë - E Premte: 9:00 - 18:00</p>
                <p className="text-xl text-medium-gray">E Shtunë: 10:00 - 15:00</p>
                <p className="text-xl text-medium-gray">E Diel: Mbyllur</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Compensation Chart */}
    </div>
  );
};

export default Home;