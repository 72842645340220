import React from 'react';
import { X } from 'lucide-react';

const Dialog = ({ children, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center" role="dialog" aria-modal="true">
      <div className="bg-white rounded-lg p-6 max-w-md w-full m-4">
        <button onClick={onClose} className="float-right text-gray-500 hover:text-gray-700" aria-label="Mbyll">
          <X className="h-6 w-6" />
        </button>
        {children}
      </div>
    </div>
  );
};

Dialog.Header = ({ children }) => (
  <div className="mb-4">{children}</div>
);

Dialog.Title = ({ children }) => (
  <h3 className="text-lg font-semibold mb-2">{children}</h3>
);

Dialog.Description = ({ children }) => (
  <div className="text-sm text-gray-600">{children}</div>
);

export default Dialog;