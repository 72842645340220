import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCompensation } from '../hooks/useCompensation';
import { useNotification } from '../hooks/useNotification';
import ClaimHeader from '../components/dashboard/claimDetails/ClaimHeader';
import FlightOverview from '../components/dashboard/claimDetails/FlightOverview';
import ClaimProgress from '../components/dashboard/claimDetails/ClaimProgress';
import CompensationInfo from '../components/dashboard/claimDetails/CompensationInfo';
import PassengerInfo from '../components/dashboard/claimDetails/PassengerInfo';
import WeatherConditions from '../components/dashboard/claimDetails/WeatherConditions';
import AdditionalInfo from '../components/dashboard/claimDetails/AdditionalInfo';
import BankDetails from '../components/dashboard/claimDetails/BankDetails';
import Documents from '../components/dashboard/claimDetails/Documents';
import Actions from '../components/dashboard/claimDetails/Actions';
import UpdateModal from '../components/dashboard/claimDetails/UpdateModal';
import SupportModal from '../components/dashboard/claimDetails/SupportModal';

const ClaimDetails = () => {
  const { id } = useParams();
  const { getClaimById, updateClaim, submitSupportRequest, updateBankDetails } = useCompensation();
  const { showNotification } = useNotification();
  const [claim, setClaim] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [airportInfo, setAirportInfo] = useState({ departure: null, arrival: null });

  useEffect(() => {
    fetchClaimDetails();
  }, [id]);

  useEffect(() => {
    if (claim) {
      fetchAirportInfo(claim.departure_airport.iata, 'departure');
      fetchAirportInfo(claim.arrival_airport.iata, 'arrival');
    }
  }, [claim]);

  const fetchClaimDetails = async () => {
    try {
      setLoading(true);
      const claimData = await getClaimById(id);
      setClaim(claimData);
      setError(null);
    } catch (err) {
      console.error('Gabim gjatë marrjes së detajeve të kërkesës:', err);
      setError('Dështoi në marrjen e detajeve të kërkesës. Ju lutemi provoni përsëri më vonë.');
    } finally {
      setLoading(false);
    }
  };

  const fetchAirportInfo = async (iataCode, type) => {
    try {
      const response = await fetch(`https://ws3.daymail.info/api/airports/info/${iataCode}`);
      if (!response.ok) {
        throw new Error('Failed to fetch airport information');
      }
      const data = await response.json();
      setAirportInfo(prevState => ({
        ...prevState,
        [type]: data
      }));
    } catch (error) {
      console.error(`Error fetching ${type} airport info:`, error);
    }
  };

  const handleUpdateSubmit = async (updateMessage) => {
    try {
      await updateClaim(claim._id, updateMessage);
      showNotification('Kërkesa juaj për përditësim u dërgua me sukses', 'success');
      setShowUpdateModal(false);
      fetchClaimDetails();
    } catch (error) {
      showNotification(error.message, 'error');
    }
  };

  const handleSupportSubmit = async (supportMessage) => {
    try {
      await submitSupportRequest(claim._id, supportMessage);
      showNotification('Kërkesa juaj për mbështetje u dërgua me sukses', 'success');
      setShowSupportModal(false);
    } catch (error) {
      showNotification(error.message, 'error');
    }
  };

  const handleBankDetailsSubmit = async (bankDetails) => {
    try {
      const updatedClaim = await updateBankDetails(claim._id, bankDetails);
      showNotification('Detajet bankare u ruajtën me sukses', 'success');
      setClaim(updatedClaim);
    } catch (error) {
      console.error('Error updating bank details:', error);
      showNotification(error.message, 'error');
    }
  };

  if (loading) return <div>Duke ngarkuar...</div>;
  if (error) return <div>{error}</div>;
  if (!claim) return <div>Kërkesa nuk u gjet</div>;

  return (
    <div className="bg-white min-h-screen font-['SF_Pro_Display',-apple-system,BlinkMacSystemFont,'Segoe_UI',Roboto,Helvetica,Arial,sans-serif] text-gray-900">
      <ClaimHeader claim={claim} />
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
        <FlightOverview claim={claim} airportInfo={airportInfo} />
        <ClaimProgress claim={claim} />
        <CompensationInfo claim={claim} />
        <PassengerInfo claim={claim} />
        {claim.weather_conditions && <WeatherConditions weatherConditions={claim.weather_conditions} />}
        <AdditionalInfo claim={claim} />
        <BankDetails claim={claim} onSubmit={handleBankDetailsSubmit} />
        <Documents claim={claim} />
        <Actions
          onOpenUpdateModal={() => setShowUpdateModal(true)}
          onOpenSupportModal={() => setShowSupportModal(true)}
        />
      </main>
      {showUpdateModal && (
        <UpdateModal
          onClose={() => setShowUpdateModal(false)}
          onSubmit={handleUpdateSubmit}
        />
      )}
      {showSupportModal && (
        <SupportModal
          onClose={() => setShowSupportModal(false)}
          onSubmit={handleSupportSubmit}
        />
      )}
    </div>
  );
};

export default ClaimDetails;