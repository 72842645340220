import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ScrollReveal from 'scrollreveal';
import 'animate.css';

const Mbingarkimi = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    ScrollReveal().reveal('.container > div', { 
      delay: 200,
      distance: '50px',
      origin: 'bottom',
      duration: 1000,
      easing: 'cubic-bezier(0.5, 0, 0, 1)',
      interval: 150
    });
  }, []);

  return (
    <div className="mbingarkimi-e-fluturimeve">
      <section className="hero relative bg-gradient-to-r from-blue-600 to-indigo-800 text-white overflow-hidden">
        <div className="absolute inset-0">
          <img src="/assets/images/flight_cancel.jpg" alt="Overbooked Flight" className="w-full h-full object-cover opacity-90" />
          <div className="absolute inset-0 bg-stone-600 mix-blend-multiply"></div>
        </div>

        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
            Mbingarkimi i Fluturimeve
          </h1>
          <p className="mt-6 max-w-3xl text-xl text-blue-100">
            Mësoni të drejtat tuaja dhe si të merrni kompensimin që meritoni në rastet e mbingarkimit të fluturimeve.
          </p>

          <div className="mt-10 max-w-sm sm:max-w-none sm:flex">
            <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
              <a href="#learn-more" className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-blue-700 bg-white hover:bg-blue-50 sm:px-8">
                Mëso më shumë
              </a>
              <Link to="/compensation/request" className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-500 bg-opacity-60 hover:bg-opacity-70 sm:px-8">
                Kontrollo fluturimin
              </Link>
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 left-0 right-0">
          <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax">
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </div>
      </section>

      <section id="learn-more" className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Të drejtat tuaja</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Çfarë duhet të dini për mbingarkimin e fluturimeve
            </p>
          </div>

          <div className="mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                    </svg>
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Çfarë është mbingarkimi?</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  Mbingarkimi ndodh kur linja ajrore shet më shumë bileta sesa ka vende në dispozicion në avion.
                </dd>
              </div>

              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                    </svg>
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Sa kompensim mund të merrni?</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  Kompensimi varion nga 250€ deri në 600€, në varësi të distancës së fluturimit dhe vonesës në mbërritje.
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </section>

      <section className="py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-semibold mb-16 text-center">Të drejtat tuaja në rast mbingarkimi</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div className="bg-white rounded-xl shadow-lg p-8 flex flex-col items-center text-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <div className="bg-blue-100 rounded-full p-6 mb-4">
                <svg className="h-12 w-12 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <h3 className="text-2xl font-semibold mb-4">Zgjedhja juaj</h3>
              <p className="text-gray-600">Ju keni të drejtë të zgjidhni midis rimbursimit të plotë ose një fluturimi alternativ.</p>
            </div>
            <div className="bg-white rounded-xl shadow-lg p-8 flex flex-col items-center text-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <div className="bg-blue-100 rounded-full p-6 mb-4">
                <svg className="h-12 w-12 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <h3 className="text-2xl font-semibold mb-4">Kompensim financiar</h3>
              <p className="text-gray-600">Ju keni të drejtë për kompensim financiar, plus rimbursimin e biletës ose fluturimin alternativ.</p>
            </div>
            <div className="bg-white rounded-xl shadow-lg p-8 flex flex-col items-center text-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <div className="bg-blue-100 rounded-full p-6 mb-4">
                <svg className="h-12 w-12 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
              </div>
              <h3 className="text-2xl font-semibold mb-4">Kujdes dhe ndihmë</h3>
              <p className="text-gray-600">Ju keni të drejtë për ushqim, pije, akomodim dhe transport gjatë pritjes për fluturimin alternativ.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-24">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-semibold mb-16 text-center">Si të veproni në rast mbingarkimi</h2>
          <div className="max-w-4xl mx-auto">
            <div className="bg-white rounded-xl shadow-lg p-8">
              <ol className="list-none space-y-6">
                <li className="flex items-center">
                  <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-blue-600 text-white rounded-full mr-4">1</span>
                  <span className="text-lg text-gray-700">Kërkoni konfirmim me shkrim nga linja ajrore për arsyen e refuzimit të hyrjes në bord</span>
                </li>
                <li className="flex items-center">
                  <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-blue-600 text-white rounded-full mr-4">2</span>
                  <span className="text-lg text-gray-700">Ruani të gjitha dokumentet përkatëse (boarding pass, email konfirmimi, etj.)</span>
                </li>
                <li className="flex items-center">
                  <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-blue-600 text-white rounded-full mr-4">3</span>
                  <span className="text-lg text-gray-700">Kërkoni informacion për të drejtat tuaja dhe opsionet e disponueshme</span>
                </li>
                <li className="flex items-center">
                  <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-blue-600 text-white rounded-full mr-4">4</span>
                  <span className="text-lg text-gray-700">Zgjidhni midis rimbursimit të plotë ose fluturimit alternativ</span>
                </li>
                <li className="flex items-center">
                  <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-blue-600 text-white rounded-full mr-4">5</span>
                  <span className="text-lg text-gray-700">Kërkoni kompensimin financiar që ju takon</span>
                </li>
                <li className="flex items-center">
                <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-blue-600 text-white rounded-full mr-4">6</span>
                  <span className="text-lg text-gray-700">Nëse linja ajrore refuzon, kontaktoni Avioza për ndihmë të specializuar</span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-semibold mb-16 text-center">Shuma e kompensimit për mbingarkim</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div className="bg-white rounded-xl shadow-lg p-8 text-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <div className="bg-blue-100 rounded-full p-6 mx-auto mb-4 w-24 h-24 flex items-center justify-center">
                <svg className="h-12 w-12 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                </svg>
              </div>
              <h3 className="text-2xl font-semibold mb-4">Deri në 1,500 km</h3>
              <p className="text-4xl font-bold text-blue-600">250€</p>
            </div>
            <div className="bg-white rounded-xl shadow-lg p-8 text-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <div className="bg-blue-100 rounded-full p-6 mx-auto mb-4 w-24 h-24 flex items-center justify-center">
                <svg className="h-12 w-12 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                </svg>
              </div>
              <h3 className="text-2xl font-semibold mb-4">1,500 km - 3,500 km</h3>
              <p className="text-4xl font-bold text-blue-600">400€</p>
            </div>
            <div className="bg-white rounded-xl shadow-lg p-8 text-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <div className="bg-blue-100 rounded-full p-6 mx-auto mb-4 w-24 h-24 flex items-center justify-center">
                <svg className="h-12 w-12 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                </svg>
              </div>
              <h3 className="text-2xl font-semibold mb-4">Mbi 3,500 km</h3>
              <p className="text-4xl font-bold text-blue-600">600€</p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-24">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-semibold mb-16 text-center">Si mund t'ju ndihmojë Avioza</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div className="bg-white rounded-xl shadow-lg p-8 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <div className="bg-blue-100 rounded-full p-6 w-24 h-24 flex items-center justify-center mb-6">
                <svg className="h-12 w-12 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <h3 className="text-2xl font-semibold mb-4">Vlerësim i shpejtë dhe falas</h3>
              <p className="text-gray-600">Ne do të vlerësojmë menjëherë nëse rasti juaj i mbingarkimit kualifikohet për kompensim, pa asnjë kosto për ju.</p>
            </div>
            <div className="bg-white rounded-xl shadow-lg p-8 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <div className="bg-blue-100 rounded-full p-6 w-24 h-24 flex items-center justify-center mb-6">
                <svg className="h-12 w-12 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                </svg>
              </div>
              <h3 className="text-2xl font-semibold mb-4">Përgatitje dhe dorëzim i kërkesës</h3>
              <p className="text-gray-600">Ne do të përgatisim të gjithë dokumentacionin e nevojshëm dhe do ta dorëzojmë kërkesën tuaj në emrin tuaj.</p>
            </div>
            <div className="bg-white rounded-xl shadow-lg p-8 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <div className="bg-blue-100 rounded-full p-6 w-24 h-24 flex items-center justify-center mb-6">
                <svg className="h-12 w-12 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                </svg>
              </div>
              <h3 className="text-2xl font-semibold mb-4">Komunikim me linjën ajrore</h3>
              <p className="text-gray-600">Ne do të komunikojmë drejtpërdrejt me linjën ajrore për të siguruar kompensimin tuaj, duke ju kursyer kohë dhe stres.</p>
            </div>
            <div className="bg-white rounded-xl shadow-lg p-8 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <div className="bg-blue-100 rounded-full p-6 w-24 h-24 flex items-center justify-center mb-6">
                <svg className="h-12 w-12 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                </svg>
              </div>
              <h3 className="text-2xl font-semibold mb-4">Ndihmë ligjore</h3>
              <p className="text-gray-600">Nëse është e nevojshme, ne ofrojmë ndihmë ligjore për të siguruar që ju të merrni kompensimin që meritoni.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="cta bg-gradient-to-r from-blue-500 to-indigo-600 py-24">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl font-semibold text-white mb-8">Gati për të kontrolluar fluturimin tuaj?</h2>
          <p className="text-xl text-blue-100 mb-12">Zbuloni nëse kualifikoheni për kompensim për mbingarkimin e fluturimit tuaj.</p>
          <Link to="/compensation/request" className="inline-block bg-white text-blue-600 font-semibold px-8 py-4 rounded-full shadow-lg hover:bg-blue-50 transition duration-300 animate__animated animate__pulse animate__infinite">
            Kontrollo Tani
          </Link>
        </div>
      </section>

      <style jsx>{`
        .waves {
          position: relative;
          width: 100%;
          height: 15vh;
          margin-bottom: -7px;
          min-height: 100px;
          max-height: 150px;
        }
        .parallax > use {
          animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
        }
        .parallax > use:nth-child(1) {
          animation-delay: -2s;
          animation-duration: 7s;
        }
        .parallax > use:nth-child(2) {
          animation-delay: -3s;
          animation-duration: 10s;
        }
        .parallax > use:nth-child(3) {
          animation-delay: -4s;
          animation-duration: 13s;
        }
        .parallax > use:nth-child(4) {
          animation-delay: -5s;
          animation-duration: 20s;
        }
        @keyframes move-forever {
          0% {
            transform: translate3d(-90px,0,0);
          }
          100% { 
            transform: translate3d(85px,0,0);
          }
        }
      `}</style>
    </div>
  );
};

export default Mbingarkimi;