import React, { useState, useMemo } from 'react';
import { 
  ChevronDownIcon, 
  ChevronUpIcon,
  PencilIcon,
  EyeIcon
} from '@heroicons/react/24/outline';
import { formatDate, formatCurrency, translateStatus, translatePayoutStatus } from '../../utils/translationUtils';

const AdminClaimsTable = ({ claims, onSelectClaim }) => {
  const [sortColumn, setSortColumn] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('desc');
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 10;

  const filteredAndSortedClaims = useMemo(() => {
    return claims
      .filter(claim => 
        Object.values(claim).some(value => 
          value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
      .sort((a, b) => {
        if (a[sortColumn] < b[sortColumn]) return sortDirection === 'asc' ? -1 : 1;
        if (a[sortColumn] > b[sortColumn]) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
  }, [claims, searchTerm, sortColumn, sortDirection]);

  const paginatedClaims = useMemo(() => {
    const startIndex = (page - 1) * itemsPerPage;
    return filteredAndSortedClaims.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredAndSortedClaims, page]);

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  return (
    <div className="mt-8">
      <div className="mb-4">
        <input
          type="text"
          placeholder="Kërko kërkesat..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        />
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {['PNR', 'Përdoruesi', 'Fluturimi', 'Shuma', 'Statusi', 'Statusi i Pagesës', 'Krijuar më', 'Veprime'].map((header) => (
                <th
                  key={header}
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort(header.toLowerCase().replace(' ', ''))}
                >
                  <div className="flex items-center">
                    {header}
                    {sortColumn === header.toLowerCase().replace(' ', '') && (
                      sortDirection === 'asc' ? <ChevronUpIcon className="w-4 h-4 ml-1" /> : <ChevronDownIcon className="w-4 h-4 ml-1" />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {paginatedClaims.map((claim) => (
              <tr key={claim._id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{claim.pnr}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {claim.user ? claim.user.email : 'N/A'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{claim.flight_number}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatCurrency(claim.amount)}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(claim.status)}`}>
                    {translateStatus(claim.status)}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getPayoutStatusColor(claim.payout_status)}`}>
                    {translatePayoutStatus(claim.payout_status)}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatDate(claim.createdAt)}</td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button
                    onClick={() => onSelectClaim(claim)}
                    className="text-indigo-600 hover:text-indigo-900"
                  >
                    <EyeIcon className="h-5 w-5" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4 flex justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Duke shfaqur <span className="font-medium">{(page - 1) * itemsPerPage + 1}</span> deri në <span className="font-medium">{Math.min(page * itemsPerPage, filteredAndSortedClaims.length)}</span> nga{' '}
            <span className="font-medium">{filteredAndSortedClaims.length}</span> rezultate
          </p>
        </div>
        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
          <button
            onClick={() => setPage(page - 1)}
            disabled={page === 1}
            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          >
            Prapa
          </button>
          <button
            onClick={() => setPage(page + 1)}
            disabled={page * itemsPerPage >= filteredAndSortedClaims.length}
            className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          >
            Përpara
          </button>
        </nav>
      </div>
    </div>
  );
};

const getStatusColor = (status) => {
  switch (status) {
    case 'pending': return 'bg-yellow-100 text-yellow-800';
    case 'under_review': return 'bg-blue-100 text-blue-800';
    case 'approved': return 'bg-green-100 text-green-800';
    case 'rejected': return 'bg-red-100 text-red-800';
    case 'on_hold': return 'bg-gray-100 text-gray-800';
    default: return 'bg-gray-100 text-gray-800';
  }
};

const getPayoutStatusColor = (status) => {
  switch (status) {
    case 'pending': return 'bg-yellow-100 text-yellow-800';
    case 'awaiting_bank_details': return 'bg-blue-100 text-blue-800';
    case 'in_process': return 'bg-purple-100 text-purple-800';
    case 'completed': return 'bg-green-100 text-green-800';
    case 'failed': return 'bg-red-100 text-red-800';
    default: return 'bg-gray-100 text-gray-800';
  }
};

export default AdminClaimsTable;