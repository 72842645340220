import React from 'react';
import { AlertTriangle, CheckCircle, RefreshCw, AlertOctagon, Info } from 'lucide-react';
import Button from './Button';
import Alert from './Alert';

const ResultStep = ({ scanError, scanResult, onReset, onManualEntry }) => {
  return (
    <div className="space-y-4">
      {scanError ? (
        <Alert variant="destructive">
          <AlertTriangle className="h-5 w-5 mb-2" />
          <Alert.Title>Gabim në skanim</Alert.Title>
          <Alert.Description>
            <p>{scanError}</p>
            <p className="mt-2 text-sm">
              Nëse problemi vazhdon, ju mund të:
              <ul className="list-disc pl-5 mt-1">
                <li>Provoni të skanoni përsëri me një foto më të qartë</li>
                <li>Plotësoni detajet manualisht duke klikuar butonin më poshtë</li>
                <li>Kontaktoni mbështetjen tonë për ndihmë</li>
              </ul>
            </p>
          </Alert.Description>
        </Alert>
      ) : (
        <Alert variant="success">
          <CheckCircle className="h-5 w-5 mb-2" />
          <Alert.Title>Skanimi u krye me sukses!</Alert.Title>
          <Alert.Description>
            {scanResult && (
              <div className="mt-2">
                <p><strong>Numri i fluturimit:</strong> {scanResult.flightNumber}</p>
                <p><strong>Data e nisjes:</strong> {scanResult.departureDate}</p>
                <p className="mt-2 text-sm">
                  Këto informacione do të përdoren për të kontrolluar të drejtën tuaj për kompensim. Ju lutemi konfirmoni nëse detajet janë të sakta.
                </p>
              </div>
            )}
          </Alert.Description>
        </Alert>
      )}
      <div className="flex space-x-2">
        <Button onClick={onReset} className="flex-1">
          <RefreshCw className="mr-2 h-4 w-4" />
          Skano një tjetër
        </Button>
        <Button variant="secondary" onClick={() => {}} className="flex-1">
          <Info className="mr-2 h-4 w-4" />
          Çfarë ndodh tani?
        </Button>
      </div>
      {scanError && (
        <Button variant="secondary" onClick={onManualEntry} className="w-full mt-2">
          <AlertOctagon className="mr-2 h-4 w-4" />
          Fut detajet manualisht
        </Button>
      )}
    </div>
  );
};

export default ResultStep;