import React from 'react';
import { getStatusClass, translateStatus } from '../../../utils/translationUtils';

const ClaimHeader = ({ claim }) => {
  return (
    <header className="sticky top-0 z-50 bg-white/85 backdrop-blur-md border-b border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 flex justify-between items-center">
        <h1 className="text-2xl sm:text-3xl font-semibold">Kërkesa #{claim.pnr || claim._id}</h1>
        <div className="flex items-center space-x-4">
          <span 
            className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusClass(claim.status)}`}
          >
            {translateStatus(claim.status)}
          </span>
          <span className="text-2xl sm:text-3xl font-semibold">€{claim.compensation_calculation.base_amount}</span>
        </div>
      </div>
    </header>
  );
};

export default ClaimHeader;