import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ScrollReveal from 'scrollreveal';
import 'animate.css';

const Shtypi = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    ScrollReveal().reveal('.container > div', { 
      delay: 200,
      distance: '50px',
      origin: 'bottom',
      duration: 1000,
      easing: 'cubic-bezier(0.5, 0, 0, 1)',
      interval: 150
    });
  }, []);

  return (
    <div className="shtypi">
      <section className="hero relative bg-gradient-to-r from-blue-600 to-indigo-800 text-white overflow-hidden">
        <div className="absolute inset-0">
          <img src="/assets/images/flight_cancel.jpg" alt="Press" className="w-full h-full object-cover opacity-20" />
          <div className="absolute inset-0 bg-blue-600 mix-blend-multiply"></div>
        </div>

        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
            Shtypi
          </h1>
          <p className="mt-6 max-w-3xl text-xl text-blue-100">
            Informacione dhe burime për gazetarët dhe mediat rreth Avioza dhe industrisë së kompensimit të fluturimeve.
          </p>
        </div>

        <div className="absolute bottom-0 left-0 right-0">
          <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax">
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </div>
      </section>

      <section className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center mb-12">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Komunikimet e Fundit</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Njoftimet tona për shtyp
            </p>
          </div>

          <div className="space-y-8">
            {[
              {
                title: "Avioza zgjeron shërbimet e saj në Evropën Juglindore",
                date: "Publikuar më 20 Qershor, 2023",
                description: "Avioza njofton zgjerimin e shërbimeve të saj të kompensimit të fluturimeve në vendet e Evropës Juglindore, duke përfshirë Shqipërinë, Maqedoninë e Veriut dhe Malin e Zi.",
                link: "/shtypi/njoftim/1"
              },
              {
                title: "Avioza arrin shifrën e 10,000 klientëve të suksesshëm",
                date: "Publikuar më 15 Maj, 2023",
                description: "Avioza ka arritur një gur kilometrik të rëndësishëm duke ndihmuar 10,000 klientë të marrin kompensimin e merituar për fluturimet e vonuara ose të anuluara.",
                link: "/shtypi/njoftim/2"
              }
            ].map((release, index) => (
              <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
                <div className="p-6">
                  <h3 className="text-2xl font-bold text-gray-900 mb-2">{release.title}</h3>
                  <p className="text-gray-600 mb-4">{release.date}</p>
                  <p className="text-gray-700 mb-4">{release.description}</p>
                  <Link to={release.link} className="inline-block bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-300">Lexo më shumë</Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center mb-12">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Në Media</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Avioza në lajme
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                title: '"Avioza po revolucionarizon industrinë e kompensimit të fluturimeve"',
                source: "Gazeta Ekonomike, 10 Qershor, 2023",
                link: "#"
              },
              {
                title: '"Si Avioza po ndihmon pasagjerët të marrin atë që u takon"',
                source: "Revista e Udhëtimit, 5 Maj, 2023",
                link: "#"
              },
              {
                title: '"Intervistë me CEO-n e Avioza: Sfidat e industrisë së aviacionit"',
                source: "Portali i Biznesit, 20 Prill, 2023",
                link: "#"
              }
            ].map((mention, index) => (
              <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
                <div className="p-6">
                  <h3 className="text-xl font-bold text-gray-900 mb-2">{mention.title}</h3>
                  <p className="text-gray-600 mb-4">{mention.source}</p>
                  <a href={mention.link} className="text-blue-600 hover:underline">Lexo artikullin e plotë</a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center mb-12">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Burime për Media</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Pako e Shtypit
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {[
              {
                title: "Logot dhe Imazhet",
                description: "Shkarkoni logot tona në formate të ndryshme dhe imazhe me cilësi të lartë për përdorim në artikujt tuaj.",
                link: "/assets/press-kit/avioza-logos.zip",
                buttonText: "Shkarko Pakon e Logove"
              },
              {
                title: "Fletë Faktesh",
                description: "Informacione të detajuara rreth Avioza, statistika kyçe dhe fakte rreth industrisë së kompensimit të fluturimeve.",
                link: "/assets/press-kit/avioza-fact-sheet.pdf",
                buttonText: "Shkarko Fletën e Fakteve"
              }
            ].map((resource, index) => (
              <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
                <div className="p-6">
                  <h3 className="text-xl font-bold text-gray-900 mb-4">{resource.title}</h3>
                  <p className="text-gray-700 mb-4">{resource.description}</p>
                  <a href={resource.link} className="inline-block bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-300">{resource.buttonText}</a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Kontakti për Media</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Na kontaktoni
            </p>
            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
              Për çdo kërkesë për media ose intervista, ju lutemi na kontaktoni në:
            </p>
          </div>
          <div className="mt-10 text-center">
            <p className="text-lg text-gray-700">Email: press@avioza.al</p>
            <p className="text-lg text-gray-700">Telefon: +355 69 123 4567</p>
          </div>
        </div>
      </section>

      <style jsx>{`
        .waves {
          position: relative;
          width: 100%;
          height: 15vh;
          margin-bottom: -7px;
          min-height: 100px;
          max-height: 150px;
        }
        .parallax > use {
          animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
        }
        .parallax > use:nth-child(1) {
          animation-delay: -2s;
          animation-duration: 7s;
        }
        .parallax > use:nth-child(2) {
          animation-delay: -3s;
          animation-duration: 10s;
        }
        .parallax > use:nth-child(3) {
          animation-delay: -4s;
          animation-duration: 13s;
        }
        .parallax > use:nth-child(4) {
          animation-delay: -5s;
          animation-duration: 20s;
        }
        @keyframes move-forever {
          0% {
            transform: translate3d(-90px,0,0);
          }
          100% { 
            transform: translate3d(85px,0,0);
          }
        }
      `}</style>
    </div>
  );
};

export default Shtypi;