import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { adminService } from '../services/adminService';
import { useNotification } from '../hooks/useNotification';
import AdminSummaryCards from '../components/admin/AdminSummaryCards';
import AdminClaimsTable from '../components/admin/AdminClaimsTable';
import AdminStatusChart from '../components/admin/AdminStatusChart';
import AdminCompensationTrend from '../components/admin/AdminCompensationTrend';
import AdminUserManagement from '../components/admin/AdminUserManagement';
import AdminSettings from '../components/admin/AdminSettings';
import AdminFilters from '../components/admin/AdminFilters';
import AdminClaimDetails from '../components/admin/AdminClaimDetails';
import LoadingSpinner from '../components/common/LoadingSpinner';
import ErrorMessage from '../components/common/ErrorMessage';

const Admin = () => {
  const [claims, setClaims] = useState([]);
  const [users, setUsers] = useState([]);
  const [analytics, setAnalytics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({});
  const [selectedClaim, setSelectedClaim] = useState(null);
  const [updatingClaim, setUpdatingClaim] = useState(false);
  const { showNotification } = useNotification();

  const fetchAdminData = useCallback(async () => {
    try {
      setLoading(true);
      const [claimsData, usersData, analyticsData] = await Promise.all([
        adminService.getAllClaims(filters),
        adminService.getAllUsers(),
        adminService.getAnalyticsData()
      ]);
      setClaims(claimsData);
      setUsers(usersData);
      setAnalytics(analyticsData);
      setError(null);
    } catch (error) {
      console.error('Gabim gjatë marrjes së të dhënave të administratorit:', error);
      setError('Dështoi në marrjen e të dhënave të administratorit. Ju lutemi provoni përsëri.');
      showNotification('Gabim në ngarkimin e të dhënave të administratorit', 'error');
    } finally {
      setLoading(false);
    }
  }, [filters, showNotification]);

  useEffect(() => {
    fetchAdminData();
  }, [fetchAdminData]);

  const handleUpdateClaim = async (updatedClaim) => {
    setUpdatingClaim(true);
    try {
      const result = await adminService.updateClaim(updatedClaim.pnr, updatedClaim);
      setClaims(prevClaims => prevClaims.map(claim => 
        claim.pnr === updatedClaim.pnr ? result : claim
      ));
      showNotification('Kërkesa u përditësua me sukses', 'success');
      setSelectedClaim(null);
    } catch (error) {
      console.error('Error updating claim:', error);
      showNotification('Dështoi në përditësimin e kërkesës: ' + (error.message || 'Ndodhi një gabim i panjohur'), 'error');
    } finally {
      setUpdatingClaim(false);
    }
  };

  const handleUpdateUserRole = async (userId, newRole) => {
    try {
      await adminService.updateUserRole(userId, newRole);
      showNotification('Roli i përdoruesit u përditësua me sukses', 'success');
      fetchAdminData();
    } catch (error) {
      showNotification('Dështoi në përditësimin e rolit të përdoruesit', 'error');
    }
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  const handleSelectClaim = (claim) => {
    setSelectedClaim(claim);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorMessage message={error} />;
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <Helmet>
        <title>Paneli i Administratorit | Avioza</title>
      </Helmet>
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Paneli i Administratorit</h1>
        
        <AdminSummaryCards analytics={analytics} />
        
        <div className="mt-8 grid grid-cols-1 gap-8 lg:grid-cols-2">
          <AdminStatusChart claims={claims} />
          <AdminCompensationTrend analytics={analytics} />
        </div>
        
        <AdminFilters onFilterChange={handleFilterChange} />
        
        {selectedClaim ? (
          <AdminClaimDetails 
            claim={selectedClaim} 
            onUpdate={handleUpdateClaim}
            onClose={() => setSelectedClaim(null)}
            isUpdating={updatingClaim}
          />
        ) : (
          <AdminClaimsTable 
            claims={claims} 
            onSelectClaim={handleSelectClaim}
          />
        )}
        
        <AdminUserManagement 
          users={users} 
          onUpdateRole={handleUpdateUserRole} 
        />
        
        <AdminSettings />
      </main>
    </div>
  );
};

export default Admin;
