import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/layout/Layout';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import ClaimDetails from './pages/ClaimDetails';
import CompensationRequest from './pages/CompensationRequest';
import Admin from './pages/Admin';
import HowItWorks from './pages/HowItWorks';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';
import PrivateRoute from './components/auth/PrivateRoute';
import AdminRoute from './components/auth/AdminRoute';
import VonesatEFluturimeve from './pages/VonesatEFluturimeve';
import Anulimet from './pages/Anulimet';
import LiveChat from './pages/LiveChat';
import Mbingarkimi from './pages/Mbingarkimi';
import PyetjeTeShpeshta from './pages/PyetjeTeShpeshta';
import RrethNesh from './pages/RrethNesh';
import Blog from './pages/Blog';
import Shtypi from './pages/Shtypi';
import Partneret from './pages/Partneret';
import Privatesia from './pages/Privatesia';
import Kushtet from './pages/Kushtet';
import AIAssistant from './components/common/AIAssistant';

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/vonesat-e-fluturimeve" element={<VonesatEFluturimeve />} />
        <Route path="/anulimet" element={<Anulimet />} />
        <Route path="/mbingarkimi" element={<Mbingarkimi />} />
        <Route path="/live-chat" element={<LiveChat />} />
        <Route path="/pyetje-te-shpeshta" element={<PyetjeTeShpeshta />} />
        <Route path="/rreth-nesh" element={<RrethNesh />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/shtypi" element={<Shtypi />} />
        <Route path="/partneret" element={<Partneret />} />
        <Route path="/privatesia" element={<Privatesia />} />
        <Route path="/kushtet" element={<Kushtet />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route path="/dashboard/claim/:id" element={<ClaimDetails />} />
        <Route
          path="/compensation-request"
          element={
            <PrivateRoute>
              <CompensationRequest />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/*"
          element={
            <AdminRoute>
              <Admin />
            </AdminRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <AIAssistant />
    </Layout>
  );
}

export default App;