import React from 'react';
import { Camera, Upload, Info } from 'lucide-react';
import Button from './Button';
import Alert from './Alert';

const InitialStep = ({ onCameraCapture, onFileSelect, onManualEntry }) => {
  return (
    <>
      <Button onClick={onCameraCapture} className="w-full mb-4" aria-label="Skano me kamerë">
        <Camera className="mr-2 h-5 w-5" />
        Skano me Kamerë
      </Button>
      <Button 
        variant="secondary" 
        className="w-full mb-4"
        onClick={onFileSelect}
        aria-label="Ngarko foto të boarding pass"
      >
        <Upload className="mr-2 h-5 w-5" />
        Ngarko Foto të Boarding Pass
      </Button>
      <Button variant="secondary" onClick={onManualEntry} className="w-full mb-4">
        <Info className="mr-2 h-5 w-5" />
        Fut detajet manualisht
      </Button>
      <Alert className="mt-4">
        <Alert.Title>Këshillë</Alert.Title>
        <Alert.Description>
          Sigurohuni që boarding pass-i juaj është i pastër dhe i drejtë përpara se ta skanoni ose fotografoni. Kjo do të rrisë saktësinë e skanimit.
        </Alert.Description>
      </Alert>
    </>
  );
};

export default InitialStep;