import React, { useState, useEffect } from 'react';

const BankDetailsModal = ({ claim, onClose, onUpdateStatus, fetchBankDetails, updateBankDetails }) => {
  const [bankDetails, setBankDetails] = useState({
    iban: '',
    swift_bic: '',
    account_holder_name: '',
    bank_name: ''
  });
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadBankDetails();
  }, []);

  const loadBankDetails = async () => {
    try {
      setLoading(true);
      const details = await fetchBankDetails();
      if (details) {
        setBankDetails(details);
        setShowForm(false);
      } else {
        setShowForm(true);
      }
    } catch (error) {
      console.error('Error fetching bank details:', error);
      setShowForm(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateBankDetails(bankDetails);
      await onUpdateStatus(claim.id, 'in_process');
      onClose();
    } catch (error) {
      console.error('Error updating bank details:', error);
    }
  };

  const handleInputChange = (e) => {
    setBankDetails({ ...bankDetails, [e.target.name]: e.target.value });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div className="inline-block align-bottom bg-white rounded-3xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h2 className="text-2xl font-semibold text-[#1D1D1F] mb-4">Detajet Bankare</h2>
            
            {!showForm && (
              <div>
                <p className="text-lg font-medium text-[#1D1D1F] mb-2">Detajet e ruajtura më parë:</p>
                <p><strong>IBAN:</strong> {bankDetails.iban}</p>
                <p><strong>SWIFT/BIC:</strong> {bankDetails.swift_bic}</p>
                <p><strong>Emri i Mbajtësit të Llogarisë:</strong> {bankDetails.account_holder_name}</p>
                <p><strong>Emri i Bankës:</strong> {bankDetails.bank_name}</p>
                
                <div className="mt-4 flex space-x-4">
                  <button
                    onClick={() => onUpdateStatus(claim.id, 'in_process')}
                    className="px-4 py-2 bg-green-600 text-white rounded-full hover:bg-green-700 transition-colors duration-300"
                  >
                    Përdor Këto Detaje
                  </button>
                  <button
                    onClick={() => setShowForm(true)}
                    className="px-4 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors duration-300"
                  >
                    Shto Detaje të Reja
                  </button>
                </div>
              </div>
            )}

            {showForm && (
              <form onSubmit={handleSubmit}>
                <div className="space-y-4">
                  <div>
                    <label htmlFor="iban" className="block text-sm font-medium text-gray-700">IBAN</label>
                    <input
                      type="text"
                      name="iban"
                      id="iban"
                      value={bankDetails.iban}
                      onChange={handleInputChange}
                      required
                      className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label htmlFor="swift_bic" className="block text-sm font-medium text-gray-700">SWIFT/BIC</label>
                    <input
                      type="text"
                      name="swift_bic"
                      id="swift_bic"
                      value={bankDetails.swift_bic}
                      onChange={handleInputChange}
                      required
                      className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label htmlFor="account_holder_name" className="block text-sm font-medium text-gray-700">Emri i Mbajtësit të Llogarisë</label>
                    <input
                      type="text"
                      name="account_holder_name"
                      id="account_holder_name"
                      value={bankDetails.account_holder_name}
                      onChange={handleInputChange}
                      required
                      className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label htmlFor="bank_name" className="block text-sm font-medium text-gray-700">Emri i Bankës</label>
                    <input
                      type="text"
                      name="bank_name"
                      id="bank_name"
                      value={bankDetails.bank_name}
                      onChange={handleInputChange}
                      required
                      className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-full border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm"
                  >
                    Ruaj
                  </button>
                  <button
                    type="button"
                    onClick={onClose}
                    className="mt-3 w-full inline-flex justify-center rounded-full border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  >
                    Anulo
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankDetailsModal;