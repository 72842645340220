import React from 'react';
import ScannerAnimation from './ScannerAnimation';

const ScanningStep = ({ progress, highlightedField }) => {
  return (
    <div className="text-center">
      <ScannerAnimation highlightedField={highlightedField} />
      <p className="text-lg font-semibold text-gray-700 mb-2">Duke skanuar Boarding Pass-in tuaj...</p>
      <p className="text-sm text-gray-500 mb-4">Ju lutemi prisni, po përpunojmë informacionin</p>
      <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700">
        <div 
          className="bg-blue-600 h-2.5 rounded-full transition-all duration-500 ease-out" 
          style={{ width: `${progress}%` }}
        />
      </div>
      <p className="text-sm text-gray-500">
        {progress < 33 
          ? "Po analizojmë imazhin..." 
          : progress < 66
          ? "Po nxjerrim detajet e fluturimit..."
          : progress < 100
          ? "Po verifikojmë informacionin..."
          : "Skanimi u krye me sukses!"}
      </p>
    </div>
  );
};

export default ScanningStep;