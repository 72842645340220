import api from '../utils/api';

export const compensationService = {
  fetchFlights: async (formData) => {
    const response = await api.get('/compensation/flights', formData);
    return response.data;
  },

  checkEligibility: async (flightNumber, departureDate) => {
    const response = await api.get('/compensation/check-eligibility', {
      params: { flight_number: flightNumber, departure_date: departureDate }
    });
    return response.data;
  },

  submitClaim: async (formData) => {
    const response = await api.post('/submit-claim', formData);
    return response.data;
  },

  uploadDocument: async (file) => {
    const formData = new FormData();
    formData.append('document', file);
    const response = await api.post('/upload-document', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response.data;
  },

  getCompensations: async () => {
    const response = await api.get('/compensations');
    return response.data;
  },

  getUserClaims: async () => {
    const response = await api.get('/compensation/user-claims');
    return response.data;
  },

    getClaimById: async (id) => {
      const response = await api.get(`/compensation/claims/${id}`);
      return response.data;
    },

  // Update other methods that use claim ID to use PNR instead
  updatePayoutStatus: async (pnr, newStatus) => {
    const response = await api.put(`/compensation/${pnr}/payout-status`, { status: newStatus });
    return response.data;
  },

  updateBankDetails: async (id, bankDetails) => {
    const response = await api.put(`/compensation/claims/${id}/bank-details`, bankDetails);
    return response.data;
  },

  updateClaim: async (pnr, message) => {
    const response = await api.put(`/compensation/${pnr}/update`, { message });
    return response.data;
  },

  submitSupportRequest: async (pnr, message) => {
    const response = await api.post(`/compensation/${pnr}/support-request`, { message });
    return response.data;
  },
};