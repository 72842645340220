import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useCompensation } from './useCompensation';
import { useNotification } from './useNotification';
import { useAuth } from './useAuth';
import api from '../utils/api';
import { convertAirlineCode } from '../utils/convertAirlineCode';

const useCompensationForm = () => {
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    departureAirport: '',
    arrivalAirport: '',
    departureDate: '',
    selectedFlight: null,
    selectedDepartureDate: '',
    passengerName: '',
    email: '',
    phone: '',
    contactMethod: 'email',
    additionalInfo: '',
    termsAccepted: false,
    selectedReason: '',
    otherReasonText: '',
    uploadedDocuments: [],
    airline: '',
    actualDeparture: '',
    scheduledArrival: '',
    actualArrival: '',
    delayDuration: '',
    distance: '',
    aircraftType: '',
    weatherConditions: {
      departure: {
        condition: '',
        temperature: '',
        windSpeed: '',
        precipitation: '',
        visibility: '',
        severity: ''
      },
      arrival: {
        condition: '',
        temperature: '',
        windSpeed: '',
        precipitation: '',
        visibility: '',
        severity: ''
      }
    },
    exceptionalCircumstances: {
      exists: false,
      description: ''
    },
    signature: '' // Added field for signature
  });

  const [selectedFlightData, setSelectedFlightData] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const { user } = useAuth();

  const {
    fetchFlights,
    checkEligibility,
    submitClaim,
    flights,
    eligibilityResult,
    isFetchingFlights,
    isCheckingEligibility,
    apiError
  } = useCompensation();

  const { showNotification } = useNotification();

  useEffect(() => {
    if (location.state && location.state.skipFirstStep) {
      setFormData(prevData => ({
        ...prevData,
        departureAirport: location.state.departureAirport,
        arrivalAirport: location.state.arrivalAirport,
        departureDate: location.state.departureDate,
      }));
      setCurrentStep(2);
      fetchFlights({
        departure_iata: location.state.departureAirport,
        arrival_iata: location.state.arrivalAirport,
        departure_date: location.state.departureDate
      });
    }
  }, [location]);

  useEffect(() => {
    if (formData.selectedFlight && flights.length > 0) {
      const selectedFlight = flights.find(flight => 
        convertAirlineCode(flight.flight_number) === formData.selectedFlight
      );
      setSelectedFlightData(selectedFlight);
      if (selectedFlight) {
        setFormData(prevData => ({
          ...prevData,
          airline: selectedFlight.airline,
          actualDeparture: selectedFlight.actual_departure,
          scheduledArrival: selectedFlight.scheduled_arrival,
          actualArrival: selectedFlight.actual_arrival,
          delayDuration: selectedFlight.delay_duration,
          distance: selectedFlight.distance,
          aircraftType: selectedFlight.aircraft_type || ''
        }));
      }
    }
  }, [formData.selectedFlight, flights]);

  const handleInputChange = (name, value) => {
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSignature = (signatureData) => {
    setFormData(prevData => ({ ...prevData, signature: signatureData })); // Store signature data
  };

  const nextStep = () => setCurrentStep(prev => prev + 1);
  const prevStep = () => setCurrentStep(prev => prev - 1);

  const handleScanComplete = async (scanData, error) => {
    console.log('Scan data received:', scanData);
    if (error || !scanData || !scanData.flightNumber || !scanData.departureDate) {
      console.error('Error or incomplete data from scan:', error || 'Incomplete scan data');
      showNotification('Unable to process the scanned document. Please enter details manually.', 'error');
      return;
    }

    try {
      setFormData(prevData => ({
        ...prevData,
        selectedFlight: scanData.flightNumber,
        departureDate: scanData.departureDate
      }));

      const eligibilityResponse = await checkEligibility(scanData.flightNumber, scanData.departureDate);
      console.log('Eligibility check response:', eligibilityResponse);

      setFormData(prevData => ({
        ...prevData,
        eligibilityResult: eligibilityResponse
      }));

      setCurrentStep(3);
      showNotification('Boarding pass scanned and eligibility checked successfully!', 'success');
    } catch (error) {
      console.error('Error checking eligibility:', error);
      showNotification('Error checking eligibility. Please try again or enter details manually.', 'error');
    }
  };

  const handleFileUpload = async (file) => {
    setUploadError(null);
    setUploadSuccess(false);

    if (!file) {
      setUploadError('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('signature', file); // Changed key to 'signature'

    try {
      const response = await api.post('/compensation/upload-signature', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('File uploaded successfully:', response.data);
      setUploadSuccess(true);
      handleInputChange('uploadedDocuments', [
        ...formData.uploadedDocuments,
        response.data.filepath
      ]);
    } catch (error) {
      console.error('Error uploading file:', error);
      setUploadError('An error occurred while uploading the file. Please try again.');
    }
  };

  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found. Please log in again.');
      }

      const requiredFields = ['selectedFlight', 'departureAirport', 'arrivalAirport', 'selectedDepartureDate', 'selectedReason', 'passengerName', 'email'];
      for (const field of requiredFields) {
        if (!formData[field]) {
          throw new Error(`Missing required field: ${field}`);
        }
      }

      // Convert data URL to Blob
      const signatureBlob = dataURLtoBlob(formData.signature);
      const formDataSignature = new FormData();
      formDataSignature.append('signature', signatureBlob, 'signature.png'); // Append the signature as a file

      const signatureResponse = await api.post('/compensation/upload-signature', formDataSignature, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!signatureResponse.data.success) {
        throw new Error('Failed to upload signature.');
      }

      const submissionData = {
        flight_number: formData.selectedFlight,
        airline: formData.airline,
        departure_airport: {
          iata: formData.departureAirport,
          name: ''
        },
        arrival_airport: {
          iata: formData.arrivalAirport,
          name: ''
        },
        scheduled_departure: formData.selectedDepartureDate,
        actual_departure: formData.actualDeparture,
        scheduled_arrival: formData.scheduledArrival,
        actual_arrival: formData.actualArrival,
        incident_type: formData.selectedReason,
        claim_reason: formData.otherReasonText,
        delay_duration: formData.delayDuration,
        distance: formData.distance,
        passenger_name: formData.passengerName,
        email: formData.email,
        phone: formData.phone,
        preferred_contact_method: formData.contactMethod,
        additional_info: formData.additionalInfo,
        documents: formData.uploadedDocuments,
        aircraft_type: formData.aircraftType,
        weather_conditions: formData.weatherConditions,
        exceptional_circumstances: formData.exceptionalCircumstances,
        eligibility_reason: formData.eligibilityResult ? formData.eligibilityResult.reason : '',
        signature: signatureResponse.data.filepath // Include the signature file path in submission data
      };

      const response = await api.post('/compensation/submit-claim', submissionData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    
      if (response.data.success) {
        showNotification('Kërkesa juaj u dërgua me sukses!', 'success');
        location.state = { claimId: response.data.claimId };
        window.history.pushState(location.state, '', `/dashboard/claim/${response.data.claimId}`);
        window.dispatchEvent(new PopStateEvent('popstate'));
      } else {
        throw new Error(response.data.error || 'Ndodhi një gabim gjatë dërgimit të kërkesës.');
      }
    } catch (error) {
      console.error('Error submitting claim:', error);
      showNotification(error.message || 'Ndodhi një gabim gjatë dërgimit të kërkesës suaj. Ju lutemi provoni përsëri.', 'error');
    }
  };

  return {
    currentStep,
    formData,
    handleInputChange,
    handleSignature, // Expose handleSignature
    nextStep,
    prevStep,
    handleScanComplete,
    handleFileUpload,
    handleSubmit,
    uploadError,
    uploadSuccess,
    selectedFlightData,
    flights,
    eligibilityResult,
    isFetchingFlights,
    isCheckingEligibility,
    apiError,
    fetchFlights,
    checkEligibility,
  };
};

export default useCompensationForm;
