import React from 'react';

const ClaimReason = ({ selectedReason, otherReasonText, handleInputChange }) => {
  return (
    <div className="bg-white rounded-lg p-6 border border-gray-200 shadow-sm">
      <h3 className="text-lg font-semibold text-gray-900 mb-4">Arsyeja e Kërkesës</h3>
      <div className="space-y-3">
        {['overbooking', 'cancelled', 'delayed', 'other'].map((reason) => (
          <label key={reason} className="flex items-center p-3 bg-gray-50 rounded-md hover:bg-gray-100 transition duration-150 ease-in-out">
            <input
              type="radio"
              name="reason"
              value={reason}
              checked={selectedReason === reason}
              onChange={(e) => handleInputChange('selectedReason', e.target.value)}
              className="form-radio h-5 w-5 text-blue-600"
            />
            <span className="ml-3 text-gray-700">
              {reason === 'overbooking' ? 'Mbingarkesë (Overbooking)' :
               reason === 'cancelled' ? 'Anulim' :
               reason === 'delayed' ? 'Vonesë' : 'Arsye tjetër'}
            </span>
          </label>
        ))}
      </div>
      {selectedReason === 'other' && (
        <div className="mt-4">
          <label htmlFor="otherReason" className="block text-sm font-medium text-gray-700 mb-1">Specifiko arsyen tjetër:</label>
          <textarea
            id="otherReason"
            value={otherReasonText}
            onChange={(e) => handleInputChange('otherReasonText', e.target.value)}
            rows="3"
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
            placeholder="Shkruani arsyen tuaj këtu..."
          />
        </div>
      )}
    </div>
  );
};

export default ClaimReason;