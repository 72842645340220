import React from 'react';
import CompensationForm from '../components/compensation/CompensationForm';

const CompensationRequest = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <CompensationForm />
    </div>
  );
};

export default CompensationRequest;