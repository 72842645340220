import React, { useState } from 'react';

const PersonalInformation = ({ formData, handleInputChange, index }) => {
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!formData.passengerName) {
      newErrors.passengerName = 'Emri i pasagjerit është i detyrueshëm';
    }
    if (!formData.email) {
      newErrors.email = 'Email-i është i detyrueshëm';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Format i pavlefshëm email-i';
    }
    if (!formData.phone) {
      newErrors.phone = 'Numri i telefonit është i detyrueshëm';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <div className="space-y-6">
      <div>
        <label htmlFor={`passenger_name_${index}`} className="block text-sm font-medium text-gray-700">Emri i Pasagjerit</label>
        <input
          type="text"
          id={`passenger_name_${index}`}
          value={formData.passengerName}
          onChange={(e) => handleInputChange('passengerName', e.target.value)}
          className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          placeholder="Emri i plotë"
        />
        {errors.passengerName && <p className="mt-2 text-sm text-red-600">{errors.passengerName}</p>}
      </div>

      <div>
        <label htmlFor={`email_${index}`} className="block text-sm font-medium text-gray-700">Email</label>
        <input
          type="email"
          id={`email_${index}`}
          value={formData.email}
          onChange={(e) => handleInputChange('email', e.target.value)}
          className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          placeholder="adresa@juaj.com"
        />
        {errors.email && <p className="mt-2 text-sm text-red-600">{errors.email}</p>}
      </div>

      <div>
        <label htmlFor={`phone_${index}`} className="block text-sm font-medium text-gray-700">Numri i Telefonit</label>
        <input
          type="tel"
          id={`phone_${index}`}
          value={formData.phone}
          onChange={(e) => handleInputChange('phone', e.target.value)}
          className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          placeholder="+355 69 123 4567"
        />
        {errors.phone && <p className="mt-2 text-sm text-red-600">{errors.phone}</p>}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Metoda e Preferuar e Kontaktit</label>
        <div className="mt-2 space-x-4">
          <label className="inline-flex items-center">
            <input
              type="radio"
              value="email"
              checked={formData.contactMethod === 'email'}
              onChange={(e) => handleInputChange('contactMethod', e.target.value)}
              className="form-radio text-blue-600"
            />
            <span className="ml-2 text-sm text-gray-700">Email</span>
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              value="phone"
              checked={formData.contactMethod === 'phone'}
              onChange={(e) => handleInputChange('contactMethod', e.target.value)}
              className="form-radio text-blue-600"
            />
            <span className="ml-2 text-sm text-gray-700">Telefon</span>
          </label>
        </div>
      </div>

      <div>
        <label htmlFor={`additional_info_${index}`} className="block text-sm font-medium text-gray-700">Informacion Shtesë</label>
        <textarea
          id={`additional_info_${index}`}
          value={formData.additionalInfo}
          onChange={(e) => handleInputChange('additionalInfo', e.target.value)}
          rows="3"
          className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          placeholder="Çdo detaj shtesë që dëshironi të ndani"
        ></textarea>
      </div>

      <div className="flex items-start">
        <div className="flex items-center h-5">
          <input
            id={`terms_${index}`}
            type="checkbox"
            checked={formData.termsAccepted}
            onChange={(e) => handleInputChange('termsAccepted', e.target.checked)}
            className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor={`terms_${index}`} className="font-medium text-gray-700">Pranoj kushtet dhe rregullat</label>
          <p className="text-gray-500">Duke vazhduar, ju pranoni <a href="/kushtet" className="text-blue-600 hover:underline">kushtet e shërbimit</a> dhe <a href="/privatesia" className="text-blue-600 hover:underline">politikën e privatësisë</a>.</p>
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;