import React, { useState } from 'react';
import api from '../../utils/api';

const DocumentUpload = ({ uploadedDocuments, handleInputChange }) => {
  const [uploadError, setUploadError] = useState(null);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('document', file);

      try {
        const response = await api.post('/upload-document', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        handleInputChange('uploadedDocuments', [...uploadedDocuments, response.data.filepath]);
        setUploadError(null);
      } catch (error) {
        console.error('Gabim:', error);
        setUploadError('Ndodhi një gabim gjatë ngarkimit të dokumentit. Ju lutemi provoni përsëri.');
      }
    }
  };

  const removeDocument = (docName) => {
    handleInputChange('uploadedDocuments', uploadedDocuments.filter(doc => doc !== docName));
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-semibold text-gray-900 mb-6">Ngarko Dokumentet</h2>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">Dokumentet Mbështetëse</label>
        <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
          <div className="space-y-1 text-center">
            <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
              <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <div className="flex text-sm text-gray-600">
              <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                <span>Ngarko një skedar</span>
                <input id="file-upload" name="document" type="file" className="sr-only" onChange={handleFileUpload} />
              </label>
              <p className="pl-1">ose tërhiq dhe lësho</p>
            </div>
            <p className="text-xs text-gray-500">
              PDF, JPG, PNG deri në 10MB
            </p>
          </div>
        </div>
      </div>

      {uploadedDocuments.length > 0 && (
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-2">Dokumentet e Ngarkuara</h3>
          <ul className="divide-y divide-gray-200">
            {uploadedDocuments.map((doc) => (
              <li key={doc} className="py-3 flex justify-between items-center">
                <div className="flex items-center">
                  <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clipRule="evenodd" />
                  </svg>
                  <span className="ml-2 flex-1 w-0 truncate">{doc}</span>
                </div>
                <div className="ml-4 flex-shrink-0">
                  <button onClick={() => removeDocument(doc)} type="button" className="font-medium text-blue-600 hover:text-blue-500">
                    Fshi
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      {uploadError && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a 1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                Gabim gjatë ngarkimit
              </h3>
              <div className="mt-2 text-sm text-red-700">
                <p>{uploadError}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentUpload;