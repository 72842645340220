import React, { useRef, useState, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';

const Signature = ({ handleSignature, passengerName }) => {
  const sigCanvas = useRef();
  const [isSigned, setIsSigned] = useState(false);

  useEffect(() => {
    if (sigCanvas.current) {
      clear();
    }
  }, [passengerName]);

  const clear = () => {
    sigCanvas.current.clear();
    setIsSigned(false);
  };

  const save = () => {
    if (sigCanvas.current.isEmpty()) {
      alert('Ju lutem vendosni nënshkrimin tuaj para se të vazhdoni.');
      return;
    }
    const signatureData = sigCanvas.current.toDataURL();
    handleSignature(signatureData);
    setIsSigned(true);
  };

  return (
    <div className="mt-6">
      <h3 className="text-lg font-medium text-gray-900 mb-2">Nënshkrimi i {passengerName || 'Pasagjerit'}</h3>
      <div className="border-2 border-gray-300 rounded-md overflow-hidden">
        <SignatureCanvas
          ref={sigCanvas}
          canvasProps={{
            width: 500,
            height: 200,
            className: 'signature-canvas'
          }}
          backgroundColor="rgb(255, 255, 255)"
        />
      </div>
      <div className="mt-2 flex justify-between">
        <button
          type="button"
          onClick={clear}
          className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Pastro
        </button>
        <button
          type="button"
          onClick={save}
          className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Ruaj Nënshkrimin
        </button>
      </div>
      {isSigned && (
        <p className="mt-2 text-sm text-green-600">Nënshkrimi u ruajt me sukses!</p>
      )}
    </div>
  );
};

export default Signature;