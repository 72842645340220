import React, { useState, useMemo } from 'react';
import { 
  UserIcon,
  PencilIcon,
  TrashIcon,
  LockClosedIcon,
  LockOpenIcon
} from '@heroicons/react/24/outline';
import { formatDate } from '../../utils/translationUtils';

const AdminUserManagement = ({ users, onUpdateRole, onDeleteUser, onResetPassword }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 10;

  const filteredUsers = useMemo(() => {
    return users.filter(user => 
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [users, searchTerm]);

  const paginatedUsers = useMemo(() => {
    const startIndex = (page - 1) * itemsPerPage;
    return filteredUsers.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredUsers, page]);

  const handleRoleChange = (userId, newRole) => {
    onUpdateRole(userId, newRole);
  };

  return (
    <div className="mt-8">
      <h2 className="text-lg font-medium text-gray-900">Menaxhimi i Përdoruesve</h2>
      <div className="mt-4">
        <input
          type="text"
          placeholder="Kërko përdorues..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        />
      </div>
      <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-md">
        <ul className="divide-y divide-gray-200">
          {paginatedUsers.map((user) => (
            <li key={user._id}>
              <div className="px-4 py-4 flex items-center sm:px-6">
                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <UserIcon className="h-12 w-12 text-gray-300" />
                    </div>
                    <div className="ml-4">
                      <p className="text-sm font-medium text-indigo-600 truncate">{user.name}</p>
                      <p className="mt-1 text-sm text-gray-500">{user.email}</p>
                      <p className="mt-1 text-xs text-gray-400">Krijuar më: {formatDate(user.createdAt)}</p>
                    </div>
                  </div>
                  <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                    <div className="flex items-center justify-between">
                      <p className="text-sm text-gray-500 mr-4">Roli: {user.isAdmin ? 'Admin' : 'Përdorues'}</p>
                      <select
                        value={user.isAdmin ? 'admin' : 'user'}
                        onChange={(e) => handleRoleChange(user._id, e.target.value === 'admin')}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      >
                        <option value="user">Përdorues</option>
                        <option value="admin">Admin</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="ml-5 flex-shrink-0">
                  <button
                    onClick={() => setSelectedUser(user)}
                    className="mr-2 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <PencilIcon className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => onDeleteUser(user._id)}
                    className="mr-2 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <TrashIcon className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => onResetPassword(user._id)}
                    className="p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {user.passwordResetRequired ? (
                      <LockOpenIcon className="h-5 w-5" />
                    ) : (
                      <LockClosedIcon className="h-5 w-5" />
                    )}
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-4 flex justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Duke shfaqur <span className="font-medium">{(page - 1) * itemsPerPage + 1}</span> deri në <span className="font-medium">{Math.min(page * itemsPerPage, filteredUsers.length)}</span> nga{' '}
            <span className="font-medium">{filteredUsers.length}</span> përdorues
          </p>
        </div>
        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
          <button
            onClick={() => setPage(page - 1)}
            disabled={page === 1}
            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          >
            Prapa
          </button>
          <button
            onClick={() => setPage(page + 1)}
            disabled={page * itemsPerPage >= filteredUsers.length}
            className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          >
            Përpara
          </button>
        </nav>
      </div>
    </div>
  );
};

export default AdminUserManagement;