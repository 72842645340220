import React, { useMemo } from 'react';

const CompensationTrend = ({ compensations }) => {
  const trendData = useMemo(() => {
    const now = new Date();
    const monthNames = ['Janar', 'Shkurt', 'Mars', 'Prill', 'Maj', 'Qershor', 'Korrik', 'Gusht', 'Shtator', 'Tetor', 'Nëntor', 'Dhjetor'];
    
    const monthlyAmounts = new Array(6).fill(0);
    const monthLabels = new Array(6).fill('');

    for (let i = 5; i >= 0; i--) {
      const d = new Date(now.getFullYear(), now.getMonth() - i, 1);
      monthLabels[5-i] = monthNames[d.getMonth()];
    }

    compensations.forEach(comp => {
      const compDate = new Date(comp.created_at);
      const monthDiff = (now.getFullYear() - compDate.getFullYear()) * 12 + now.getMonth() - compDate.getMonth();
      if (monthDiff >= 0 && monthDiff < 6) {
        monthlyAmounts[5-monthDiff] += parseFloat(comp.amount);
      }
    });

    const currentMonthCompensation = monthlyAmounts[5].toFixed(2);
    const lastMonthCompensation = monthlyAmounts[4].toFixed(2);
    const averageMonthlyCompensation = (monthlyAmounts.reduce((a, b) => a + b, 0) / 6).toFixed(2);
    
    const monthlyChange = lastMonthCompensation > 0 
      ? (((currentMonthCompensation - lastMonthCompensation) / lastMonthCompensation) * 100).toFixed(1)
      : 100;

    const maxAmount = Math.max(...monthlyAmounts);
    const totalCompensation = monthlyAmounts.reduce((a, b) => a + b, 0).toFixed(2);

    return {
      monthlyAmounts,
      monthLabels,
      currentMonthCompensation,
      lastMonthCompensation,
      averageMonthlyCompensation,
      monthlyChange,
      maxAmount,
      totalCompensation,
      currentMonthName: monthNames[now.getMonth()],
      currentYear: now.getFullYear(),
    };
  }, [compensations]);

  const hasCompensationData = trendData.monthlyAmounts.some(amount => amount > 0);

  return (
    <div className="bg-white rounded-3xl p-6 md:p-8 shadow-sm">
      <h3 className="text-2xl font-semibold text-[#1D1D1F] mb-6">Trendi i Kompensimit Mujor</h3>
      
      {hasCompensationData ? (
        <div className="space-y-8">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-[#86868B]">Muaji aktual</p>
              <p className="text-3xl font-bold text-[#1D1D1F] mt-1">€{trendData.currentMonthCompensation}</p>
            </div>
            <div className="text-right">
              <p className="text-lg font-semibold text-[#1D1D1F]">{trendData.currentMonthName}</p>
              <p className="text-sm text-[#86868B]">{trendData.currentYear}</p>
            </div>
          </div>

          <div className="relative pt-8">
            <div className="absolute top-0 left-0 w-full h-1 bg-gray-200"></div>
            <div className="relative h-32">
              {trendData.monthlyAmounts.map((amount, index) => (
                <div key={index} className="absolute bottom-0 w-1/6 flex flex-col items-center" style={{ left: `${index * 20}%` }}>
                  <div className="h-24 w-1 bg-[#0071E3] rounded-t-full" style={{ height: `${(amount / trendData.maxAmount * 96)}px` }}></div>
                  <p className="mt-2 text-xs text-[#86868B]">{trendData.monthLabels[index]}</p>
                  <p className="mt-1 text-sm font-semibold text-[#1D1D1F]">€{amount.toFixed(2)}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4 text-center">
            <div>
              <p className="text-sm text-[#86868B]">Muaji i kaluar</p>
              <p className="text-lg font-semibold text-[#1D1D1F] mt-1">€{trendData.lastMonthCompensation}</p>
            </div>
            <div>
              <p className="text-sm text-[#86868B]">Ndryshimi</p>
              <p className={`text-lg font-semibold mt-1 ${trendData.monthlyChange >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                {trendData.monthlyChange >= 0 ? '+' : ''}{trendData.monthlyChange}%
              </p>
            </div>
            <div>
              <p className="text-sm text-[#86868B]">Mesatarja</p>
              <p className="text-lg font-semibold text-[#1D1D1F] mt-1">€{trendData.averageMonthlyCompensation}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-64 flex items-center justify-center">
          <p className="text-[#86868B] text-lg">Nuk ka të dhëna për të shfaqur</p>
        </div>
      )}

      <div className="mt-8 pt-6 border-t border-gray-200">
        <div className="flex justify-between items-center">
          <span className="text-sm font-medium text-[#1D1D1F]">Totali i Kompensimit</span>
          <span className="text-2xl font-bold text-[#1D1D1F]">€{trendData.totalCompensation}</span>
        </div>
      </div>
    </div>
  );
};

export default CompensationTrend;