import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Camera, Upload, AlertTriangle, CheckCircle, X, RefreshCw, Image as ImageIcon, HelpCircle, Info, AlertOctagon, Loader, ArrowLeft } from 'lucide-react';
import api from '../../utils/api';
import Button from './boardingPassScanner/Button';
import Alert from './boardingPassScanner/Alert';
import Dialog from './boardingPassScanner/Dialog';
import StepIndicator from './boardingPassScanner/StepIndicator';
import InitialStep from './boardingPassScanner/InitialStep';
import PreviewStep from './boardingPassScanner/PreviewStep';
import ScanningStep from './boardingPassScanner/ScanningStep';
import ResultStep from './boardingPassScanner/ResultStep';
import ManualEntryStep from './boardingPassScanner/ManualEntryStep';
import CameraStep from './boardingPassScanner/CameraStep';
import { cn } from './boardingPassScanner/utils';

const BoardingPassScanner = ({ onScanComplete }) => {
  const [step, setStep] = useState('initial');
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [scanError, setScanError] = useState(null);
  const [scanResult, setScanResult] = useState(null);
  const [scanningProgress, setScanningProgress] = useState(0);
  const [showTips, setShowTips] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isManualEntryMode, setIsManualEntryMode] = useState(false);
  const [manualEntryData, setManualEntryData] = useState({ flightNumber: '', departureDate: '' });
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [cameraError, setCameraError] = useState(null);
  const [highlightedField, setHighlightedField] = useState(null);
  const [scanningStage, setScanningStage] = useState(0);
  const [currentAction, setCurrentAction] = useState('');
  const fileInputRef = useRef(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  useEffect(() => {
    let interval;
    if (step === 'scanning') {
      interval = setInterval(() => {
        setScanningProgress((prev) => (prev < 90 ? prev + 10 : 90));
      }, 500);
    }
    return () => clearInterval(interval);
  }, [step]);

  useEffect(() => {
    if (isCameraActive) {
      startCamera();
    } else {
      stopCamera();
    }
    return () => {
      stopCamera();
    };
  }, [isCameraActive]);

  const handleFileSelect = useCallback((event) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      if (selectedFile.type.startsWith('image/')) {
        setFile(selectedFile);
        setPreviewUrl(URL.createObjectURL(selectedFile));
        setStep('preview');
      } else {
        alert('Ju lutemi ngarkoni vetëm imazhe. Formatet e pranuara janë JPG, PNG, dhe GIF.');
      }
    }
  }, []);

  const handleScan = useCallback(async () => {
    setStep('scanning');
    setScanError(null);
    setScanningProgress(0);
    const formData = new FormData();
    formData.append('boardingPass', file);

    try {
      const response = await api.post('/boarding-pass/scan', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setScanResult(response.data);
      setScanningProgress(100);
      setStep('result');
      onScanComplete(response.data, null);
    } catch (error) {
      console.error('Error scanning boarding pass:', error);
      setScanError('Nuk mund të lexohej dokumenti. Ju lutemi provoni përsëri ose plotësoni detajet manualisht.');
      setStep('result');
      onScanComplete(null, error);
    }
  }, [file, onScanComplete]);

  const resetScan = useCallback(() => {
    setStep('initial');
    setFile(null);
    setPreviewUrl('');
    setScanError(null);
    setScanResult(null);
    setScanningProgress(0);
    setShowTips(false);
    setIsManualEntryMode(false);
    setManualEntryData({ flightNumber: '', departureDate: '' });
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }, []);

  const handleManualEntry = useCallback(() => {
    setIsManualEntryMode(true);
    setStep('manual');
  }, []);

  const handleManualEntrySubmit = useCallback(() => {
    if (manualEntryData.flightNumber && manualEntryData.departureDate) {
      setScanResult(manualEntryData);
      setStep('result');
      onScanComplete(manualEntryData, null);
    } else {
      setScanError('Ju lutemi plotësoni të gjitha fushat.');
    }
  }, [manualEntryData, onScanComplete]);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ 
        video: { 
          facingMode: 'environment',
          width: { ideal: 1280 },
          height: { ideal: 720 }
        } 
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.onloadedmetadata = () => {
          videoRef.current.play();
        };
      }
      setCameraError(null);
    } catch (err) {
      console.error("Error accessing camera:", err);
      setCameraError(err.message || "Pati një problem me hapjen e kamerës.");
      setIsCameraActive(false);
    }
  };

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach(track => track.stop());
    }
  };

  const captureImage = () => {
    if (videoRef.current && canvasRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext('2d').drawImage(video, 0, 0);
      canvas.toBlob((blob) => {
        const file = new File([blob], "captured_image.jpg", { type: "image/jpeg" });
        setFile(file);
        setPreviewUrl(URL.createObjectURL(file));
        setStep('preview');
        setIsCameraActive(false);
      }, 'image/jpeg');
    }
  };

  const handleCameraCapture = () => {
    setCameraError(null);
    setIsCameraActive(true);
    setStep('camera');
  };

  return (
    <div className="mt-6 p-6 bg-white rounded-lg shadow-lg border border-gray-200 max-w-md mx-auto">
      <h3 className="text-2xl font-bold mb-6 text-center text-gray-800">Skano Boarding Pass-in Tuaj</h3>
      <StepIndicator currentStep={step} />
      <div className="space-y-4">
        {step === 'initial' && <InitialStep onCameraCapture={handleCameraCapture} onFileSelect={() => fileInputRef.current?.click()} onManualEntry={handleManualEntry} />}
        {step === 'preview' && <PreviewStep previewUrl={previewUrl} onScan={handleScan} onReset={resetScan} showTips={showTips} setShowTips={setShowTips} />}
        {step === 'scanning' && <ScanningStep progress={scanningProgress} highlightedField={highlightedField} />}
        {step === 'result' && <ResultStep scanError={scanError} scanResult={scanResult} onReset={resetScan} onManualEntry={handleManualEntry} />}
        {step === 'manual' && <ManualEntryStep manualEntryData={manualEntryData} setManualEntryData={setManualEntryData} onSubmit={handleManualEntrySubmit} onReset={resetScan} />}
        {step === 'camera' && <CameraStep videoRef={videoRef} canvasRef={canvasRef} cameraError={cameraError} onCapture={captureImage} onRetry={handleCameraCapture} />}
      </div>
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={handleFileSelect}
        className="hidden"
        aria-label="Ngarko foto të boarding pass"
      />
      <div className="mt-6 text-sm text-gray-500 text-center">
        <p>Skanoni boarding pass-in tuaj ose konfirmimin e rezervimit për të kontrolluar kompensimin e mundshëm për vonesa ose anulime fluturimi.</p>
        <Button 
          variant="secondary"
          onClick={() => setIsDialogOpen(true)}
          className="mt-2 mx-auto text-blue-500 hover:text-blue-700"
        >
          <HelpCircle className="h-4 w-4 mr-1" />
          Si funksionon procesi i skanimit?
        </Button>
      </div>

      <Dialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <Dialog.Header>
          <Dialog.Title>Procesi i Skanimit të Boarding Pass</Dialog.Title>
          <Dialog.Description>
            <ol className="list-decimal pl-5 space-y-2 mt-2">
              <li>Zgjidhni një metodë për të ngarkuar boarding pass-in tuaj (kamera ose foto e ruajtur).</li>
              <li>Sigurohuni që imazhi është i qartë dhe të gjitha detajet janë të dukshme.</li>
              <li>Sistemi ynë i avancuar i njohjes optike të karaktereve (OCR) analizon imazhin.</li>
              <li>Nxjerrim informacionet kyçe si numri i fluturimit, data, dhe detaje të tjera relevante.</li>
              <li>Këto të dhëna krahasohen me bazën tonë të të dhënave për të verifikuar vlefshmërinë.</li>
              <li>Nëse skanimi është i suksesshëm, ju do të shihni detajet e nxjerra dhe mund të vazhdoni me procesin e verifikimit të kompensimit.</li>
              <li>Nëse ka ndonjë problem, do t'ju ofrohen opsione për të provuar përsëri ose për të futur manualisht informacionin.</li>
            </ol>
          </Dialog.Description>
        </Dialog.Header>
      </Dialog>
    </div>
  );
};

export default BoardingPassScanner;