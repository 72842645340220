import React from 'react';

const EligibilityResult = ({ eligibilityResult }) => {
  return (
    <div className="bg-blue-50 rounded-lg p-6 border border-blue-200">
      <h3 className="text-lg font-semibold text-blue-900 mb-4">Rezultati i Kualifikimit</h3>
      <p className="text-blue-800 mb-2">{eligibilityResult.reason}</p>
      {eligibilityResult.eligible && (
        <div className="mt-4">
          <p className="text-sm text-blue-800">Kompensimi i mundshëm:</p>
          <p className="text-2xl font-bold text-blue-900">€{eligibilityResult.compensation} për person</p>
        </div>
      )}
    </div>
  );
};

export default EligibilityResult;