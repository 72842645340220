import React, { useState } from 'react';

const BankDetails = ({ claim, onSubmit }) => {
  const [showForm, setShowForm] = useState(false);
  const [bankDetails, setBankDetails] = useState(claim.bank_details || {
    iban: '',
    swift_bic: '',
    account_holder_name: '',
    bank_name: ''
  });

  const handleInputChange = (e) => {
    setBankDetails({ ...bankDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(bankDetails);
    setShowForm(false);
  };

  return (
    <section className="mb-12 bg-white rounded-xl shadow-sm p-6">
      <h2 className="text-2xl sm:text-3xl font-semibold mb-8 text-center">Detajet Bankare</h2>
      
      {!showForm && claim.bank_details && (
        <div className="bg-gray-50 rounded-lg p-6">
          <ul className="space-y-4">
            <li className="flex justify-between py-2 border-b border-gray-200">
              <span className="text-gray-600">IBAN:</span>
              <span className="font-medium">{claim.bank_details.iban}</span>
            </li>
            <li className="flex justify-between py-2 border-b border-gray-200">
              <span className="text-gray-600">SWIFT/BIC:</span>
              <span className="font-medium">{claim.bank_details.swift_bic}</span>
            </li>
            <li className="flex justify-between py-2 border-b border-gray-200">
              <span className="text-gray-600">Emri i Mbajtësit të Llogarisë:</span>
              <span className="font-medium">{claim.bank_details.account_holder_name}</span>
            </li>
            <li className="flex justify-between py-2">
              <span className="text-gray-600">Emri i Bankës:</span>
              <span className="font-medium">{claim.bank_details.bank_name}</span>
            </li>
          </ul>
          <button 
            onClick={() => setShowForm(true)} 
            className="mt-6 px-4 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition duration-300 text-sm font-medium"
          >
            Ndrysho Detajet Bankare
          </button>
        </div>
      )}

      {(showForm || !claim.bank_details) && (
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="iban" className="block text-sm font-medium text-gray-700 mb-1">IBAN</label>
            <input
              type="text"
              name="iban"
              id="iban"
              value={bankDetails.iban}
              onChange={handleInputChange}
              required 
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 text-sm"
            />
          </div>
          <div>
            <label htmlFor="swift_bic" className="block text-sm font-medium text-gray-700 mb-1">SWIFT/BIC</label>
            <input
              type="text"
              name="swift_bic"
              id="swift_bic"
              value={bankDetails.swift_bic}
              onChange={handleInputChange}
              required 
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 text-sm"
            />
          </div>
          <div>
            <label htmlFor="account_holder_name" className="block text-sm font-medium text-gray-700 mb-1">Emri i Mbajtësit të Llogarisë</label>
            <input
              type="text"
              name="account_holder_name"
              id="account_holder_name"
              value={bankDetails.account_holder_name}
              onChange={handleInputChange}
              required 
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 text-sm"
            />
          </div>
          <div>
            <label htmlFor="bank_name" className="block text-sm font-medium text-gray-700 mb-1">Emri i Bankës</label>
            <input
              type="text"
              name="bank_name"
              id="bank_name"
              value={bankDetails.bank_name}
              onChange={handleInputChange}
              required 
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 text-sm"
            />
          </div>
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={() => setShowForm(false)}
              className="px-4 py-2 bg-gray-100 text-gray-700 rounded-full hover:bg-gray-200 transition duration-300 text-sm font-medium"
            >
              Anulo
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition duration-300 text-sm font-medium"
            >
              Ruaj Ndryshimet
            </button>
          </div>
        </form>
      )}
    </section>
  );
};

export default BankDetails;