import React, { useMemo, useCallback } from 'react';
import { Check, AlertCircle } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const ProgressIndicator = ({
  currentStep,
  steps,
  activeColor = 'bg-blue-600',
  inactiveColor = 'bg-gray-200',
  textColor = 'text-gray-900',
  inactiveTextColor = 'text-gray-400',
  errorColor = 'bg-red-500',
  showStepNumbers = true,
  showLabels = true,
  animationDuration = 0.7,
  onStepClick,
  stepStatuses = [],
}) => {
  const progressPercentage = useMemo(() => {
    return ((currentStep - 1) / (steps.length - 1)) * 100;
  }, [currentStep, steps.length]);

  const handleStepClick = useCallback((index) => {
    if (onStepClick) {
      onStepClick(index + 1);
    }
  }, [onStepClick]);

  return (
    <div className="progress-indicator my-8 sm:my-16 md:my-24 lg:my-32 select-none" role="navigation" aria-label="Progress">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative">
          <ProgressBar
            percentage={progressPercentage}
            activeColor={activeColor}
            inactiveColor={inactiveColor}
            animationDuration={animationDuration}
          />
          <StepIndicators
            steps={steps}
            currentStep={currentStep}
            activeColor={activeColor}
            inactiveColor={inactiveColor}
            textColor={textColor}
            inactiveTextColor={inactiveTextColor}
            errorColor={errorColor}
            showStepNumbers={showStepNumbers}
            showLabels={showLabels}
            onStepClick={handleStepClick}
            stepStatuses={stepStatuses}
          />
        </div>
      </div>
    </div>
  );
};

const ProgressBar = ({ percentage, activeColor, inactiveColor, animationDuration }) => (
  <div className={`absolute top-1/2 left-0 w-full h-0.5 sm:h-1 ${inactiveColor} rounded-full overflow-hidden -translate-y-1/2`}>
    <motion.div 
      className={`absolute top-0 left-0 h-full ${activeColor}`}
      initial={{ width: 0 }}
      animate={{ width: `${percentage}%` }}
      transition={{ duration: animationDuration, ease: "easeInOut" }}
    />
  </div>
);

const StepIndicators = ({ steps, currentStep, activeColor, inactiveColor, textColor, inactiveTextColor, errorColor, showStepNumbers, showLabels, onStepClick, stepStatuses }) => (
  <div className="relative flex justify-between">
    {steps.map((step, index) => (
      <StepIndicator 
        key={index}
        step={step}
        index={index}
        currentStep={currentStep}
        activeColor={activeColor}
        inactiveColor={inactiveColor}
        textColor={textColor}
        inactiveTextColor={inactiveTextColor}
        errorColor={errorColor}
        showStepNumbers={showStepNumbers}
        showLabels={showLabels}
        onStepClick={onStepClick}
        status={stepStatuses[index]}
      />
    ))}
  </div>
);

const StepIndicator = ({ step, index, currentStep, activeColor, inactiveColor, textColor, inactiveTextColor, errorColor, showStepNumbers, showLabels, onStepClick, status }) => {
  const isCompleted = currentStep > index + 1;
  const isCurrent = currentStep === index + 1;
  const isError = status === 'error';

  const handleClick = useCallback(() => {
    onStepClick(index);
  }, [onStepClick, index]);

  return (
    <div 
      className={`group flex flex-col items-center relative step-${index + 1}`}
      role="button"
      tabIndex={0}
      aria-current={isCurrent ? 'step' : undefined}
      aria-label={`Step ${index + 1}: ${step}${isError ? ' (Error)' : ''}`}
      onClick={handleClick}
      onKeyPress={(e) => e.key === 'Enter' && handleClick()}
    >
      <StepCircle
        isCompleted={isCompleted}
        isCurrent={isCurrent}
        stepNumber={index + 1}
        activeColor={activeColor}
        inactiveColor={inactiveColor}
        textColor={textColor}
        inactiveTextColor={inactiveTextColor}
        errorColor={errorColor}
        showStepNumbers={showStepNumbers}
        isError={isError}
      />
      {showLabels && (
        <StepLabel
          step={step}
          isActive={currentStep >= index + 1}
          textColor={textColor}
          inactiveTextColor={inactiveTextColor}
          isError={isError}
          errorColor={errorColor}
        />
      )}
    </div>
  );
};

const StepCircle = ({ isCompleted, isCurrent, stepNumber, activeColor, inactiveColor, textColor, inactiveTextColor, errorColor, showStepNumbers, isError }) => (
  <div className={`w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 rounded-full bg-white transition-all duration-300 ease-out flex items-center justify-center overflow-hidden ${
    isCurrent ? 'shadow-md scale-110' : ''
  }`}>
    <AnimatePresence mode="wait">
      <motion.div
        key={`${isCompleted}-${isCurrent}-${isError}`}
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.5 }}
        transition={{ duration: 0.3 }}
        className={`relative z-10 w-3 h-3 sm:w-4 sm:h-4 md:w-5 md:h-5 rounded-full flex items-center justify-center ${
          isError ? errorColor :
          isCompleted ? activeColor : 
          isCurrent ? `${activeColor} scale-110` :
          inactiveColor
        }`}
      >
        {isCompleted && !isError && (
          <Check className="w-2 h-2 sm:w-2.5 sm:h-2.5 md:w-3 md:h-3 text-white" />
        )}
        {isError && (
          <AlertCircle className="w-2 h-2 sm:w-2.5 sm:h-2.5 md:w-3 md:h-3 text-white" />
        )}
        {!isCompleted && !isError && showStepNumbers && (
          <span className={`text-[10px] sm:text-xs md:text-sm font-medium ${
            isCurrent ? 'text-white' : inactiveTextColor
          }`}>
            {stepNumber}
          </span>
        )}
      </motion.div>
    </AnimatePresence>
  </div>
);

const StepLabel = ({ step, isActive, textColor, inactiveTextColor, isError, errorColor }) => (
  <span className={`absolute top-full mt-2 sm:mt-3 text-[10px] sm:text-xs md:text-sm font-medium text-center transition-all duration-300 ease-out tracking-wide uppercase max-w-[80px] sm:max-w-[100px] md:max-w-[120px] leading-tight ${
    isError ? errorColor :
    isActive ? `${textColor} font-semibold` : inactiveTextColor
  }`}>
    {step}
  </span>
);

export default ProgressIndicator;