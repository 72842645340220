import React from 'react';
import Button from './Button';
import Alert from './Alert';

const ManualEntryStep = ({ manualEntryData, setManualEntryData, onSubmit, onReset }) => {
  return (
    <div className="space-y-4">
      <Alert variant="warning">
        <Alert.Title>Hyrje manuale e të dhënave</Alert.Title>
        <Alert.Description>
          Ju lutemi plotësoni detajet e fluturimit tuaj me kujdes. Sigurohuni që informacioni është i saktë për të verifikuar të drejtën tuaj për kompensim.
        </Alert.Description>
      </Alert>
      <div>
        <label htmlFor="flightNumber" className="block text-sm font-medium text-gray-700 mb-1">
          Numri i fluturimit
        </label>
        <input
          type="text"
          id="flightNumber"
          value={manualEntryData.flightNumber}
          onChange={(e) => setManualEntryData({ ...manualEntryData, flightNumber: e.target.value })}
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          placeholder="p.sh. AL123"
        />
      </div>
      <div>
        <label htmlFor="departureDate" className="block text-sm font-medium text-gray-700 mb-1">
          Data e nisjes
        </label>
        <input
          type="date"
          id="departureDate"
          value={manualEntryData.departureDate}
          onChange={(e) => setManualEntryData({ ...manualEntryData, departureDate: e.target.value })}
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
        />
      </div>
      <Button onClick={onSubmit} className="w-full">
        Dërgo detajet
      </Button>
      <Button variant="secondary" onClick={onReset} className="w-full">
        Kthehu te skanimi
      </Button>
    </div>
  );
};

export default ManualEntryStep;