import { useState } from 'react';
import api from '../utils/api';
import { compensationService } from '../services/compensationService';

export const useCompensation = () => {
  const [compensations, setCompensations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [flights, setFlights] = useState([]);
  const [eligibilityResult, setEligibilityResult] = useState(null);
  const [isFetchingFlights, setIsFetchingFlights] = useState(false);
  const [isCheckingEligibility, setIsCheckingEligibility] = useState(false);
  const [apiError, setApiError] = useState(null);

  const fetchFlights = async (formData) => {
    setIsFetchingFlights(true);
    setApiError(null);
    try {
      const response = await api.get('/compensation/flights', {
        params: {
          departure_iata: formData.departure_iata,
          arrival_iata: formData.arrival_iata,
          departure_date: formData.departure_date
        }
      });
      setFlights(response.data);
    } catch (error) {
      console.error('Error fetching flights:', error);
      setApiError('Ndodhi një gabim gjatë marrjes së fluturimeve. Ju lutemi provoni përsëri.');
    } finally {
      setIsFetchingFlights(false);
    }
  };

  const checkEligibility = async (flightNumber, departureDate) => {
    setIsCheckingEligibility(true);
    setApiError(null);
    try {
      const response = await api.get('/compensation/check-eligibility', {
        params: { flight_number: flightNumber, departure_date: departureDate }
      });
      setEligibilityResult(response.data);
    } catch (error) {
      console.error('Error checking eligibility:', error);
      setApiError('Ndodhi një gabim gjatë kontrollit të kualifikimit. Ju lutemi provoni përsëri.');
    } finally {
      setIsCheckingEligibility(false);
    }
  };

  const submitClaim = async (formData) => {
    try {
      await api.post('/submit-claim', formData);
    } catch (error) {
      throw new Error('Ndodhi një gabim gjatë dërgimit të kërkesës suaj. Ju lutemi provoni përsëri.');
    }
  };

  const fetchCompensations = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await compensationService.getUserClaims();
      setCompensations(data);
    } catch (error) {
      console.error('Error fetching compensations:', error);
      setError('An error occurred while fetching your claims.');
    } finally {
      setLoading(false);
    }
  };

  const getClaimById = async (id) => {
    try {
      setLoading(true);
      const claim = await compensationService.getClaimById(id);
      return claim;
    } catch (error) {
      setError(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updatePayoutStatus = async (pnr, newStatus) => {
    try {
      await compensationService.updatePayoutStatus(pnr, newStatus);
      await fetchCompensations();
    } catch (error) {
      console.error('Error updating payout status:', error);
      throw error;
    }
  };

  const fetchBankDetails = async () => {
    try {
      return await compensationService.getBankDetails();
    } catch (error) {
      console.error('Error fetching bank details:', error);
      throw error;
    }
  };

  const updateBankDetails = async (pnr, bankDetails) => {
    try {
      const response = await compensationService.updateBankDetails(pnr, bankDetails);
      return response;
    } catch (error) {
      console.error('Error updating bank details:', error);
      throw error;
    }
  };

  const updateClaim = async (claimId, message) => {
    try {
      await compensationService.updateClaim(claimId, message);
    } catch (error) {
      console.error('Error updating claim:', error);
      throw error;
    }
  };

  const submitSupportRequest = async (claimId, message) => {
    try {
      await compensationService.submitSupportRequest(claimId, message);
    } catch (error) {
      console.error('Error submitting support request:', error);
      throw error;
    }
  };

  return {
    compensations,
    flights,
    eligibilityResult,
    isFetchingFlights,
    isCheckingEligibility,
    apiError,
    fetchFlights,
    checkEligibility,
    submitClaim,
    loading,
    error,
    fetchCompensations,
    getClaimById,
    updatePayoutStatus,
    fetchBankDetails,
    updateBankDetails,
    updateClaim,
    submitSupportRequest,
  };
};
