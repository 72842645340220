import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { XMarkIcon } from '@heroicons/react/24/outline';

const Header = () => {
  const { user, logout } = useAuth();
  const [showNotice, setShowNotice] = useState(true);
  const [noticeVisible, setNoticeVisible] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  // Close notice smoothly after setting opacity and max-height
  const closeNotice = () => {
    setShowNotice(false);
    setTimeout(() => setNoticeVisible(false), 500); // After animation ends
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 10;
      setIsScrolled(scrolled);
      if (scrolled) {
        setShowNotice(false);
        setTimeout(() => setNoticeVisible(false), 500); // Hide after transition
      } else {
        setNoticeVisible(true);
        setTimeout(() => setShowNotice(true), 100); // Delay show for smooth effect
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  return (
    <>
      {noticeVisible && (
        <div
          className={`bg-blue-600 text-white px-4 transition-all duration-500 ease-in-out overflow-hidden ${
            showNotice ? 'opacity-100 max-h-20 py-2' : 'opacity-0 max-h-0 py-0'
          }`}
        >
          <div className="container mx-auto flex items-center justify-center relative">
            <p className="text-xs font-medium text-center pr-8 sm:pr-0">
              <span className="hidden sm:inline">
                E rëndësishme: Ju lutemi sigurohuni që të gjitha të dhënat e fluturimit dhe të pasagjerit janë të sakta dhe të plota para dorëzimit të kërkesës për kompensim. Saktësia e informacionit ndikon drejtpërdrejt në procesin e shqyrtimit.
              </span>
              <span className="sm:hidden">
                E rëndësishme: Ju lutemi sigurohuni që të dhënat e fluturimit dhe të pasagjerit janë të sakta dhe të plota para dorëzimit të kërkesës për kompensim.
              </span>{' '}
              Për udhëzime të detajuara, vizitoni{' '}
              <Link
                to="/how-it-works"
                className="underline hover:text-blue-200 transition-colors duration-300"
              >
                Si Funksionon
              </Link>
              .
            </p>
            <button
              onClick={closeNotice}
              className="absolute right-0 top-1/2 transform -translate-y-1/2 text-white hover:text-blue-200 transition-colors duration-300"
              aria-label="Mbyll njoftimin"
            >
              <XMarkIcon className="h-4 w-4" />
            </button>
          </div>
        </div>
      )}
      <header
        className={`sticky top-0 z-50 transition-all duration-300 ${
          isScrolled ? 'bg-white/100 backdrop-blur-md shadow-md' : 'bg-white'
        }`}
      >
        <nav
          className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
          aria-label="Top"
        >
          <div className="flex w-full items-center justify-between py-6">
          <div className="flex items-center">
            <Link to="/" className="flex items-center group">
            <svg className="h-8 w-auto text-black transition-transform duration-300 group-hover:scale-110" fill="none" viewBox="0 0 35 32" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M15.258 26.865a4.043 4.043 0 01-1.133 2.917A4.006 4.006 0 0111.253 31a3.992 3.992 0 01-2.872-1.218 4.028 4.028 0 01-1.133-2.917c.009-.698.2-1.382.557-1.981.356-.6.863-1.094 1.47-1.433-.024.109.09-.055 0 0l1.86-1.652a8.495 8.495 0 002.304-5.793c0-2.926-1.711-5.901-4.17-7.457.094.055-.036-.094 0 0A3.952 3.952 0 017.8 7.116a3.975 3.975 0 01-.557-1.98 4.042 4.042 0 011.133-2.918A4.006 4.006 0 0111.247 1a3.99 3.99 0 012.872 1.218 4.025 4.025 0 011.133 2.917 8.521 8.521 0 002.347 5.832l.817.8c.326.285.668.551 1.024.798.621.33 1.142.826 1.504 1.431a3.902 3.902 0 01-1.504 5.442c.033-.067-.063.036 0 0a8.968 8.968 0 00-3.024 3.183 9.016 9.016 0 00-1.158 4.244zM19.741 5.123c0 .796.235 1.575.676 2.237a4.01 4.01 0 001.798 1.482 3.99 3.99 0 004.366-.873 4.042 4.042 0 00.869-4.386 4.02 4.02 0 00-1.476-1.806 3.994 3.994 0 00-5.058.501 4.038 4.038 0 00-1.175 2.845zM23.748 22.84c-.792 0-1.567.236-2.226.678a4.021 4.021 0 00-1.476 1.806 4.042 4.042 0 00.869 4.387 3.99 3.99 0 004.366.873A4.01 4.01 0 0027.08 29.1a4.039 4.039 0 00-.5-5.082 4 4 0 00-2.832-1.18zM34 15.994c0-.796-.235-1.574-.675-2.236a4.01 4.01 0 00-1.798-1.483 3.99 3.99 0 00-4.367.873 4.042 4.042 0 00-.869 4.387 4.02 4.02 0 001.476 1.806 3.993 3.993 0 002.226.678 4.003 4.003 0 002.832-1.18A4.04 4.04 0 0034 15.993z"/>
                <path fill="currentColor" d="M5.007 11.969c-.793 0-1.567.236-2.226.678a4.021 4.021 0 00-1.476 1.807 4.042 4.042 0 00.869 4.386 4.001 4.001 0 004.366.873 4.011 4.011 0 001.798-1.483 4.038 4.038 0 00-.5-5.08 4.004 4.004 0 00-2.831-1.181z"/>
                </svg>
              <span className="ml-3 text-xl font-semibold text-black group-hover:text-blue-600 transition-colors duration-300">Avioza</span>
            </Link>
          </div>
          <div className="hidden md:flex space-x-8">
            {['/', '/how-it-works', '/contact'].map((path, index) => (
              <Link
                key={path}
                to={path}
                className={`text-base font-medium ${location.pathname === path ? 'text-blue-600' : 'text-gray-500 hover:text-gray-900'} transition-all duration-300 ease-in-out transform hover:-translate-y-0.5`}
              >
                {['Ballina', 'Si Funksionon', 'Kontaktoni'][index]}
              </Link>
            ))}
            {user && user.isAdmin && (
              <Link to="/admin" className="text-base font-medium text-blue-600 hover:text-blue-800 transition-all duration-300 ease-in-out transform hover:-translate-y-0.5">Panel i Adminit</Link>
            )}
          </div>
          <div className="flex items-center space-x-4">
            {user ? (
              <>
                <Link to="/dashboard" className="text-base font-medium text-gray-500 hover:text-gray-900 transition-all duration-300 ease-in-out transform hover:-translate-y-0.5">Paneli Kryesor</Link>
                <button onClick={logout} className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-black hover:bg-gray-800 transition-all duration-300 ease-in-out transform hover:-translate-y-0.5">
                  Shkyçu
                </button>
              </>
            ) : (
              <>
                <Link to="/login" className="text-base font-medium text-gray-500 hover:text-gray-900 transition-all duration-300 ease-in-out transform hover:-translate-y-0.5">Kyçu</Link>
                <Link to="/register" className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-800 transition-all duration-300 ease-in-out transform hover:-translate-y-0.5">
                  Regjistrohu
                </Link>
              </>
            )}
          </div>
          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="w-10 h-10 flex flex-col justify-center items-center focus:outline-none"
              aria-label="Toggle menu"
              aria-expanded={isMenuOpen}
            >
              <span className={`w-6 h-0.5 bg-gray-900 transition-all duration-300 ease-out ${isMenuOpen ? 'rotate-45 translate-y-1.5' : ''}`} />
              <span className={`w-6 h-0.5 bg-gray-900 mt-1.5 transition-all duration-300 ease-out ${isMenuOpen ? 'opacity-0' : ''}`} />
              <span className={`w-6 h-0.5 bg-gray-900 mt-1.5 transition-all duration-300 ease-out ${isMenuOpen ? '-rotate-45 -translate-y-1.5' : ''}`} />
            </button>
          </div>
        </div>
        <div 
          className={`md:hidden overflow-hidden transition-all duration-500 ease-in-out ${isMenuOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'}`}
          aria-hidden={!isMenuOpen}
        >
          <div className="px-2 pt-2 pb-3 space-y-1">
            {['/', '/how-it-works', '/contact'].map((path, index) => (
              <Link
                key={path}
                to={path}
                className={`block px-3 py-2 rounded-md text-base font-medium ${location.pathname === path ? 'text-blue-600 bg-blue-50' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50'} transition-colors duration-300`}
              >
                {['Ballina', 'Si Funksionon', 'Kontaktoni'][index]}
              </Link>
            ))}
            {user && user.isAdmin && (
              <Link to="/admin" className="block px-3 py-2 rounded-md text-base font-medium text-blue-600 hover:text-blue-800 hover:bg-blue-50 transition-colors duration-300">Panel i Adminit</Link>
            )}
            {user && (
              <>
                <Link to="/dashboard" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 transition-colors duration-300">Paneli Kryesor</Link>
                <button onClick={logout} className="w-full text-left block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 transition-colors duration-300">
                  Shkyçu
                </button>
              </>
            )}
          </div>
        </div>
        </nav>
      </header>
    </>
  );
};

export default Header;