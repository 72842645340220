import React, { useState } from 'react';
import ProgressIndicator from '../common/ProgressIndicator';
import FlightDetails from './FlightDetails';
import SelectFlight from './SelectFlight';
import EligibilityCheck from './EligibilityCheck';
import PersonalInformation from './PersonalInformation';
import DocumentUpload from './DocumentUpload';
import Signature from './Signature';
import BoardingPassScanner from './BoardingPassScanner';
import useCompensationForm from '../../hooks/useCompensationForm';

const CompensationForm = () => {
  const {
    currentStep,
    formData,
    handleInputChange,
    nextStep,
    prevStep,
    handleScanComplete,
    handleFileUpload,
    handleSubmit,
    handleSignature,
    uploadError,
    uploadSuccess,
    selectedFlightData,
    flights,
    eligibilityResult,
    isFetchingFlights,
    isCheckingEligibility,
    apiError,
    fetchFlights,
    checkEligibility,
  } = useCompensationForm();

  const [isSignatureSaved, setIsSignatureSaved] = useState(false);

  const handleSignatureSave = (signatureData) => {
    handleSignature(signatureData);
    setIsSignatureSaved(true);
  };

  const handleFormSubmit = () => {
    if (!isSignatureSaved) {
      alert('Ju lutem ruani nënshkrimin tuaj para se të dërgoni kërkesën.');
      return;
    }
    handleSubmit();
  };

  return (
    <div className="bg-white min-h-screen">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-8">Kërkoni Kompensimin Tuaj</h1>
        
        <ProgressIndicator currentStep={currentStep} steps={['Detajet e Fluturimit', 'Zgjedhja e Fluturimit', 'Kontrolli i Kualifikimit', 'Informacioni Personal dhe Dokumentet']} />

        <div className="mt-12 bg-gray-50 rounded-2xl p-8 shadow-sm">
          {currentStep === 1 && (
            <>
              <FlightDetails 
                formData={formData}
                handleInputChange={handleInputChange}
                fetchFlights={fetchFlights}
                nextStep={nextStep}
                isFetchingFlights={isFetchingFlights}
              />
              <BoardingPassScanner onScanComplete={handleScanComplete} />
            </>
          )}

          {currentStep === 2 && (
            <SelectFlight 
              flights={flights}
              selectedFlight={formData.selectedFlight}
              handleInputChange={handleInputChange}
              nextStep={nextStep}
              prevStep={prevStep}
              checkEligibility={checkEligibility}
            />
          )}

          {currentStep === 3 && (
            <EligibilityCheck 
              eligibilityResult={eligibilityResult}
              isCheckingEligibility={isCheckingEligibility}
              apiError={apiError}
              selectedReason={formData.selectedReason}
              otherReasonText={formData.otherReasonText}
              handleInputChange={handleInputChange}
              nextStep={nextStep}
              prevStep={prevStep}
              selectedFlightData={selectedFlightData}
            />
          )}

          {currentStep === 4 && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <PersonalInformation 
                  formData={formData}
                  handleInputChange={handleInputChange}
                />
              </div>
              <div>
                <DocumentUpload 
                  uploadedDocuments={formData.uploadedDocuments}
                  handleInputChange={handleInputChange}
                  handleFileUpload={handleFileUpload}
                  uploadError={uploadError}
                  uploadSuccess={uploadSuccess}
                />
                <Signature 
                  handleSignature={handleSignatureSave}
                  passengerName={formData.passengerName}
                />
              </div>
              <div className="mt-8 flex justify-between">
                <button
                  type="button"
                  onClick={prevStep}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Kthehu
                </button>
                <button
                  onClick={handleFormSubmit}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Dërgo Kërkesën
                </button>
              </div>
            </div>
          )}
          
        </div>
      </div>
    </div>
  );
};

export default CompensationForm;
