import React from 'react';
import { isImage } from '../../../utils/translationUtils'; // Changed from helpers to translationUtils

const Documents = ({ claim }) => {
  return (
    <section className="mb-12 bg-white rounded-xl shadow-sm p-6">
      <h2 className="text-2xl sm:text-3xl font-semibold mb-8 text-center">Dokumentet e Ngarkuara</h2>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {claim.documents && claim.documents.map((doc) => (
          <div key={doc._id} className="bg-gray-50 rounded-lg p-4 flex flex-col items-center transition-all duration-300 hover:shadow-md">
            <div className="w-16 h-16 mb-3 flex items-center justify-center bg-white rounded-lg overflow-hidden shadow-sm">
              {isImage(doc.name) ? (
                <img src={doc.url} alt="Document preview" className="object-cover w-full h-full"/>
              ) : (
                <svg className="w-8 h-8 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"></path>
                </svg>
              )}
            </div>
            <p className="text-sm text-center truncate w-full mb-2">{doc.name}</p>
            <a 
              href={doc.url}
              target="_blank" 
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800 transition duration-300 text-sm font-medium"
            >
              Shiko
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Documents;