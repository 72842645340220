import React from 'react';
import { formatDateTime } from '../../../utils/translationUtils';

const ClaimProgress = ({ claim }) => {
  return (
    <section className="mb-12 bg-white rounded-xl shadow-sm p-6 sm:p-8">
      <h2 className="text-2xl sm:text-3xl font-semibold mb-8 text-center">Progresi i Kërkesës</h2>
      <div className="relative">
        <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-0.5 bg-gray-200"></div>
        <div className="relative z-10 space-y-12">
          {claim.timeline && claim.timeline.map((event, index) => (
            <div key={index} className="flex items-center">
              <div className={`flex items-center justify-center w-12 h-12 rounded-full border-2 font-semibold shadow-sm transition-all duration-500 ${
                index === claim.timeline.length - 1 ? 'bg-blue-500 text-white border-blue-500' : 'bg-white text-gray-400 border-gray-300'
              }`}>
                {index === claim.timeline.length - 1 ? (
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                ) : (
                  <span>{index + 1}</span>
                )}
              </div>
              <div className="ml-6">
                <h3 className="text-xl font-semibold">{event.description}</h3>
                {event.timestamp && <p className="text-sm text-blue-600 mt-2">Përditësuar më: {formatDateTime(event.timestamp)}</p>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ClaimProgress;