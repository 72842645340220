export const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString('sq-AL', options);
};

export const formatTime = (dateString) => {
  if (!dateString) return 'N/A';
  const options = { hour: '2-digit', minute: '2-digit' };
  return new Date(dateString).toLocaleTimeString('sq-AL', options);
};

export const formatDateTime = (dateString) => {
  if (!dateString) return 'N/A';
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
  return new Date(dateString).toLocaleString('sq-AL', options);
};

export const translateStatus = (status) => {
  const translations = {
    'pending': 'Në pritje',
    'under_review': 'Në shqyrtim',
    'approved': 'Miratuar',
    'rejected': 'Refuzuar',
    'on_hold': 'Në pritje'
  };
  return translations[status] || status;
};

export const translatePayoutStatus = (status) => {
  const translations = {
    'pending': 'Në pritje',
    'awaiting_bank_details': 'Në pritje të detajeve bankare',
    'in_process': 'Në proces',
    'completed': 'Kompletuar',
    'failed': 'Dështuar'
  };
  return translations[status] || status;
};

export const translateIncidentType = (type) => {
  const translations = {
    'delay': 'Vonesë',
    'cancellation': 'Anulim',
    'overbooking': 'Mbingarkesë',
    'downgrade': 'Ulje klasi',
    'other': 'Tjetër'
  };
  return translations[type] || type;
};

export const getStatusClass = (status) => {
  const classes = {
    'pending': 'bg-yellow-100 text-yellow-800',
    'under_review': 'bg-blue-100 text-blue-800',
    'approved': 'bg-green-100 text-green-800',
    'rejected': 'bg-red-100 text-red-800',
    'on_hold': 'bg-gray-100 text-gray-800'
  };
  return classes[status] || 'bg-gray-100 text-gray-800';
};

export const getStatusIcon = (status) => {
  const icons = {
    'approved': '<svg class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" /></svg>',
    'under_review': '<svg class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>',
    'pending': '<svg class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>',
    'rejected': '<svg class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" /></svg>',
    'on_hold': '<svg class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>'
  };
  return icons[status] || icons['pending'];
};

export const isImage = (filename) => {
  if (!filename) return false;
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
  const extension = filename.split('.').pop().toLowerCase();
  return imageExtensions.includes(extension);
};


export const formatDelay = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  
  if (hours > 0) {
    return `${hours}h ${remainingMinutes}m`;
  } else {
    return `${minutes}m`;
  }
};


export const formatCurrency = (amount) => {
  return new Intl.NumberFormat('sq-AL', {
    style: 'currency',
    currency: 'EUR',
  }).format(amount);
};