import React from 'react';
import { formatDate, formatTime, translateIncidentType, translateStatus } from '../../../utils/translationUtils';

const FlightOverview = ({ claim, airportInfo }) => {
  const getAirlineCode = (flightNumber) => {
    return flightNumber.slice(0, 2);
  };

  return (
    <section className="mb-12 bg-white rounded-xl shadow-sm overflow-hidden">
      <div className="bg-gray-50 p-6 sm:p-8 border-b border-gray-200">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
          <div className="flex items-center mb-4 sm:mb-0">
            {claim.flight_number && (
              <img 
                src={`https://images.kiwi.com/airlines/128/${getAirlineCode(claim.flight_number)}.png`} 
                alt={claim.airline} 
                className="w-16 h-16 object-contain mr-4"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/assets/images/default-airline.png';
                }}
              />
            )}
            <div>
              <h2 className="text-2xl sm:text-3xl font-bold">{claim.flight_number}</h2>
              <p className="text-gray-600">{claim.airline}</p>
            </div>
          </div>
          <p className="text-lg sm:text-xl font-medium">{formatDate(claim.scheduled_departure)}</p>
        </div>
        
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <div className="text-center mb-6 sm:mb-0">
            <p className="text-gray-600 mb-1">Nisja</p>
            <p className="text-2xl sm:text-3xl font-semibold mb-1">{claim.departure_airport.iata}</p>
            <p className="text-sm text-gray-500 mb-2">{airportInfo.departure ? airportInfo.departure.city : 'Duke ngarkuar...'}</p>
            <p className="text-lg font-medium">{formatTime(claim.scheduled_departure)}</p>
            <p className="text-sm text-gray-500">{claim.actual_departure ? `Aktuale: ${formatTime(claim.actual_departure)}` : 'N/A'}</p>
          </div>
          
          <div className="flex-1 px-8 relative hidden sm:block">
            <div className="glowing-dots h-[2px] absolute left-0 right-0 mx-auto w-[50%] top-1/2 transform -translate-y-1/2"></div>
            <svg className="w-16 h-16 text-gray-500 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" viewBox="0 0 24 24" fill="currentColor">
              <path d="M11.92,19.58L15.84,14H20a2,2,0 0,0,0-4H15.84L11.92,4.42A1,1,0 0,0,11.11,4h-.93a1,1,0 0,0-1,1.16L10,10H6.38L4.68,8.29A1.05,1.05,0 0,0,4,8H3a1,1,0 0,0-.89,1.45L3.38,12,2.11,14.55A1,1,0 0,0,3,16H4a1.05,1.05,0 0,0,.71-.29L6.38,14H10l-.81,4.84a1,1,0 0,0,1,1.16h.93A1,1,0 0,0,11.92,19.58Z" />
            </svg>
          </div>
          
          <div className="text-center">
            <p className="text-gray-600 mb-1">Mbërritja</p>
            <p className="text-2xl sm:text-3xl font-semibold mb-1">{claim.arrival_airport.iata}</p>
            <p className="text-sm text-gray-500 mb-2">{airportInfo.arrival ? airportInfo.arrival.city : 'Duke ngarkuar...'}</p>
            <p className="text-lg font-medium">{formatTime(claim.scheduled_arrival)}</p>
            <p className="text-sm text-gray-500">{claim.actual_arrival ? `Aktuale: ${formatTime(claim.actual_arrival)}` : 'N/A'}</p>
          </div>
        </div>
      </div>
      <div className="p-6 sm:p-8">
        <h3 className="text-xl font-semibold mb-6">Detajet e Fluturimit</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className="bg-gray-50 p-4 rounded-lg">
            <p className="text-gray-600 mb-1">Vonesa e Mbërritjes</p>
            <p className="text-2xl font-semibold">{claim.delay_duration} minuta</p>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg">
            <p className="text-gray-600 mb-1">Distanca e Fluturimit</p>
            <p className="text-2xl font-semibold">{claim.distance} km</p>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg">
            <p className="text-gray-600 mb-1">Arsyeja e Kërkesës</p>
            <p className="text-2xl font-semibold">{translateIncidentType(claim.incident_type)}</p>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg">
            <p className="text-gray-600 mb-1">Statusi i Fluturimit</p>
            <p className="text-2xl font-semibold">{translateStatus(claim.status)}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FlightOverview;