import React from 'react';
import { formatDateTime, formatDelay } from '../../../utils/translationUtils';

const FlightSchedule = ({ eligibilityResult }) => {
  return (
    <div className="bg-white rounded-lg p-6 border border-gray-200">
      <h3 className="text-lg font-semibold text-gray-900 mb-4">Orari i Fluturimit</h3>
      <div className="relative">
        <div className="absolute left-4 inset-y-0 w-0.5 bg-gray-200"></div>
        <ul className="space-y-6 relative">
          <li className="ml-6">
            <div className="flex items-center">
              <div className="bg-blue-500 rounded-full h-2 w-2 absolute left-4 mt-1.5 -ml-1"></div>
              <h4 className="font-medium text-gray-900">Nisja e Planifikuar</h4>
            </div>
            <p className="mt-1 text-sm text-gray-500">{formatDateTime(eligibilityResult.scheduled_departure)}</p>
          </li>
          <li className="ml-6">
            <div className="flex items-center">
              <div className="bg-green-500 rounded-full h-2 w-2 absolute left-4 mt-1.5 -ml-1"></div>
              <h4 className="font-medium text-gray-900">Nisja Aktuale</h4>
            </div>
            <p className="mt-1 text-sm text-gray-500">{formatDateTime(eligibilityResult.actual_departure)}</p>
            <p className={`mt-1 text-sm ${eligibilityResult.departure_delay > 0 ? 'text-red-600' : 'text-green-600'}`}>
              {eligibilityResult.departure_delay > 0 ? `Vonesë: ${formatDelay(eligibilityResult.departure_delay)}` : 'Në kohë'}
            </p>
          </li>
          <li className="ml-6">
            <div className="flex items-center">
              <div className="bg-blue-500 rounded-full h-2 w-2 absolute left-4 mt-1.5 -ml-1"></div>
              <h4 className="font-medium text-gray-900">Mbërritja e Planifikuar</h4>
            </div>
            <p className="mt-1 text-sm text-gray-500">{formatDateTime(eligibilityResult.scheduled_arrival)}</p>
          </li>
          <li className="ml-6">
            <div className="flex items-center">
              <div className="bg-green-500 rounded-full h-2 w-2 absolute left-4 mt-1.5 -ml-1"></div>
              <h4 className="font-medium text-gray-900">Mbërritja Aktuale</h4>
            </div>
            <p className="mt-1 text-sm text-gray-500">{formatDateTime(eligibilityResult.actual_arrival)}</p>
            <p className={`mt-1 text-sm ${eligibilityResult.arrival_delay > 0 ? 'text-red-600' : 'text-green-600'}`}>
              {eligibilityResult.arrival_delay > 0 ? `Vonesë: ${formatDelay(eligibilityResult.arrival_delay)}` : 'Në kohë'}
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FlightSchedule;