import React from 'react';
import FlightInformation from './eligibilityCheck/FlightInformation';
import FlightSchedule from './eligibilityCheck/FlightSchedule';
import FlightStatus from './eligibilityCheck/FlightStatus';
import WeatherConditions from './eligibilityCheck/WeatherConditions';
import EligibilityResult from './eligibilityCheck/EligibilityResult';
import ClaimReason from './eligibilityCheck/ClaimReason';

const EligibilityCheck = ({
  eligibilityResult,
  isCheckingEligibility,
  apiError,
  selectedReason,
  otherReasonText,
  handleInputChange,
  nextStep,
  prevStep,
  selectedFlightData
}) => {
  if (isCheckingEligibility) {
    return (
      <div className="flex justify-center items-center py-12">
        <svg className="animate-spin h-8 w-8 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <span className="ml-3 text-lg font-medium text-gray-700">Duke kontrolluar kualifikimin...</span>
      </div>
    );
  }

  if (apiError) {
    return (
      <div className="text-center py-12">
        <p className="text-red-600">{apiError}</p>
        <button onClick={prevStep} className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          Kthehu
        </button>
      </div>
    );
  }

  if (!eligibilityResult) {
    return null;
  }

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-semibold text-gray-900 mb-6">Kontrolli i Kualifikimit</h2>

      <FlightInformation eligibilityResult={eligibilityResult} selectedFlightData={selectedFlightData} />
      <FlightSchedule eligibilityResult={eligibilityResult} />
      <FlightStatus eligibilityResult={eligibilityResult} />
      <WeatherConditions eligibilityResult={eligibilityResult} />
      <EligibilityResult eligibilityResult={eligibilityResult} />
      <ClaimReason
        selectedReason={selectedReason}
        otherReasonText={otherReasonText}
        handleInputChange={handleInputChange}
      />

      <div className="mt-8 flex justify-between">
        <button
          onClick={prevStep}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Kthehu
        </button>
        <button
          onClick={nextStep}
          disabled={!eligibilityResult || !eligibilityResult.eligible || !selectedReason}
          className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Vazhdo me Kërkesën
        </button>
      </div>
    </div>
  );
};

export default EligibilityCheck;