import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ScrollReveal from 'scrollreveal';
import 'animate.css';

const Privatesia = () => {
  const [activeTab, setActiveTab] = useState('collection');
  const [openQuestion, setOpenQuestion] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    ScrollReveal().reveal('.container > div', { 
      delay: 200,
      distance: '50px',
      origin: 'bottom',
      duration: 1000,
      easing: 'cubic-bezier(0.5, 0, 0, 1)',
      interval: 150
    });
  }, []);

  const privacyOverview = [
    {
      title: "Siguria e të Dhënave",
      description: "Përdorim teknologji të avancuar enkriptimi dhe protokolle sigurie për të mbrojtur të dhënat tuaja nga aksesi i paautorizuar.",
      icon: (
        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
        </svg>
      )
    },
    {
      title: "Transparenca",
      description: "Jemi transparentë rreth mënyrës se si mbledhim, përdorim dhe ndajmë të dhënat tuaja. Ju mund të na kontaktoni në çdo kohë për pyetje rreth privatësisë suaj.",
      icon: (
        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
        </svg>
      )
    },
    {
      title: "Minimizimi i të Dhënave",
      description: "Mbledhim vetëm të dhënat që janë të nevojshme për të ofruar shërbimet tona dhe për të përmbushur kërkesat ligjore.",
      icon: (
        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
        </svg>
      )
    },
    {
      title: "Ruajtja e Kufizuar",
      description: "Ruajmë të dhënat tuaja vetëm për aq kohë sa është e nevojshme për qëllimet e deklaruara dhe në përputhje me kërkesat ligjore.",
      icon: (
        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
        </svg>
      )
    }
  ];

  const privacyDetails = {
    collection: {
      title: "Mbledhja e të Dhënave",
      content: (
        <>
          <p className="mb-4">Ne mbledhim informacion personal në rastet e mëposhtme:</p>
          <ul className="list-disc list-inside mb-4">
            <li>Kur regjistroheni për një llogari</li>
            <li>Kur paraqisni një kërkesë për kompensim</li>
            <li>Kur komunikoni me shërbimin tonë të klientit</li>
            <li>Kur përdorni faqen tonë të internetit ose aplikacionin mobil</li>
          </ul>
          <p className="mb-4">Informacioni që mbledhim mund të përfshijë:</p>
          <ul className="list-disc list-inside mb-4">
            <li>Emrin dhe mbiemrin</li>
            <li>Adresën e email-it</li>
            <li>Numrin e telefonit</li>
            <li>Detajet e fluturimit (numri i fluturimit, data, linja ajrore)</li>
            <li>Informacion për pagesën (kur aplikohet)</li>
          </ul>
          <p>Ne përdorim teknologji si cookies dhe pixel tags për të mbledhur informacion rreth përdorimit të faqes sonë të internetit. Këto të dhëna na ndihmojnë të përmirësojmë shërbimet tona dhe të personalizojmë përvojën tuaj.</p>
        </>
      )
    },
    usage: {
      title: "Përdorimi i të Dhënave",
      content: (
        <>
          <p className="mb-4">Ne përdorim të dhënat tuaja personale për qëllimet e mëposhtme:</p>
          <ul className="list-disc list-inside mb-4">
            <li>Për të përpunuar dhe menaxhuar kërkesat tuaja për kompensim</li>
            <li>Për të komunikuar me ju rreth shërbimeve tona</li>
            <li>Për të përmirësuar dhe personalizuar përvojën tuaj të përdoruesit</li>
            <li>Për të analizuar dhe përmirësuar shërbimet tona</li>
            <li>Për të zbatuar detyrimet tona ligjore dhe kontraktuale</li>
            <li>Për të parandaluar mashtrimet dhe për të mbrojtur sigurinë e sistemeve tona</li>
          </ul>
          <p className="mb-4">Ne përdorim teknika të avancuara analitike për të kuptuar më mirë nevojat e përdoruesve tanë dhe për të ofruar shërbime më të mira. Këto analiza kryhen në nivel të agreguar dhe nuk përfshijnë identifikimin individual të përdoruesve.</p>
          <p>Ne nuk përdorim të dhënat tuaja për vendimmarrje të automatizuar që mund të ketë efekte ligjore ose të ngjashme ndaj jush pa pëlqimin tuaj të qartë.</p>
        </>
      )
    },
    sharing: {
      title: "Ndarja e të Dhënave",
      content: (
        <>
          <p className="mb-4">Ne ndajmë të dhënat tuaja vetëm në rrethana specifike dhe të kufizuara:</p>
          <ul className="list-disc list-inside mb-4">
            <li>Me linjat ajrore për të përpunuar kërkesat për kompensim</li>
            <li>Me ofruesit e shërbimeve që na ndihmojnë në ofrimin e shërbimeve tona (p.sh., shërbimet e hostingut, përpunimit të pagesave)</li>
            <li>Me autoritetet rregullatore ose ligjzbatuese kur kërkohet me ligj</li>
          </ul>
          <p className="mb-4">Kur ndajmë të dhënat me palë të treta, ne:</p>
          <ul className="list-disc list-inside mb-4">
            <li>Sigurojmë që ata të respektojnë standardet tona të larta të privatësisë dhe sigurisë</li>
            <li>Kufizojmë informacionin që ndajmë vetëm në atë që është absolutisht i nevojshëm</li>
            <li>Kërkojmë garanci kontraktuale për mbrojtjen e të dhënave tuaja</li>
          </ul>
          <p>Ne kurrë nuk shesim të dhënat tuaja personale te palët e treta për qëllime marketingu.</p>
        </>
      )
    },
    rights: {
      title: "Të Drejtat Tuaja",
      content: (
        <>
          <p className="mb-4">Sipas ligjeve të privatësisë, ju keni disa të drejta në lidhje me të dhënat tuaja personale:</p>
          <ul className="list-disc list-inside mb-4">
            <li>E drejta për akses: Mund të kërkoni një kopje të të dhënave personale që kemi për ju.</li>
            <li>E drejta për korrigjim: Mund të na kërkoni të korrigjojmë të dhënat e pasakta ose të pa plotësuara.</li>
            <li>E drejta për fshirje: Në disa rrethana, mund të kërkoni fshirjen e të dhënave tuaja personale.</li>
            <li>E drejta për të kufizuar përpunimin: Mund të kërkoni kufizimin e përpunimit të të dhënave tuaja në disa situata.</li>
            <li>E drejta për portabilitet të të dhënave: Mund të kërkoni transferimin e të dhënave tuaja te një ofrues tjetër shërbimi.</li>
            <li>E drejta për të kundërshtuar: Mund të kundërshtoni përpunimin e të dhënave tuaja në disa rrethana.</li>
          </ul>
          <p className="mb-4">Për të ushtruar ndonjë nga këto të drejta, ju lutemi na kontaktoni në privacy@avioza.com. Ne do të përpunojmë kërkesën tuaj brenda 30 ditëve dhe do t'ju informojmë për çdo veprim që ndërmarrim.</p>
          <p>Ju keni gjithashtu të drejtë të paraqisni një ankesë pranë autoritetit mbikëqyrës të mbrojtjes së të dhënave nëse mendoni se të drejtat tuaja të privatësisë janë shkelur.</p>
        </>
      )
    }
  };

  const faqItems = [
    {
      question: "Si i mbron Avioza të dhënat e mia personale?",
      answer: "Avioza përdor teknologji të avancuar enkriptimi dhe protokolle të forta sigurie për të mbrojtur të dhënat tuaja. Ne kryejmë rregullisht auditime të sigurisë, përdorim firewall-e të avancuara, dhe zbatojmë praktikat më të mira të industrisë për të siguruar që informacioni juaj personal të jetë i sigurt. Gjithashtu, ne kufizojmë aksesin në të dhënat tuaja personale vetëm për ata punonjës që kanë nevojë për to për të kryer detyrat e tyre."
    },
    {
      question: "A ndani ju të dhënat e mia me palë të treta?",
      answer: "Ne ndajmë të dhënat tuaja vetëm kur është absolutisht e nevojshme për të përpunuar kërkesën tuaj për kompensim ose kur kërkohet me ligj. Kjo mund të përfshijë ndarjen e informacionit me linjat ajrore përkatëse ose autoritetet rregullatore. Ne nuk i shesim kurrë të dhënat tuaja personale palëve të treta për qëllime marketingu. Çdo palë e tretë me të cilën ne ndajmë të dhëna është e detyruar kontraktualisht të mbrojë informacionin tuaj me të njëjtat standarde të larta si ne."
    },
    {
      question: "Si mund të kem akses ose të fshij të dhënat e mia?",
      answer: "Ju mund të na kontaktoni në çdo kohë për të kërkuar akses, korrigjim ose fshirje të të dhënave tuaja personale. Dërgoni një email në privacy@avioza.com me kërkesën tuaj specifike dhe ne do të përpunojmë kërkesën tuaj brenda 30 ditëve. Për kërkesat e aksesit, ne do t'ju ofrojmë një kopje të të dhënave personale që kemi për ju në një format të lexueshëm. Për kërkesat e fshirjes, ne do të fshijmë të gjitha të dhënat tuaja personale, me përjashtim të atyre që jemi të detyruar ligjërisht t'i ruajmë."
    },
    {
      question: "Çfarë janë cookies dhe si i përdorni ato?",
      answer: "Cookies janë skedarë të vegjël teksti që vendosen në pajisjen tuaj kur vizitoni faqen tonë të internetit. Ne përdorim cookies për të përmirësuar funksionalitetin e faqes, për të analizuar trafikun dhe për të personalizuar përmbajtjen. Disa cookies janë të nevojshme për funksionimin e faqes, ndërsa të tjerat janë opsionale. Ju mund të menaxhoni preferencat tuaja për cookies përmes cilësimeve të shfletuesit tuaj. Për më shumë informacion, ju lutemi shikoni Politikën tonë të Cookies."
    }
  ];

  return (
    <div className="privatesia">
      <section className="hero relative bg-gradient-to-r from-blue-600 to-indigo-800 text-white overflow-hidden">
        <div className="absolute inset-0">
          <img src="/assets/images/privacy-background.jpg" alt="Privacy" className="w-full h-full object-cover opacity-20" />
          <div className="absolute inset-0 bg-blue-600 mix-blend-multiply"></div>
        </div>

        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
            Privatësia dhe Mbrojtja e të Dhënave Tuaja
          </h1>
          <p className="mt-6 max-w-3xl text-xl text-blue-100">
            Në Avioza, siguria dhe privatësia e të dhënave tuaja është prioriteti ynë kryesor. Zbuloni se si i mbrojmë të dhënat tuaja dhe cilat janë të drejtat tuaja.
          </p>
          <div className="mt-10 flex justify-center">
            <a href="#privacy-overview" className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-blue-700 bg-white hover:bg-blue-50">
              Mëso më shumë
              <svg className="ml-2 -mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
            </a>
          </div>
        </div>

        <div className="absolute bottom-0 left-0 right-0">
          <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax">
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </div>
      </section>

      <section id="privacy-overview" className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center mb-12">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Vështrim i Përgjithshëm i Privatësisë</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Si e mbrojmë privatësinë tuaj
            </p>
          </div>

          <div className="mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              {privacyOverview.map((item, index) => (
                <div key={index} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                      {item.icon}
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.title}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    {item.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </section>

      <section className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center mb-12">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Politika e Privatësisë në Detaje</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Çfarë duhet të dini për të dhënat tuaja
            </p>
          </div>

          <div className="flex justify-center mb-8">
            {Object.keys(privacyDetails).map((key) => (
              <button
                key={key}
                onClick={() => setActiveTab(key)}
                className={`px-4 py-2 ${activeTab === key ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'} focus:outline-none transition duration-300`}
              >
                {privacyDetails[key].title}
              </button>
            ))}
          </div>

          <div className="bg-white rounded-lg shadow-lg p-6">
            <h3 className="text-2xl font-bold mb-4">{privacyDetails[activeTab].title}</h3>
            {privacyDetails[activeTab].content}
          </div>
        </div>
      </section>

      <section className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center mb-12">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Pyetje të Shpeshta për Privatësinë</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Përgjigje për pyetjet tuaja më të zakonshme
            </p>
          </div>

          <div className="mt-10">
            <div className="space-y-6">
              {faqItems.map((item, index) => (
                <div key={index} className="bg-gray-50 rounded-xl shadow-lg overflow-hidden">
                  <button
                    onClick={() => setOpenQuestion(openQuestion === index ? null : index)}
                    className="flex justify-between items-center w-full px-4 py-5 sm:p-6 focus:outline-none"
                  >
                    <span className="text-lg font-semibold text-gray-900">{item.question}</span>
                    <svg
                      className={`h-6 w-6 text-blue-500 transform ${openQuestion === index ? 'rotate-180' : ''}`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>
                  {openQuestion === index && (
                    <div className="px-4 pb-5 sm:px-6 sm:pb-6">
                      <p className="text-gray-700">{item.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center mb-12">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Ndryshimet në Politikën e Privatësisë</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Qëndroni të informuar për ndryshimet
            </p>
          </div>

          <div className="bg-white rounded-lg shadow-lg p-6">
            <p className="text-gray-700 mb-4">Ne mund të përditësojmë këtë politikë privatësie herë pas here për të reflektuar ndryshimet në praktikat tona të privatësisë ose për arsye të tjera operacionale, ligjore ose rregullatore. Versioni më i fundit i politikës së privatësisë do të jetë gjithmonë i disponueshëm në këtë faqe.</p>
            <p className="text-gray-700 mb-4">Kur bëjmë ndryshime materiale në këtë politikë, ne do t'ju njoftojmë duke postuar një njoftim të dukshëm në faqen tonë të internetit ose duke ju dërguar një email në adresën e regjistruar.</p>
            <p className="text-gray-700 mb-4">Ne ju inkurajojmë të rishikoni rregullisht këtë faqe për informacionin më të fundit rreth praktikave tona të privatësisë. Vazhdimi i përdorimit të shërbimeve tona pas ndryshimeve në politikën e privatësisë do të konsiderohet si pranim i këtyre ndryshimeve.</p>
            <p className="text-gray-700">Nëse nuk jeni dakord me ndryshimet në politikën tonë të privatësisë, ju lutemi ndërprisni përdorimin e shërbimeve tona dhe na kontaktoni për të diskutuar opsionet tuaja.</p>
          </div>
        </div>
      </section>

      <section className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center mb-12">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Kontakti për Çështje të Privatësisë</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Na kontaktoni për çdo pyetje
            </p>
          </div>

          <div className="mt-10 text-center">
            <p className="text-xl text-gray-700 mb-6">Nëse keni pyetje, shqetësime ose kërkesa në lidhje me privatësinë tuaj, ju lutemi na kontaktoni:</p>
            <div className="space-y-4">
              <p className="text-lg text-gray-700">
                <span className="font-semibold">Email:</span> 
                <a href="mailto:privacy@avioza.com" className="text-blue-600 hover:underline">privacy@avioza.com</a>
              </p>
              <p className="text-lg text-gray-700">
                <span className="font-semibold">Telefon:</span> +355 69 123 4567
              </p>
              <p className="text-lg text-gray-700">
                <span className="font-semibold">Adresa:</span> Rruga e Durrësit, Nd. 91, H.14, Njësia Administrative Nr. 2, 1001 Tiranë, Shqipëri
              </p>
            </div>
          </div>

          <div className="mt-12 text-center">
            <Link to="/contact" className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              Na kontaktoni
              <svg className="ml-2 -mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </Link>
          </div>
        </div>
      </section>

      <section className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center mb-12">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Angazhimi Ynë</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Përkushtimi ynë ndaj privatësisë suaj
            </p>
          </div>

          <div className="mt-10">
            <p className="text-xl text-gray-700 mb-6 text-center">
              Në Avioza, ne besojmë se privatësia juaj është themelore. Angazhohemi të:
            </p>
            <ul className="space-y-4 text-lg text-gray-700">
              <li className="flex items-center">
                <svg className="h-6 w-6 text-green-500 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                Jemi transparentë rreth mënyrës se si mbledhim dhe përdorim të dhënat tuaja
              </li>
              <li className="flex items-center">
                <svg className="h-6 w-6 text-green-500 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                Ofrojmë kontroll mbi të dhënat tuaja personale
              </li>
              <li className="flex items-center">
                <svg className="h-6 w-6 text-green-500 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                Mbrojmë të dhënat tuaja me masat më të avancuara të sigurisë
              </li>
              <li className="flex items-center">
                <svg className="h-6 w-6 text-green-500 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                Respektojmë të drejtat tuaja të privatësisë dhe i marrim seriozisht kërkesat tuaja
              </li>
            </ul>
          </div>
        </div>
      </section>

      <style jsx>{`
        .waves {
          position: relative;
          width: 100%;
          height: 15vh;
          margin-bottom: -7px;
          min-height: 100px;
          max-height: 150px;
        }
        .parallax > use {
          animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
        }
        .parallax > use:nth-child(1) {
          animation-delay: -2s;
          animation-duration: 7s;
        }
        .parallax > use:nth-child(2) {
          animation-delay: -3s;
          animation-duration: 10s;
        }
        .parallax > use:nth-child(3) {
          animation-delay: -4s;
          animation-duration: 13s;
        }
        .parallax > use:nth-child(4) {
          animation-delay: -5s;
          animation-duration: 20s;
        }
        @keyframes move-forever {
          0% {
            transform: translate3d(-90px,0,0);
          }
          100% { 
            transform: translate3d(85px,0,0);
          }
        }
      `}</style>
    </div>
  );
};

export default Privatesia;