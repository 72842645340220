import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ScrollReveal from 'scrollreveal';
import 'animate.css';

const Anulimet = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    ScrollReveal().reveal('.container > div', { 
      delay: 200,
      distance: '50px',
      origin: 'bottom',
      duration: 1000,
      easing: 'cubic-bezier(0.5, 0, 0, 1)',
      interval: 150
    });
  }, []);

  return (
    <div className="anulimet-e-fluturimeve">
      <section className="hero relative bg-gradient-to-r from-blue-600 to-indigo-800 text-white overflow-hidden">
        <div className="absolute inset-0">
          <img src="/assets/images/flight_cancel.jpg" alt="Cancelled Flight" className="w-full h-full object-cover opacity-90" />
          <div className="absolute inset-0 bg-stone-600 mix-blend-multiply"></div>
        </div>

        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
            Anulimet e Fluturimeve
          </h1>
          <p className="mt-6 max-w-3xl text-xl text-blue-100">
            Mësoni të drejtat tuaja dhe si të merrni kompensimin që meritoni për fluturimet e anuluara.
          </p>

          <div className="mt-10 max-w-sm sm:max-w-none sm:flex">
            <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
              <a href="#learn-more" className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-blue-700 bg-white hover:bg-blue-50 sm:px-8">
                Mëso më shumë
              </a>
              <Link to="/compensation/request" className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-500 bg-opacity-60 hover:bg-opacity-70 sm:px-8">
                Kontrollo fluturimin
              </Link>
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 left-0 right-0">
          <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax">
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </div>
      </section>

      <section id="learn-more" className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Të drejtat tuaja</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Çfarë duhet të dini për anulimet e fluturimeve
            </p>
          </div>

          <div className="mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Çfarë konsiderohet anulim?</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  Një fluturim konsiderohet i anuluar kur nuk operohet fare, pavarësisht nga arsyeja.
                </dd>
              </div>

              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                    </svg>
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Sa kompensim mund të merrni?</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  Kompensimi varion nga 250€ deri në 600€, në varësi të distancës së fluturimit dhe kohës së njoftimit.
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </section>

      <section className="py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-semibold mb-16 text-center">Të drejtat tuaja gjatë anulimeve</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div className="bg-white rounded-xl shadow-lg p-8 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <svg className="h-16 w-16 text-blue-600 mx-auto mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
              </svg>
              <h3 className="text-2xl font-semibold mb-4 text-center">Rimbursim i plotë</h3>
              <p className="text-gray-600 text-center">Ju keni të drejtë për rimbursim të plotë të biletës nëse zgjidhni të mos udhëtoni.</p>
            </div>
            <div className="bg-white rounded-xl shadow-lg p-8 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <svg className="h-16 w-16 text-blue-600 mx-auto mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
              </svg>
              <h3 className="text-2xl font-semibold mb-4 text-center">Fluturim alternativ</h3>
              <p className="text-gray-600 text-center">Linja ajrore duhet t'ju ofrojë një fluturim alternativ në destinacionin tuaj përfundimtar.</p>
            </div>
            <div className="bg-white rounded-xl shadow-lg p-8 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl">
              <svg className="h-16 w-16 text-blue-600 mx-auto mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
              </svg>
              <h3 className="text-2xl font-semibold mb-4 text-center">Kujdes dhe ndihmë</h3>
              <p className="text-gray-600 text-center">Ju keni të drejtë për ushqim, pije, akomodim dhe transport gjatë pritjes për fluturimin alternativ.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-24">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-semibold mb-16 text-center">Si të veproni në rast anulimi</h2>
          <div className="max-w-4xl mx-auto">
            <div className="bg-white rounded-xl shadow-lg p-8">
              <ol className="list-none space-y-6">
                <li className="flex items-center">
                  <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-blue-600 text-white rounded-full mr-4">1</span>
                  <span className="text-lg text-gray-700">Mblidhni të gjitha dokumentet përkatëse (boarding pass, email konfirmimi, etj.)</span>
                </li>
                <li className="flex items-center">
                  <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-blue-600 text-white rounded-full mr-4">2</span>
                  <span className="text-lg text-gray-700">Kërkoni shpjegim zyrtar nga linja ajrore për arsyen e anulimit</span>
                </li>
                <li className="flex items-center">
                  <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-blue-600 text-white rounded-full mr-4">3</span>
                  <span className="text-lg text-gray-700">Ruani të gjitha faturat për shpenzimet e bëra gjatë pritjes</span>
                </li>
                <li className="flex items-center">
                  <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-blue-600 text-white rounded-full mr-4">4</span>
                  <span className="text-lg text-gray-700">Kontaktoni linjën ajrore për të kërkuar kompensim</span>
                </li>
                <li className="flex items-center">
                  <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-blue-600 text-white rounded-full mr-4">5</span>
                  <span className="text-lg text-gray-700">Nëse linja ajrore refuzon, kontaktoni Avioza për ndihmë të specializuar</span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="cta bg-gradient-to-r from-blue-500 to-indigo-600 py-24">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl font-semibold text-white mb-8">Gati për të kontrolluar fluturimin tuaj?</h2>
          <p className="text-xl text-blue-100 mb-12">Zbuloni nëse kualifikoheni për kompensim për anulimin e fluturimit tuaj.</p>
          <Link to="/compensation/request" className="inline-block bg-white text-blue-600 font-semibold px-8 py-4 rounded-full shadow-lg hover:bg-blue-50 transition duration-300 animate__animated animate__pulse animate__infinite">
            Kontrollo Tani
          </Link>
        </div>
      </section>

      <style jsx>{`
        .waves {
          position: relative;
          width: 100%;
          height: 15vh;
          margin-bottom: -7px;
          min-height: 100px;
          max-height: 150px;
        }
        .parallax > use {
            animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
          }
          .parallax > use:nth-child(1) {
            animation-delay: -2s;
            animation-duration: 7s;
          }
          .parallax > use:nth-child(2) {
            animation-delay: -3s;
            animation-duration: 10s;
          }
          .parallax > use:nth-child(3) {
            animation-delay: -4s;
            animation-duration: 13s;
          }
          .parallax > use:nth-child(4) {
            animation-delay: -5s;
            animation-duration: 20s;
          }
          @keyframes move-forever {
            0% {
              transform: translate3d(-90px,0,0);
            }
            100% { 
              transform: translate3d(85px,0,0);
            }
          }
        `}</style>
      </div>
    );
  };
  
  export default Anulimet;