import React from 'react';

const WeatherConditions = ({ weatherConditions }) => {
  return (
    <section className="mb-12 bg-white rounded-xl shadow-sm p-6">
      <h2 className="text-2xl sm:text-3xl font-semibold mb-8 text-center">Kushtet e Motit</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
        <div>
          <h3 className="text-xl font-semibold mb-4">Nisja</h3>
          <ul className="space-y-2">
            <li><span className="text-gray-600">Kushtet:</span> {weatherConditions.departure.condition}</li>
            <li><span className="text-gray-600">Temperatura:</span> {weatherConditions.departure.temperature}°C</li>
            <li><span className="text-gray-600">Shpejtësia e erës:</span> {weatherConditions.departure.wind_speed} km/h</li>
            <li><span className="text-gray-600">Reshjet:</span> {weatherConditions.departure.precipitation} mm</li>
            <li><span className="text-gray-600">Dukshmëria:</span> {weatherConditions.departure.visibility} km</li>
            <li><span className="text-gray-600">Ashpërsia:</span> {weatherConditions.departure.severity}</li>
          </ul>
        </div>
        <div>
          <h3 className="text-xl font-semibold mb-4">Mbërritja</h3>
          <ul className="space-y-2">
            <li><span className="text-gray-600">Kushtet:</span> {weatherConditions.arrival.condition}</li>
            <li><span className="text-gray-600">Temperatura:</span> {weatherConditions.arrival.temperature}°C</li>
            <li><span className="text-gray-600">Shpejtësia e erës:</span> {weatherConditions.arrival.wind_speed} km/h</li>
            <li><span className="text-gray-600">Reshjet:</span> {weatherConditions.arrival.precipitation} mm</li>
            <li><span className="text-gray-600">Dukshmëria:</span> {weatherConditions.arrival.visibility} km</li>
            <li><span className="text-gray-600">Ashpërsia:</span> {weatherConditions.arrival.severity}</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default WeatherConditions;