import api from '../utils/api';

export const adminService = {
  getAllClaims: async (filters = {}) => {
    const queryString = new URLSearchParams(filters).toString();
    const response = await api.get(`/admin/claims?${queryString}`);
    return response.data;
  },

  getClaimById: async (claimId) => {
    const response = await api.get(`/admin/claims/${claimId}`);
    return response.data;
  },

  updateClaim: async (pnr, claimData) => {
    const response = await api.put(`/compensation/${pnr}/update`, claimData);
    return response.data;
  },

  updateClaimStatus: async (pnr, status) => {
    const response = await api.put(`/compensation/${pnr}/update`, { status });
    return response.data;
  },

  updatePayoutStatus: async (pnr, payoutStatus) => {
    const response = await api.put(`/compensation/${pnr}/payout-status`, { status: payoutStatus });
    return response.data;
  },

  deleteClaim: async (claimId) => {
    const response = await api.delete(`/admin/claims/${claimId}`);
    return response.data;
  },

  getAllUsers: async () => {
    const response = await api.get('/admin/users');
    return response.data;
  },

  getUserById: async (userId) => {
    const response = await api.get(`/admin/users/${userId}`);
    return response.data;
  },

  updateUserRole: async (userId, isAdmin) => {
    const response = await api.put(`/admin/users/${userId}/role`, { isAdmin });
    return response.data;
  },

  deleteUser: async (userId) => {
    const response = await api.delete(`/admin/users/${userId}`);
    return response.data;
  },

  resetUserPassword: async (userId) => {
    const response = await api.post(`/admin/users/${userId}/reset-password`);
    return response.data;
  },

  getAnalyticsData: async () => {
    const response = await api.get('/admin/analytics');
    return response.data;
  },

  getMonthlyCompensationData: async () => {
    const response = await api.get('/admin/analytics/monthly-compensation');
    return response.data;
  },

  getSettings: async () => {
    const response = await api.get('/admin/settings');
    return response.data;
  },

  updateSettings: async (settings) => {
    const response = await api.put('/admin/settings', settings);
    return response.data;
  },

  getAllFlights: async () => {
    const response = await api.get('/admin/flights');
    return response.data;
  },
  
  calculateCompensation: async (flightDetails) => {
    const response = await api.post('/admin/calculate-compensation', flightDetails);
    return response.data;
  },

  getAuditLogs: async (filters = {}) => {
    const queryString = new URLSearchParams(filters).toString();
    const response = await api.get(`/admin/audit-logs?${queryString}`);
    return response.data;
  },

  exportClaimsData: async (format = 'csv') => {
    const response = await api.get(`/admin/export-claims?format=${format}`, {
      responseType: 'blob' // Important for file downloads
    });
    return response.data;
  },

  importClaimsData: async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await api.post('/admin/import-claims', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  },

  sendBulkEmails: async (userIds, emailData) => {
    const response = await api.post('/admin/send-bulk-emails', { userIds, emailData });
    return response.data;
  },

  getSystemHealth: async () => {
    const response = await api.get('/admin/system-health');
    return response.data;
  },

  backupDatabase: async () => {
    const response = await api.post('/admin/backup-database');
    return response.data;
  },

  restoreDatabase: async (backupFile) => {
    const formData = new FormData();
    formData.append('backupFile', backupFile);
    const response = await api.post('/admin/restore-database', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  },

  updatePrivacyPolicy: async (newPolicy) => {
    const response = await api.put('/admin/privacy-policy', { policy: newPolicy });
    return response.data;
  },

  updateTermsOfService: async (newTerms) => {
    const response = await api.put('/admin/terms-of-service', { terms: newTerms });
    return response.data;
  },

  getPaymentProviders: async () => {
    const response = await api.get('/admin/payment-providers');
    return response.data;
  },

  updatePaymentProvider: async (providerId, providerData) => {
    const response = await api.put(`/admin/payment-providers/${providerId}`, providerData);
    return response.data;
  },

  getCurrencyExchangeRates: async () => {
    const response = await api.get('/admin/currency-exchange-rates');
    return response.data;
  },

  updateCurrencyExchangeRate: async (currencyCode, rate) => {
    const response = await api.put('/admin/currency-exchange-rates', { currencyCode, rate });
    return response.data;
  }
};

export default adminService;
