import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ScrollReveal from 'scrollreveal';
import 'animate.css';

const Kushtet = () => {
  const [activeTab, setActiveTab] = useState('usage');
  const [openQuestion, setOpenQuestion] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    ScrollReveal().reveal('.container > div', { 
      delay: 200,
      distance: '50px',
      origin: 'bottom',
      duration: 1000,
      easing: 'cubic-bezier(0.5, 0, 0, 1)',
      interval: 150
    });
  }, []);

  const termsOverview = [
    {
      title: "Përdorimi i Shërbimit",
      description: "Kushtet për përdorimin e shërbimeve të Avioza, duke përfshirë të drejtat dhe përgjegjësitë tuaja si përdorues.",
      icon: (
        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
        </svg>
      )
    },
    {
      title: "Pagesat dhe Tarifat",
      description: "Informacion për tarifat e shërbimit, metodat e pagesës dhe politikat e rimbursimit.",
      icon: (
        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
        </svg>
      )
    },
    {
      title: "Pronësia Intelektuale",
      description: "Të drejtat e pronësisë intelektuale të Avioza dhe përdorimi i lejuar i materialeve tona.",
      icon: (
        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4" />
        </svg>
      )
    },
    {
      title: "Përgjegjësitë dhe Kufizimet",
      description: "Kufizimet e përgjegjësisë së Avioza dhe përgjegjësitë e përdoruesve.",
      icon: (
        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
        </svg>
      )
    }
  ];

  const termsDetails = {
    usage: {
      title: "Përdorimi i Shërbimit",
      content: (
        <>
          <p className="mb-4">Duke përdorur shërbimet e Avioza, ju pranoni të:</p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>Jepni informacion të saktë dhe të plotë gjatë regjistrimit dhe përdorimit të shërbimit</li>
            <li>Përdorni shërbimin vetëm për qëllime të ligjshme dhe në përputhje me këto kushte</li>
            <li>Mos abuzoni ose ndërhyni në funksionimin normal të platformës</li>
            <li>Respektoni të drejtat e përdoruesve të tjerë dhe të palëve të treta</li>
            <li>Mbani konfidenciale kredencialet tuaja të llogarisë dhe të mos i ndani ato me të tjerët</li>
            <li>Njoftoni menjëherë Avioza për çdo përdorim të paautorizuar të llogarisë suaj</li>
            <li>Mos përdorni shërbimin për të shpërndarë materiale të paligjshme, fyese ose të dëmshme</li>
          </ul>
          <p className="mb-4">Avioza rezervon të drejtën të:</p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>Modifikojë, pezullojë ose ndërpresë shërbimin në çdo kohë, me ose pa njoftim</li>
            <li>Refuzojë, kufizojë ose ndërpresë aksesin tuaj në shërbim nëse besojmë se keni shkelur këto kushte</li>
            <li>Hetojë çdo dyshim për shkelje të këtyre kushteve dhe të ndërmarrë veprimet e duhura</li>
          </ul>
          <p>Përdorimi i vazhdueshëm i shërbimit pas ndryshimeve në këto kushte përbën pranimin tuaj të kushteve të reja.</p>
        </>
      )
    },
    payments: {
      title: "Pagesat dhe Tarifat",
      content: (
        <>
          <p className="mb-4">Për shërbimet e Avioza aplikohen kushtet e mëposhtme të pagesës:</p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>Tarifat tona bazohen në një përqindje të kompensimit të fituar (zakonisht 25% plus TVSH)</li>
            <li>Nuk ka kosto fillestare ose tarifa të fshehura</li>
            <li>Pagesat kryhen vetëm pas marrjes së suksesshme të kompensimit</li>
            <li>Metodat e pranuara të pagesës përfshijnë transfertën bankare dhe kartat kryesore të kreditit</li>
            <li>Të gjitha çmimet janë në Euro, përveç nëse specifikohet ndryshe</li>
            <li>Ju jeni përgjegjës për të gjitha taksat e aplikueshme përtej tarifës sonë të shërbimit</li>
          </ul>
          <p className="mb-4">Politika jonë e rimbursimit:</p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>Nëse nuk arrijmë të sigurojmë kompensimin tuaj, nuk do të paguani asgjë ("No win, no fee")</li>
            <li>Nëse tërhiqeni nga kërkesa pas fillimit të procesit, mund të aplikohen tarifa administrative</li>
            <li>Rimbursime të plota jepen vetëm në rrethana të jashtëzakonshme, të vlerësuara rast pas rasti</li>
          </ul>
          <p>Avioza rezervon të drejtën të ndryshojë tarifat e saj në çdo kohë, por ndryshimet do të aplikohen vetëm për shërbimet e ardhshme, jo ato ekzistuese.</p>
        </>
      )
    },
    ip: {
      title: "Pronësia Intelektuale",
      content: (
        <>
          <p className="mb-4">Të gjitha të drejtat e pronësisë intelektuale në lidhje me Avioza, duke përfshirë por pa u kufizuar në tekstin, imazhet, logot, kodin e softuerit dhe dizajnin e faqes së internetit, janë pronë e Avioza ose licensuesve të saj. Duke përdorur shërbimin tonë, ju pranoni që:</p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>Nuk do të kopjoni, modifikoni, shpërndani ose përdorni materialet tona pa leje të shkruar</li>
            <li>Përdorimi i materialeve tona është vetëm për qëllime personale dhe jo-komerciale</li>
            <li>Nuk do të përdorni asnjë element të shërbimit tonë për të krijuar një shërbim konkurrues</li>
            <li>Nuk do të përdorni robotë, kërkues, ose mjete të tjera automatike për të aksesuar shërbimin tonë</li>
            <li>Nuk do të dekompiloni, kryeni inxhinieri të kundërt ose tentoni të nxirrni kodin burim të softuerit tonë</li>
          </ul>
          <p className="mb-4">Marka tregtare:</p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>Emri "Avioza" dhe logoja jonë janë marka të regjistruara tregtare</li>
            <li>Përdorimi i paautorizuar i markave tona tregtare është rreptësisht i ndaluar</li>
          </ul>
          <p>Çdo përdorim i paautorizuar i materialeve tona mund të rezultojë në ndërprerje të menjëhershme të shërbimit dhe veprime ligjore.</p>
        </>
      )
    },
    liability: {
      title: "Përgjegjësitë dhe Kufizimet",
      content: (
        <>
          <p className="mb-4">Ndërsa ne përpiqemi të ofrojmë shërbimin më të mirë të mundshëm, ka disa kufizime të përgjegjësisë sonë:</p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>Avioza nuk është përgjegjëse për vonesa ose dështime që rezultojnë nga rrethana jashtë kontrollit tonë të arsyeshëm</li>
            <li>Ne nuk garantojmë rezultate specifike nga përdorimi i shërbimit tonë</li>
            <li>Shërbimi ofrohet "siç është" dhe "siç disponohet" pa garanci të çdo lloji</li>
            <li>Përgjegjësia jonë maksimale është e kufizuar në shumën e tarifave të paguara për shërbimin tonë</li>
            <li>Ne nuk jemi përgjegjës për dëme indirekte, aksidentale, speciale ose rrjedhimore</li>
            <li>Përdoruesit janë përgjegjës për saktësinë e informacionit që japin</li>
          </ul>
          <p className="mb-4">Dëmshpërblimi:</p>
          <p>Ju pranoni të na mbani të padëmtuar nga çdo pretendim, humbje, përgjegjësi, dëmtim ose kosto (përfshirë tarifat ligjore) që rrjedhin nga:</p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>Shkelja juaj e këtyre kushteve</li>
            <li>Përdorimi juaj i shërbimit</li>
            <li>Çdo informacion që ju ofroni</li>
            <li>Çdo pretendim se informacioni që keni dhënë shkel të drejtat e një pale të tretë</li>
          </ul>
          <p>Avioza rezervon të drejtën të marrë kontrollin ekskluziv të mbrojtjes dhe zgjidhjes së çdo çështjeje që i nënshtrohet dëmshpërblimit nga ju.</p>
        </>
      )
    }
  };

  const faqItems = [
    {
      question: "A mund të ndryshojnë kushtet e përdorimit?",
      answer: "Po, Avioza rezervon të drejtën të ndryshojë kushtet e përdorimit në çdo kohë. Ndryshimet do të hyjnë në fuqi menjëherë pas publikimit në faqen tonë të internetit. Ne do t'ju njoftojmë për ndryshime të rëndësishme përmes emailit ose njoftimeve në platformë. Përdorimi i vazhdueshëm i shërbimit pas ndryshimeve përbën pranimin tuaj të kushteve të reja."
    },
    {
      question: "Çfarë ndodh nëse nuk jam dakord me kushtet?",
      answer: "Nëse nuk jeni dakord me kushtet tona të përdorimit, ju lutemi të mos përdorni shërbimin tonë. Nëse nuk pranoni ndryshimet në kushte, duhet të ndërprisni përdorimin e shërbimit. Mund të na kontaktoni për të diskutuar shqetësimet tuaja ose për të kërkuar sqarime të mëtejshme."
    }
  ];

  return (
    <div className="kushtet">
      <section className="hero relative bg-gradient-to-r from-blue-600 to-indigo-800 text-white overflow-hidden">
        <div className="absolute inset-0">
          <img src="/assets/images/terms-background.jpg" alt="Terms and Conditions" className="w-full h-full object-cover opacity-20" />
          <div className="absolute inset-0 bg-blue-600 mix-blend-multiply"></div>
        </div>

        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
            Kushtet e Përdorimit
          </h1>
          <p className="mt-6 max-w-3xl text-xl text-blue-100">
            Informacion i detajuar për kushtet dhe rregullat e përdorimit të shërbimeve të Avioza. Kuptoni të drejtat dhe përgjegjësitë tuaja kur përdorni platformën tonë.
          </p>
          <div className="mt-10 flex justify-center">
            <a href="#terms-overview" className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-blue-700 bg-white hover:bg-blue-50">
              Eksploroni Kushtet
              <svg className="ml-2 -mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
            </a>
          </div>
        </div>

        <div className="absolute bottom-0 left-0 right-0">
          <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax">
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </div>
      </section>

      <section id="terms-overview" className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center mb-12">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Vështrim i Përgjithshëm i Kushteve</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Çfarë përfshijnë kushtet tona
            </p>
          </div>

          <div className="mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              {termsOverview.map((item, index) => (
                <div key={index} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                      {item.icon}
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.title}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    {item.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </section>

      <section className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center mb-12">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Kushtet e Përdorimit në Detaje</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Çfarë duhet të dini për përdorimin e Avioza
            </p>
          </div>

          <div className="flex justify-center mb-8">
            {Object.keys(termsDetails).map((key) => (
              <button
                key={key}
                onClick={() => setActiveTab(key)}
                className={`px-4 py-2 ${activeTab === key ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'} focus:outline-none transition duration-300`}
              >
                {termsDetails[key].title}
              </button>
            ))}
          </div>

          <div className="bg-white rounded-lg shadow-lg p-6">
            <h3 className="text-2xl font-bold mb-4">{termsDetails[activeTab].title}</h3>
            {termsDetails[activeTab].content}
          </div>
        </div>
      </section>

      <section className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center mb-12">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Pyetje të Shpeshta për Kushtet</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Përgjigje për pyetjet tuaja më të zakonshme
            </p>
          </div>

          <div className="mt-10">
            <div className="space-y-6">
              {faqItems.map((item, index) => (
                <div key={index} className="bg-gray-50 rounded-xl shadow-lg overflow-hidden">
                  <button
                    onClick={() => setOpenQuestion(openQuestion === index ? null : index)}
                    className="flex justify-between items-center w-full px-4 py-5 sm:p-6 focus:outline-none"
                  >
                    <span className="text-lg font-semibold text-gray-900">{item.question}</span>
                    <svg
                      className={`h-6 w-6 text-blue-500 transform ${openQuestion === index ? 'rotate-180' : ''}`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>
                  {openQuestion === index && (
                    <div className="px-4 pb-5 sm:px-6 sm:pb-6">
                      <p className="text-gray-700">{item.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center mb-12">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Ndryshimet në Kushtet e Përdorimit</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Qëndroni të informuar për ndryshimet
            </p>
          </div>

          <div className="bg-white rounded-lg shadow-lg p-6">
            <p className="text-gray-700 mb-4">Ne mund të përditësojmë këto kushte të përdorimit herë pas here për të reflektuar ndryshimet në praktikat tona të biznesit ose për arsye të tjera ligjore ose rregullatore. Versioni më i fundit i kushteve të përdorimit do të jetë gjithmonë i disponueshëm në këtë faqe.</p>
            <p className="text-gray-700 mb-4">Kur bëjmë ndryshime të rëndësishme në këto kushte, ne do t'ju njoftojmë duke:</p>
            <ul className="list-disc list-inside mb-4 space-y-2 text-gray-700">
              <li>Postuar një njoftim të dukshëm në faqen tonë të internetit</li>
              <li>Dërguar një email në adresën e regjistruar të llogarisë suaj</li>
              <li>Përditësuar datën e "Përditësimit të fundit" në fillim të këtyre kushteve</li>
            </ul>
            <p className="text-gray-700">Ne ju inkurajojmë të rishikoni rregullisht këtë faqe për informacionin më të fundit rreth kushteve tona të përdorimit. Vazhdimi i përdorimit të shërbimeve tona pas ndryshimeve në kushtet e përdorimit do të konsiderohet si pranim i këtyre ndryshimeve.</p>
          </div>
        </div>
      </section>

      <section className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Na Kontaktoni</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Keni pyetje rreth kushteve të përdorimit?
            </p>
            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
              Nëse keni ndonjë pyetje, shqetësim ose kërkesë në lidhje me kushtet tona të përdorimit, ju lutemi na kontaktoni:
            </p>
          </div>
          <div className="mt-10 text-center">
            <p className="text-lg text-gray-700">Email: terms@avioza.al</p>
            <p className="text-lg text-gray-700">Telefon: +355 69 123 4567</p>
            <p className="text-lg text-gray-700">Adresa: Rruga e Durrësit, Nd. 91, H.14, Njësia Administrative Nr. 2, 1001 Tiranë, Shqipëri</p>
          </div>
          <div className="mt-10 text-center">
            <Link to="/contact" className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              Na kontaktoni
              <svg className="ml-2 -mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </Link>
          </div>
        </div>
      </section>

      <style jsx>{`
        .waves {
          position: relative;
          width: 100%;
          height: 15vh;
          margin-bottom: -7px;
          min-height: 100px;
          max-height: 150px;
        }
        .parallax > use {
          animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
        }
        .parallax > use:nth-child(1) {
          animation-delay: -2s;
          animation-duration: 7s;
        }
        .parallax > use:nth-child(2) {
          animation-delay: -3s;
          animation-duration: 10s;
        }
        .parallax > use:nth-child(3) {
          animation-delay: -4s;
          animation-duration: 13s;
        }
        .parallax > use:nth-child(4) {
            animation-delay: -5s;
            animation-duration: 20s;
          }
          @keyframes move-forever {
            0% {
              transform: translate3d(-90px,0,0);
            }
            100% { 
              transform: translate3d(85px,0,0);
            }
          }
        `}</style>
      </div>
    );
  };
  
  export default Kushtet;