import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import SummaryCards from '../components/dashboard/SummaryCards';
import ClaimsTable from '../components/dashboard/ClaimsTable';
import StatusChart from '../components/dashboard/StatusChart';
import CompensationTrend from '../components/dashboard/CompensationTrend';
import NotificationPreferences from '../components/dashboard/NotificationPreferences';
import BankDetailsModal from '../components/dashboard/BankDetailsModal';
import { useAuth } from '../hooks/useAuth';
import { useCompensation } from '../hooks/useCompensation';
import { useNotification } from '../hooks/useNotification';

const Dashboard = () => {
  const { user } = useAuth();
  const { showNotification } = useNotification();
  const {
    compensations,
    fetchCompensations,
    updatePayoutStatus,
    fetchBankDetails,
    updateBankDetails,
  } = useCompensation();

  const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState(null);
  const [emailNotifications, setEmailNotifications] = useState(true);
  const [smsNotifications, setSmsNotifications] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCompensations();
  }, []);

  const handleOpenBankDetails = (claim) => {
    setSelectedClaim(claim);
    setShowBankDetailsModal(true);
  };

  const handleUpdatePayoutStatus = async (claimId, newStatus) => {
    try {
      await updatePayoutStatus(claimId, newStatus);
      showNotification('Statusi i pagesës u përditësua me sukses', 'success');
      fetchCompensations();
    } catch (error) {
      showNotification('Ndodhi një gabim gjatë përditësimit të statusit të pagesës', 'error');
    }
  };

  const handleUpdateNotificationPreferences = async (email, sms) => {
    // Implement the API call to update notification preferences
    // For now, we'll just update the local state
    setEmailNotifications(email);
    setSmsNotifications(sms);
    showNotification('Preferencat e njoftimeve u përditësuan me sukses', 'success');
  };

  const handleExportCompensationData = () => {
    // Implement the export functionality
    showNotification('Të dhënat e kompensimit u eksportuan me sukses', 'success');
  };

  return (
    <div className="bg-white min-h-screen font-['SF_Pro_Display',-apple-system,BlinkMacSystemFont,'Segoe_UI',Roboto,Helvetica,Arial,sans-serif]">
      <Helmet>
        <title>Paneli Juaj | Avioza</title>
      </Helmet>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl sm:text-5xl md:text-6xl font-semibold text-[#1D1D1F] mb-8 md:mb-12 tracking-tight">Paneli Juaj</h1>
        
        <SummaryCards compensations={compensations} userRole={user.role} />
        
        <ClaimsTable
          compensations={compensations}
          onOpenBankDetails={handleOpenBankDetails}
          onExport={handleExportCompensationData}
          userRole={user.role}
        />
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12 md:mb-16">
          <StatusChart compensations={compensations} />
          <CompensationTrend compensations={compensations} />
        </div>
        
        <NotificationPreferences
          emailNotifications={emailNotifications}
          smsNotifications={smsNotifications}
          onUpdate={handleUpdateNotificationPreferences}
        />
      </div>

      {showBankDetailsModal && (
        <BankDetailsModal
          claim={selectedClaim}
          onClose={() => setShowBankDetailsModal(false)}
          onUpdateStatus={handleUpdatePayoutStatus}
          fetchBankDetails={fetchBankDetails}
          updateBankDetails={updateBankDetails}
        />
      )}
    </div>
  );
};

export default Dashboard;