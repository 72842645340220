import api from '../utils/api';

export const airportService = {
  searchAirports: async (query) => {
    const response = await api.get('/airports/search', { params: { query } });
    return response.data;
  }
};

export const getAirportInfo = async (iataCode) => {
  try {
    const response = await api.get(`/api/airports/info/${iataCode}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching airport info:', error);
    throw error;
  }
};