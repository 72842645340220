import React from 'react';
import { 
  UserGroupIcon, 
  CurrencyEuroIcon, 
  DocumentTextIcon,
  CheckCircleIcon,
  ArrowTrendingUpIcon,
  ExclamationCircleIcon
} from '@heroicons/react/24/outline';
import { formatCurrency } from '../../utils/translationUtils';

const AdminSummaryCard = ({ title, value, icon: Icon, change, info }) => (
  <div className="bg-white overflow-hidden shadow rounded-lg">
    <div className="p-5">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <Icon className="h-6 w-6 text-gray-400" aria-hidden="true" />
        </div>
        <div className="ml-5 w-0 flex-1">
          <dl>
            <dt className="text-sm font-medium text-gray-500 truncate">{title}</dt>
            <dd className="flex items-baseline">
              <div className="text-2xl font-semibold text-gray-900">{value}</div>
              {change && (
                <div className={`ml-2 flex items-baseline text-sm font-semibold ${change >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                  {change >= 0 ? (
                    <ArrowTrendingUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                  ) : (
                    <ArrowTrendingUpIcon className="self-center flex-shrink-0 h-5 w-5 text-red-500 transform rotate-180" aria-hidden="true" />
                  )}
                  <span className="sr-only">{change >= 0 ? 'Rritje' : 'Ulje'} prej</span>
                  {Math.abs(change)}%
                </div>
              )}
            </dd>
          </dl>
        </div>
      </div>
    </div>
    {info && (
      <div className="bg-gray-50 px-5 py-3">
        <div className="text-sm">
          <span className="font-medium text-cyan-700 hover:text-cyan-900">
            {info}
          </span>
        </div>
      </div>
    )}
  </div>
);

const AdminSummaryCards = ({ analytics }) => {
  return (
    <div className="mt-8">
      <h2 className="text-lg leading-6 font-medium text-gray-900 mb-4">Përmbledhje e Përgjithshme</h2>
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <AdminSummaryCard
          title="Totali i Përdoruesve"
          value={analytics.totalUsers}
          icon={UserGroupIcon}
          change={analytics.userGrowthRate}
          info={`${analytics.activeUsers} përdorues aktivë`}
        />
        <AdminSummaryCard
          title="Totali i Kërkesave"
          value={analytics.totalClaims}
          icon={DocumentTextIcon}
          change={analytics.claimGrowthRate}
          info={`${analytics.pendingClaims} kërkesa në pritje`}
        />
        <AdminSummaryCard
          title="Totali i Kompensimit"
          value={formatCurrency(analytics.totalCompensation)}
          icon={CurrencyEuroIcon}
          change={analytics.compensationGrowthRate}
          info={`Mesatarja: ${formatCurrency(analytics.averageCompensation)}`}
        />
        <AdminSummaryCard
          title="Kërkesat e Miratuara"
          value={analytics.approvedClaims}
          icon={CheckCircleIcon}
          change={analytics.approvalRateChange}
          info={`${analytics.approvalRate}% shkallë miratimi`}
        />
        <AdminSummaryCard
          title="Kërkesat e Refuzuara"
          value={analytics.rejectedClaims}
          icon={ExclamationCircleIcon}
          change={analytics.rejectionRateChange}
          info={`${analytics.rejectionRate}% shkallë refuzimi`}
        />
        <AdminSummaryCard
          title="Pagesat e Kryera"
          value={formatCurrency(analytics.totalPayouts)}
          icon={CurrencyEuroIcon}
          change={analytics.payoutGrowthRate}
          info={`${analytics.pendingPayouts} pagesa në pritje`}
        />
      </div>
    </div>
  );
};

export default AdminSummaryCards;