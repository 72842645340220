import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'animate.css';
import { 
  ClipboardDocumentCheckIcon, 
  DocumentTextIcon, 
  CreditCardIcon,
  ShieldCheckIcon,
  ClockIcon,
  ExclamationTriangleIcon,
  CheckIcon,
  PaperAirplaneIcon
} from '@heroicons/react/24/outline';
import StorytellingHowItWorks from '../components/StorytellingHowItWorks';  // Adjust the import path as needed

const HowItWorks = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="how-it-works">
      {/* <section className="hero bg-gradient-to-r from-black to-black text-white py-32 relative">
        <div className="container mx-auto px-4 relative z-10">
          <h1 className="text-5xl md:text-6xl font-bold mb-6 text-center animate__animated animate__fadeInDown">Si Funksionon Avioza</h1>
          <p className="text-xl md:text-2xl text-center max-w-3xl mx-auto animate__animated animate__fadeInUp animate__delay-1s">Mëso se si Avioza e bën kompensimin e fluturimit të thjeshtë dhe të lehtë për ju. Ne kujdesemi për të gjithë procesin, duke ju lejuar të përqendroheni në udhëtimin tuaj.</p>
        </div>
        <div className="absolute inset-0 w-full h-full bg-black opacity-60"></div>
        <div className="absolute inset-0 w-full h-full opacity-20" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/hero-how-it-works.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center'}}></div>      </section> */}

        <section>
<StorytellingHowItWorks />
</section>

      <section className="process py-24">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-semibold mb-12 text-center">Hapat e Procesit të Kompensimit</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div className="process-step bg-white rounded-lg shadow-xl p-8 flex flex-col items-center text-center transition duration-500 ease-in-out transform hover:-translate-y-2 hover:shadow-lg">
              <div className="process-icon bg-blue-600 text-white w-24 h-24 rounded-full flex items-center justify-center mb-6 transition duration-500 ease-in-out transform hover:scale-110">
                <ClipboardDocumentCheckIcon className="h-12 w-12" />
              </div>
              <h3 className="text-2xl font-semibold mb-4">Kontrolloni Përshtatshmërinë</h3>
              <p className="text-gray-600">Futni detajet e fluturimit tuaj në faqen tonë të internetit për të kontrolluar menjëherë përshtatshmërinë tuaj.</p>
            </div>
            <div className="process-step bg-white rounded-lg shadow-xl p-8 flex flex-col items-center text-center transition duration-500 ease-in-out transform hover:-translate-y-2 hover:shadow-lg">
              <div className="process-icon bg-blue-600 text-white w-24 h-24 rounded-full flex items-center justify-center mb-6 transition duration-500 ease-in-out transform hover:scale-110">
                <DocumentTextIcon className="h-12 w-12" />
              </div>
              <h3 className="text-2xl font-semibold mb-4">Ne Përgatisim Kërkesën</h3>
              <p className="text-gray-600">Nëse fluturimi juaj kualifikohet, ne do të përgatisim kërkesën tuaj dhe do ta paraqesim atë në emrin tuaj.</p>
            </div>
            <div className="process-step bg-white rounded-lg shadow-xl p-8 flex flex-col items-center text-center transition duration-500 ease-in-out transform hover:-translate-y-2 hover:shadow-lg">
              <div className="process-icon bg-blue-600 text-white w-24 h-24 rounded-full flex items-center justify-center mb-6 transition duration-500 ease-in-out transform hover:scale-110">
                <CreditCardIcon className="h-12 w-12" />
              </div>
              <h3 className="text-2xl font-semibold mb-4">Merrni Kompensimin Tuaj</h3>
              <p className="text-gray-600">Pasi të keni marrë kompensimin, ne do t'ju transferojmë shumën minus tarifat tona të shërbimit.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="eligibility bg-gray-100 py-24">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-24 items-center">
            <div>
              <h2 className="text-4xl font-semibold mb-8">Kriteret e Përshtatshmërisë</h2>
              <ul className="eligibility-list space-y-6">
                <li className="flex items-center">
                  <div className="eligibility-icon flex-shrink-0 inline-flex items-center justify-center bg-blue-600 text-white rounded-full w-14 h-14 mr-6">
                    <ShieldCheckIcon className="h-8 w-8" />
                  </div>
                  <span className="text-xl">Fluturimi u nis nga BE ose u operua nga një linjë ajrore e BE-së</span>
                </li>
                <li className="flex items-center">
                  <div className="eligibility-icon flex-shrink-0 inline-flex items-center justify-center bg-blue-600 text-white rounded-full w-14 h-14 mr-6">
                    <ClockIcon className="h-8 w-8" />
                  </div>
                  <span className="text-xl">Fluturimi u anulua ose u vonua për më shumë se 3 orë</span>
                </li>
                <li className="flex items-center">
                  <div className="eligibility-icon flex-shrink-0 inline-flex items-center justify-center bg-blue-600 text-white rounded-full w-14 h-14 mr-6">
                    <ExclamationTriangleIcon className="h-8 w-8" />
                  </div>
                  <span className="text-xl">Vonesa ose anulimi nuk u shkaktua nga rrethana të jashtëzakonshme</span>
                </li>
                <li className="flex items-center">
                  <div className="eligibility-icon flex-shrink-0 inline-flex items-center justify-center bg-blue-600 text-white rounded-full w-14 h-14 mr-6">
                    <CheckIcon className="h-8 w-8" />
                  </div>
                  <span className="text-xl">Ju u paraqitët në kohë për check-in</span>
                </li>
              </ul>
            </div>
            <div className="relative">
              <svg className="text-blue-600 mx-auto animate__animated animate__zoomIn" xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 35 35">
                <path fill="currentColor" d="M15.258 26.865a4.043 4.043 0 01-1.133 2.917A4.006 4.006 0 0111.253 31a3.992 3.992 0 01-2.872-1.218 4.028 4.028 0 01-1.133-2.917c.009-.698.2-1.382.557-1.981.356-.6.863-1.094 1.47-1.433-.024.109.09-.055 0 0l1.86-1.652a8.495 8.495 0 002.304-5.793c0-2.926-1.711-5.901-4.17-7.457.094.055-.036-.094 0 0A3.952 3.952 0 017.8 7.116a3.975 3.975 0 01-.557-1.98 4.042 4.042 0 011.133-2.918A4.006 4.006 0 0111.247 1a3.99 3.99 0 012.872 1.218 4.025 4.025 0 011.133 2.917 8.521 8.521 0 002.347 5.832l.817.8c.326.285.668.551 1.024.798.621.33 1.142.826 1.504 1.431a3.902 3.902 0 01-1.504 5.442c.033-.067-.063.036 0 0a8.968 8.968 0 00-3.024 3.183 9.016 9.016 0 00-1.158 4.244zM19.741 5.123c0 .796.235 1.575.676 2.237a4.01 4.01 0 001.798 1.482 3.99 3.99 0 004.366-.873 4.042 4.042 0 00.869-4.386 4.02 4.02 0 00-1.476-1.806 3.994 3.994 0 00-5.058.501 4.038 4.038 0 00-1.175 2.845zM23.748 22.84c-.792 0-1.567.236-2.226.678a4.021 4.021 0 00-1.476 1.806 4.042 4.042 0 00.869 4.387 3.99 3.99 0 004.366.873A4.01 4.01 0 0027.08 29.1a4.039 4.039 0 00-.5-5.082 4 4 0 00-2.832-1.18zM34 15.994c0-.796-.235-1.574-.675-2.236a4.01 4.01 0 00-1.798-1.483 3.99 3.99 0 00-4.367.873 4.042 4.042 0 00-.869 4.387 4.02 4.02 0 001.476 1.806 3.993 3.993 0 002.226.678 4.003 4.003 0 002.832-1.18A4.04 4.04 0 0034 15.993z"/>
                <path fill="currentColor" d="M5.007 11.969c-.793 0-1.567.236-2.226.678a4.021 4.021 0 00-1.476 1.807 4.042 4.042 0 00.869 4.386 4.001 4.001 0 004.366.873 4.011 4.011 0 001.798-1.483 4.038 4.038 0 00-.5-5.08 4.004 4.004 0 00-2.831-1.181z"/>
              </svg>
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent to-gray-100"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="compensation py-24">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-semibold mb-12 text-center">Shuma e Kompensimit</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div className="compensation-card bg-white rounded-lg shadow-xl p-8 flex flex-col items-center text-center transition duration-500 ease-in-out transform hover:-translate-y-2 hover:shadow-lg">
              <div className="icon-container bg-blue-600 text-white w-28 h-28 rounded-full flex items-center justify-center mb-6 transition duration-500 ease-in-out transform hover:scale-110">
                <PaperAirplaneIcon className="h-14 w-14" />
              </div>
              <h3 className="text-2xl font-semibold mb-4">Fluturimet deri në 1,500 km</h3>
              <p className="text-gray-600">Për fluturimet me distancë më të shkurtër se 1,500 km, ju mund të keni të drejtë për kompensim deri në 250€.</p>
            </div>
            
            <div className="compensation-card bg-white rounded-lg shadow-xl p-8 flex flex-col items-center text-center transition duration-500 ease-in-out transform hover:-translate-y-2 hover:shadow-lg">
              <div className="icon-container bg-blue-600 text-white w-28 h-28 rounded-full flex items-center justify-center mb-6 transition duration-500 ease-in-out transform hover:scale-110">
                <PaperAirplaneIcon className="h-14 w-14" />
              </div>
              <h3 className="text-2xl font-semibold mb-4">Fluturimet midis 1,500 km dhe 3,500 km</h3>
              <p className="text-gray-600">Për fluturimet me distancë midis 1,500 km dhe 3,500 km, ju mund të keni të drejtë për kompensim deri në 400€.</p>
            </div>

            <div className="compensation-card bg-white rounded-lg shadow-xl p-8 flex flex-col items-center text-center transition duration-500 ease-in-out transform hover:-translate-y-2 hover:shadow-lg">
              <div className="icon-container bg-blue-600 text-white w-28 h-28 rounded-full flex items-center justify-center mb-6 transition duration-500 ease-in-out transform hover:scale-110">
                <PaperAirplaneIcon className="h-14 w-14" />
              </div>
              <h3 className="text-2xl font-semibold mb-4">Fluturimet mbi 3,500 km</h3>
              <p className="text-gray-600">Për fluturimet me distancë mbi 3,500 km, ju mund të keni të drejtë për kompensim deri në 600€.</p>
            </div>
          </div>
          <p className="mt-12 text-center text-gray-600 text-xl">Këto shuma mund të reduktohen me 50% nëse linja ajrore ju ofron një opsion riplanifikimi që plotëson disa kritere specifike.</p>
        </div>
      </section>

      <section className="cta bg-gradient-to-r from-blue-500 to-indigo-600 py-24">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl font-semibold text-white mb-8">Gati për të filluar?</h2>
          <p className="text-xl text-blue-100 mb-12">Kontrolloni tani përshtatshmërinë tuaj dhe merrni kompensimin që ju takon.</p>
          <Link 
            to="/compensation/request" 
            className="inline-block bg-white text-blue-600 font-semibold px-8 py-4 rounded-full shadow-lg hover:bg-blue-50 transition duration-300 animate__animated animate__tada animate__delay-2s animate__slower animate__infinite"
          >
            Kontrollo Fluturimin Tim
          </Link>
        </div>
      </section>
    </div>
  );
};

export default HowItWorks;