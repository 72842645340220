import axios from 'axios';

// Axios instance setup
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://ws3.daymail.info/api',
});

// Axios request interceptor to add Authorization header
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Axios response interceptor to handle 401 errors
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

// Fetch service with Authorization header and 401 error handling
export const fetchWithAuth = async (url, options = {}) => {
  const token = localStorage.getItem('token');
  const headers = {
    ...options.headers,
    Authorization: token ? `Bearer ${token}` : undefined,
    'Content-Type': 'application/json', // Ensure JSON content-type
  };

  try {
    const response = await fetch(url, { ...options, headers });

    if (response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/login';
    }

    if (!response.ok) {
      const errorData = await response.json();
      return Promise.reject(errorData);
    }

    return response.json();
  } catch (error) {
    return Promise.reject(error);
  }
};

export default api;
