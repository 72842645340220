import React from 'react';
import { Plane, Calendar } from 'lucide-react';
import { formatDate, formatTime } from '../../../utils/translationUtils';

const FlightInformation = ({ eligibilityResult, selectedFlightData }) => {
  const flightData = selectedFlightData || eligibilityResult;

  if (!flightData) return null;

  return (
    <div className="bg-white rounded-lg p-3 sm:p-4 border border-gray-200 mb-6">
      <h3 className="text-lg font-semibold text-gray-900 mb-4">Fluturimi i Zgjedhur</h3>
      <div className="flex justify-between items-center mb-2 sm:mb-3">
        <div className="flex items-center space-x-2 sm:space-x-3">
          <img 
            src={`https://images.kiwi.com/airlines/128x128/${flightData.airline.toUpperCase()}.png`} 
            alt={`${flightData.airline} logo`}
            className="w-8 h-8 object-contain"
            onError={(e) => {e.target.onerror = null; e.target.src = '/path/to/fallback/image.png'}}
          />
          <div>
            <span className="text-sm sm:text-base text-gray-900">
              {flightData.airline.toUpperCase()}
            </span>
            <div className="flex items-center text-xs text-gray-500">
              {flightData.flight_number}
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-1 px-2 py-1 rounded-full text-xs font-medium bg-blue-50 text-blue-700">
          <span className="hidden sm:inline">
            {selectedFlightData ? 'Zgjedhur' : 'Skanuar'}
          </span>
        </div>
      </div>
      <div className="flex justify-between items-center bg-gray-50 p-2 sm:p-3 rounded-md">
        <div className="text-center flex-1">
          <p className="text-xs text-gray-500 mt-0.5 hidden sm:block">
            {flightData.departure.iataCode}
          </p>
          <p className="text-base sm:text-lg font-bold text-gray-900">
            {flightData.departure.airport.city}
          </p>
          <p className="text-xs font-medium text-gray-700">
            {formatTime(flightData.departure.scheduledTime)}
          </p>
          <p className="text-xs text-gray-500 mt-1">{flightData.departure.airport.name}</p>
        </div>
        <div className="flex-1 px-2 sm:px-4 relative">
          <div className="h-px bg-gray-300 w-full"></div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gray-50 px-1 sm:px-2">
            <Plane className="text-blue-500" size={14} />
          </div>
        </div>
        <div className="text-center flex-1">
          <p className="text-xs text-gray-500 mt-0.5 hidden sm:block">
            {flightData.arrival.iataCode}
          </p>
          <p className="text-base sm:text-lg font-bold text-gray-900">
            {flightData.arrival.airport.city}
          </p>
          <p className="text-xs font-medium text-gray-700">
            {formatTime(flightData.arrival.scheduledTime)}
          </p>
          <p className="text-xs text-gray-500 mt-1">{flightData.arrival.airport.name}</p>
        </div>
      </div>
      <div className="mt-2 sm:mt-3 flex items-center text-xs">
        <Calendar size={12} />
        <span className="text-gray-600 ml-2">
          {formatDate(flightData.departure.scheduledTime)}
        </span>
      </div>
    </div>
  );
};

export default FlightInformation;