import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';

const AdminStatusChart = ({ claims }) => {
  const chartRef = useRef(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    const statusCounts = claims.reduce((acc, claim) => {
      acc[claim.status] = (acc[claim.status] || 0) + 1;
      return acc;
    }, {});

    const ctx = document.getElementById('statusChart').getContext('2d');
    chartRef.current = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['Në pritje', 'Miratuar', 'Refuzuar'],
        datasets: [{
          data: [
            statusCounts.pending || 0,
            statusCounts.approved || 0,
            statusCounts.rejected || 0
          ],
          backgroundColor: ['#FFA500', '#4CAF50', '#F44336'],
          borderColor: '#FFFFFF',
          borderWidth: 2
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: true,
            text: 'Shpërndarja e Statusit të Kërkesave',
            font: {
              size: 16
            }
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                const label = context.label || '';
                const value = context.parsed || 0;
                const total = context.dataset.data.reduce((acc, data) => acc + data, 0);
                const percentage = ((value / total) * 100).toFixed(2) + '%';
                return `${label}: ${value} (${percentage})`;
              }
            }
          }
        },
        onClick: (event, elements) => {
          if (elements.length > 0) {
            const status = ['pending', 'approved', 'rejected'][elements[0].index];
            setSelectedStatus(status);
          }
        }
      }
    });

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [claims]);

  const filteredClaims = selectedStatus
    ? claims.filter(claim => claim.status === selectedStatus)
    : claims;

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div style={{ height: '300px' }}>
        <canvas id="statusChart"></canvas>
      </div>
      {selectedStatus && (
        <div className="mt-4">
          <h3 className="text-lg font-medium text-gray-900">Kërkesat me status: {selectedStatus}</h3>
          <ul className="mt-2 divide-y divide-gray-200">
            {filteredClaims.slice(0, 5).map(claim => (
              <li key={claim._id} className="py-2">
                <p className="text-sm text-gray-600">PNR: {claim.pnr}</p>
                <p className="text-sm text-gray-600">Fluturimi: {claim.flight_number}</p>
              </li>
            ))}
          </ul>
          {filteredClaims.length > 5 && (
            <p className="mt-2 text-sm text-gray-500">Duke shfaqur 5 nga {filteredClaims.length} kërkesat</p>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminStatusChart;