import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';

const StorytellingHowItWorks = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [compensationAmount, setCompensationAmount] = useState(0);
  const [isAutoPlay, setIsAutoPlay] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const videoRef = useRef(null);
  const controls = useAnimation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const stories = [
    {
      title: "Vonesa e Papritur e Artës",
      description: "Fluturimi i Artës nga Tirana në Paris u vonua për 5 orë të gjata.",
      icon: "M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z",
      content: (
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h4 className="text-2xl font-semibold mb-4">Sfida e Artës</h4>
          <p className="mb-4">Arta ishte në aeroportin e Tiranës, gati për udhëtimin e saj të shumëpritur në Paris. Por fati kishte plane të tjera.</p>
          <motion.div 
            className="bg-white-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4"
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <p className="font-bold">Njoftim i Papritur</p>
            <p>"Fluturimi juaj është vonuar për 5 orë për shkak të problemeve teknike."</p>
          </motion.div>
          <p className="mb-4">E lodhur dhe e frustruar, Arta nuk e dinte se çfarë të bënte. Ishte atëherë kur një mikeshë i sugjeroi të kontrollonte Avioza.</p>
        </div>
      )
    },
    {
      title: "Zbulimi i të Drejtave",
      description: "Arta mëson për të drejtat e saj si pasagjere dhe vendos të veprojë.",
      icon: "M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z",
      content: (
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h4 className="text-2xl font-semibold mb-4">Arta Zbulon Avioza</h4>
          <p className="mb-4">Me telefonin e saj, Arta vizitoi faqen e internetit të Avioza dhe u habit nga ajo që zbuloi.</p>
          <ul className="space-y-2 mb-4">
            {["E drejta për kompensim deri në 600€", "Proces i thjeshtë aplikimi", "Asnjë pagesë nëse kërkesa nuk miratohet"].map((item, index) => (
              <motion.li 
                key={index}
                className="flex items-center"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
              >
                <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                {item}
              </motion.li>
            ))}
          </ul>
        </div>
      )
    },
    {
      title: "Ekspertët në Veprim",
      description: "Ekipi i Avioza merr përsipër rastin e Artës me profesionalizëm.",
      icon: "M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z",
      content: (
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h4 className="text-2xl font-semibold mb-4">Puna e Palodhur e Ekspertëve</h4>
          <p className="mb-4">Sapo Arta dorëzoi kërkesën, ekspertët e Avioza u vunë menjëherë në punë.</p>
          <div className="space-y-4 mb-4">
            {['Verifikimi i detajeve', 'Përgatitja e dokumenteve', 'Negocimi me kompaninë ajrore'].map((step, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.5 }}
                className="flex items-center"
              >
                <svg className="w-6 h-6 mr-2 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                </svg>
                {step}
              </motion.div>
            ))}
          </div>
        </div>
      )
    },
    {
      title: "Suksesi i Artës",
      description: "Arta merr kompensimin e merituar dhe ndjen lehtësim.",
      icon: "M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
      content: (
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h4 className="text-2xl font-semibold mb-4">Rezultati i Suksesshëm</h4>
          <p className="mb-4">Pas dy javësh pritje, Arta mori lajmin e mirë.</p>
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ type: 'spring', stiffness: 260, damping: 20 }}
            className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4"
            role="alert"
          >
            <p className="font-bold text-3xl mb-2">Arta mori {compensationAmount}€ kompensim!</p>
            <p>Falë Avioza, Arta mori kompensimin e saj pa stres dhe pa kompleksitet.</p>
          </motion.div>
          <p className="text-lg text-gray-600 italic mb-4">"Nuk e besoja se do të ishte kaq e lehtë. Faleminderit Avioza për ndihmën tuaj!" - Arta</p>
        </div>
      )
    }
  ];

  useEffect(() => {
    let interval;
    if (isAutoPlay) {
      interval = setInterval(() => {
        setActiveStep((prevStep) => (prevStep + 1) % stories.length);
      }, 15000);
    }
    return () => clearInterval(interval);
  }, [isAutoPlay]);

  useEffect(() => {
    controls.start({
      scale: [1, 1.05, 1],
      transition: { duration: 0.5 }
    });
    setCompensationAmount(400);
  }, [activeStep]);

  const handlePrevStep = () => {
    setActiveStep((prevStep) => (prevStep - 1 + stories.length) % stories.length);
  };

  const handleNextStep = () => {
    setActiveStep((prevStep) => (prevStep + 1) % stories.length);
  };

  const handleVideoPlay = () => {
    setIsVideoPlaying(true);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const renderBarChart = () => {
    const data = [
      { name: 'Vonesa', value: compensationAmount },
      { name: 'Anulim', value: 600 },
      { name: 'Mbingarkesë', value: 250 },
    ];
    const maxValue = Math.max(...data.map(item => item.value));

    return (
      <div className="w-full h-64 mt-4">
        {data.map((item, index) => (
          <motion.div key={index} className="flex items-center mb-2"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: index * 0.2 }}
          >
            <div className="w-24 text-right mr-2">{item.name}</div>
            <div className="flex-grow bg-gray-200 rounded-full h-4 overflow-hidden">
              <motion.div
                className="bg-blue-500 h-full"
                initial={{ width: 0 }}
                animate={{ width: `${(item.value / maxValue) * 100}%` }}
                transition={{ duration: 1, delay: index * 0.2 }}
              />
            </div>
            <div className="w-16 text-left ml-2">{item.value}€</div>
          </motion.div>
        ))}
      </div>
    );
  };

  return (
    <section className="py-12 md:py-24 bg-gradient-to-b from-blue-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h2 
          className="text-3xl md:text-5xl font-bold text-center mb-6 md:mb-12 text-gray-900"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Si Funksionon Procesi i Kompensimit
        </motion.h2>
        <motion.p 
          className="text-lg md:text-xl text-center mb-8 md:mb-16 text-gray-600 max-w-3xl mx-auto"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          Ndiqni udhëtimin e Artës dhe zbuloni se si Avioza e ndihmoi atë të merrte kompensimin e merituar, pa stres dhe pa kompleksitet.
        </motion.p>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 md:gap-12 items-start">
          <div className="space-y-4 md:space-y-8 order-2 lg:order-1">
            {stories.map((story, index) => (
              <motion.div
                key={index}
                className={`p-4 md:p-6 rounded-lg transition-all duration-500 cursor-pointer ${
                  activeStep === index ? 'bg-blue-600 text-white shadow-xl' : 'bg-white hover:bg-blue-50'
                }`}
                onClick={() => setActiveStep(index)}
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.98 }}
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <div className="flex items-center mb-2 md:mb-4">
                  <div className={`w-10 h-10 md:w-12 md:h-12 rounded-full flex items-center justify-center ${
                    activeStep === index ? 'bg-white text-blue-600' : 'bg-blue-100 text-blue-600'
                  }`}>
                    <svg className="w-5 h-5 md:w-6 md:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={story.icon} />
                    </svg>
                  </div>
                  <h3 className="text-xl md:text-2xl font-semibold ml-3 md:ml-4">{story.title}</h3>
                </div>
                <p className={`text-sm md:text-lg ${activeStep === index ? 'text-blue-100' : 'text-gray-600'}`}>
                  {story.description}
                </p>
              </motion.div>
            ))}
          </div>

          <motion.div 
            className="lg:sticky lg:top-24 order-1 lg:order-2"
            animate={controls}
          >
            <AnimatePresence mode="wait">
              <motion.div
                key={activeStep}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
              >
                {stories[activeStep].content}
                {activeStep === 3 && renderBarChart()}
              </motion.div>
            </AnimatePresence>

            <div className="mt-8">
              <div className="aspect-w-16 aspect-h-9 bg-gray-200 rounded-lg overflow-hidden shadow-xl">
                {isVideoPlaying ? (
                  <video
                    ref={videoRef}
                    src="/path/to/your/video.mp4"
                    controls
                    onEnded={() => setIsVideoPlaying(false)}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="relative">
                    <img
                      src="https://images.pexels.com/photos/9433870/pexels-photo-9433870.jpeg"
                      alt="Si funksionon video placeholder"
                      className="w-full h-96 object-cover"
                    />
                    <motion.div 
                      className="absolute inset-0 flex items-center justify-center"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <button
                        onClick={handleVideoPlay}
                        className="w-16 h-16 md:w-20 md:h-20 bg-blue-600 bg-opacity-75 rounded-full flex items-center justify-center transition-all duration-300 hover:bg-opacity-100 focus:outline-none focus:ring-4 focus:ring-blue-300 focus:ring-opacity-50"
                      >
                        <svg className="w-8 h-8 md:w-10 md:h-10 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </button>
                    </motion.div>
                  </div>
                )}
              </div>
            </div>

            <motion.div 
              className="mt-8 flex justify-between items-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
            >
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handlePrevStep}
                className="p-2 rounded-full bg-blue-100 text-blue-600 hover:bg-blue-200"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
              </motion.button>
              <div className="flex items-center">
                <span className="mr-2 text-gray-600 text-sm md:text-base">Auto-play</span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={isAutoPlay}
                    onChange={() => setIsAutoPlay(!isAutoPlay)}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleNextStep}
                className="p-2 rounded-full bg-blue-100 text-blue-600 hover:bg-blue-200"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </motion.button>
            </motion.div>
          </motion.div>
        </div>

        <motion.div 
          className="mt-12 md:mt-20 text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.7 }}
        >
          <motion.a
            href="/kerkese-kompensimi"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="inline-flex items-center justify-center px-6 py-3 md:px-8 md:py-4 border border-transparent text-base md:text-lg font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 shadow-lg hover:shadow-xl animate__animated animate__tada animate__delay-2s animate__slower animate__infinite"
          >
            Fillo Procesin e Kompensimit Tënd
            <svg className="ml-2 -mr-1 w-4 h-4 md:w-5 md:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </motion.a>
        </motion.div>
      </div>
    </section>
  );
};

const styles = `
  .switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 28px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #3b82f6;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #3b82f6;
  }

  input:checked + .slider:before {
    transform: translateX(20px);
  }

  .slider.round {
    border-radius: 28px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  @media (max-width: 768px) {
    .switch {
      width: 40px;
      height: 24px;
    }

    .slider:before {
      height: 16px;
      width: 16px;
    }

    input:checked + .slider:before {
      transform: translateX(16px);
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.7);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(59, 130, 246, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
    }
  }

  .pulse {
    animation: pulse 2s infinite;
  }
`;

export default () => (
  <>
    <style>{styles}</style>
    <StorytellingHowItWorks />
  </>
);