import React from 'react';
import { cn } from './utils';

const StepIndicator = ({ currentStep }) => {
  const steps = ['initial', 'preview', 'scanning', 'result', 'manual'];

  return (
    <div className="flex justify-center items-center space-x-2 mb-4">
      {steps.map((step, index) => (
        <div 
          key={step}
          className={cn(
            'w-3 h-3 rounded-full',
            currentStep === step ? 'bg-blue-500' : 'bg-gray-300',
            index <= steps.indexOf(currentStep) ? 'bg-blue-500' : ''
          )}
        />
      ))}
    </div>
  );
};

export default StepIndicator;