import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Plane, Calendar, CheckCircle, AlertCircle, Luggage, Clock, Coffee, Users, HelpCircle, Book, Award, Bullseye, Download, ExternalLink, ChevronDown } from 'lucide-react';

// Custom Accordion component
const Accordion = ({ children, title, icon }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 py-4">
      <button
        className="flex items-center justify-between w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-xl font-light flex items-center">
          {icon && React.createElement(icon, { className: "mr-2" })}
          {title}
        </span>
        <ChevronDown
          className={`w-5 h-5 transition-transform duration-200 ${
            isOpen ? 'transform rotate-180' : ''
          }`}
        />
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="mt-4"
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

// Custom Progress component
const Progress = ({ value }) => (
  <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700">
    <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${value}%` }}></div>
  </div>
);

// Custom Alert component
const Alert = ({ title, children }) => (
  <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4" role="alert">
    <p className="font-bold">{title}</p>
    <p>{children}</p>
  </div>
);

const journeyStages = [
  {
    id: 'booking',
    icon: Calendar,
    title: 'Rezervimi',
    description: 'Zgjidhni fluturimin tuaj dhe konfirmoni rezervimin.',
    rights: [
      'E drejta për informacion të qartë mbi çmimet dhe kushtet, përfshirë të gjitha tarifat e aplikueshme.',
      'E drejta për të anuluar rezervimin brenda 24 orëve pa pagesë (për rezervimet e bëra të paktën një javë para fluturimit).',
      'E drejta për t\'u informuar për ndryshimet e orarit të fluturimit.'
    ],
    tips: [
      'Krahasoni çmimet nga burime të ndryshme dhe lexoni me kujdes kushtet e anulimit.',
      'Konsideroni sigurimin e udhëtimit për mbrojtje shtesë.',
      'Ruani një kopje të konfirmimit të rezervimit dhe kushteve të biletës.',
      'Kontrolloni kërkesat e vizës dhe pasaportës për destinacionin tuaj.'
    ],
    stats: [
      { label: 'Pasagjerë që nuk i lexojnë kushtet e biletës', value: '23%' },
      { label: 'Rezervime të bëra përmes platformave online', value: '68%' },
      { label: 'Udhëtarë që blejnë sigurim udhëtimi', value: '35%' }
    ],
    scenarios: [
      {
        title: 'Ndryshim i çmimit pas rezervimit',
        description: 'Nëse çmimi i biletës ndryshon pas rezervimit, kompania ajrore duhet t\'ju informojë dhe t\'ju ofrojë mundësinë për të anuluar pa pagesë.',
        action: 'Kontaktoni menjëherë kompaninë ajrore dhe kërkoni sqarime. Nëse rritja e çmimit është e pajustifikuar, kërkoni anulimin pa pagesë.'
      },
      {
        title: 'Anulim i fluturimit nga kompania ajrore',
        description: 'Nëse kompania ajrore anulon fluturimin tuaj, keni të drejtë për rimbursim të plotë ose ri-rutim.',
        action: 'Zgjidhni midis rimbursimit dhe ri-rutimit. Nëse zgjidhni ri-rutimin, keni të drejtë për ndihmë (ushqim, pije, akomodim nëse është e nevojshme).'
      }
    ],
    regulation: {
      title: 'Rregullorja (EC) Nr 1008/2008',
      description: 'Kërkon transparencë të plotë të çmimeve, duke përfshirë të gjitha taksat dhe tarifat e pashmangshme.',
      link: 'https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32008R1008'
    },
    quiz: {
      question: 'Çfarë duhet të përfshijë çmimi final i biletës?',
      options: [
        'Vetëm tarifën bazë',
        'Tarifën bazë dhe taksat',
        'Tarifën bazë, taksat, dhe të gjitha tarifat e pashmangshme',
        'Vetëm tarifën bazë dhe tarifat opsionale'
      ],
      correctAnswer: 2,
      explanation: 'Sipas rregullores së BE-së, çmimi final i biletës duhet të përfshijë tarifën bazë, të gjitha taksat, dhe tarifat e pashmangshme. Kjo siguron transparencë të plotë për konsumatorët.'
    }
  },
  {
    id: 'check-in',
    icon: CheckCircle,
    title: 'Check-in',
    description: 'Regjistrohuni për fluturimin tuaj.',
    rights: [
      'E drejta për të bërë check-in pa pagesë shtesë, përfshirë check-in online ose në aeroport.',
      'E drejta për të zgjedhur vendin tuaj (mund të aplikohen tarifa për vende specifike).',
      'E drejta për ndihmë nëse keni nevoja të veçanta (p.sh. lëvizshmëri e kufizuar).'
    ],
    tips: [
      'Bëni check-in online për të kursyer kohë në aeroport.',
      'Kontrolloni kufizimet e bagazheve dhe përgatitini dokumentet e udhëtimit paraprakisht.',
      'Mbërrijni në aeroport të paktën 2 orë para fluturimit për fluturime ndërkombëtare.',
      'Nëse keni nevoja të veçanta, njoftoni kompaninë ajrore paraprakisht.'
    ],
    stats: [
      { label: 'Pasagjerë që preferojnë check-in online', value: '65%' },
      { label: 'Udhëtarë që mbërrijnë vonë për check-in', value: '12%' },
      { label: 'Pasagjerë që kërkojnë ndihmë të veçantë', value: '7%' }
    ],
    scenarios: [
      {
        title: 'Vonesë në check-in',
        description: 'Nëse mbërrini vonë për check-in, kompania ajrore mund t\'ju refuzojë hipjen në bord.',
        action: 'Kontaktoni menjëherë stafin e kompanisë ajrore. Ata mund t\'ju ndihmojnë të gjeni një zgjidhje alternative, por nuk janë të detyruar ta bëjnë këtë.'
      },
      {
        title: 'Problemet me dokumentacionin',
        description: 'Nëse keni probleme me dokumentet e udhëtimit gjatë check-in, mund t\'ju refuzohet hipja në bord.',
        action: 'Sigurohuni që të keni të gjitha dokumentet e nevojshme para se të shkoni në aeroport. Nëse hasni probleme, kërkoni ndihmë nga ambasada juaj.'
      }
    ],
    regulation: {
      title: 'Rregullorja (EC) Nr 1107/2006',
      description: 'Përcakton të drejtat e personave me aftësi të kufizuara dhe personave me lëvizshmëri të reduktuar kur udhëtojnë me avion.',
      link: 'https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32006R1107'
    },
    quiz: {
      question: 'Kur duhet të mbërrini në aeroport për një fluturim ndërkombëtar?',
      options: [
        '30 minuta para fluturimit',
        '1 orë para fluturimit',
        'Të paktën 2 orë para fluturimit',
        '4 orë para fluturimit'
      ],
      correctAnswer: 2,
      explanation: 'Për fluturime ndërkombëtare, rekomandohet të mbërrini në aeroport të paktën 2 orë para kohës së planifikuar të nisjes. Kjo ju jep kohë të mjaftueshme për check-in, kontrollet e sigurisë dhe procedurat e emigracionit.'
    }
  },
  {
    id: 'security',
    icon: Users,
    title: 'Kontrolli i Sigurisë',
    description: 'Kaloni kontrollin e sigurisë në aeroport.',
    rights: [
      'E drejta për trajtim me respekt dhe dinjitet gjatë kontrollit të sigurisë.',
      'E drejta për të kërkuar një kontroll privat nëse nevojitet një kontroll i detajuar fizik.',
      'E drejta për të refuzuar skanimin me valë milimetrike dhe të zgjedhni një metodë alternative kontrolli.'
    ],
    tips: [
      'Vishni këpucë të lehta për t\'u hequr.',
      'Vendosni lëngjet në qese transparente dhe sigurohuni që janë nën 100ml.',
      'Hiqni pajisjet elektronike dhe metalike para se të kaloni nëpër skaner.',
      'Mos bëni shaka në lidhje me sigurinë - mund të merren seriozisht dhe të shkaktojnë vonesa.'
    ],
    stats: [
      { label: 'Koha mesatare e kalimit të sigurisë', value: '12 minuta' },
      { label: 'Pasagjerë që harrojnë të heqin lëngjet nga çanta', value: '18%' },
      { label: 'Udhëtarë që kërkojnë kontroll alternativ', value: '3%' }
    ],
    scenarios: [
      {
        title: 'Konfiskimi i sendeve të ndaluara',
        description: 'Nëse keni sende të ndaluara në bagazhin tuaj të dorës, ato do të konfiskohen.',
        action: 'Nëse është e mundur, transferoni sendet në bagazhin e regjistruar ose lërini në ruajtje në aeroport. Nëse jo, duhet t\'i dorëzoni.'
      },
      {
        title: 'Kërkesë për kontroll shtesë',
        description: 'Mund t\'ju kërkohet të kaloni një kontroll shtesë nëse skaneri alarmohet.',
        action: 'Bashkëpunoni me stafin e sigurisë. Keni të drejtë të kërkoni një kontroll privat nëse është e nevojshme.'
      }
    ],
    regulation: {
      title: 'Rregullorja (EC) Nr 300/2008',
      description: 'Përcakton rregullat e përbashkëta në fushën e sigurisë së aviacionit civil.',
      link: 'https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32008R0300'
    },
    quiz: {
      question: 'Cila është sasia maksimale e lëngjeve që mund të mbani me vete në kabinë?',
      options: [
        '50 ml për kontejner',
        '100 ml për kontejner',
        '200 ml për kontejner',
        'Nuk ka kufizim'
      ],
      correctAnswer: 1,
      explanation: 'Rregullat e sigurisë lejojnë lëngje në kontejnerë deri në 100 ml secili. Këta kontejnerë duhet të vendosen në një qese plastike transparente me volum maksimal 1 litër.'
    }
  },
  {
    id: 'boarding',
    icon: Plane,
    title: 'Boarding',
    description: 'Hipni në avion për fluturimin tuaj.',
    rights: [
      'E drejta për ndihmë nëse keni lëvizshmëri të kufizuar.',
      'E drejta për informacion në rast vonese ose anulimi.',
      'E drejta për kompensim nëse jeni refuzuar në bord për shkak të mbingarkesës.'
    ],
    tips: [
      'Mbani dokumentet tuaja gati për kontroll.',
      'Dëgjoni me kujdes udhëzimet e ekuipazhit.',
      'Respektoni radhën e hipjes.',
      'Vendosni bagazhin e dorës në hapësirën mbi kokë ose nën ndenjësen përpara jush.'
    ],
    stats: [
      { label: 'Vonesa mesatare e boarding', value: '15 minuta' },
      { label: 'Pasagjerë që refuzohen për shkak të mbingarkesës', value: '0.1%' },
      { label: 'Udhëtarë që kërkojnë ndihmë gjatë boarding', value: '5%' }
    ],
    scenarios: [
      {
        title: 'Refuzimi i hipjes për shkak të mbingarkesës',
        description: 'Nëse jeni refuzuar në bord për shkak të mbingarkesës, keni të drejtë për kompensim dhe ndihmë.',
        action: 'Kërkoni kompensimin tuaj dhe ndihmë për ushqim, pije, dhe akomodim nëse është e nevojshme. Kërkoni ri-rutim në fluturimin e ardhshëm të disponueshëm.'
      },
      {
        title: 'Vonesë në boarding',
        description: 'Nëse ka vonesë në procesin e boarding, keni të drejtë të informoheni për arsyen dhe kohëzgjatjen e pritshme.',
        action: 'Qëndroni pranë portës së boarding dhe dëgjoni me kujdes njoftimet. Mos hezitoni të pyesni stafin për informacion të përditësuar.'
      }
    ],
    regulation: {
      title: 'Rregullorja (EC) 261/2004',
      description: 'Përcakton rregullat e kompensimit dhe ndihmës për pasagjerët në rast të refuzimit të hipjes dhe të anulimit ose vonesës së gjatë të fluturimeve.',
      link: 'https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32004R0261'
    },
    quiz: {
      question: 'Çfarë kompensimi mund të merrni nëse refuzoheni në bord për një fluturim mbi 3500 km?',
      options: [
        '250€',
        '400€',
        '600€',
        'Asnjë kompensim'
      ],
      correctAnswer: 2,
      explanation: 'Sipas Rregullores EC 261/2004, për fluturime mbi 3500 km, kompensimi për refuzim të hipjes është 600€, përveç nëse ju është ofruar një rrugë alternative që arrin në destinacionin final me jo më shumë se 4 orë vonesë.'
    }
  },
  {
    id: 'in-flight',
    icon: Coffee,
    title: 'Gjatë Fluturimit',
    description: 'Udhëtimi juaj ajror.',
    rights: [
      'E drejta për trajtim të duhur dhe shërbime bazë.',
      'E drejta për informacion për vonesat ose ndryshimet e destinacionit.',
      'E drejta për ndihmë mjekësore nëse është e nevojshme.'
    ],
    tips: [
      'Pini ujë rregullisht për të qëndruar të hidratuar.',
      'Lëvizni këmbët dhe ecni periodikisht për të përmirësuar qarkullimin.',
      'Ndiqni udhëzimet e ekuipazhit, veçanërisht gjatë turbulencës.',
      'Respektoni hapësirën personale të pasagjerëve të tjerë.'
    ],
    stats: [
      { label: 'Pasagjerë që raportojnë përvojë pozitive në fluturim', value: '80%' },
      { label: 'Udhëtarë që përjetojnë ankth gjatë fluturimit', value: '25%' },
      { label: 'Incidente mjekësore në fluturim (për 1 milion pasagjerë)', value: '16' }
    ],
    scenarios: [
      {
        title: 'Turbulencë e fortë',
        description: 'Gjatë turbulencës së fortë, siguria juaj është prioriteti kryesor.',
        action: 'Kthehuni në vendin tuaj menjëherë dhe lidhni rripin e sigurimit. Ndiqni udhëzimet e ekuipazhit të kabinës.'
      },
      {
        title: 'Emergjencë mjekësore',
        description: 'Nëse ju ose një pasagjer tjetër keni nevojë për ndihmë mjekësore, ekuipazhi është i trajnuar për të ofruar ndihmë të parë.',
        action: 'Informoni menjëherë një anëtar të ekuipazhit. Ata mund të kërkojnë ndihmën e mjekëve në bord nëse ka.'
      }
    ],
    regulation: {
      title: 'Konventa e Montrealit',
      description: 'Rregullon përgjegjësinë e kompanive ajrore në rast aksidenti dhe për dëmtimin e bagazheve.',
      link: 'https://www.iata.org/en/policy/smarter-regulation/montreal-convention/'
    },
    quiz: {
      question: 'Çfarë duhet të bëni nëse ndiheni keq gjatë fluturimit?',
      options: [
        'Prisni derisa të uleni',
        'Informoni menjëherë ekuipazhin e kabinës',
        'Merrni ilaçe pa konsultuar askënd',
        'Injoroni simptomat'
      ],
      correctAnswer: 1,
      explanation: 'Është gjithmonë më mirë të informoni menjëherë ekuipazhin e kabinës nëse ndiheni keq. Ata janë të trajnuar për të ofruar ndihmë dhe mund të vlerësojnë nëse nevojitet ndihmë mjekësore shtesë.'
    }
  },
  {
    id: 'arrival',
    icon: Luggage,
    title: 'Mbërritja',
    description: 'Mbërrini në destinacionin tuaj.',
    rights: [
      'E drejta për të marrë bagazhet tuaja në gjendje të mirë.',
      'E drejta për kompensim për bagazhet e humbura, të dëmtuara ose të vonuara.',
      'E drejta për ndihmë nëse keni nevoja të veçanta (p.sh. lëvizshmëri e kufizuar).'
    ],
    tips: [
      'Kontrolloni bagazhin tuaj menjëherë për dëmtime.',
      'Raportoni çdo problem me bagazhin para se të largoheni nga aeroporti.',
      'Mbani dokumentet e udhëtimit gati për kontrollin e pasaportës.',
      'Familjarizohuni me rregullat doganore të vendit ku po mbërrini.'
    ],
    stats: [
      { label: 'Bagazhe që humbasin ose dëmtohen', value: '2%' },
      { label: 'Pasagjerë që raportojnë vonesë në marrjen e bagazheve', value: '15%' },
      { label: 'Udhëtarë që kalojnë doganën pa probleme', value: '98%' }
    ],
    scenarios: [
      {
        title: 'Bagazh i humbur ose i dëmtuar',
        description: 'Nëse bagazhi juaj është i humbur, i dëmtuar ose vonohet, keni të drejtë për kompensim.',
        action: 'Raportoni problemin menjëherë në zyrën e shërbimit të bagazheve të kompanisë ajrore. Plotësoni një raport për Artikujt e Bagazhit të Parregullt (PIR) dhe mbani një kopje.'
      },
      {
        title: 'Probleme në kontrollin e pasaportës',
        description: 'Nëse hasni probleme gjatë kontrollit të pasaportës, qëndroni i qetë dhe bashkëpunues.',
        action: 'Ofroni informacionin e kërkuar. Nëse problemi vazhdon, kërkoni të flisni me një zyrtar të lartë ose kontaktoni ambasadën tuaj.'
      }
    ],
    regulation: {
      title: 'Konventa e Montrealit',
      description: 'Përcakton përgjegjësinë e kompanive ajrore për humbjen, dëmtimin ose vonesën e bagazheve.',
      link: 'https://www.iata.org/en/policy/smarter-regulation/montreal-convention/'
    },
    quiz: {
      question: 'Brenda sa kohësh duhet të raportoni një bagazh të humbur?',
      options: [
        'Menjëherë në aeroport',
        'Brenda 24 orëve',
        'Brenda 7 ditëve',
        'Brenda 21 ditëve'
      ],
      correctAnswer: 0,
      explanation: 'Duhet të raportoni menjëherë në aeroport nëse bagazhi juaj është i humbur. Vonesa në raportim mund të komplikojë procesin e gjetjes dhe kompensimit.'
    }
  },
  {
    id: 'disruption',
    icon: AlertCircle,
    title: 'Ndërprerje',
    description: 'Në rast vonese, anulimi, ose mbingarkese.',
    rights: [
      'E drejta për kompensim financiar në raste të caktuara.',
      'E drejta për ri-rregullim të udhëtimit ose rimbursim.',
      'E drejta për ndihmë (ushqim, pije, akomodim) gjatë pritjes së gjatë.'
    ],
    tips: [
      'Ruani të gjitha faturat për shpenzimet shtesë të shkaktuara nga ndërprerja.',
      'Kërkoni konfirmim me shkrim për arsyen e ndërprerjes.',
      'Kontrolloni politikat e kompanisë ajrore dhe të drejtat tuaja sipas rregulloreve.',
      'Konsideroni blerjen e sigurimit të udhëtimit për mbrojtje shtesë.'
    ],
    stats: [
      { label: 'Fluturime me vonesë mbi 15 minuta', value: '20%' },
      { label: 'Pasagjerë që kërkojnë kompensim për ndërprerje', value: '8%' },
      { label: 'Kompensimi mesatar i paguar për ndërprerje', value: '400€' }
    ],
    scenarios: [
      {
        title: 'Vonesë e gjatë',
        description: 'Për vonesa mbi 3 orë, keni të drejtë për kompensim të ngjashëm me atë të fluturimeve të anuluara.',
        action: 'Kërkoni ndihmë nga kompania ajrore për ushqim dhe pije. Nëse vonesa zgjat natën, kërkoni akomodim. Mblidhni prova të vonesës dhe aplikoni për kompensim.'
      },
      {
        title: 'Anulim i fluturimit',
        description: 'Në rast anulimi, keni të drejtë për rimbursim të plotë ose ri-rutim, plus kompensim në raste të caktuara.',
        action: 'Zgjidhni midis rimbursimit dhe ri-rutimit. Kërkoni ndihmë për nevojat tuaja bazë. Aplikoni për kompensim nëse jeni njoftuar me pak njoftim paraprak.'
      }
    ],
    regulation: {
      title: 'Rregullorja (EC) 261/2004',
      description: 'Përcakton të drejtat e pasagjerëve në rast të refuzimit të hipjes dhe të anulimit ose vonesës së gjatë të fluturimeve.',
      link: 'https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32004R0261'
    },
    quiz: {
      question: 'Për çfarë distance fluturimi aplikohet kompensimi maksimal 600€?',
      options: [
        'Të gjitha distancat',
        'Mbi 1500 km',
        'Mbi 3000 km',
        'Mbi 3500 km'
      ],
      correctAnswer: 3,
      explanation: 'Kompensimi maksimal prej 600€ aplikohet për fluturime mbi 3500 km. Kjo vlen në rast të vonesave të gjata, anulimeve, ose refuzimit të hipjes, përveç rasteve të rrethanave të jashtëzakonshme.'
    }
  }
];

const StageInfo = ({ stage, onQuizSubmit }) => {
  const [quizAnswer, setQuizAnswer] = useState(null);
  const [showQuizResult, setShowQuizResult] = useState(false);
  const [expandedScenario, setExpandedScenario] = useState(null);

  const handleQuizSubmit = () => {
    setShowQuizResult(true);
    onQuizSubmit(quizAnswer === stage.quiz.correctAnswer);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
      className="bg-white rounded-lg shadow-lg p-4 sm:p-6 md:p-8 mt-4 sm:mt-8"
    >
      <h3 className="text-2xl sm:text-3xl font-light mb-2 sm:mb-4">{stage.title}</h3>
      <p className="text-lg sm:text-xl text-medium-gray mb-4 sm:mb-6">{stage.description}</p>
      
      <div className="grid grid-cols-1 gap-4 sm:gap-6 md:gap-8 mb-4 sm:mb-6 md:mb-8">
        <div>
          <Accordion title="Të Drejtat Tuaja" icon={HelpCircle}>
            <ul className="list-disc pl-5 space-y-2">
              {stage.rights.map((right, index) => (
                <li key={index} className="text-base sm:text-lg text-medium-gray">{right}</li>
              ))}
            </ul>
          </Accordion>
          
          <Accordion title="Këshilla" icon={Award}>
            <ul className="list-disc pl-5 space-y-2">
              {stage.tips.map((tip, index) => (
                <li key={index} className="text-base sm:text-lg text-medium-gray">{tip}</li>
              ))}
            </ul>
          </Accordion>
        </div>
        
        <div>
          <Accordion title="Rregullore" icon={Book}>
            <h5 className="text-lg font-semibold mb-2">{stage.regulation.title}</h5>
            <p className="text-base sm:text-lg text-medium-gray mb-2">{stage.regulation.description}</p>
            <a 
              href={stage.regulation.link} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-blue-500 hover:underline flex items-center"
            >
              Lexo më shumë <ExternalLink className="ml-1 w-4 h-4" />
            </a>
          </Accordion>
          
          <Accordion title="Skenarë" icon={AlertCircle}>
            {stage.scenarios.map((scenario, index) => (
              <div key={index} className="mb-4">
                <h5 
                  className="text-lg font-semibold mb-2 cursor-pointer hover:text-blue-500"
                  onClick={() => setExpandedScenario(expandedScenario === index ? null : index)}
                >
                  {scenario.title}
                </h5>
                {expandedScenario === index && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <p className="text-base sm:text-lg text-medium-gray mb-2">{scenario.description}</p>
                    <Alert title="Veprim i Rekomanduar">
                      {scenario.action}
                    </Alert>
                  </motion.div>
                )}
              </div>
            ))}
          </Accordion>
        </div>
      </div>

      <div className="bg-light-gray p-4 sm:p-6 rounded-lg mb-8">
        <h4 className="text-xl font-light mb-4">Statistika</h4>
        {stage.stats.map((stat, index) => (
          <div key={index} className="mb-4">
            <div className="flex justify-between mb-1">
              <span className="text-base sm:text-lg text-medium-gray">{stat.label}</span>
              <span className="text-base sm:text-lg font-semibold">{stat.value}</span>
            </div>
            <Progress value={parseInt(stat.value)} />
          </div>
        ))}
      </div>

      <div className="bg-blue-50 p-4 sm:p-6 rounded-lg">
        <h4 className="text-xl font-light mb-4">Kuiz</h4>
        <p className="text-base sm:text-lg mb-4">{stage.quiz.question}</p>
        <div className="space-y-2">
          {stage.quiz.options.map((option, index) => (
            <button
              key={index}
              onClick={() => setQuizAnswer(index)}
              className={`w-full text-left p-3 rounded ${
                quizAnswer === index ? 'bg-blue-200' : 'bg-white'
              } hover:bg-blue-100 transition-colors duration-300`}
            >
              {option}
            </button>
          ))}
        </div>
        {!showQuizResult && (
          <button
            onClick={handleQuizSubmit}
            className="mt-4 bg-black text-white px-6 py-2 rounded-full text-base sm:text-lg font-light hover:bg-opacity-80 transition duration-300"
          >
            Kontrollo Përgjigjen
          </button>
        )}
        {showQuizResult && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <p className={`mt-4 text-lg ${quizAnswer === stage.quiz.correctAnswer ? 'text-green-600' : 'text-red-600'}`}>
              {quizAnswer === stage.quiz.correctAnswer ? 'Saktë!' : 'Gabim. Provoni përsëri!'}
            </p>
            <p className="mt-2 text-base sm:text-lg text-medium-gray">{stage.quiz.explanation}</p>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

const InteractiveFlightJourneyTimeline = () => {
  const [selectedStage, setSelectedStage] = useState(null);
  const [quizScore, setQuizScore] = useState(0);
  const [quizAttempts, setQuizAttempts] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const completedStages = journeyStages.filter(stage => 
      localStorage.getItem(`stage_${stage.id}_completed`) === 'true'
    ).length;
    setProgress((completedStages / journeyStages.length) * 100);
  }, [selectedStage]);

  const handleQuizSubmit = (isCorrect) => {
    setQuizAttempts(quizAttempts + 1);
    if (isCorrect) {
      setQuizScore(quizScore + 1);
      localStorage.setItem(`stage_${selectedStage}_completed`, 'true');
    }
  };

  const handleDownloadCertificate = () => {
    // Logic to generate and download a certificate
    console.log("Downloading certificate...");
  };

  return (
    <section className="py-8 sm:py-16 md:py-24 bg-white">
      <div className="max-w-xl sm:max-w-3xl md:max-w-4xl lg:max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-light mb-4 sm:mb-8 md:mb-12 text-center">Udhëtimi Juaj, Të Drejtat Tuaja</h2>
        <p className="text-lg sm:text-xl md:text-2xl mb-8 sm:mb-12 md:mb-16 text-center text-medium-gray">Eksploroni çdo fazë të udhëtimit tuaj ajror dhe mësoni për të drejtat tuaja</p>

        <div className="mb-6 sm:mb-8">
          <h3 className="text-xl sm:text-2xl font-light mb-2 sm:mb-4">Progresi juaj</h3>
          <Progress value={progress} />
          <p className="mt-2 text-base sm:text-lg text-medium-gray">{Math.round(progress)}% e plotësuar</p>
        </div>

        <div className="relative mb-8 sm:mb-12 md:mb-16 overflow-x-auto pb-4">
          <div className="absolute top-1/2 left-0 right-0 h-1 bg-light-gray transform -translate-y-1/2"></div>
          <div className="relative flex justify-between min-w-max">
            {journeyStages.map((stage, index) => (
              <button
                key={stage.id}
                className="relative z-10 flex flex-col items-center focus:outline-none group px-2 sm:px-4"
                onClick={() => setSelectedStage(stage.id === selectedStage ? null : stage.id)}
              >
                <div className={`w-12 h-12 sm:w-16 sm:h-16 rounded-full flex items-center justify-center bg-light-gray transition-all duration-300 group-hover:bg-black ${selectedStage === stage.id ? 'bg-black' : ''}`}>
                  <stage.icon className={`w-6 h-6 sm:w-8 sm:h-8 text-black transition-colors duration-300 group-hover:text-white ${selectedStage === stage.id ? 'text-white' : ''}`} />
                </div>
                <p className={`mt-2 text-sm sm:text-base md:text-lg font-light ${selectedStage === stage.id ? 'text-black' : 'text-medium-gray'} group-hover:text-black transition-colors duration-300`}>
                  {stage.title}
                </p>
              </button>
            ))}
          </div>
        </div>

        <AnimatePresence>
          {selectedStage && (
            <StageInfo 
              stage={journeyStages.find(s => s.id === selectedStage)} 
              onQuizSubmit={handleQuizSubmit}
            />
          )}
        </AnimatePresence>

        <div className="mt-8 sm:mt-12 md:mt-16 text-center">
          <p className="text-xl sm:text-2xl text-medium-gray mb-2 sm:mb-4">
            Rezultati juaj në kuiz: {quizScore} / {quizAttempts}
          </p>
          <p className="text-lg sm:text-xl md:text-2xl text-medium-gray mb-4 sm:mb-6 md:mb-8">
            Njohja e të drejtave tuaja është hapi i parë. Lini Avioza t'ju ndihmojë t'i zbatoni ato në çdo fazë të udhëtimit tuaj.
          </p>
          {progress === 100 && (
            <button 
              onClick={handleDownloadCertificate}
              className="inline-block bg-green-500 text-white px-6 sm:px-8 md:px-12 py-3 sm:py-4 rounded-full text-base sm:text-lg font-light hover:bg-green-600 transition duration-300 mb-4 sm:mb-6 md:mb-8"
            >
              <Download className="inline-block mr-2" />
              Shkarko Certifikatën e Plotësimit
            </button>
          )}
          <a 
            href="#" 
            className="inline-block bg-black text-white px-6 sm:px-8 md:px-12 py-3 sm:py-4 rounded-full text-base sm:text-lg font-light hover:bg-opacity-80 transition duration-300"
          >
            Kontrolloni të Drejtën Tuaj për Kompensim
          </a>
        </div>
      </div>
    </section>
  );
};

export default InteractiveFlightJourneyTimeline;