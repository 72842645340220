import React from 'react';
import { DocumentIcon, ClockIcon, CurrencyEuroIcon } from '@heroicons/react/24/outline';

const SummaryCard = ({ title, value, icon: Icon }) => (
  <div className="bg-[#F5F5F7] rounded-3xl p-6 md:p-8 transition duration-300 ease-in-out hover:shadow-lg">
    <h2 className="text-lg md:text-xl font-medium text-[#86868B] mb-2">{title}</h2>
    <div className="flex items-center">
      <Icon className="h-8 w-8 text-[#1D1D1F] mr-3" />
      <p className="text-3xl md:text-4xl lg:text-5xl font-semibold text-[#1D1D1F]">{value}</p>
    </div>
  </div>
);

const SummaryCards = ({ compensations, userRole }) => {
  const totalClaims = compensations.length;
  const pendingClaims = compensations.filter(claim => claim.status === 'pending').length;
  const totalCompensation = compensations.reduce((sum, claim) => sum + parseFloat(claim.amount), 0).toFixed(2);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-12 md:mb-16">
      <SummaryCard title="Kërkesat Totale" value={totalClaims} icon={DocumentIcon} />
      <SummaryCard title="Kërkesat në Pritje" value={pendingClaims} icon={ClockIcon} />
      <SummaryCard title="Kompensimi Total" value={`€${totalCompensation}`} icon={CurrencyEuroIcon} />
      {userRole === 'partner' && (
        <SummaryCard title="Fitimet Totale" value="€0.00" icon={CurrencyEuroIcon} />
      )}
    </div>
  );
};

export default SummaryCards;